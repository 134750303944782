define("fcs-dashboard/pods/gateway/model", ["exports", "ember", "ember-data/model", "ember-data/attr", "ember-data/relationships", "model-fragments/attributes", "ember-cp-validations"], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberDataRelationships, _modelFragmentsAttributes, _emberCpValidations) {
  var computed = _ember["default"].computed;

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'gateways.details.name.label' }),
    gatewayEnvironment: (0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'gateways.details.environment.label' }),
    processorType: (0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'gateways.details.processorType.label' }),
    processor: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      descriptionKey: 'gateways.details.processor.label',
      disabled: computed(function () {
        var disabled = true;
        if (this.get('model.processorType.id') !== undefined) {
          disabled = false;
        }
        return disabled;
      }).volatile()
    })
  });

  exports["default"] = _emberDataModel["default"].extend(Validations, {
    name: (0, _emberDataAttr["default"])('string'),
    enabled: (0, _emberDataAttr["default"])('boolean'),
    failoverEnabled: (0, _emberDataAttr["default"])('boolean'),
    backupCreditCardGatewayId: (0, _emberDataAttr["default"])('string', { defaultValue: null }),
    gatewayEnvironment: (0, _emberDataRelationships.belongsTo)('gatewayEnvironment'),
    processorType: (0, _emberDataRelationships.belongsTo)('processorType'),
    processor: (0, _emberDataRelationships.belongsTo)('processor'),
    credentials: (0, _modelFragmentsAttributes.fragmentArray)('processorCredential'),
    menuLocations: (0, _modelFragmentsAttributes.fragmentArray)('gatewayMenuLocation'),
    gatewaySubmerchants: (0, _emberDataRelationships.hasMany)('gatewaySubmerchant'),
    intl: _ember["default"].inject.service(),
    translatedStatus: computed('enabled', function () {
      var status = undefined;
      if (this.get('enabled')) {
        status = this.get('intl').t("gateways.list.status.enabled");
      } else {
        status = this.get('intl').t("gateways.list.status.disabled");
      }
      return status;
    })
  });
});