define("fcs-dashboard/initializers/coordinator-setup", ["exports", "fcs-dashboard/models/coordinator"], function (exports, _fcsDashboardModelsCoordinator) {
  exports["default"] = {
    name: "setup coordinator",

    initialize: function initialize() {
      var app = arguments[1] || arguments[0];
      app.register("drag:coordinator", _fcsDashboardModelsCoordinator["default"]);
      app.inject("component", "coordinator", "drag:coordinator");
    }
  };
});