define("fcs-dashboard/pods/password-reset/route", ["exports", "ember", "ember-simple-auth/mixins/application-route-mixin"], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin) {
  var Route = _ember["default"].Route;
  exports["default"] = Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin["default"], {
    beforeModel: function beforeModel(transition) {
      if (this.get('session.isAuthenticated')) {
        this.get('session').invalidate();
      }
      this.transitionTo(transition);
    },
    setupController: function setupController(controller) {
      controller.set('token', null);
      controller.set('showPasswordOptions', false);
    },
    model: function model(params) {
      var _this = this;

      console.log("Loading data for menu " + params.session_token);

      var path = '/api/v1/passwordResets/' + params.session_token;
      var _window$location = window.location;
      var protocol = _window$location.protocol;
      var host = _window$location.host;

      var url = protocol + "//" + host + path;
      var options = {
        url: url,
        type: 'GET'
      };
      options.success = function (result) {
        if (result !== undefined && result !== null) {
          _this.controller.set('token', params.session_token);
          _this.controller.set('showPasswordOptions', true);
        }
      };
      options.error = function () {
        console.log('error');
      };
      _ember["default"].$.ajax(options);
    },
    actions: {
      willTransition: function willTransition() {
        window.stop();
        return true;
      },
      transitionToLogin: function transitionToLogin() {
        this.transitionTo('login');
      }
    }
  });
});