define("fcs-dashboard/pods/components/location-group/details/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "moment"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _moment) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var htmlSafe = _ember["default"].String.htmlSafe;
  var Logger = _ember["default"].Logger;
  var isEqual = _ember["default"].isEqual;
  var run = _ember["default"].run;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], {
    companiesLoaded: false,
    companyRolesLoaded: false,
    showDeleteCustomFeeModal: false,
    selectedGateway: null,
    locationGroupCompanies: [],
    reportsToCompanies: [],
    needRefreshPromotionsTable: true,
    _isV4Processor: false,
    tagName: '',
    _timeZones: computed(function () {
      var timeZones = _moment["default"].tz.names();
      var filteredTimezones = [];
      var reg = new RegExp('(?:.*[/].*)');
      timeZones.forEach(function (timezone) {
        if (reg.test(timezone)) {
          filteredTimezones.push(timezone);
        }
      });
      return filteredTimezones.sort();
    }),
    filteredCustomFees: [],
    _locationTypes: computed(function () {
      return this.get('store').findAll('locationType');
    }),
    _customFeeAppliesTos: computed(function () {
      return this.get('store').findAll('customFeeAppliesTo');
    }),
    _customFeeTypes: computed(function () {
      return this.get('store').findAll('customFeeType');
    }),
    _customPaymentTypes: computed(function () {
      return this.get('store').findAll('customPaymentType');
    }),
    _promotionTypes: computed(function () {
      return this.get('store').findAll('promotionType');
    }),
    _promotionRedemptionRules: computed(function () {
      return this.get('store').findAll('promotionRedemptionRule');
    }),
    _promotionStatuses: computed(function () {
      return this.get('store').findAll('promotionStatus');
    }),
    _promotionTriggerTypes: computed(function () {
      return this.get('store').findAll('promotionTriggerType');
    }),
    _creditCards: computed(function () {
      return this.get('store').findAll('creditCardType');
    }),
    _promotionColumns: computed(function () {
      return [{
        title: '',
        template: "promotion/actions/edit-action",
        className: 'editRow'
      }, {
        propertyName: 'name',
        title: this.get('intl').t('locationGroups.details.promotions.list.table.name')
      }, {
        propertyName: 'status.name',
        title: this.get('intl').t('locationGroups.details.promotions.list.table.status')
      }, {
        propertyName: 'type.name',
        title: this.get('intl').t('locationGroups.details.promotions.list.table.type')
      }, {
        propertyName: 'promotionRedemptionRule.name',
        title: this.get('intl').t('locationGroups.details.promotions.list.table.promotionRedemptionRule')
      }, {
        propertyName: 'receiptDisplay',
        title: this.get('intl').t('locationGroups.details.promotions.list.table.receiptDisplay')
      }, {
        title: this.get('intl').t('locationGroups.details.promotions.list.table.active'),
        template: "promotion/actions/activate-action",
        className: 'editRow'
      }];
    }),
    _customFeeColumns: computed(function () {
      return [{
        title: '',
        template: "custom-fee/actions/edit-action",
        className: 'editRow'
      }, {
        propertyName: 'name',
        title: this.get('intl').t('locationGroups.details.customFees.list.table.name')
      }, {
        propertyName: 'type.name',
        title: this.get('intl').t('locationGroups.details.customFees.list.table.type')
      }, {
        propertyName: 'value',
        title: this.get('intl').t('locationGroups.details.customFees.list.table.value')
      }, {
        propertyName: 'appliesTo.name',
        title: this.get('intl').t('locationGroups.details.customFees.list.table.appliesTo')
      }, {
        propertyName: 'taxable',
        title: this.get('intl').t('locationGroups.details.customFees.list.table.taxable'),
        template: "custom-fee/actions/taxable-action",
        className: 'editRow'
      }, {
        title: this.get('intl').t('locationGroups.details.customFees.list.table.enable'),
        template: "custom-fee/actions/enable-action",
        className: 'editRow'
      }, {
        template: "custom-fee/actions/delete-action",
        className: 'editRow'
      }];
    }),
    _typeOptions: computed(function () {
      return this.get('store').peekAll('locationGroupType');
    }),
    loadReady: computed('companiesLoaded', 'companyRolesLoaded', function () {
      return this.get('companiesLoaded') && this.get('companyRolesLoaded');
    }),
    _gateways: computed(function () {
      return this.get('store').query('gateway', { cashless: true, pageSize: -1 }).then(function (results) {
        var resultsArray = results.toArray();
        return resultsArray.filter(function (gateway) {
          return gateway.get('processorType.id') == 1;
        });
      });
    }),
    logoName: computed('locationGroup.logo', function () {
      return this.get('locationGroup.logo') ? 'logo' : null;
    }),
    _isEvent: computed('locationGroup.locationGroupType', function () {
      return isEqual(this.get('locationGroup.locationGroupType.name'), 'Event');
    }),
    logoPreview: computed('locationGroup.logo', function () {
      var url = this.get('locationGroup.logo');
      return htmlSafe("background-image: url(\"" + url + "\")");
    }),
    _showModal: false,
    _showEndDate: computed('locationGroup.startDate', function () {
      return this.get('locationGroup.startDate') !== null;
    }),
    companies: computed(function () {
      return this.get('store').query('company', { pageSize: -1 });
    }),
    roles: computed(function () {
      return this.get('store').findAll('roleType');
    }),
    minStartDate: computed(function () {
      return new Date();
    }),
    _selectedLocationGroupType: computed.alias('locationGroup.locationGroupType'),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('locationGroup.startDate', new Date(_moment["default"].tz(this.get('locationGroup.startDate'), "UTC").format('MM/DD/YYYY')));
      this.set('locationGroup.endDate', new Date(_moment["default"].tz(this.get('locationGroup.endDate'), "UTC").format('MM/DD/YYYY')));
    },
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var promos = [];
      this.get('locationGroup.promotions').forEach(function (promotion) {
        if (promotion.get('id') !== null && promotion.get('id') !== undefined) {
          promos.addObject(promotion);
        }
      });
      this.set('locationGroup.promotions', promos.sortBy('name'));
      this.get('reportsToCompanies').clear();
      this._initializeLocationTypes();
      this._initializeCompanies();
      this._initializeCompanyRoles();
      this._initializePromotionTypes();
      this._initializePromotionStatuses();
      this._initializeCustomFeeAppliesTos();
      this._initializeCustomFeeTypes();
      this._initializeCustomPaymentTypes();
      this._initializePromotionRules();
      this._initializeCreditCardTypes();
      this._initializePromotionTriggerTypes();
      this.get('filteredCustomFees').clear();
      this.get('locationGroup.customFees').forEach(function (customFee) {
        if (customFee.get('id') !== null) {
          _this.get('filteredCustomFees').addObject(customFee);
        } else {
          _this.get('locationGroup.customFees').removeObject(customFee);
        }
      });
      this.set('refreshCustomFees', true);
      var selectedGateway = undefined;
      if (!_ember["default"].isEmpty(this.get('locationGroup.cashlessInformation.gateway')) && this.get('locationGroup.cashlessInformation.gateway') !== 0) {
        selectedGateway = this.get('store').findRecord('gateway', this.get('locationGroup.cashlessInformation.gateway'));
      }
      if (selectedGateway !== undefined && selectedGateway.then !== undefined) {
        selectedGateway.then(function (content) {
          run(function () {
            _this.set('selectedGateway', content);
            if (_this.get('selectedGateway.processor').then !== undefined) {
              _this.get('selectedGateway.processor').then(function (content) {
                run(function () {
                  if (content.name === "FGT") {
                    _this.set('_isV4Processor', false);
                  } else {
                    _this.set('_isV4Processor', true);
                  }
                });
              });
            }
          });
        });
      }
    },
    _initializeLocationTypes: function _initializeLocationTypes() {
      var _this2 = this;

      Logger.debug('Loading Location Types');
      if (this.get('_locationTypes').then !== undefined) {
        this.get('_locationTypes').then(function (content) {
          run(function () {
            Logger.debug('Loading Location Types');
            _this2.set('_locationTypes', content);
          });
        });
      }
    },
    _filterCustomFees: function _filterCustomFees() {
      this.set('refreshCustomFees', false);
      this.get('filteredCustomFees').clear();
      this.get('filteredCustomFees').addObjects(this.get('locationGroup.customFees').filterBy('deleted', false));
      this.set('refreshCustomFees', true);
    },
    _initializeCompanies: function _initializeCompanies() {
      var _this3 = this;

      Logger.debug('Loading Companies for Company Roles');
      if (this.get('companies').then !== undefined) {
        this.get('companies').then(function (content) {
          run(function () {
            Logger.debug('Loaded Companies for Company Roles');
            _this3.set('companies', content);
            content.forEach(function (company) {
              _this3.get('reportsToCompanies').addObject({ id: company.id, name: company.get('name') });
            });
            _this3.set('companiesLoaded', true);
          });
        });
      }
    },
    _initializeCompanyRoles: function _initializeCompanyRoles() {
      var _this4 = this;

      Logger.debug('Loading Company Roles');
      if (this.get('roles').then !== undefined) {
        this.get('roles').then(function (content) {
          run(function () {
            Logger.debug('Loaded Company Roles');
            _this4.set('roles', content);
            _this4.set('companyRolesLoaded', true);
          });
        });
      }
    },
    _initializePromotionTypes: function _initializePromotionTypes() {
      var _this5 = this;

      Logger.debug('Loading Promotion Types');
      if (this.get('_promotionTypes').then !== undefined) {
        this.get('_promotionTypes').then(function (content) {
          run(function () {
            Logger.debug('Loading Promotion Types');
            _this5.set('_promotionTypes', content);
          });
        });
      }
    },
    _initializePromotionTriggerTypes: function _initializePromotionTriggerTypes() {
      var _this6 = this;

      Logger.debug('Loading Promotion Trigger Types');
      if (this.get('_promotionTriggerTypes').then !== undefined) {
        this.get('_promotionTriggerTypes').then(function (content) {
          run(function () {
            Logger.debug('Loading Promotion Trigger Types');
            _this6.set('_promotionTriggerTypes', content);
          });
        });
      }
    },
    _initializeCustomFeeAppliesTos: function _initializeCustomFeeAppliesTos() {
      var _this7 = this;

      Logger.debug('Loading Custom Fee Applies Tos');
      if (this.get('_customFeeAppliesTos').then !== undefined) {
        this.get('_customFeeAppliesTos').then(function (content) {
          run(function () {
            Logger.debug('Loading Custom Fee Applies Tos');
            _this7.set('_customFeeAppliesTos', content);
          });
        });
      }
    },
    _initializeCustomFeeTypes: function _initializeCustomFeeTypes() {
      var _this8 = this;

      Logger.debug('Loading Custom Fee Types');
      if (this.get('_customFeeTypes').then !== undefined) {
        this.get('_customFeeTypes').then(function (content) {
          run(function () {
            Logger.debug('Loading Custom Fee Types');
            _this8.set('_customFeeTypes', content);
          });
        });
      }
    },
    _initializeCustomPaymentTypes: function _initializeCustomPaymentTypes() {
      var _this9 = this;

      Logger.debug('Loading Custom Payment Types');
      if (this.get('_customPaymentTypes').then !== undefined) {
        this.get('_customPaymentTypes').then(function (content) {
          run(function () {
            _this9.set('_customPaymentTypes', content);
          });
        });
      }
    },
    _initializeCreditCardTypes: function _initializeCreditCardTypes() {
      var _this10 = this;

      Logger.debug('Loading Credit Card Types');
      if (this.get('_creditCards').then !== undefined) {
        this.get('_creditCards').then(function (content) {
          run(function () {
            Logger.debug('Loading Credit Card Types');
            _this10.set('_creditCards', content);
          });
        });
      }
    },
    _initializePromotionRules: function _initializePromotionRules() {
      var _this11 = this;

      Logger.debug('Loading Promotion Rules');
      if (this.get('_promotionRedemptionRules').then !== undefined) {
        this.get('_promotionRedemptionRules').then(function (content) {
          run(function () {
            Logger.debug('Loading Promotion Types');
            _this11.set('_promotionRedemptionRules', content);
          });
        });
      }
    },
    _initializePromotionStatuses: function _initializePromotionStatuses() {
      var _this12 = this;

      Logger.debug('Loading Promotion Statuses');
      if (this.get('_promotionStatuses').then !== undefined) {
        this.get('_promotionStatuses').then(function (content) {
          run(function () {
            Logger.debug('Loading Promotion Statuses');
            _this12.set('_promotionStatuses', content);
          });
        });
      }
    },
    actions: {
      save: function save(item) {
        this.set('saveEnabled', false);
        this.sendAction('save', item);
      },
      cancel: function cancel(item) {
        this.sendAction('cancel', item);
      },
      uploadLogo: function uploadLogo(fileList) {
        var _this13 = this;

        var re = new RegExp('image/*');
        if (re.test(fileList[0].type)) {
          (function () {
            var that = _this13;
            _this13.set('logoName', fileList[0].name);
            var reader = new FileReader();

            reader.onloadend = function () {
              that.set('locationGroup.logo', reader.result);
            };

            reader.readAsDataURL(fileList[0]);
          })();
        } else {
          Logger.debug('not an image.');
        }
      },
      openModal: function openModal() {
        this.set('showModal', true);
      },
      closeModal: function closeModal() {
        Logger.debug("Closing modal. Timezone staid as it was " + this.get('locationGroup.timezone'));
        this.set('showModal', false);
      },
      saveTimezoneAction: function saveTimezoneAction(timezone) {
        Logger.debug("Timezone received from modal was " + timezone);
        this.set('locationGroup.timezone', timezone);
        Logger.debug("Timezone set into the model as " + this.get('locationGroup.timezone'));
        this.set('showModal', false);
      },
      changeTimeZone: function changeTimeZone(timezone) {
        this.set('locationGroup.timezone', timezone);
      },
      addCompany: function addCompany() {
        var company = this.get('store').createFragment('companyRole');
        this.get('locationGroup.companiesRoles').addObject(company);
      },
      removeItem: function removeItem(item) {
        this.get('locationGroup.companiesRoles').removeObject(item);
      },
      changeStartDate: function changeStartDate(value) {
        this.set('locationGroup.startDate', value);
        if ((0, _moment["default"])(value).isAfter(this.get('locationGroup.endDate'))) {
          this.set('locationGroup.endDate', null);
        }
      },
      changeEndDate: function changeEndDate(value) {
        this.set('locationGroup.endDate', value);
      },
      removeLogo: function removeLogo() {
        this.set('logoName', null);
        this.set('locationGroup.logo', null);
      },
      changeLocationGroupType: function changeLocationGroupType(type) {
        if (type.data.name === 'Venue') {
          this.set('_isEvent', false);
        } else {
          this.set('_isEvent', true);
        }
        this.set('_selectedLocationGroupType', type);
      },
      changeSelectedGateway: function changeSelectedGateway(gateway) {
        this.set('selectedGateway', gateway);
        this.set('locationGroup.cashlessInformation.gateway', gateway.id);
        if (this.get('selectedGateway.processor.name') === "FGT") {
          this.set('_isV4Processor', false);
        } else {
          this.set('_isV4Processor', true);
        }
      },
      inactivatePromotion: function inactivatePromotion(promotion) {
        promotion.set('status', this.get('store').peekRecord('promotionStatus', 2));
      },
      activatePromotion: function activatePromotion(promotion) {
        promotion.set('status', this.get('store').peekRecord('promotionStatus', 1));
      },
      addPromotion: function addPromotion(promotion) {
        var _this14 = this;

        var filteredPromotionsByNames = this.get('locationGroup.promotions').filterBy('name', promotion.get('name'));
        if (filteredPromotionsByNames.length > 0) {
          this.get('notify').alert(this.get('intl').t('locationGroups.details.promotions.modal.errors.duplicatedName'), { closeAfter: 5000 });
        } else {
          this.get('locationGroup.promotions').addObject(promotion);
          this.set('locationGroup.promotions', this.get('locationGroup.promotions').sortBy('name'));
          this.set('needRefreshPagesTable', false);
          run.next(function () {
            _this14.set('showPromotionModal', false);
            _this14.set('needRefreshPagesTable', true);
          });
        }
      },
      editPromotion: function editPromotion(promotion) {
        var _this15 = this;

        var filteredPromotionsByNames = this.get('locationGroup.promotions').filterBy('name', promotion.get('name'));
        if (filteredPromotionsByNames.length > 0 && filteredPromotionsByNames.get('firstObject').get('id') !== this.get('promotionId')) {
          this.get('notify').alert(this.get('intl').t('locationGroups.details.promotions.modal.errors.duplicatedName'), { closeAfter: 5000 });
        } else {
          // update promotion values with new ones
          var oldPromo = this.get('locationGroup.promotions').filterBy('id', this.get('promotionId')).get('firstObject');
          oldPromo.set('name', promotion.get('name'));
          oldPromo.set('status', promotion.get('status'));
          oldPromo.set('amount', promotion.get('amount'));
          oldPromo.set('type', promotion.get('type'));
          oldPromo.set('promotionRedemptionRule', promotion.get('promotionRedemptionRule'));
          oldPromo.set('productCatalogs', promotion.get('productCatalogs'));
          oldPromo.set('quantity', promotion.get('quantity'));
          oldPromo.set('receiptDisplayInline', promotion.get('receiptDisplayInline'));
          oldPromo.set('redemptionMaxCount', promotion.get('redemptionMaxCount'));
          oldPromo.set('redemptionExpirationDateTime', promotion.get('redemptionExpirationDateTime'));
          oldPromo.set('cardType', promotion.get('cardType'));
          oldPromo.set('triggerType', promotion.get('triggerType'));
          oldPromo.set('images', promotion.get('images'));
          oldPromo.set('binCsv', promotion.get('binCsv'));
          oldPromo.set('minimumSpentTotal', promotion.get('minimumSpentTotal'));
          oldPromo.set('binCount', promotion.get('binCount'));

          promotion.get('images').clear();
          this.set('needRefreshPagesTable', false);
          run.next(function () {
            _this15.set('showPromotionModal', false);
            _this15.set('needRefreshPagesTable', true);
          });
        }
      },
      openPromotionModal: function openPromotionModal() {
        this.set('locationGroupCompanies', this.get('locationGroup.companiesRoles').mapBy('companyId'));
        this.set('editing', false);
        var promotion = this.get('store').createRecord('promotion');
        promotion.set('status', this.get('store').peekRecord('promotionStatus', 0));
        this.set('promotion', promotion);
        this.set('showPromotionModal', true);
      },
      editPromotionModal: function editPromotionModal(promotion) {
        this.set('locationGroupCompanies', this.get('locationGroup.companiesRoles').mapBy('companyId'));
        this.set('editing', true);
        var tempPromotion = this.get('store').createRecord('promotion', {
          name: promotion.get('name'),
          status: promotion.get('status'),
          amount: promotion.get('amount'),
          type: promotion.get('type'),
          promotionRedemptionRule: promotion.get('promotionRedemptionRule'),
          productCatalogs: promotion.get('productCatalogs'),
          quantity: promotion.get('quantity'),
          receiptDisplayInline: promotion.get('receiptDisplayInline'),
          redemptionMaxCount: promotion.get('redemptionMaxCount'),
          redemptionExpirationDateTime: promotion.get('redemptionExpirationDateTime'),
          triggerType: promotion.get('triggerType'),
          cardType: promotion.get('cardType'),
          images: promotion.get('images'),
          binCsv: promotion.get('binCsv'),
          binCount: promotion.get('binCount'),
          minimumSpentTotal: promotion.get('minimumSpentTotal')
        });
        this.set('promotion', tempPromotion);
        this.set('promotionId', promotion.get('id'));
        this.set('showPromotionModal', true);
      },
      cancelPromotionModal: function cancelPromotionModal() {
        this.set('showPromotionModal', false);
      },
      cancelDeleteCustomFeeModal: function cancelDeleteCustomFeeModal() {
        this.set('showDeleteCustomFeeModal', false);
      },
      closePromotionModal: function closePromotionModal() {
        this.set('showPromotionModal', false);
      },
      disableCustomFee: function disableCustomFee(customFee) {
        customFee.set('changed', true);
        customFee.set('enabled', false);
      },
      enableCustomFee: function enableCustomFee(customFee) {
        customFee.set('changed', true);
        customFee.set('enabled', true);
      },
      openDeleteCustomFeeModal: function openDeleteCustomFeeModal(customFee) {
        this.set('selectedCustomFee', customFee);
        this.set('showDeleteCustomFeeModal', true);
      },
      deleteCustomFee: function deleteCustomFee(customFee) {
        customFee.set('changed', true);
        customFee.set('deleted', true);
        this.set('showDeleteCustomFeeModal', false);
        this.set('selectedCustomFee', null);
        this._filterCustomFees();
      },
      addCustomFee: function addCustomFee(customFee) {
        var _this16 = this;

        var filteredCustomFeesByNames = this.get('locationGroup.customFees').filterBy('name', customFee.get('name'));
        if (filteredCustomFeesByNames.length > 0) {
          this.get('notify').alert(this.get('intl').t('locationGroups.details.customFees.modal.errors.duplicatedName'), { closeAfter: 5000 });
        } else {
          this.get('filteredCustomFees').addObject(customFee);
          this.get('locationGroup.customFees').addObject(customFee);
          this.set('locationGroup.customFees', this.get('locationGroup.customFees').sortBy('name'));
          this.set('refreshCustomFees', false);
          run.next(function () {
            _this16.set('showCustomFeeModal', false);
            _this16.set('refreshCustomFees', true);
          });
        }
      },
      editCustomFee: function editCustomFee(customFee) {
        var _this17 = this;

        var filteredCustomFeesByNames = this.get('locationGroup.customFees').filterBy('name', customFee.get('name'));
        if (filteredCustomFeesByNames.length > 0 && filteredCustomFeesByNames.get('firstObject').get('id') !== this.get('customFeeId')) {
          this.get('notify').alert(this.get('intl').t('locationGroups.details.customFees.modal.errors.duplicatedName'), { closeAfter: 5000 });
        } else {
          // update promotion values with new ones
          var oldCustomFee = this.get('locationGroup.customFees').filterBy('id', this.get('customFeeId')).get('firstObject');
          oldCustomFee.set('name', customFee.get('name'));
          oldCustomFee.set('enabled', customFee.get('enabled'));
          oldCustomFee.set('type', customFee.get('type'));
          oldCustomFee.set('appliesTo', customFee.get('appliesTo'));
          oldCustomFee.set('value', customFee.get('value'));
          oldCustomFee.set('deleted', customFee.get('deleted'));
          oldCustomFee.set('taxable', customFee.get('taxable'));
          oldCustomFee.set('changed', customFee.get('changed'));
          oldCustomFee.set('paymentType', customFee.get('paymentType'));
          this.set('needRefreshPagesTable', false);
          run.next(function () {
            _this17.set('showCustomFeeModal', false);
            _this17.set('needRefreshPagesTable', true);
          });
        }
      },
      openCustomFeeModal: function openCustomFeeModal() {
        this.set('editing', false);
        var customFee = this.get('store').createRecord('customFee');
        this.set('customFee', customFee);
        this.set('customFeeId', null);
        this.set('showCustomFeeModal', true);
      },
      editCustomFeeModal: function editCustomFeeModal(customFee) {
        this.set('editing', true);
        var tempCustomFee = this.get('store').createRecord('customFee', {
          name: customFee.get('name'),
          enabled: customFee.get('enabled'),
          appliesTo: customFee.get('appliesTo'),
          type: customFee.get('type'),
          paymentType: customFee.get('paymentType'),
          value: customFee.get('value'),
          taxable: customFee.get('taxable'),
          changed: true
        });
        this.set('customFee', tempCustomFee);
        this.set('customFeeId', customFee.get('id'));
        this.set('showCustomFeeModal', true);
      },
      cancelCustomFeeModal: function cancelCustomFeeModal() {
        this.set('showCustomFeeModal', false);
      },
      closeCustomFeeModal: function closeCustomFeeModal() {
        this.set('showCustomFeeModal', false);
      }
    }
  });
});