define("fcs-dashboard/pods/components/location-mng/list/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/table-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsTableComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var observer = _ember["default"].observer;
  var isEqual = _ember["default"].isEqual;
  var Logger = _ember["default"].Logger;
  var A = _ember["default"].A;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], _fcsDashboardMixinsTableComponent["default"], {
    locationTypes: [],
    locationStatuses: computed(function () {
      return [this.get('intl').t('menu.list.table.status.active'), this.get('intl').t('menu.list.table.status.inactive')];
    }),
    selectedModels: A(),
    data: [],
    showList: true,
    selectedLocationsCount: computed('selectedModels.length', function () {
      if (this.get('selectedModels') !== undefined && this.get('selectedModels.length') > 0) {
        return this.get('selectedModels.length');
      } else {
        return '0';
      }
    }),
    columns: computed(function () {
      var hideCompanyColumn = !this.get('isBRUser');
      var availableEnvironments = this.get('exampleEnvironments');
      return [{
        template: "meta-product-catalog/actions/row-select-checkbox",
        useFilter: false,
        mayBeHidden: false,
        templateForSortCell: "meta-product-catalog/actions/row-select-all-checkbox"
      }, {
        title: '',
        template: "location/actions/edit-cell",
        className: 'editRow'
      }, {
        propertyName: 'name',
        title: this.get('intl').t('location.list.table.location'),
        filteredBy: 'name'
      }, {
        propertyName: 'typeName',
        title: this.get('intl').t('location.list.table.type'),
        filterWithSelect: true,
        filteredBy: 'typeName',
        predefinedFilterOptions: availableEnvironments,
        className: 'stateRow',
        filterFunction: function filterFunction(cell, neededStr, obj) {
          return isEqual(obj.get('typeName').toLowerCase(), neededStr);
        }
      }, {
        propertyName: 'companyName',
        title: this.get('intl').t('location.list.table.company'),
        isHidden: hideCompanyColumn,
        filteredBy: 'companyName',
        className: 'companyNameRow'
      }, {
        propertyName: 'menuCount',
        filteredBy: 'menuCount',
        title: this.get('intl').t('location.list.table.menus')
      }, {
        propertyName: 'translatedStatus',
        title: this.get('intl').t('menu.list.table.status.header'),
        filterWithSelect: true,
        predefinedFilterOptions: this.get('locationStatuses'),
        filterString: 'Active',
        filteredBy: 'translatedStatus',
        className: 'stateRow',
        filterFunction: function filterFunction(cell, neededStr, obj) {
          return isEqual(obj.get('translatedStatus').toLowerCase(), neededStr);
        }
      }, {
        useFilter: false
      }];
    }),
    _showModal: false,
    _currentLocation: undefined,
    _checkCompanyLock: observer('lockedCompany', function () {
      Logger.debug('calling refresh');
      this.sendAction('refreshAction');
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('showList', false);
      var temp = this.get('store').findAll('locationType');
      if (temp.then !== undefined) {
        temp.then(function (content) {
          _this.set('locationTypes', content);
          _this.set('showList', true);
        });
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.set('selectedModels', null);
    },
    actions: {
      newLocation: function newLocation() {
        var company = undefined;
        if (this.get('isBRUser')) {
          if (this.get('lockedCompany') !== undefined) {
            company = this.get('lockedCompany');
          }
        } else {
          company = this.get('loggedAccount.company');
        }

        this.set('_currentLocation', this.get('store').createRecord('location', { companyId: company ? company.id : null, companyName: company ? company.name : null }));
        this.set('_showModal', true);
      },
      editLocation: function editLocation(item) {
        this.set('_currentLocation', item);
        this.set('_showModal', true);
      },
      cancel: function cancel() {
        this.set('_showModal', false);
        this.send('cleanMessages');
        this.get('_currentLocation').rollbackAttributes();
        this.set('_currentLocation', undefined);
        this.get('selectedModels').clear();
      },
      save: function save() {
        this.set('_showModal', false);
        this.sendAction('save');
      },
      toggleLocationsActive: function toggleLocationsActive(activate) {
        var _this2 = this;

        if (this.get('selectedModels.length') > 0) {
          (function () {
            var locationIds = A();
            _this2.get('selectedModels').forEach(function (location) {
              locationIds.addObject(parseInt(location.id));
            });

            _this2.sendAction('toggleLocationsActive', locationIds, activate);
          })();
        }
      }
    }
  });
});