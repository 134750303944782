define("fcs-dashboard/pods/components/payment-result/search-criteria/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/report-mixin", "moment"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsReportMixin, _moment) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var run = _ember["default"].run;
  var Logger = _ember["default"].Logger;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], _fcsDashboardMixinsReportMixin["default"], {
    sortedReportFilters: computed.sort('_reportFilters', 'sortDefinition'),
    summarizeBy: computed.alias('reportOptions.summarizeBy'),
    _reportFilters: computed(function () {
      return this.get('store').findAll('paymentResultReportFilter');
    }),
    isBrAdmin: computed.alias('isBRUser'),
    init: function init() {
      this._super.apply(this, arguments);
      this._initializeLocationGroups();
      this._initializeReportFilters();
      this._initializeReportExportParameters();

      var stime = (0, _moment["default"])('05:00 AM', "hh:mm a");
      var fdate = (0, _moment["default"])(this.get('fromDate')).set({
        'hour': stime.get('hour'),
        'minute': stime.get('minute')
      });
      this.set('fromDate', fdate);

      var etime = (0, _moment["default"])('05:00 AM', "hh:mm a");
      var tdate = (0, _moment["default"])(this.get('toDate')).set({
        'hour': etime.get('hour'),
        'minute': etime.get('minute')
      });
      this.set('toDate', tdate);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (!this.get('selectedLocationGroup.isDestroyed') || this.get('selectedLocationGroup.isDestroying')) {
        this.set('selectedLocationGroup', null);
      }
      this.set('summarizeBy', null);
    },
    _initializeReportFilters: function _initializeReportFilters() {
      var _this = this;

      Logger.debug('Loading ReportFilters for Payment Result Report');
      if (this.get('_reportFilters').then !== undefined) {
        this.get('_reportFilters').then(function (content) {
          run(function () {
            Logger.debug('Loaded ReportFilters for Payment Result Report');
            _this.set('_reportFilters', content);
          });
        });
      }
    },
    actions: {
      showExportModal: function showExportModal() {
        this._initializeReportExportParameters();
        this.set('showExportModal', true);
      },
      closeModal: function closeModal() {
        this.set('showExportModal', false);
      },
      accept: function accept(item) {
        this.set('showExportModal', false);
        this.sendAction('search', item);
      },
      searchReport: function searchReport() {
        this._initializeReportExportParameters();
        this.set('isShowExportButton', false);
        this.send('search');
      },
      search: function search() {
        this.send('cleanMessages');
        var isValid = true;
        var errorMessage = undefined;

        this._setDefaultFilters(isValid, errorMessage);

        if (this.get('summarizeBy') === undefined || this.get('summarizeBy') === null) {
          isValid = false;
          errorMessage = this.get('notify').alert(this.get('intl').t('report.result.errors.noSummarizeFilterError'), { closeAfter: 5000 });
          this.get('errorMessages').addObject(errorMessage);
        }

        if (isValid) {
          this.set('page', 0);
          this.sendAction('search', this.get('reportOptions'));
        }
      },
      reportFilterSelection: function reportFilterSelection(filter) {
        this.set('summarizeBy', filter);
      }
    }
  });
});