define("fcs-dashboard/pods/components/change-password-modal/component", ["exports", "ember", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var isEqual = _ember["default"].isEqual;
  var isEmpty = _ember["default"].isEmpty;
  var htmlSafe = _ember["default"].String.htmlSafe;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], {
    password_confirmation: '',
    password: '',
    password_error: null,
    displayErrorClass: 'hideError',
    displayProperty: computed('displayErrorClass', function () {
      return htmlSafe(this.get('displayErrorClass'));
    }),
    actions: {
      saveAction: function saveAction() {
        var _getProperties = this.getProperties('password', 'password_confirmation');

        var password = _getProperties.password;
        var password_confirmation = _getProperties.password_confirmation;

        if (!isEmpty(password) && !isEmpty(password_confirmation) && isEqual(password, password_confirmation)) {
          this.sendAction('changePassword', this.get('model'), password);
        } else {
          this.set('displayErrorClass', 'displayError');
          this.set('password_error', "'Password' does not match 'Password Confirmation'.");
        }
      },
      closeModal: function closeModal() {
        this.get('model').rollbackAttributes();
        this.sendAction('closeModal');
      }
    }
  });
});