define('fcs-dashboard/pods/payment-result-report/report-items-by-company/model', ['exports', 'ember-data/attr', 'model-fragments/fragment'], function (exports, _emberDataAttr, _modelFragmentsFragment) {
  exports['default'] = _modelFragmentsFragment['default'].extend({
    event: (0, _emberDataAttr['default'])('string'),
    company: (0, _emberDataAttr['default'])('string'),
    gateway: (0, _emberDataAttr['default'])('string'),
    approvedCount: (0, _emberDataAttr['default'])('number'),
    approvedAmount: (0, _emberDataAttr['default'])('number'),
    compsDueToDeclineCount: (0, _emberDataAttr['default'])('number'),
    compsDueToDeclineAmount: (0, _emberDataAttr['default'])('number'),
    realTimeDeclinesCount: (0, _emberDataAttr['default'])('number'),
    realTimeDeclinesAmount: (0, _emberDataAttr['default'])('number'),
    batchedErrorsCount: (0, _emberDataAttr['default'])('number'),
    batchedErrorsAmount: (0, _emberDataAttr['default'])('number'),
    realTimeErrorsCount: (0, _emberDataAttr['default'])('number'),
    realTimeErrorsAmount: (0, _emberDataAttr['default'])('number'),
    voidCount: (0, _emberDataAttr['default'])('number'),
    voidAmount: (0, _emberDataAttr['default'])('number'),
    refundCount: (0, _emberDataAttr['default'])('number'),
    refundAmount: (0, _emberDataAttr['default'])('number')
  });
});