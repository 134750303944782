define("fcs-dashboard/pods/components/fcs-login/component", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var service = _ember["default"].inject.service;
  var computed = _ember["default"].computed;
  var htmlSafe = _ember["default"].String.htmlSafe;
  var isEmpty = _ember["default"].isEmpty;
  exports["default"] = Component.extend({
    showForgotPassword: false,
    session: service(),
    intl: service(),
    errorMessage: "",
    displayErrorClass: 'hideError',
    displayProperty: computed('displayErrorClass', function () {
      return htmlSafe(this.get('displayErrorClass'));
    }),
    actions: {
      authenticate: function authenticate() {
        var _this = this;

        this.set('displayErrorClass', 'hideError');
        this.set('errorMessage', '');

        var _getProperties = this.getProperties('username', 'password');

        var username = _getProperties.username;
        var password = _getProperties.password;

        if (isEmpty(username)) {
          this.set('displayErrorClass', 'displayError');
          this.set('errorMessage', this.get('intl').t('login.signin.errors.usernameRequired'));
          return;
        }

        if (isEmpty(password)) {
          this.set('displayErrorClass', 'displayError');
          this.set('errorMessage', this.get('intl').t('login.signin.errors.passwordRequired'));
          return;
        }

        this.get('session').authenticate('authenticator:oauth2', username, password).then(function () {
          //TODO show modal if user is company user and has more the one profile
        })["catch"](function (reason) {
          _this.set('displayErrorClass', 'displayError');
          _this.set('errorMessage', reason.error_description);
        });
      },
      forgotPassword: function forgotPassword() {
        console.log('forgotPassword');
        this.set("showForgotPassword", true);
      }
    }
  });
});