define("fcs-dashboard/authenticators/oauth2", ["exports", "ember", "ember-simple-auth/authenticators/oauth2-password-grant"], function (exports, _ember, _emberSimpleAuthAuthenticatorsOauth2PasswordGrant) {
  var computed = _ember["default"].computed;
  exports["default"] = _emberSimpleAuthAuthenticatorsOauth2PasswordGrant["default"].extend({
    serverTokenEndpoint: '/oauth/token',
    serverTokenRevocationEndpoint: '/revoke',
    _clientIdHeader: computed(function () {
      var client_id = 'fcs_dashboard_server';
      var client_secret = '04cfb9d39b494db2fa60bc7a76f06afa';

      var base64ClientId = window.btoa(client_id + ":" + client_secret);
      return { Authorization: "Basic " + base64ClientId };
    })
  });
});