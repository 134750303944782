define('fcs-dashboard/pods/simple-report-by-product/model', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _emberDataModel, _emberDataAttr) {
  exports['default'] = _emberDataModel['default'].extend({
    product: (0, _emberDataAttr['default'])('string'),
    productType: (0, _emberDataAttr['default'])('string'),
    regPriceSales: (0, _emberDataAttr['default'])('number'),
    totalDiscounts: (0, _emberDataAttr['default'])('number'),
    grossSales: (0, _emberDataAttr['default'])('number'),
    creditGrossSales: (0, _emberDataAttr['default'])('number'),
    cashGrossSales: (0, _emberDataAttr['default'])('number'),
    openLoopGrossSales: (0, _emberDataAttr['default'])('number'),
    percentageTotal: (0, _emberDataAttr['default'])('number'),
    itemsSold: (0, _emberDataAttr['default'])('number'),
    avgPrice: (0, _emberDataAttr['default'])('number')
  });
});