define('fcs-dashboard/instance-initializers/svg-use-polyfill', ['exports', 'npm:svg4everybody', 'ember-frost-core/instance-initializers/svg-use-polyfill'], function (exports, _npmSvg4everybody, _emberFrostCoreInstanceInitializersSvgUsePolyfill) {
  // eslint-disable-line no-unused-vars

  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberFrostCoreInstanceInitializersSvgUsePolyfill['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _emberFrostCoreInstanceInitializersSvgUsePolyfill.initialize;
    }
  });
});