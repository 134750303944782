define("fcs-dashboard/pods/attribute-set/model", ["exports", "ember", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberDataRelationships, _emberCpValidations) {
  var computed = _ember["default"].computed;
  var isEmpty = _ember["default"].isEmpty;

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'attributeSet.details.name.label' }),
    attributes: [(0, _emberCpValidations.validator)('length', { min: 1, message: 'Must add at least 1 attribute' })]
  });

  exports["default"] = _emberDataModel["default"].extend(Validations, {
    name: (0, _emberDataAttr["default"])('string'),
    deleted: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    companyId: (0, _emberDataAttr["default"])('number'),
    companyName: (0, _emberDataAttr["default"])('string'),
    content: (0, _emberDataAttr["default"])('string'),
    attributes: (0, _emberDataRelationships.hasMany)('attribute'),
    deletedAttributes: (0, _emberDataAttr["default"])({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    priority: (0, _emberDataAttr["default"])('number', { defaultValue: 1 }),
    attributesNames: computed('attributes.[]', function () {
      var attributeNames = '';
      this.get('attributes').forEach(function (attribute) {
        if (isEmpty(attributeNames)) {
          attributeNames = attribute.get('name');
        } else {
          attributeNames = attributeNames + ', ' + attribute.get('name');
        }
      });
      return attributeNames;
    })
  });
});