define("fcs-dashboard/pods/user/model", ["exports", "ember", "ember-data/model", "ember-data/attr", "fcs-dashboard/mixins/common-mixin", "ember-data/relationships", "ember-cp-validations"], function (exports, _ember, _emberDataModel, _emberDataAttr, _fcsDashboardMixinsCommonMixin, _emberDataRelationships, _emberCpValidations) {
  var isEmpty = _ember["default"].isEmpty;
  var computed = _ember["default"].computed;
  var isEqual = _ember["default"].isEqual;

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'user.details.name.label' }),
    lastName: (0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'user.details.lastName.label' }),
    username: (0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'user.details.username.label' }),
    state: (0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'user.details.state.label' }),
    userType: (0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'user.details.userType.label' }),
    email: (0, _emberCpValidations.validator)('format', {
      type: 'email',
      regex: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
      descriptionKey: 'user.details.email.label',
      disabled: computed(function () {
        return isEmpty(this.get('model.email'));
      }).volatile()
    }),
    userProfile: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      descriptionKey: 'user.details.userProfile.label',
      disabled: computed(function () {
        var disabled = true;
        //8 refers to COMPANY_USER userType
        if (this.get('model.company.id') !== undefined && this.get('model.userType') !== undefined && this.get('model.userType.id') === '8') {
          disabled = false;
        }
        return disabled;
      }).volatile()
    }),
    company: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      descriptionKey: 'user.details.company.label',
      disabled: computed(function () {
        var disabled = true;
        //8 refers to COMPANY_USER userType
        if (this.get('model.userType') !== undefined && this.get('model.userType.id') === '8') {
          disabled = false;
        }
        return disabled;
      }).volatile()
    }),
    password: (0, _emberCpValidations.validator)('format', {
      regex: /^\d{4,8}$/,
      messageKey: 'user.details.errors.passwordNotNumeric',
      disabled: computed(function () {
        var disabled = true;
        if (this.get('model.company.id') !== undefined && this.get('model.userProfile') !== undefined && this.get('model.userProfile.id') === '2' && this.get('model.password') !== null) {
          disabled = false;
        }
        return disabled;
      }).volatile()
    })
  });

  exports["default"] = _emberDataModel["default"].extend(Validations, _fcsDashboardMixinsCommonMixin["default"], {
    name: (0, _emberDataAttr["default"])('string'),
    lastName: (0, _emberDataAttr["default"])('string'),
    email: (0, _emberDataAttr["default"])('string'),
    username: (0, _emberDataAttr["default"])('string'),
    password: (0, _emberDataAttr["default"])('string'),
    state: (0, _emberDataAttr["default"])('string'),
    userType: (0, _emberDataRelationships.belongsTo)('userType'),
    company: (0, _emberDataRelationships.belongsTo)('company'),
    userProfile: (0, _emberDataRelationships.belongsTo)('userProfile'),
    locationGroups: (0, _emberDataRelationships.hasMany)('locationGroup'),
    intl: _ember["default"].inject.service(),
    translatedState: computed('state', function () {
      return this.get('intl').t("user.statuses." + this.get('state'));
    }),
    fullName: computed('name', 'lastName', function () {
      return this.get('name') + " " + this.get('lastName');
    }),
    dropDownName: computed('name', 'lastName', 'username', function () {
      return this.get('name') + " " + this.get('lastName') + "(" + this.get('username') + ")";
    }),

    navbarUserHeader: computed('lockedCompany', function () {
      if (isEqual(Number(this.get('userType.id')), this.get('BR_USERTYPE')) && this.get('lockedCompany') === undefined) {
        return this.get('intl').t('userType.brUser');
      } else if (isEqual(Number(this.get('userType.id')), this.get('BR_USERTYPE')) && this.get('lockedCompany') !== undefined) {
        return this.get('lockedCompany.name');
      } else {
        return this.get('company.name');
      }
    }),
    lockedCompany: undefined
  });
});