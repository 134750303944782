define("fcs-dashboard/pods/components/payment-result/report-result/report-items-by-company/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/table-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsTableComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var isEmpty = _ember["default"].isEmpty;
  exports["default"] = Component.extend(_fcsDashboardMixinsTableComponent["default"], _fcsDashboardMixinsBaseComponent["default"], {
    data: [],
    _showData: computed('data', function () {
      return this.get('data') !== null && !isEmpty(this.get('data'));
    }),
    _columns: computed(function () {
      return [{
        className: 'payment-report-category-cell',
        propertyName: 'event',
        disableSorting: true
      }, {
        className: 'payment-report-category-cell',
        propertyName: 'company',
        disableSorting: true
      }, {
        className: 'payment-report-category-cell',
        propertyName: 'gateway',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.paymentResultReport.headers.approvedCount'),
        className: 'payment-report-quantity-cell',
        propertyName: 'approvedCount',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.paymentResultReport.headers.approvedAmount'),
        className: 'payment-report-quantity-cell',
        template: 'paymentResultReport/amountCell',
        propertyName: 'approvedAmount',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.paymentResultReport.headers.compsDueToDeclineCount'),
        className: 'payment-report-quantity-cell',
        propertyName: 'compsDueToDeclineCount',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.paymentResultReport.headers.compsDueToDeclineAmount'),
        className: 'payment-report-quantity-cell',
        template: 'paymentResultReport/amountCell',
        propertyName: 'compsDueToDeclineAmount',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.paymentResultReport.headers.realTimeDeclinesCount'),
        className: 'payment-report-quantity-cell',
        propertyName: 'realTimeDeclinesCount',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.paymentResultReport.headers.realTimeDeclinesAmount'),
        className: 'payment-report-quantity-cell',
        template: 'paymentResultReport/amountCell',
        propertyName: 'realTimeDeclinesAmount',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.paymentResultReport.headers.batchedErrorsCount'),
        className: 'payment-report-quantity-cell',
        propertyName: 'batchedErrorsCount',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.paymentResultReport.headers.batchedErrorsAmount'),
        className: 'payment-report-quantity-cell',
        template: 'paymentResultReport/amountCell',
        propertyName: 'batchedErrorsAmount',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.paymentResultReport.headers.realTimeErrorsCount'),
        className: 'payment-report-quantity-cell',
        propertyName: 'realTimeErrorsCount',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.paymentResultReport.headers.realTimeErrorsAmount'),
        className: 'payment-report-quantity-cell',
        template: 'paymentResultReport/amountCell',
        propertyName: 'realTimeErrorsAmount',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.paymentResultReport.headers.voidCount'),
        className: 'payment-report-quantity-cell',
        propertyName: 'voidCount',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.paymentResultReport.headers.voidAmount'),
        className: 'payment-report-quantity-cell',
        template: 'paymentResultReport/amountCell',
        propertyName: 'voidAmount',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.paymentResultReport.headers.refundCount'),
        className: 'payment-report-quantity-cell',
        propertyName: 'refundCount',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.paymentResultReport.headers.refundAmount'),
        className: 'payment-report-quantity-cell',
        template: 'paymentResultReport/amountCell',
        propertyName: 'refundAmount',
        disableSorting: true
      }];
    })
  });
});