define('fcs-dashboard/mixins/common-mixin', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    BR_USERTYPE: 7,
    COMPANY_USERTYPE: 8,
    CASHLESS_USERTYPE: 9,
    MENU_TAX_RATE: '0',
    PRODUCT_TYPE_TAX_RATE: '1',
    PRODUCT_TAX_RATE: '2',
    notify: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),
    errorMessages: [],
    actions: {
      cleanMessages: function cleanMessages() {
        this.get('errorMessages').forEach(function (item) {
          item.set('visible', false);
        });
      },
      displayErrorMessages: function displayErrorMessages(messages) {
        var _this = this;

        messages.forEach(function (error) {
          var errorMessage = _this.get('notify').alert(error.get('message'), { closeAfter: 5000 });
          _this.get('errorMessages').addObject(errorMessage);
        });
      }
    }
  });
});