define("fcs-dashboard/pods/combined-product-catalog/model", ["exports", "ember", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberDataRelationships, _emberCpValidations) {
  var computed = _ember["default"].computed;
  var inject = _ember["default"].inject;
  var isEmpty = _ember["default"].isEmpty;

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      descriptionKey: 'combinedProductCatalog.modal.name.label'
    }),
    suggestedPrice: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      descriptionKey: 'combinedProductCatalog.modal.suggestedPrice.label'
    }), (0, _emberCpValidations.validator)('number', {
      positive: true,
      allowString: true,
      descriptionKey: 'combinedProductCatalog.modal.suggestedPrice.label'
    })],
    companyId: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      dependentKey: ['disableValidateCompany'],
      descriptionKey: 'combinedProductCatalog.modal.company.label',
      disabled: computed(function () {
        return this.get('model.disableValidateCompany');
      }).volatile()
    }),
    productType: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      descriptionKey: 'combinedProductCatalog.modal.productType.label'
    }),
    productClass: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      descriptionKey: 'combinedProductCatalog.modal.productClass.label'
    })
  });

  exports["default"] = _emberDataModel["default"].extend(Validations, {
    version: (0, _emberDataAttr["default"])('number'),
    productClass: (0, _emberDataRelationships.belongsTo)('productClass'),
    name: (0, _emberDataAttr["default"])('string'),
    companyId: (0, _emberDataAttr["default"])('number'),
    companyName: (0, _emberDataAttr["default"])('string'),
    suggestedPrice: (0, _emberDataAttr["default"])('number', { defaultValue: 0 }),
    description: (0, _emberDataAttr["default"])('string'),
    custom: (0, _emberDataAttr["default"])('boolean'),
    currency: (0, _emberDataAttr["default"])('string', { defaultValue: 'USD' }),
    products: (0, _emberDataRelationships.hasMany)('product'),
    changed: (0, _emberDataAttr["default"])('boolean'),
    image: (0, _emberDataAttr["default"])('string'),
    imageURL: (0, _emberDataAttr["default"])('string'),
    deleteImage: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    productCatalogTemplate: (0, _emberDataAttr["default"])('boolean'),
    isModified: computed('name', 'suggestedPrice', 'description', function () {
      this.set('changed', this.get('hasDirtyAttributes'));
    }),
    disableValidateCompany: true,
    productType: (0, _emberDataRelationships.belongsTo)('productType'),
    attributes: (0, _emberDataRelationships.hasMany)('attribute'),
    comboSelectionCatalogs: (0, _emberDataRelationships.hasMany)('comboSelectionCatalog'),
    metaProductCatalogId: (0, _emberDataAttr["default"])('number'),
    archived: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    attributesNames: computed('attributes.[]', function () {
      var attributeNames = '';
      this.get('attributes').forEach(function (attribute) {
        if (isEmpty(attributeNames)) {
          attributeNames = attribute.get('name');
        } else {
          attributeNames = attributeNames + ', ' + attribute.get('name');
        }
      });
      return attributeNames;
    }),
    intl: inject.service(),
    translatedStatus: computed('archived', function () {
      if (this.get('archived')) {
        return this.get('intl').t('menu.list.table.status.inactive');
      } else {
        return this.get('intl').t('menu.list.table.status.active');
      }
    }),
    displayClassName: (0, _emberDataAttr["default"])('string'),
    deletedComboSelectionCatalogs: (0, _emberDataAttr["default"])({
      defaultValue: function defaultValue() {
        return [];
      }
    })
  });
});