define("fcs-dashboard/pods/custom-fee/model", ["exports", "ember-data/attr", "ember-data/relationships", "ember-data/model"], function (exports, _emberDataAttr, _emberDataRelationships, _emberDataModel) {
  exports["default"] = _emberDataModel["default"].extend({
    name: (0, _emberDataAttr["default"])('string'),
    status: (0, _emberDataRelationships.belongsTo)('promotionStatus'),
    value: (0, _emberDataAttr["default"])('number', { defaultValue: 0.0 }),
    type: (0, _emberDataRelationships.belongsTo)('customFeeType'),
    paymentType: (0, _emberDataRelationships.belongsTo)('customPaymentType'),
    appliesTo: (0, _emberDataRelationships.belongsTo)('customFeeAppliesTo'),
    taxable: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    enabled: (0, _emberDataAttr["default"])('boolean', { defaultValue: true }),
    deleted: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    changed: (0, _emberDataAttr["default"])('boolean', { defaultValue: false })
  });
});