define("fcs-dashboard/pods/components/product-catalog/details/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/common-mixin"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsCommonMixin) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var htmlSafe = _ember["default"].String.htmlSafe;
  var sort = _ember["default"].computed.sort;
  var Logger = _ember["default"].Logger;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], _fcsDashboardMixinsCommonMixin["default"], {
    productClasses: [],
    showComboSelectionModal: false,
    belongsToTemplate: false,
    customEnabled: false,
    showComboSelections: true,
    _sortCriteria: ['name'],
    _comboSelectionsSortCriteria: ['position'],
    _sortedCompanies: sort('_companies', '_sortCriteria'),
    imageName: computed('product.image', 'product.imageURL', function () {
      return this.get('product.image') ? 'image' : null;
    }),
    imagePreview: computed('product.image', 'product.imageURL', function () {
      var url = "";
      if (this.get('product.image')) {
        url = this.get('product.image');
      } else {
        url = this.get('product.imageURL');
      }
      return htmlSafe("background-image: url(\"" + url + "\")");
    }),
    _companies: computed(function () {
      return this.get('store').query('company', { pageSize: -1 });
    }),
    _showCompanies: computed(function () {
      return this.get('product.isNew') && this.get('isBRUser') && this.get('lockedCompany') === undefined && this.get('product.companyId') === undefined;
    }),
    _showProductType: computed('product.companyId', function () {
      return this.get('product.companyId') !== undefined;
    }),
    _productTypes: computed('product.companyId', function () {
      if (this.get('product.companyId')) {
        return this.get('store').query('productType', { companyId: this.get('product.companyId'), pageSize: -1 });
      }
    }),
    _sortedProductTypes: sort('_productTypes', '_sortCriteria'),
    _sortedComboSelections: sort('product.comboSelectionCatalogs', '_comboSelectionsSortCriteria'),
    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('product.metaProductCatalogId') !== undefined && this.get('product.metaProductCatalogId') !== null) {
        this.set('belongsToTemplate', true);
      }
    },
    actions: {
      refreshComboSelectionList: function refreshComboSelectionList(comboSelectionCatalog, direction) {
        this.set('showComboSelections', false);
        var newPosition = undefined;
        if (direction === "DOWN") {
          if (comboSelectionCatalog.get('position') < this.get('product.comboSelectionCatalogs.length')) {
            newPosition = comboSelectionCatalog.get('position') + 1;
            var comboSelectionSecond = this.get('product.comboSelectionCatalogs').filterBy('position', newPosition).get('firstObject');
            comboSelectionSecond.set('position', comboSelectionCatalog.get('position'));
            comboSelectionCatalog.set('position', newPosition);
          }
        } else {
          if (comboSelectionCatalog.get('position') > 1) {
            newPosition = comboSelectionCatalog.get('position') - 1;
            var comboSelectionSecond = this.get('product.comboSelectionCatalogs').filterBy('position', newPosition).get('firstObject');
            comboSelectionSecond.set('position', comboSelectionCatalog.get('position'));
            comboSelectionCatalog.set('position', newPosition);
          }
        }
        this.set('showComboSelections', true);
      },
      deleteComboSelection: function deleteComboSelection(comboSelectionCatalog) {
        this.set('showComboSelections', false);
        var position = 1;

        // Remove comboSelectionCatalog
        this.get('product.comboSelectionCatalogs').removeObject(comboSelectionCatalog);
        if (!comboSelectionCatalog.get('isNew')) {
          this.get('product.deletedComboSelectionCatalogs').addObject(comboSelectionCatalog.get('id'));
        } else {
          comboSelectionCatalog.rollbackAttributes();
        }

        // Reset positions for all selections
        this.get('product.comboSelectionCatalogs').forEach(function (comboSelectionCatalog) {
          comboSelectionCatalog.set('position', position);
          position++;
        });
        this.set('showComboSelections', true);
      },
      openComboSelectionCatalogModal: function openComboSelectionCatalogModal() {
        this.set('comboSelectionCatalog', this.get('store').createRecord('comboSelectionCatalog', {
          productCatalogId: this.get('product.id')
        }));
        this.set('showComboSelectionModal', true);
      },
      closeComboSelectionModal: function closeComboSelectionModal(comboSelectionCatalog) {
        comboSelectionCatalog.rollbackAttributes();
        this.set('showComboSelectionModal', false);
      },
      addComboSelection: function addComboSelection(comboSelectionCatalog) {
        var filteredSelectionsByNames = this.get('product.comboSelectionCatalogs').filterBy('displayText', comboSelectionCatalog.get('displayText'));
        if (filteredSelectionsByNames.length > 0) {
          this.get('notify').alert(this.get('intl').t('comboSelectionProductCatalog.list.errors.duplicatedComboSelectionCatalogNameError'), { closeAfter: 5000 });
        } else {
          comboSelectionCatalog.set('position', this.get('product.comboSelectionCatalogs.length') + 1);
          this.get('product.comboSelectionCatalogs').addObject(comboSelectionCatalog);
          this.set('showComboSelectionModal', false);
        }
      },
      selectCompany: function selectCompany(company) {
        this.get('product').set('companyId', company.get('id'));
        this.get('product').set('companyName', company.get('name'));
        this.set('selectedCompany', company);
      },
      uploadImage: function uploadImage(fileList) {
        var _this = this;

        var re = new RegExp('image/(png|jpeg)');
        if (fileList[0].size <= 10000000) {
          if (re.test(fileList[0].type)) {
            (function () {
              var that = _this;
              _this.set('imageName', fileList[0].name);
              var reader = new FileReader();

              reader.onloadend = function () {
                that.set('product.deleteImage', false);
                that.set('product.image', reader.result);
              };

              reader.readAsDataURL(fileList[0]);
            })();
          } else {
            Logger.debug('not an image.');
            var errorMessage = this.get('notify').alert(this.get('intl').t('productCatalog.modal.errors.invalidImageFormat'), { closeAfter: 5000 });
            this.get('errorMessages').addObject(errorMessage);
          }
        } else {
          Logger.debug('image size max is 10mb.');
          var errorMessage = this.get('notify').alert(this.get('intl').t('productCatalog.modal.errors.invalidImageSize'), { closeAfter: 5000 });
          this.get('errorMessages').addObject(errorMessage);
        }
      },
      removeImage: function removeImage() {
        this.set('imageName', null);
        this.set('product.image', null);
        this.set('product.imageURL', null);
        this.set('product.deleteImage', true);
      }
    }

  });
});