define('fcs-dashboard/pods/components/report/sales-report-result/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    data: null,
    _deductionsTableColumns: computed(function () {
      return [{
        className: 'deductions-category-cell',
        propertyName: 'category',
        disableSorting: true
      }, {
        className: 'deductions-total-cell',
        propertyName: 'total',
        template: 'salesReport/amountCell',
        disableSorting: true
      }];
    }),
    _nonCashTableColumns: computed(function () {
      return [{
        className: 'non-cash-category-cell',
        propertyName: 'type',
        disableSorting: true
      }, {
        className: 'non-cash-quantity-cell',
        propertyName: 'quantity',
        disableSorting: true
      }, {
        className: 'non-cash-total-cell',
        title: 'Amount',
        template: 'salesReport/amountCell',
        propertyName: 'total',
        disableSorting: true
      }];
    }),
    _salesByCategoryTableColumns: computed(function () {
      return [{
        className: 'sales-by-category-cell',
        propertyName: 'category',
        disableSorting: true
      }, {
        className: 'sales-by-category-cell',
        propertyName: 'netSales',
        template: 'salesReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-category-cell',
        propertyName: 'comps',
        template: 'salesReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-category-cell',
        propertyName: 'voids',
        template: 'salesReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-category-cell',
        propertyName: 'spills',
        template: 'salesReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-category-cell',
        propertyName: 'declined',
        template: 'salesReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-category-cell',
        propertyName: 'taxes',
        template: 'salesReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-category-cell',
        propertyName: 'grossSales',
        template: 'salesReport/amountCell',
        disableSorting: true
      }];
    })
  });
});