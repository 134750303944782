define('fcs-dashboard/mixins/user-mixin', ['exports', 'ember'], function (exports, _ember) {
  var Mixin = _ember['default'].Mixin;
  var computed = _ember['default'].computed;
  exports['default'] = Mixin.create({
    statuses: computed(function () {
      return [this.get('intl').t('user.statuses.active'), this.get('intl').t('user.statuses.inactive')];
    }),
    userProfiles: computed(function () {
      return [this.get('intl').t('user.profiles.serverClerk'), this.get('intl').t('user.profiles.manager'), this.get('intl').t('user.profiles.reporting')];
    }),
    bradminUserProfiles: computed(function () {
      return [this.get('intl').t('user.profiles.serverClerk'), this.get('intl').t('user.profiles.manager'), this.get('intl').t('user.profiles.reporting'), this.get('intl').t('user.profiles.bradmin'), this.get('intl').t('user.profiles.cashlessAdmin')];
    })
  });
});