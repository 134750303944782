define("fcs-dashboard/pods/clerk-report/clerk-report-item/model", ["exports", "ember-data/attr", "model-fragments/fragment"], function (exports, _emberDataAttr, _modelFragmentsFragment) {
  exports["default"] = _modelFragmentsFragment["default"].extend({
    name: (0, _emberDataAttr["default"])('string'),
    username: (0, _emberDataAttr["default"])('string'),
    cashDrawer: (0, _emberDataAttr["default"])('number'),
    cashSales: (0, _emberDataAttr["default"])('number'),
    creditSales: (0, _emberDataAttr["default"])('number'),
    rfidSales: (0, _emberDataAttr["default"])('number'),
    totalSales: (0, _emberDataAttr["default"])('number'),
    coupon: (0, _emberDataAttr["default"])('number'),
    spills: (0, _emberDataAttr["default"])('number'),
    comp: (0, _emberDataAttr["default"])('number'),
    cashDiscounts: (0, _emberDataAttr["default"])('number'),
    digitalDiscounts: (0, _emberDataAttr["default"])('number'),
    totalDiscounts: (0, _emberDataAttr["default"])('number'),
    serviceFees: (0, _emberDataAttr["default"])('number'),
    taxes: (0, _emberDataAttr["default"])('number'),
    netSales: (0, _emberDataAttr["default"])('number'),
    creditCardTips: (0, _emberDataAttr["default"])('number'),
    rfidTips: (0, _emberDataAttr["default"])('number'),
    totalDigitalTips: (0, _emberDataAttr["default"])('number'),
    voids: (0, _emberDataAttr["default"])('number'),
    refunds: (0, _emberDataAttr["default"])('number')
  });
});