define("fcs-dashboard/pods/promotion/model", ["exports", "ember-data/attr", "ember-data/relationships", "ember-data/model", "ember"], function (exports, _emberDataAttr, _emberDataRelationships, _emberDataModel, _ember) {
  var computed = _ember["default"].computed;
  exports["default"] = _emberDataModel["default"].extend({
    name: (0, _emberDataAttr["default"])('string'),
    status: (0, _emberDataRelationships.belongsTo)('promotionStatus'),
    amount: (0, _emberDataAttr["default"])('number', { defaultValue: 0 }),
    type: (0, _emberDataRelationships.belongsTo)('promotionType'),
    promotionRedemptionRule: (0, _emberDataRelationships.belongsTo)('promotionRedemptionRule'),
    redemptionMaxCount: (0, _emberDataAttr["default"])('number', { defaultValue: 0 }),
    minimumSpentTotal: (0, _emberDataAttr["default"])('number', { defaultValue: 0 }),
    triggerType: (0, _emberDataRelationships.belongsTo)('promotionTriggerType'),
    redemptionExpirationDateTime: (0, _emberDataAttr["default"])('date'),
    cardType: (0, _emberDataRelationships.belongsTo)('creditCardType'),
    binCsv: (0, _emberDataAttr["default"])('string'),
    binCount: (0, _emberDataAttr["default"])('number', { defaultValue: 0 }),
    images: (0, _emberDataRelationships.hasMany)('promotionImage'),
    productCatalogs: (0, _emberDataAttr["default"])({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    quantity: (0, _emberDataAttr["default"])('number', { defaultValue: 0 }),
    enabled: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    receiptDisplayInline: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    receiptDisplay: computed('type', function () {
      if (this.get('type.id') === '0') {
        if (this.get('receiptDisplayInline')) {
          return 'inline';
        } else {
          return 'as payment';
        }
      } else if (this.get('type.id') === '1') {
        return 'within Item';
      } else if (this.get('type.id') === '2') {
        return 'as discount';
      } else {
        return 'Unknown';
      }
    })
  });
});