define("fcs-dashboard/pods/company/model", ["exports", "ember", "fcs-dashboard/config/environment", "cryptojs", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (exports, _ember, _fcsDashboardConfigEnvironment, _cryptojs, _emberDataModel, _emberDataAttr, _emberDataRelationships, _emberCpValidations) {
  var computed = _ember["default"].computed;
  var _cryptojs$enc = _cryptojs["default"].enc;
  var Base64 = _cryptojs$enc.Base64;
  var Utf8 = _cryptojs$enc.Utf8;
  var _cryptojs$AES = _cryptojs["default"].AES;
  var decrypt = _cryptojs$AES.decrypt;
  var encrypt = _cryptojs$AES.encrypt;
  var CBC = _cryptojs["default"].mode.CBC;
  var Pkcs7 = _cryptojs["default"].pad.Pkcs7;

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'company.detail.name.label' }),
    tabletLogin: (0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'company.detail.tabletLogin.label' }),
    password: (0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'company.detail.password.label' }),
    userProfiles: [(0, _emberCpValidations.validator)('length', { min: 1, message: 'At least one company profile must be selected' })]
  });

  exports["default"] = _emberDataModel["default"].extend(Validations, {
    name: (0, _emberDataAttr["default"])('string'),
    tabletLogin: (0, _emberDataAttr["default"])('string'),
    status: (0, _emberDataAttr["default"])('string'),
    password: (0, _emberDataAttr["default"])('string'),
    userProfiles: (0, _emberDataRelationships.hasMany)('userProfile', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    locations: (0, _emberDataRelationships.hasMany)('location'),
    menus: (0, _emberDataRelationships.hasMany)('menu'),
    disabled: false,
    intl: _ember["default"].inject.service(),
    translatedStatus: computed('status', function () {
      return this.get('intl').t("company.statuses." + this.get('status'));
    }),
    encryptedPassword: computed('decryptedPassword', function () {
      return this._encryptCompanyPassword(this.get('decryptedPassword'));
    }),
    decryptedPassword: computed(function () {
      var decryptedPassword = '';
      if (!this.get('isNew')) {
        decryptedPassword = this._decryptCompanyPassword(this.get('password'));
      }
      return decryptedPassword;
    }),
    _decryptCompanyPassword: function _decryptCompanyPassword(encryptedPassword) {
      var encodedBase64 = Base64.parse(encryptedPassword);
      var key = Base64.parse(_fcsDashboardConfigEnvironment["default"].APP.secretKey);
      var iv = Base64.parse(_fcsDashboardConfigEnvironment["default"].APP.iv);
      return Utf8.stringify(decrypt({ ciphertext: encodedBase64 }, key, { mode: CBC, padding: Pkcs7, iv: iv }));
    },
    _encryptCompanyPassword: function _encryptCompanyPassword(plainPassword) {
      var key = Base64.parse(_fcsDashboardConfigEnvironment["default"].APP.secretKey);
      var iv = Base64.parse(_fcsDashboardConfigEnvironment["default"].APP.iv);
      var encrypted = encrypt(plainPassword, key, { mode: CBC, padding: Pkcs7, iv: iv });
      return encrypted.ciphertext.toString(Base64);
    }

  });
});