define("fcs-dashboard/mirage/fixtures/categories", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  _emberCliMirage.faker.locale = 'en_US';

  exports["default"] = [{
    id: 1,
    name: _emberCliMirage.faker.lorem.sentence(1, 4),
    pages: [1, 2, 3]
  }, {
    id: 2,
    name: _emberCliMirage.faker.lorem.sentence(1, 4),
    pages: [4, 5, 6]
  }, {
    id: 3,
    name: _emberCliMirage.faker.lorem.sentence(1, 4),
    pages: [7]
  }];
});
/**
 * Created by alberto on 03/02/16.
 */