define('fcs-dashboard/pods/gateway-menu-location/model', ['exports', 'ember', 'ember-data/attr', 'model-fragments/fragment'], function (exports, _ember, _emberDataAttr, _modelFragmentsFragment) {
  exports['default'] = _modelFragmentsFragment['default'].extend({
    menuId: (0, _emberDataAttr['default'])('string'),
    menuName: (0, _emberDataAttr['default'])('string'),
    locationId: (0, _emberDataAttr['default'])('string'),
    locationName: (0, _emberDataAttr['default'])('string'),
    selected: (0, _emberDataAttr['default'])('boolean', { defaultValue: false }),
    displayName: _ember['default'].computed(function () {
      return this.get('menuName') + ' - ' + this.get('locationName');
    })
  });
});