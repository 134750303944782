define("fcs-dashboard/pods/product/model", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    price: [(0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'menu.builder.steps.stepSix.productTable.headers.price' }), (0, _emberCpValidations.validator)('format', { regex: /^[\d]+(\.\d*)?$/, messageKey: 'menu.builder.steps.stepSix.errors.invalidNumber' })],
    taxRate: [(0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'menu.builder.steps.stepSix.productTable.headers.taxRate' }), (0, _emberCpValidations.validator)('format', { regex: /^(100|[0-9]{1,2})(\.[0-9]{1,2})?$/, messageKey: 'menu.builder.steps.stepSix.errors.invalidPercentage' })],
    productType: (0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'menu.builder.steps.stepSix.productTable.headers.productType' })
  });

  exports["default"] = _emberDataModel["default"].extend(Validations, {
    productCatalog: (0, _emberDataRelationships.belongsTo)('productCatalog'),
    buttonColor: (0, _emberDataAttr["default"])('string', { defaultValue: '#f8f8f8' }),
    price: (0, _emberDataAttr["default"])('number'),
    priceChange: (0, _emberDataAttr["default"])('number'),
    taxRate: (0, _emberDataAttr["default"])('number'),
    allowModifications: (0, _emberDataAttr["default"])('boolean'),
    changed: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    position: (0, _emberDataAttr["default"])('number', { defaultValue: 0 }),
    productType: (0, _emberDataAttr["default"])('string'),
    taxRateType: (0, _emberDataRelationships.belongsTo)('taxRateType'),
    productClass: (0, _emberDataRelationships.belongsTo)('productClass'),
    comboSelections: (0, _emberDataRelationships.hasMany)('comboSelection', { inverse: null }),
    modifiers: (0, _emberDataRelationships.hasMany)('modifier'),
    deletedModifiers: (0, _emberDataAttr["default"])({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    isProduct: true
  });
});