define("fcs-dashboard/pods/company/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin", "fcs-dashboard/mixins/common-mixin", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _fcsDashboardMixinsCommonMixin, _fcsDashboardMixinsBaseComponent) {
  var Route = _ember["default"].Route;
  var isEqual = _ember["default"].isEqual;
  exports["default"] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], _fcsDashboardMixinsCommonMixin["default"], _fcsDashboardMixinsBaseComponent["default"], {
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      if (this.get('session.isAuthenticated')) {
        this.store.findAll('userProfile');
      }
    },
    model: function model() {
      return this.store.query('company', {});
    },
    afterModel: function afterModel() {
      this._super.apply(this, arguments);
      if (!this.get('isBRUser')) {
        this.transitionTo('');
      }
    },
    actions: {
      willTransition: function willTransition() {
        window.stop();
        return true;
      },
      save: function save(model, passwordConfirmation) {
        var _this = this;

        this.send('cleanMessages');
        if (model.get('validations.isValid')) {
          if (model.get('isNew') && !isEqual(model.get('decryptedPassword'), passwordConfirmation)) {
            var errorMessage = this.get('notify').alert(this.get('intl').t('company.detail.errors.passwordNotMatch'), { closeAfter: 5000 });
            this.get('errorMessages').addObject(errorMessage);
          } else {
            (function () {
              model.set('password', model.get('encryptedPassword'));
              var message = '';
              if (passwordConfirmation === undefined && !model.get('isNew')) {
                message = 'company.detail.errors.successPasswordChange';
              } else {
                message = 'company.detail.errors.successSave';
              }
              model.save().then(function () {
                _this.get('notify').success(_this.get('intl').t(message));
                _this.send('cleanMessages');
              })["catch"](function (error) {
                var message = 'company.detail.errors.savingError';
                if (error !== undefined) {
                  message = "company.detail.errors." + error.errors.get(0).detail;
                }
                var errorMessage = _this.get('notify').alert(_this.get('intl').t(message, { name: model.get('name') }), { closeAfter: 5000 });
                _this.get('errorMessages').addObject(errorMessage);
              });
            })();
          }
        } else {
          model.get('validations.errors').forEach(function (error) {
            var errorMessage = _this.get('notify').alert(error.get('message'), { closeAfter: 5000 });
            _this.get('errorMessages').addObject(errorMessage);
          });
        }
      },
      cancel: function cancel(model) {
        this.send('cleanMessages');
        model.rollbackAttributes();
        this.transitionTo('company');
      }
    }
  });
});