define('fcs-dashboard/pods/components/menu/menu-list/push-menu-modal/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    locations: [],
    selectedLocations: [],
    _internalSelectedLocations: [],
    _sortCriteria: ['name'],
    _sortedLocations: computed.sort('_locations', '_sortCriteria'),
    _showLocationsContent: true,
    init: function init() {
      this._super.apply(this, arguments);
      this.get('selectedLocations').clear();
      this.get('selectedLocations').addObjects(this.get('locations'));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('locations').clear();
      this.get('selectedLocations').clear();
    },
    actions: {
      closePushMenuModal: function closePushMenuModal() {
        this.sendAction('closePushMenuModal');
      },
      pushMenu: function pushMenu() {
        var locations = [];
        this.get('selectedLocations').forEach(function (location) {
          locations.addObject(parseInt(location.id));
        });
        this.sendAction('pushMenu', this.get('menu'), locations);
      }
    }
  });
});