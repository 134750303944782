define("fcs-dashboard/mirage/factories/page", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  _emberCliMirage.faker.locale = 'en_US';

  exports["default"] = _emberCliMirage["default"].Factory.extend({
    name: _emberCliMirage.faker.lorem.sentence(1, 4),
    products: [1, 2, 3]
  });
});
/**
 * Created by aenrosello on 12/15/16.
 */