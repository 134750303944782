define("fcs-dashboard/mirage/fixtures/users", ["exports"], function (exports) {
  /**
   * Created by alberto on 11/12/15.
   */
  exports["default"] = [{
    "id": 1,
    "name": "test manager",
    "lastName": "test",
    "email": "test2@test",
    "username": "test2",
    "password": "1234",
    "state": "active",
    "updatedAt": '4/7/2016',
    "userType": 8,
    "company": 1,
    "userProfile": 1
  }, {
    "id": 2,
    "name": "Test clerk",
    "lastName": "test",
    "email": "test@test",
    "username": "test3",
    "password": '1234',
    "state": "active",
    "updatedAt": '4/7/2016',
    "userType": 8,
    "company": 1,
    "userProfile": 2
  }, {
    "id": 3,
    "name": "Test BR",
    "lastName": "Test",
    "email": "test@test",
    "username": "test",
    "password": '1234',
    "state": "active",
    "updatedAt": '4/7/2016',
    "userType": 7,
    "company": null,
    "userProfile": null
  }];
});