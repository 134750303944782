define('fcs-dashboard/pods/components/product-mix-report/product-mix-result/pmix-by-locations/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  exports['default'] = Component.extend({
    _showData: computed('data', function () {
      return this.get('data') !== null && !isEmpty(this.get('data'));
    }),
    actions: {
      onFilterChanged: function onFilterChanged(value) {
        this.sendAction('onFilterChanged', value);
      }
    }
  });
});