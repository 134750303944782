define("fcs-dashboard/pods/components/menu/menu-wizard/payment-information/component", ["exports", "ember", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var Logger = _ember["default"].Logger;
  var run = _ember["default"].run;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], {
    showLocationSelector: false,
    menuEnabledLocations: [],
    selectedLocations: [],
    refreshLocation: true,
    sortCriteria: ['name'],
    showTooltip: computed('selectedMenuLocation.noPaymentMethods', 'showLocationSelector', function () {
      return this.get('selectedMenuLocation.noPaymentMethods') && !this.get('showLocationSelector');
    }),
    sortedLocations: computed.sort('locations', 'sortCriteria'),
    locations: computed(function () {
      var locations = [];
      this.get('menu.menuLocations').forEach(function (menuLocation) {
        if (typeof menuLocation.get === 'function') {
          locations.addObject(menuLocation.get('locationObject'));
        }
      });
      return locations;
    }),
    selectedLocation: computed(function () {
      if (typeof this.get('menu.menuLocations').get('firstObject').get === 'function') {
        return this.get('menu.menuLocations').get('firstObject').get('locationObject');
      }
    }),
    selectedMenuLocation: computed('selectedLocation', function () {
      var menuLocation = undefined;
      if (this.get('selectedLocation.id') !== undefined) {
        return menuLocation = this.get('menu.menuLocations').findBy('location', this.get('selectedLocation.id'));
      } else {
        menuLocation = this.get('menu.menuLocations').get('firstObject');
      }

      this.set('selectedGateway', this.get('gateways').findBy('id', menuLocation.get('gateway')));
      return menuLocation;
    }),
    gateways: computed(function () {
      return this.get('store').query('gateway', { cashless: false, pageSize: -1 });
    }),
    gatewaySubmerchants: computed(function () {
      return this.get('store').findAll('gatewaySubmerchant');
    }),
    validationMethods: computed(function () {
      return this.get('store').findAll('validationMethodType');
    }),
    _isLoadingCreditCardInformation: false,
    _loadGateways: function _loadGateways() {
      var _this = this;

      if (this.get('gateways').then !== undefined) {
        // if a promise set the content when it resolves
        this.set('_isLoadingCreditCardInformation', true);
        this.get('gateways').then(function (content) {
          run(function () {
            Logger.debug(content.get('length') + " Gateways loaded");
            _this.set('gateways', content);
            _this.set('_isLoadingCreditCardInformation', false);
          });
        });
      }
    },
    _promotionColumns: computed(function () {
      return [{
        propertyName: 'locationGroupName',
        title: this.get('intl').t('locationGroups.details.promotions.list.table.locationGroupName')
      }, {
        propertyName: 'name',
        title: this.get('intl').t('locationGroups.details.promotions.list.table.name')
      }, {
        propertyName: 'type',
        title: this.get('intl').t('locationGroups.details.promotions.list.table.type')
      }, {
        title: this.get('intl').t('locationGroups.details.promotions.list.table.enable'),
        template: "promotion/actions/enable-action",
        className: 'editRow'
      }];
    }),
    _customFeeColumns: computed(function () {
      return [{
        propertyName: 'locationGroupName',
        title: this.get('intl').t('locationGroups.details.promotions.list.table.locationGroupName')
      }, {
        propertyName: 'name',
        title: this.get('intl').t('locationGroups.details.customFees.list.table.name')
      }, {
        propertyName: 'value',
        title: this.get('intl').t('locationGroups.details.customFees.list.table.value')
      }, {
        propertyName: 'type',
        title: this.get('intl').t('locationGroups.details.customFees.list.table.type')
      }, {
        propertyName: 'appliesTo',
        title: this.get('intl').t('locationGroups.details.customFees.list.table.appliesTo')
      }, {
        title: this.get('intl').t('locationGroups.details.customFees.list.table.taxable'),
        template: "customFee/actions/taxable-action",
        className: 'editRow'
      }, {
        title: this.get('intl').t('locationGroups.details.customFees.list.table.enable'),
        template: "customFee/actions/enable-action",
        className: 'editRow'
      }];
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this._loadGateways();
    },
    actions: {
      nextStep: function nextStep() {
        this.send('cleanMessages');
        var isValid = true;
        this.sendAction('nextStep', isValid);
      },
      previousStep: function previousStep() {
        this.sendAction('previousStep');
      },
      showMenuList: function showMenuList() {
        this.sendAction('showMenuList');
      },
      save: function save() {
        this.sendAction('save');
      },
      changeSelectedLocation: function changeSelectedLocation(location) {
        var _this2 = this;

        this.set('refreshLocation', false);
        this.set('selectedLocation', location);
        run.next(function () {
          _this2.set('refreshLocation', true);
        });
      },
      enablePromotion: function enablePromotion(promotion) {
        promotion.set('enabled', true);
      },
      disablePromotion: function disablePromotion(promotion) {
        promotion.set('enabled', false);
      },
      enableCustomFee: function enableCustomFee(customFee) {
        customFee.set('enabled', true);
      },
      disableCustomFee: function disableCustomFee(customFee) {
        customFee.set('enabled', false);
      },
      openLocationsSelectors: function openLocationsSelectors() {
        this.get('menuEnabledLocations').clear();
        this.get('menuEnabledLocations').addObjects(this.get('menu.menuLocations').mapBy('locationObject'));
        this.set('showLocationSelector', true);
      },
      closeLocationsSelectors: function closeLocationsSelectors() {
        // Clear the selected locations
        this.get('selectedLocations').clear();
        this.set('showLocationSelector', false);
      },
      copyConfigurations: function copyConfigurations() {
        var _this3 = this;

        Logger.info(this.get('selectedLocations'));

        // Check all menu locations
        this.get('menu.menuLocations').forEach(function (menuLocation) {

          // Compare menuLocations by selected locations
          var updateMenuLocation = _this3.get('selectedLocations').findBy('id', menuLocation.get('location'));

          // If the menuLocation.location matches a selected location copy all info
          if (updateMenuLocation) {
            menuLocation.set('creditCardSurchargeEnabled', _this3.get('selectedMenuLocation.creditCardSurchargeEnabled'));
            menuLocation.set('patronLoadedFundsEnabled', _this3.get('selectedMenuLocation.patronLoadedFundsEnabled'));
            menuLocation.set('creditCardSurchargePercentage', _this3.get('selectedMenuLocation.creditCardSurchargePercentage'));
            menuLocation.set('openLoopSurchargeEnabled', _this3.get('selectedMenuLocation.openLoopSurchargeEnabled'));
            menuLocation.set('openLoopSurchargePercentage', _this3.get('selectedMenuLocation.openLoopSurchargePercentage'));
            menuLocation.set('cash', _this3.get('selectedMenuLocation.cash').copy());
            menuLocation.set('manualCredit', _this3.get('selectedMenuLocation.manualCredit').copy());
            menuLocation.set('swipeCredit', _this3.get('selectedMenuLocation.swipeCredit').copy());
            menuLocation.set('chipCredit', _this3.get('selectedMenuLocation.chipCredit').copy());
            menuLocation.set('cashless', _this3.get('selectedMenuLocation.cashless').copy());
            menuLocation.set('gateway', _this3.get('selectedMenuLocation.gateway'));
            menuLocation.set('noGateway', _this3.get('selectedMenuLocation.noGateway'));
            menuLocation.set('updateCheckpoint', _this3.get('selectedMenuLocation.updateCheckpoint'));
            menuLocation.set('noPaymentMethods', _this3.get('selectedMenuLocation.noPaymentMethods'));
            menuLocation.set('customFees', _this3.get('selectedMenuLocation.customFees').copy());
            menuLocation.set('promotions', _this3.get('selectedMenuLocation.promotions').copy());
            menuLocation.set('creditEnabled', _this3.get('selectedMenuLocation.creditEnabled'));
          }
        });

        // Clear the selected locations
        this.get('selectedLocations').clear();
        // Hide locations selector
        this.set('showLocationSelector', false);
      }
    }
  });
});