define("fcs-dashboard/pods/components/user-mng/list/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/table-component", "fcs-dashboard/mixins/user-mixin", "fcs-dashboard/mixins/common-mixin"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsTableComponent, _fcsDashboardMixinsUserMixin, _fcsDashboardMixinsCommonMixin) {
  var Component = _ember["default"].Component;
  var isEqual = _ember["default"].isEqual;
  var computed = _ember["default"].computed;
  var observer = _ember["default"].observer;
  var Logger = _ember["default"].Logger;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], _fcsDashboardMixinsTableComponent["default"], _fcsDashboardMixinsUserMixin["default"], _fcsDashboardMixinsCommonMixin["default"], {
    metaItemsCountProperty: 'total',
    data: [],
    showUserListModal: false,
    showPasswordModal: false,
    _record: null,
    _columns: computed(function () {
      var hideCompanyColumn = !this.get('isBRUser');
      var availableStatuses = this.get('statuses');
      var availableUserProfiles = undefined;
      if (this.get('isBRUser')) {
        availableUserProfiles = this.get('bradminUserProfiles');
      } else {
        availableUserProfiles = this.get('userProfiles');
      }

      return [{
        title: '',
        template: "user/edit-action",
        className: 'editRow'
      }, {
        propertyName: 'name',
        title: this.get('intl').t('user.list.table.name')
      }, {
        propertyName: 'lastName',
        title: this.get('intl').t('user.list.table.lastName')
      }, {
        propertyName: 'username',
        filteredBy: 'sUsername',
        title: this.get('intl').t('user.list.table.username')
      }, {
        propertyName: 'email',
        title: this.get('intl').t('user.list.table.email')
      }, {
        propertyName: 'userProfile.name',
        filteredBy: 'userProfileName',
        title: this.get('intl').t('user.list.table.userProfile'),
        className: 'userProfileRow',
        filterWithSelect: true,
        predefinedFilterOptions: availableUserProfiles
      }, {
        propertyName: 'translatedState',
        filteredBy: 'state',
        title: this.get('intl').t('user.list.table.state'),
        filterWithSelect: true,
        predefinedFilterOptions: availableStatuses,
        className: 'stateRow',
        filterFunction: function filterFunction(cell, neededStr, obj) {
          return isEqual(obj.get('translatedState').toLowerCase(), neededStr);
        }
      }, {
        propertyName: 'company.name',
        filteredBy: 'companyName',
        title: this.get('intl').t('user.list.table.company'),
        isHidden: hideCompanyColumn
      }, {
        title: '',
        template: "user/reset-password-email-action",
        className: 'changePasswordRow'
      }, {
        title: '',
        template: "user/change-password-action",
        className: 'changePasswordRow'
      }];
    }),
    _checkCompanyLock: observer('lockedCompany', function () {
      Logger.debug('calling refresh');
      this.sendAction('refreshAction');
    }),
    actions: {
      showChangePasswordModal: function showChangePasswordModal(record) {
        this.set('_record', record);
        this.set('showPasswordModal', true);
      },
      changePassword: function changePassword(model, password) {
        model.set('password', password);
        this.set('showPasswordModal', false);
        this.sendAction('save', model);
      },
      closeModal: function closeModal() {
        this.set('showPasswordModal', false);
      },
      resetPasswordEmail: function resetPasswordEmail(record) {
        var _this = this;

        var userInfo = {
          username: record.get('username'),
          email: record.get('email')
        };

        var path = '/api/v1/passwordResets/forgotPassword';
        var _window$location = window.location;
        var protocol = _window$location.protocol;
        var host = _window$location.host;

        var url = protocol + "//" + host + path;
        var options = {
          data: userInfo,
          url: url,
          type: 'POST'
        };
        options.success = function () {
          var errorMessage = _this.get('notify').success(_this.get('intl').t('user.list.messages.passwordResetEmailSent'), { closeAfter: 5000 });
          _this.get('errorMessages').addObject(errorMessage);
        };
        options.error = function () {
          console.log('error');
        };
        _ember["default"].$.ajax(options);
      },
      showImportUserList: function showImportUserList() {
        this.set('showUserListModal', true);
      },
      closeImportUsersModal: function closeImportUsersModal() {
        this.set('showUserListModal', false);
      },
      importUsersSuccess: function importUsersSuccess() {
        this.set('showUserListModal', false);
        this.sendAction('refreshAction');
      },
      gotoFirst: function gotoFirst() {
        // console.log('first')
      }
    }
  });
});