define("fcs-dashboard/pods/components/simple-report-by-product/simple-report-result/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/table-component", "fcs-dashboard/mixins/company-mixin"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsTableComponent, _fcsDashboardMixinsCompanyMixin) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], _fcsDashboardMixinsTableComponent["default"], _fcsDashboardMixinsCompanyMixin["default"], {
    data: null,
    hideProductColumn: false,
    _salesTableColumns: computed(function () {
      return [{
        title: this.get('intl').t('report.result.itemDescription'),
        propertyName: 'product',
        isHidden: this.get('hideProductColumn')
      }, {
        title: this.get('intl').t('report.result.productType'),
        propertyName: 'productType'
      }, {
        className: 'sales-cell',
        propertyName: 'regPriceSales',
        template: 'simpleReportByProduct/amountCell'
      }, {
        title: this.get('intl').t('report.result.grossSales'),
        className: 'sales-cell',
        propertyName: 'grossSales',
        template: 'simpleReportByProduct/amountCell'
      }, {
        className: 'sales-cell',
        propertyName: 'cashGrossSales',
        template: 'simpleReportByProduct/amountCell'
      }, {
        className: 'sales-cell',
        propertyName: 'creditGrossSales',
        template: 'simpleReportByProduct/amountCell'
      }, {
        title: this.get('intl').t('report.result.rfidGrossSales'),
        className: 'sales-cell',
        propertyName: 'openLoopGrossSales',
        template: 'simpleReportByProduct/amountCell'
      }, {
        title: this.get('intl').t('report.result.percentageTotal'),
        className: 'sales-percentage-total-cell',
        propertyName: 'percentageTotal',
        template: 'simpleReportByProduct/percentageCell'
      }, {
        title: this.get('intl').t('report.result.itemsQuantity'),
        className: 'sales-orders-quantity-cell',
        propertyName: 'itemsSold'
      }, {
        title: this.get('intl').t('report.result.dolarsPerItem'),
        className: 'sales-dolars-per-order-cell',
        propertyName: 'avgPrice',
        template: 'simpleReportByProduct/amountCell'
      }];
    })
  });
});