define("fcs-dashboard/pods/components/menu/menu-locations/component", ["exports", "ember", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], {
    selectedLocations: [],
    _sortCriteria: ['name'],
    _sortedCompanies: computed.sort('_companies', '_sortCriteria'),
    locations: [],
    actions: {
      closeModal: function closeModal() {
        this.sendAction('close');
      },
      addLocations: function addLocations() {
        this.sendAction('saveAction');
      }
    }
  });
});