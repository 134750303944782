define('fcs-dashboard/mirage/factories/dailySalesSummary', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  _emberCliMirage.faker.locale = 'en_US';

  exports['default'] = _emberCliMirage['default'].Factory.extend({
    sales: _emberCliMirage.faker.commerce.price,
    taxes: _emberCliMirage.faker.commerce.price,
    voids: _emberCliMirage.faker.commerce.price,
    comps: _emberCliMirage.faker.commerce.price,
    declines: _emberCliMirage.faker.commerce.price,
    spills: _emberCliMirage.faker.commerce.price,
    refunds: _emberCliMirage.faker.commerce.price,
    coupons: _emberCliMirage.faker.commerce.price,
    discounts: _emberCliMirage.faker.commerce.price,
    totalDeductions: _emberCliMirage.faker.commerce.price,
    netSales: _emberCliMirage.faker.commerce.price,
    totalSales: _emberCliMirage.faker.commerce.price,
    nonCashSalesPayments: _emberCliMirage.faker.commerce.price,
    nonCashTips: _emberCliMirage.faker.commerce.price,
    netCashDeposit: _emberCliMirage.faker.commerce.price,
    cashPayments: _emberCliMirage.faker.commerce.price
  });
});
/**
 * Created by ernesto on 5/03/17.
 */