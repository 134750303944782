define("fcs-dashboard/pods/components/product-catalog/meta-product-catalog/details/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/table-component", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsTableComponent, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var sort = _ember["default"].computed.sort;
  var A = _ember["default"].A;
  var run = _ember["default"].run;
  exports["default"] = Component.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], _fcsDashboardMixinsBaseComponent["default"], _fcsDashboardMixinsTableComponent["default"], {
    columns: computed(function () {
      return [{
        template: "meta-product-catalog/actions/row-select-checkbox",
        useFilter: false,
        mayBeHidden: false,
        templateForSortCell: "meta-product-catalog/actions/row-select-all-checkbox"
      }, {
        propertyName: 'name',
        filteredBy: 'name',
        title: this.get('intl').t('metaProductCatalog.modal.attributeSet.name')
      }, {
        propertyName: 'content',
        filteredBy: 'content',
        title: this.get('intl').t('metaProductCatalog.modal.attributeSet.attributes')
      }];
    }),
    isLoading: true,
    generatedProductCatalogs: A(),
    selectedModels: A(),
    data: [],
    displayAttributeSetList: false,
    customEnabled: false,
    _showGeneratedOptions: false,
    _sortCriteria: ['name'],
    _sortedCompanies: sort('_companies', '_sortCriteria'),
    _companies: computed(function () {
      return this.get('store').query('company', { pageSize: -1 });
    }),
    _showCompanies: computed(function () {
      return this.get('metaProductCatalog.isNew') && this.get('isBRUser') && this.get('lockedCompany') === undefined && this.get('metaProductCatalog.companyId') === undefined;
    }),
    _showProductType: computed('metaProductCatalog.companyId', function () {
      return this.get('metaProductCatalog.companyId') !== undefined;
    }),
    _productTypes: computed('metaProductCatalog.companyId', function () {
      if (this.get('metaProductCatalog.companyId')) {
        return this.get('store').query('productType', { companyId: this.get('metaProductCatalog.companyId'), pageSize: -1 });
      }
    }),
    _sortedProductTypes: sort('_productTypes', '_sortCriteria'),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('selectedModels', null);

      //initialize attributeSets
      if (this.get('data').then === undefined) {
        // no promise, lets load the data
        this.set('data', this.get('data'));
        this.set('displayAttributeSetList', true);
      } else {
        // if a promise set the content when it resolves
        this.get('data').then(function (content) {
          run(function () {
            _this.set('data', content);
            _this.set('displayAttributeSetList', true);
          });
        });
      }
    },
    actions: {
      clearAllSelected: function clearAllSelected() {
        var _this2 = this;

        this.set('displayAttributeSetList', false);
        run.next(function () {
          _this2.get('selectedModels').clear();
          _this2.set('displayAttributeSetList', true);
        });
      },
      generateProductCatalogs: function generateProductCatalogs() {
        var _this3 = this;

        this.get('metaProductCatalog.attributeSets').clear();
        this.get('metaProductCatalog.attributeSets').addObjects(this.get('selectedModels'));
        if (this.get('metaProductCatalog.validations.isValid')) {
          this.get('metaProductCatalog').save().then(function (metaProduct) {
            _this3.set('metaProductCatalog', metaProduct);
            _this3.set('isLoading', false);
            _this3.set('_showGeneratedOptions', true);
          })["catch"](function (error) {
            var message = 'metaProductCatalog.modal.errors.savingError';
            if (error !== undefined) {
              message = "metaProductCatalog.modal.errors." + error.errors.get(0).detail;
            }
            var errorMessage = _this3.get('notify').alert(_this3.get('intl').t(message, { name: _this3.get('metaProductCatalog.name') }), { closeAfter: 5000 });
            _this3.get('errorMessages').addObject(errorMessage);
            _this3.get('metaProductCatalog').rollbackAttributes();
            _this3.refresh();
          });
        } else {
          this.send('cleanMessages');
          this.get('metaProductCatalog.validations.errors').forEach(function (error) {
            var errorMessage = _this3.get('notify').alert(error.get('message'), { closeAfter: 5000 });
            _this3.get('errorMessages').addObject(errorMessage);
          });
        }
      },
      closeMetaProductCatalogModal: function closeMetaProductCatalogModal() {
        this.sendAction('closeMetaProductCatalogModal');
      },
      acceptMetaProductCatalogModal: function acceptMetaProductCatalogModal(selectedProductCatalogs) {
        var _this4 = this;

        this.get('metaProductCatalog.generatedProductCatalogs').clear();
        var newProductCatalogs = [];
        selectedProductCatalogs.forEach(function (productCatalog) {
          var newProductCatalog = _this4.get('store').createRecord('productCatalog');
          newProductCatalog.set('name', productCatalog.get('name'));
          newProductCatalog.set('suggestedPrice', productCatalog.get('suggestedPrice'));
          newProductCatalog.set('productType', productCatalog.get('productType'));
          newProductCatalog.set('attributes', productCatalog.get('attributes'));
          newProductCatalog.set('company', productCatalog.get('company'));
          newProductCatalogs.addObject(newProductCatalog);
        });
        this.set('metaProductCatalog.generatedProductCatalogs', newProductCatalogs);
        this.get('metaProductCatalog').save().then(function () {
          _this4.get('notify').success(_this4.get('intl').t('productCatalog.modal.errors.successSave', { name: _this4.get('metaProductCatalog.name') }));
          _this4.sendAction('refreshAction');
        })["catch"](function (error) {
          var message = 'productCatalog.modal.errors.savingError';
          if (error !== undefined) {
            message = "productCatalog.modal.errors." + error.errors.get(0).detail;
          }
          var errorMessage = _this4.get('notify').alert(_this4.get('intl').t(message, { name: _this4.get('metaProductCatalog.name') }), { closeAfter: 5000 });
          _this4.get('errorMessages').addObject(errorMessage);
          _this4.get('metaProductCatalog').rollbackAttributes();
          _this4.refresh();
        });
      },
      selectCompany: function selectCompany(company) {
        this.get('metaProductCatalog').set('companyId', company.get('id'));
        this.get('metaProductCatalog').set('companyName', company.get('name'));
        this.set('selectedCompany', company);
      }
    }
  });
});