define('fcs-dashboard/pods/components/menu/menu-wizard/receipt-options/component', ['exports', 'ember', 'fcs-dashboard/mixins/base-component', 'fcs-dashboard/mixins/common-mixin'], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsCommonMixin) {
  exports['default'] = _ember['default'].Component.extend(_fcsDashboardMixinsBaseComponent['default'], _fcsDashboardMixinsCommonMixin['default'], {
    availableTenders: _ember['default'].computed(function () {
      return this.get('store').peekAll('tenderType');
    }),
    selectedPrintableTenders: _ember['default'].computed('menu.receipt.printableTendersModels', function () {
      if (this.get('menu.receipt.printableTenders')) {
        return this.get('menu.receipt.printableTendersModels');
      }
    }),
    selectedOpenCashDrawerTenders: _ember['default'].computed('menu.openCashDrawerTenders', function () {
      if (this.get('menu.openCashDrawerTenders')) {
        return this.get('menu.openCashDrawerTenders');
      }
    }),
    selectedOpenCashDrawerTendersObserver: _ember['default'].observer('selectedOpenCashDrawerTenders', function () {
      this.set('menu.changed', true);
      this.get('menu.openCashDrawerTenders').clear();
      this.get('menu.openCashDrawerTenders').addObjects(this.get('selectedOpenCashDrawerTenders'));
    }),
    selectedPrintableTendersObserver: _ember['default'].observer('selectedPrintableTenders', function () {
      var _this = this;

      this.set('menu.changed', true);
      this.set('menu.receipt.printableTendersModels', this.get('selectedPrintableTenders'));
      this.get('menu.receipt.printableTenders').clear();
      this.get('menu.receipt.printableTendersModels').forEach(function (selectedTender) {
        _this.get('menu.receipt.printableTenders').addObject(selectedTender.get('id'));
      });
    }),
    allSelectedObserver: _ember['default'].observer('selectedOpenCashDrawerTenders', function () {
      this.set('allSelected', _ember['default'].isEqual(this.get('availableTenders.length'), this.get('selectedOpenCashDrawerTenders.length')));
    }),
    defaultReceiptTypes: _ember['default'].computed(function () {
      return this.get('store').findAll('receiptType');
    }),
    selectedDefaultReceiptTender: _ember['default'].computed('menu.receipt.defaultReceiptType', function () {
      if (this.get('menu.receipt.defaultReceiptType') !== undefined) {
        return this.get('store').findRecord('receiptType', this.get('menu.receipt.defaultReceiptType'));
      }
    }),
    logoName: _ember['default'].computed('menu.receipt.logo', function () {
      return this.get('menu.receipt.logo') ? 'logo' : null;
    }),
    logoPreview: _ember['default'].computed('menu.receipt.logo', function () {
      var url = this.get('menu.receipt.logo');
      return _ember['default'].String.htmlSafe('background-image: url("' + url + '")');
    }),
    showPrintOptions: _ember['default'].computed('selectedPrintableTenders', function () {
      var availableTenders = this.get('availableTenders.length');
      var selectedTenders = this.get('selectedPrintableTenders.length');
      return availableTenders === selectedTenders;
    }),
    inputCustomerCopies: _ember['default'].computed(function () {
      return this.get('menu.receipt.customerCopies');
    }),
    inputCustomerCopiesObserver: _ember['default'].observer('inputCustomerCopies', function () {
      this.set('menu.receipt.customerCopies', parseInt(this.get('inputCustomerCopies')));
    }),
    inputMerchantCopies: _ember['default'].computed(function () {
      return this.get('menu.receipt.merchantCopies');
    }),
    inputMerchantCopiesObserver: _ember['default'].observer('inputMerchantCopies', function () {
      this.set('menu.receipt.merchantCopies', parseInt(this.get('inputMerchantCopies')));
    }),
    allSelected: false,
    headerError: '',
    footerError: '',
    address1Error: '',
    address2Error: '',
    defaultReceiptTypeError: '',
    actions: {
      nextStep: function nextStep() {
        this.send('cleanMessages');
        var isValid = true;
        var errorMessage = undefined;
        if (this.get('menu.receipt.enabled')) {
          if (this.get('menu.receipt.validations.isValid')) {
            if (_ember['default'].isEmpty(this.get('menu.receipt.header'))) {
              isValid = false;
              this.set('headerError', _ember['default'].String.htmlSafe('has-error alert-danger'));
              errorMessage = this.get('notify').alert(this.get('intl').t('menu.builder.steps.stepFive.design.errors.missingHeader'), { closeAfter: 5000 });
              this.get('errorMessages').addObject(errorMessage);
            }
            if (_ember['default'].isEmpty(this.get('menu.receipt.footer'))) {
              isValid = false;
              this.set('footerError', _ember['default'].String.htmlSafe('has-error alert-danger'));
              errorMessage = this.get('notify').alert(this.get('intl').t('menu.builder.steps.stepFive.design.errors.missingFooter'), { closeAfter: 5000 });
              this.get('errorMessages').addObject(errorMessage);
            }
            if (_ember['default'].isEmpty(this.get('menu.receipt.address1'))) {
              isValid = false;
              this.set('address1Error', _ember['default'].String.htmlSafe('has-error alert-danger'));
              errorMessage = this.get('notify').alert(this.get('intl').t('menu.builder.steps.stepFive.design.errors.missingAddress1'), { closeAfter: 5000 });
              this.get('errorMessages').addObject(errorMessage);
            }
            if (_ember['default'].isEmpty(this.get('menu.receipt.address2'))) {
              isValid = false;
              this.set('address2Error', _ember['default'].String.htmlSafe('has-error alert-danger'));
              errorMessage = this.get('notify').alert(this.get('intl').t('menu.builder.steps.stepFive.design.errors.missingAddress2'), { closeAfter: 5000 });
              this.get('errorMessages').addObject(errorMessage);
            }
            if (this.get('menu.receipt.defaultReceiptType') === undefined) {
              isValid = false;
              this.set('defaultReceiptTypeError', _ember['default'].String.htmlSafe('has-error alert-danger'));
              errorMessage = this.get('notify').alert(this.get('intl').t('menu.builder.steps.stepFive.defaultReceiptTender.errors.anyOptionSelected'), { closeAfter: 5000 });
              this.get('errorMessages').addObject(errorMessage);
            }
          } else {
            isValid = false;
            this.send('displayErrorMessages', this.get('menu.receipt.validations.errors'));
          }
        }
        this.sendAction('nextStep', isValid);
      },
      previousStep: function previousStep() {
        this.sendAction('previousStep');
      },
      showMenuList: function showMenuList() {
        this.sendAction('showMenuList');
      },
      printReceiptAllTendersChanged: function printReceiptAllTendersChanged(value) {
        this.set('showPrintOptions', value);
        if (value) {
          this.set('selectedPrintableTenders', this.get('availableTenders'));
        } else {
          this.set('selectedPrintableTenders', []);
        }
      },
      defaultReceiptTendersSelected: function defaultReceiptTendersSelected(receiptType) {
        this.set('menu.receipt.defaultReceiptType', receiptType.get('id'));
      },
      uploadLogo: function uploadLogo(fileList) {
        var _this2 = this;

        var re = new RegExp('image/*');
        if (re.test(fileList[0].type)) {
          (function () {
            var that = _this2;
            _this2.set('logoName', fileList[0].name);
            var reader = new FileReader();

            reader.onloadend = function () {
              that.set('menu.receipt.logo', reader.result);
            };

            reader.readAsDataURL(fileList[0]);
          })();
        } else {
          _ember['default'].Logger.debug('not an image.');
        }
      },
      removeLogo: function removeLogo() {
        this.set('logoName', null);
        this.set('menu.receipt.logo', null);
      },
      selectAllOpenDrawerTypes: function selectAllOpenDrawerTypes() {
        if (this.get('allSelected')) {
          this.set('selectedOpenCashDrawerTenders', []);
        } else {
          this.set('selectedOpenCashDrawerTenders', this.get('availableTenders'));
        }
      },
      updateOpenCashDrawerTendersSelection: function updateOpenCashDrawerTendersSelection(newSelection) {
        this.set('selectedOpenCashDrawerTenders', newSelection);
      },
      updatePrintableTendersSelection: function updatePrintableTendersSelection(newSelection) {
        this.set('selectedPrintableTenders', newSelection);
      },
      save: function save() {
        this.sendAction('save');
      }
    }
  });
});