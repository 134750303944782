define("fcs-dashboard/pods/menu/serializer", ["exports", "ember", "ember-data"], function (exports, _ember, _emberData) {
  exports["default"] = _emberData["default"].RESTSerializer.extend(_emberData["default"].EmbeddedRecordsMixin, {
    attrs: {
      company: { embedded: 'always' },
      categories: { embedded: 'always' },
      modifiers: { embedded: 'always' }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      if (payload.hasOwnProperty('extendedMenu')) {
        payload.menu = payload.extendedMenu;
        delete payload.extendedMenu;
      }

      return this._super.apply(this, arguments);
    },
    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      var serialized = record.serialize(record, options);

      //remove the root element
      _ember["default"].merge(hash, serialized);
    }
  });
});