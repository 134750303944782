define('fcs-dashboard/pods/location-group/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'fcs-dashboard/mixins/common-mixin', 'fcs-dashboard/mixins/base-component'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _fcsDashboardMixinsCommonMixin, _fcsDashboardMixinsBaseComponent) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _fcsDashboardMixinsCommonMixin['default'], _fcsDashboardMixinsBaseComponent['default'], {
    model: function model() {
      this.store.unloadAll('promotionImage');
      return this.store.query('locationGroup', { reload: true, isActive: true });
      // return preload(this.store.findAll('locationGroup', {reload: true}), ['location', {companyRoles: 'locations'}]);
    },
    afterModel: function afterModel() {
      this._super.apply(this, arguments);
      if (!this.get('isBRUser')) {
        this.transitionTo('');
      }
    },
    actions: {
      willTransition: function willTransition() {
        window.stop();
        return true;
      },
      saveLocationGroup: function saveLocationGroup(item) {
        var _this = this;

        item.save().then(function () {
          _this.get('notify').success(_this.get('intl').t('locationGroups.details.errors.successSave', {
            locationGroupType: item.get('locationGroupType.name'),
            name: item.get('name')
          }));
          _this.refresh();
          _this.transitionTo('locationGroup');
        })['catch'](function (error) {
          if (error !== undefined) {
            message = 'locationGroups.details.errors.' + error.errors.get(0).detail;
          }
          var message = 'locationGroups.details.errors.savingError';
          var errorMessage = _this.get('notify').alert(_this.get('intl').t(message, {
            locationGroupType: item.get('locationGroupType.name'),
            name: item.get('name')
          }), { closeAfter: 5000 });
          _this.get('errorMessages').addObject(errorMessage);
        });
      },
      refreshList: function refreshList() {
        this.refresh();
        this.transitionTo('locationGroup');
      }
    }
  });
});