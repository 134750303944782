define('fcs-dashboard/pods/components/select-button/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var observer = _ember['default'].observer;
  var computed = _ember['default'].computed;
  var htmlSafe = _ember['default'].String.htmlSafe;
  exports['default'] = Component.extend({
    active: computed('category.activeCategoryButton', function () {
      var activeValue = false;
      if (this.get('category') !== undefined && this.get('category.activeCategoryButton') !== undefined && this.get('category.activeCategoryButton')) {
        activeValue = true;
      }
      return activeValue;
    }),
    _disabledClass: null,
    _activeClass: null,
    _checkButtonState: observer('category.activeCategoryButton', function () {
      this._setButtonState(this.get('category.activeCategoryButton'));
    }),
    _setButtonState: function _setButtonState(isButtonActive) {
      if (isButtonActive) {
        this.set('_activeClass', htmlSafe('active'));
        this.set('_disabledClass', false);
      } else {
        this.set('_activeClass', htmlSafe(''));
        this.set('_disabledClass', true);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this._setButtonState(this.get('active'));
    }
  });
});