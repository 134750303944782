define("fcs-dashboard/pods/components/product-type/details/component", ["exports", "ember", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var Logger = _ember["default"].Logger;
  var run = _ember["default"].run;
  var sort = _ember["default"].computed.sort;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], {
    palette: [["#f8f8f8"], ["#e06666", "#f6b26b", "#ffd966", "#93c47d", "#76a5af", "#6fa8dc", "#8e7cc3", "#c27ba0"]],
    productType: undefined,
    _sortCriteria: ['name'],
    _sortedCompanies: sort('_companies', '_sortCriteria'),
    _selectedPrinterType: null,
    printerTypes: computed(function () {
      return this.get('store').findAll('printerType');
    }),
    _companies: computed(function () {
      return this.get('store').query('company', { pageSize: -1 });
    }),
    _showCompanies: computed(function () {
      return this.get('productType.isNew') && this.get('isBRUser') && this.get('lockedCompany') === undefined;
    }),
    /**
     * Ember's hook method
     */
    init: function init() {
      this._super.apply(this, arguments);
      this._initializePrinterTypes();
    },
    _initializePrinterTypes: function _initializePrinterTypes() {
      var _this = this;

      Logger.debug('Loading Printer Types');
      if (this.get('printerTypes').then !== undefined) {
        this.get('printerTypes').then(function (content) {
          run(function () {
            Logger.debug('Loaded Printer Types');
            _this.set('printerTypes', content);
            if (_this.get('productType.printerType.id') !== null && _this.get('productType.printerType.id') !== undefined) {
              _this.set('_selectedPrinterType', _this.get('productType.printerType'));
            } else {
              // Set default Printer Type 'None' = 1
              var defaultPrinterType = _this.get('printerTypes').filterBy('id', '1');
              _this.set('productType.printerType', defaultPrinterType.get(0));
              _this.set('_selectedPrinterType', defaultPrinterType.get(0));
            }
          });
        });
      }
    },
    actions: {
      saveAction: function saveAction() {
        this.sendAction('saveAction', this.get('productType'));
      },
      cancelAction: function cancelAction() {
        this.sendAction('cancelAction', this.get('productType'));
      },
      selectType: function selectType(item) {
        this.set('productType.printerType', item);
        this.set('_selectedPrinterType', item);
      },
      selectCompany: function selectCompany(company) {
        this.get('productType').set('companyId', company.get('id'));
        this.get('productType').set('companyName', company.get('name'));
        this.set('selectedCompany', company);
      }
    }
  });
});