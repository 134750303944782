define("fcs-dashboard/pods/components/saved-network/saved-network-list/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/table-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsTableComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var isEmpty = _ember["default"].isEmpty;
  var isBlank = _ember["default"].isBlank;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], _fcsDashboardMixinsTableComponent["default"], {
    refreshList: true,
    currentSavedNetwork: undefined,
    ssid: '',
    password: '',
    columns: computed(function () {
      return [{
        title: '',
        template: "saved-network/edit-cell",
        className: 'editRow'
      }, {
        propertyName: 'ssid',
        title: this.get('intl').t('savedNetwork.list.ssid')
      }, {
        propertyName: 'password',
        title: this.get('intl').t('savedNetwork.list.password')
      }, {
        title: '',
        template: "saved-network/delete-cell",
        className: 'editRow'
      }];
    }),
    actions: {
      deleteSavedNetwork: function deleteSavedNetwork(item) {
        item.set('deleted', true);
        this.sendAction('save', item);
      },
      openEditModal: function openEditModal(item) {
        this.set('currentSavedNetwork', item);
        this.set('showEditModal', true);
      },
      saveAction: function saveAction() {
        if (!isEmpty(this.get('ssid')) || !isEmpty(this.get('password')) || !isBlank(this.get('ssid')) || !isBlank(this.get('password'))) {
          var savedNetwork = this.get('store').createRecord('savedNetwork', {
            ssid: this.get('ssid'),
            password: this.get('password')
          });
          this.set('ssid', '');
          this.set('password', '');
          this.sendAction('save', savedNetwork);
        } else {
          this.get('notify').alert(this.get('intl').t('savedNetwork.list.errors.blankValues'), { closeAfter: 3000 });
        }
      },
      editAction: function editAction(savedNetwork) {
        this.sendAction('save', savedNetwork);
      },
      inputSsidUp: function inputSsidUp(value) {
        this.set('ssid', value);
      },
      inputPasswordUp: function inputPasswordUp(value) {
        this.set('password', value);
      },
      closeModal: function closeModal(item) {
        this.send('cleanMessages');
        this.set('showEditModal', false);
        item.rollbackAttributes();
      }
    }
  });
});