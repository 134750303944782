define("fcs-dashboard/pods/combo-selection-catalog/model", ["exports", "ember-data/model", "ember-data/attr", "model-fragments/attributes"], function (exports, _emberDataModel, _emberDataAttr, _modelFragmentsAttributes) {
  exports["default"] = _emberDataModel["default"].extend({
    productCatalogId: (0, _emberDataAttr["default"])('number'),
    position: (0, _emberDataAttr["default"])('number'),
    displayText: (0, _emberDataAttr["default"])('string'),
    deleted: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    comboSelectionProductCatalogList: (0, _modelFragmentsAttributes.fragmentArray)('comboSelectionProductCatalog'),
    deletedComboSelectionProductCatalogList: (0, _emberDataAttr["default"])({
      defaultValue: function defaultValue() {
        return [];
      }
    })
  });
});