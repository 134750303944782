define('fcs-dashboard/pods/components/menu/menu-wizard/category-view/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    viewSelected: undefined,
    actions: {
      changeSelectedView: function changeSelectedView(selectedTab) {
        this.set('viewSelected', selectedTab);
        this.sendAction('onChangeTabView', this.get('viewSelected'));
      }
    }
  });
});