define('fcs-dashboard/pods/components/input-promotion-minimum-spent-value/component', ['exports', 'ember'], function (exports, _ember) {
  var TextField = _ember['default'].TextField;
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  exports['default'] = TextField.extend({
    tagName: 'input',
    type: 'number',
    maxValue: 100,
    showTooltip: false,
    value: computed('value', function () {
      return 0;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      var value = 0;
      if (!isEmpty(this.get('value'))) {
        value = parseFloat(this.get('value'));
      }
      this.set('value', value);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      this.$().keyup(function (key) {
        if (key.target.value > _this.get('maxValue')) {
          key.target.value = _this.get('maxValue');
          return true;
        }
        if (key.target.value % 1 !== 0) {
          key.target.value = Math.round(key.target.value * 1000) / 1000;
        }
      });
      this.$().keypress(function (key) {
        return !(key.keyCode !== 46 && key.keyCode > 31 && (key.keyCode < 48 || key.keyCode > 57));
      });
      this.$().focusout(function () {
        if (!/^(10000|[0-9]{1,2})(\.[0-9]{1,2})?$/.test(_this.get('value'))) {
          _this.set('showTooltip', true);
        } else {
          _this.set('value', parseFloat(_this.get('value')));
        }
      });
      this.$().focusin(function () {
        return _this.set('showTooltip', false);
      });
    }
  });
});