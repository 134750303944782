define("fcs-dashboard/pods/menu/new/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _fcsDashboardMixinsBaseComponent) {
  var Route = _ember["default"].Route;
  var Logger = _ember["default"].Logger;
  var isEqual = _ember["default"].isEqual;
  exports["default"] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], _fcsDashboardMixinsBaseComponent["default"], {
    renderTemplate: function renderTemplate() {
      this.render('menu/new', { into: 'application' });
    },
    model: function model() {
      var company = undefined;
      if (this.get('isBRUser')) {
        //since a menu cannot be create without being locked to a company
        //get locked company's id
        company = this.get('lockedCompany');
      } else {
        company = this.get('loggedAccount.company');
      }
      return this.store.createRecord('menu', {
        orderSendingType: this.store.findRecord('orderSendingType', 1),
        company: company,
        menuProductTypes: []
      });
    },
    afterModel: function afterModel(model) {
      var _this = this;

      var company = '';
      if (this.get('isBRUser')) {
        //since a menu cannot be create without being locked to a company
        //get locked company's id
        company = this.get('lockedCompany.id');
      }
      this.store.query('productType', { companyId: company, pageSize: -1 }).then(function (productTypes) {
        productTypes.forEach(function (productType) {
          model.get('menuProductTypes').addObject(_this.store.createFragment('menuProductType', { productType: productType.get('id') }));
        });
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('showModal', false);
      controller.set('saveEnabled', true);
    },
    cleanupMenu: function cleanupMenu() {
      Logger.debug('Cleaning menu and relationships.');
      this.store.unloadAll('product');
      this.store.unloadAll('page');
      this.store.unloadAll('category');
      this.store.unloadAll('promotion');
      this.store.unloadAll('menu');
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (this.controller.get('model.hasDirtyAttributes') && !isEqual(transition.targetName, 'menu.new')) {
          this.controller.set('transition', transition);
          this.controller.set('showModal', true);
          transition.abort();
        } else {
          this.cleanupMenu();
        }
      },
      closeModal: function closeModal() {
        this.controller.set('showModal', false);
      },
      save: function save(menu, shouldClean) {
        Logger.debug('Save action called.');
        var validMenu = true;
        if (validMenu) {
          shouldClean = shouldClean === undefined ? true : shouldClean;
          if (menu.get('validations.isValid')) {
            if (menu.get('receipt.enabled')) {
              if (menu.get('receipt.validations.isValid')) {
                this.send('saveMenu', menu, shouldClean);
              } else {
                this.controller.set('saveEnabled', true);
                this.send('displayErrorMessages', menu.get('receipt.validations.errors'));
              }
            } else {
              this.send('saveMenu', menu, shouldClean);
            }
          } else {
            this.controller.set('saveEnabled', true);
            this.send('displayErrorMessages', menu.get('validations.errors'));
          }
        } else {
          this.controller.set('saveEnabled', true);
          this.send('displayErrorMessages', menu.get('validations.errors'));
        }
      }
    }
  });
});