define("fcs-dashboard/mirage/fixtures/companies", ["exports"], function (exports) {
  /**
   * Created by alberto on 11/12/15.
   */
  exports["default"] = [{
    id: 1,
    name: "new company",
    password: null,
    userProfiles: [1, 2],
    locations: [1, 2, 3],
    menus: [1]
  }, {
    id: 2,
    name: "new company 2",
    password: null,
    userProfiles: [3],
    locations: [4, 5, 6]
  }, {
    id: 3,
    name: "company_test",
    password: null,
    userProfiles: [3, 2, 1],
    locations: [7, 8, 9]
  }];
});