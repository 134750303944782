define('fcs-dashboard/pods/report-options/model', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'moment'], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships, _moment) {
  exports['default'] = _emberDataModel['default'].extend({
    reportId: (0, _emberDataAttr['default'])('string'),
    toDate: (0, _emberDataAttr['default'])('date', {
      defaultValue: function defaultValue() {
        return (0, _moment['default'])().add(1, 'days').toDate();
      }
    }),
    fromDate: (0, _emberDataAttr['default'])('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    selectedMenu: (0, _emberDataAttr['default'])('string'),
    summarizeBy: (0, _emberDataAttr['default'])('string'),
    locationGroup: (0, _emberDataRelationships.belongsTo)('locationGroup'),
    selectedLocationGroup: (0, _emberDataAttr['default'])('string'),
    selectedVendor: (0, _emberDataAttr['default'])('string'),
    companyRole: (0, _emberDataAttr['default'])('string'),
    selectedVendorManagement: (0, _emberDataAttr['default'])('string'),
    locations: (0, _emberDataAttr['default'])({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    vendors: (0, _emberDataAttr['default'])({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    isTimestampOccurrence: (0, _emberDataAttr['default'])('boolean', { defaultValue: false }),
    reportOutput: (0, _emberDataAttr['default'])('string', { defaultValue: undefined }),
    isEmailDelivery: (0, _emberDataAttr['default'])('boolean', { defaultValue: false }),
    emails: (0, _emberDataAttr['default'])('string', { defaultValue: undefined }),
    isExport: (0, _emberDataAttr['default'])('boolean', { defaultValue: false }),
    menus: (0, _emberDataAttr['default'])({
      defaultValue: function defaultValue() {
        return [];
      }
    })
  });
});