define('fcs-dashboard/mirage/fixtures/taxRateTypes', ['exports'], function (exports) {
  /**
   * Created by alberto on 24/05/16.
   */
  exports['default'] = [{
    id: 0,
    name: 'Menu tax rate'
  }, {
    id: 1,
    name: 'Product type tax rate'
  }, {
    id: 2,
    name: 'Product tax rate'
  }];
});