define("fcs-dashboard/pods/custom-fee-log/model", ["exports", "ember-data/attr", "ember-data/model"], function (exports, _emberDataAttr, _emberDataModel) {
  exports["default"] = _emberDataModel["default"].extend({
    username: (0, _emberDataAttr["default"])('string'),
    logDate: (0, _emberDataAttr["default"])('string'),
    name: (0, _emberDataAttr["default"])('string'),
    value: (0, _emberDataAttr["default"])('number', { defaultValue: 0.0 }),
    type: (0, _emberDataAttr["default"])('string'),
    appliesTo: (0, _emberDataAttr["default"])('string'),
    taxable: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    enabled: (0, _emberDataAttr["default"])('boolean', { defaultValue: true })
  });
});