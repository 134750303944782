define("fcs-dashboard/router", ["exports", "ember", "fcs-dashboard/config/environment"], function (exports, _ember, _fcsDashboardConfigEnvironment) {

  var Router = _ember["default"].Router.extend({
    location: _fcsDashboardConfigEnvironment["default"].locationType,
    rootURL: _fcsDashboardConfigEnvironment["default"].rootURL
  });

  Router.map(function () {
    this.route('login');
    this.route('user', function () {
      this.route('new');
      this.route('edit', { path: ':user_id' });
      this.route('edit-account', { path: ':user_id/edit-account' });
      this.route('loading');
    });
    this.route('company', function () {
      this.route('new');
      this.route('edit', { path: ':company_id' });
      this.route('loading');
    });
    this.route('userProfile');
    this.route('location');
    this.route('menu', function () {
      this.route('new');
      this.route('edit', { path: ':menu_id' });
      this.route('loading');
    });
    this.route('productType');
    this.route('productCatalog');
    this.route('gateway', function () {
      this.route('edit', { path: ':gateway_id' });
      this.route('new');
      this.route('loading');
    });
    this.route('locationGroup', function () {
      this.route('new');
      this.route('edit', { path: ':locationGroup_id' });
    });
    this.route('searchOrder', function () {
      this.route('result');
    });
    this.route('salesReport');
    this.route('reviewConfiguration');
    this.route('contact');

    this.route('location-group', function () {
      this.route('loading');
    });
    this.route('clerkReport');
    this.route('financialOverviewReport');
    this.route('productMixReport');
    this.route('version');
    this.route('paymentResultReport');
    this.route('sponsorLoadedFundUsageReport');
    this.route('itemTokenUsageReport');
    this.route('attributeSet');
    this.route('trait');
    this.route('pmixWithCombos');
    this.route('embeddableReport', { path: ':report_id' });
    this.route('posToken');
    this.route('passwordReset', { path: '/passwordResets/:session_token' });
    this.route('savedNetwork');
    this.route('high-level-invoicing-report');
  });

  exports["default"] = Router;
});