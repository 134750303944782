define('fcs-dashboard/mixins/base-component', ['exports', 'ember', 'fcs-dashboard/mixins/common-mixin'], function (exports, _ember, _fcsDashboardMixinsCommonMixin) {
  var Mixin = _ember['default'].Mixin;
  var inject = _ember['default'].inject;
  var computed = _ember['default'].computed;
  exports['default'] = Mixin.create(_fcsDashboardMixinsCommonMixin['default'], {
    validReportingRoutes: ['salesReport', 'clerkReport', 'financialOverviewReport', 'productMixReport', 'dailyCloseOutReport', 'simpleReportByProduct', 'sponsorLoadedFundUsageReport', 'itemTokenUsageReport', 'paymentResultReport', 'searchOrder.index', 'contact', 'user.edit-account', 'searchOrder'],
    store: inject.service(),
    accountSession: inject.service('account-session'),
    isReportingUser: false,
    isBRUser: computed('accountSession.account.userType', function () {
      if (this.get('accountSession.account.userProfileId') !== null && this.get('accountSession.account.userProfileId') === "3") {
        this.set('isReportingUser', true);
      }
      return Number(this.get('accountSession.account.userType.id')) === this.get('BR_USERTYPE');
    }),
    loggedAccount: computed.alias('accountSession.account'),
    lockedCompany: computed.alias('loggedAccount.lockedCompany'),
    versionSession: inject.service(),
    dbVersion: computed('versionSession.version', function () {
      return this.get('versionSession.version');
    }),
    _isValidTransition: function _isValidTransition(routeName) {
      var valid = false;
      if (this.get('accountSession.account.userProfileId') === "3") {
        this.get('validReportingRoutes').forEach(function (validRouteName) {
          if (routeName === validRouteName) {
            valid = true;
          }
        });
      } else {
        valid = true;
      }
      return valid;
    }
  });
});