define("fcs-dashboard/mirage/fixtures/toppings", ["exports"], function (exports) {
  /**
   * Created by alberto on 11/04/16.
   */
  exports["default"] = [{
    id: 1,
    version: 1,
    name: "bacon strip",
    price: 2
  }, {
    id: 2,
    version: 1,
    name: "mushrooms",
    price: 2
  }, {
    id: 3,
    version: 1,
    name: "grilled onions",
    price: 2
  }, {
    id: 4,
    version: 1,
    name: "BBQ sauce",
    price: 2
  }];
});