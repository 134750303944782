define("fcs-dashboard/pods/components/menu/menu-list/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/table-component", "fcs-dashboard/mixins/menu-mixin", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsTableComponent, _fcsDashboardMixinsMenuMixin, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var observer = _ember["default"].observer;
  var isEqual = _ember["default"].isEqual;
  var A = _ember["default"].A;
  var run = _ember["default"].run;
  var Logger = _ember["default"].Logger;
  exports["default"] = Component.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], _fcsDashboardMixinsBaseComponent["default"], _fcsDashboardMixinsTableComponent["default"], _fcsDashboardMixinsMenuMixin["default"], {
    selectedModels: A(),
    menu: [],
    selectedLocations: [],
    selectedCompany: undefined,
    currentMenu: null,
    deactivateSelectedMenu: null,
    copyMenuId: 0,
    copyMenuName: "",
    showDeactivateMenuModal: false,
    showCopyMenuModal: false,
    showPushMenuModal: false,
    _disableMenuActions: computed('lockedCompany', function () {
      return this.get('isBRUser') && this.get('lockedCompany') === undefined;
    }),
    selectedMenusCount: computed('selectedModels.length', function () {
      if (this.get('selectedModels') !== undefined && this.get('selectedModels.length') > 0) {
        return this.get('selectedModels.length');
      } else {
        return '0';
      }
    }),
    _showNoCreationMenuTooltip: computed.alias('_disableMenuActions'),
    data: [],
    columns: computed(function () {
      var hideColumn = !this.get('isBRUser');
      var usableStatuses = this.get('usableStatuses');
      return [{
        template: "meta-product-catalog/actions/row-select-checkbox",
        useFilter: false,
        mayBeHidden: false,
        templateForSortCell: "meta-product-catalog/actions/row-select-all-checkbox"
      }, {
        title: '',
        template: "menu/actions/edit-action",
        className: 'editRow'
      }, {
        title: '',
        template: "menu/actions/menu-archive-action",
        className: 'editRow'
      }, {
        title: '',
        template: "menu/actions/copy-action",
        className: 'editRow'
      }, {
        title: '',
        template: "menu/actions/lock-action",
        isHidden: hideColumn,
        className: 'editRow'
      }, {
        title: '',
        template: "menu/actions/push-action",
        className: 'editRow'
      }, {
        propertyName: 'name',
        filteredBy: 'name',
        title: this.get('intl').t('menu.list.table.menu')
      }, {
        title: this.get('intl').t('menu.list.table.usable.header'),
        template: "menu/actions/payments-warning",
        propertyName: 'translatedUsableStatus',
        filteredBy: 'usable',
        filterWithSelect: true,
        predefinedFilterOptions: usableStatuses,
        className: 'stateRow'
      }, {
        propertyName: 'translatedStatus',
        title: this.get('intl').t('menu.list.table.status.header'),
        filterWithSelect: true,
        filteredBy: 'status',
        predefinedFilterOptions: this.get('menuStatuses'),
        filterString: 'Active',
        className: 'stateRow',
        filterFunction: function filterFunction(cell, neededStr, obj) {
          return isEqual(obj.get('translatedStatus').toLowerCase(), neededStr);
        }
      }, {
        propertyName: 'company.name',
        filteredBy: 'companyName',
        title: this.get('intl').t('menu.list.table.company'),
        isHidden: hideColumn
      }, {
        propertyName: 'updatedAt',
        filteredBy: 'updatedAt',
        title: this.get('intl').t('menu.list.table.updatedAt'),
        template: "menu/updateAt-cell"
      }];
    }),
    _checkCompanyLock: observer('lockedCompany', function () {
      Logger.debug('calling refresh');
      this.sendAction('refreshAction');
    }),
    _filterData: function _filterData(menus) {
      return menus.filter(function (item) {
        return !item.get('isNew') || !item.get('isDeleted');
      }).sortBy('name');
    },
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('selectedModels', null);

      if (this.get('menu').then === undefined) {
        this.set('data', this.get('menu'));
      } else {
        this.get('menu').then(function (content) {
          return run(function () {
            return _this.set('data', content);
          });
        });
      }
    },
    actions: {

      /**
       * Displays the modal asking if the user is sure that they want to archived the menu
       * @param record the selected menu in the table
       */
      openDeactivateMenuModal: function openDeactivateMenuModal(record) {
        this.set('deactivateSelectedMenu', record);
        if (record.get('archived')) {
          this.send('changeMenuVisibility');
        } else {
          this.set('showDeactivateMenuModal', true);
        }
      },

      /**
       * Displays the modal asking if the user is sure that they want to copy the menu
       * @param record the selected menu in the table
       */
      openCopyMenuModal: function openCopyMenuModal(record) {
        this.set('copyMenuId', record.get('id'));
        this.set('copyMenuName', record.get('name') + " (copy)");
        this.set('showCopyMenuModal', true);
      },

      /**
       * Opens modal to push locations for menus
       * @param record menu
       */
      openPushMenuModal: function openPushMenuModal(record) {
        var _this2 = this;

        var endpoint = "/api/v1/menus/locations/";
        var _authToken = this.get('session.data.authenticated.access_token');
        var options = {
          url: endpoint,
          type: 'GET',
          data: { menuId: record.id }
        };
        options.beforeSend = function (xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + _authToken);
        };
        options.success = function (locations) {
          _this2.set('pushLocations', locations.locations);
          _this2.set('selectedMenuId', record.id);
          _this2.set('showPushMenuModal', true);
        };
        options.error = function () {
          var errorMessage = undefined;
          // Catch error and return proper error message
          errorMessage = _this2.get('notify').alert(_this2.get('intl').t('menu.builder.steps.stepSix.errors.saveError'), { closeAfter: 5000 });
          _this2.get('errorMessages').addObject(errorMessage);
        };
        _ember["default"].$.ajax(options);
      },

      /**
       * Displays the modal asking if the user is sure that they want to unlock the menu
       * @param record the selected menu in the table
       */
      openLockMenuModal: function openLockMenuModal(record) {
        this.set('lockMenuId', record.get('id'));
        this.set('showLockMenuModal', true);
      },

      /**
       *  Sends menu record to copy
       * @param record menu
       */
      copyMenu: function copyMenu() {
        this.set('showCopyMenuModal', false);
        this.sendAction('copyMenu', this.get('copyMenuId'), this.get('copyMenuName'));
      },

      /**
       * Unlocks Menu
       * @param record menu
       */
      unlockMenu: function unlockMenu() {
        this.set('showLockMenuModal', false);
        this.sendAction('unlockMenu', this.get('lockMenuId'));
      },

      /**
       * Changes the visibility state of a menu Archived: True/False
       */
      changeMenuVisibility: function changeMenuVisibility() {
        var menuId = A();
        menuId.addObject(this.get('deactivateSelectedMenu.id'));
        this.set('showDeactivateMenuModal', false);
        this.sendAction('toggleMenusArchive', menuId, !this.get('deactivateSelectedMenu.archived'));
      },

      /**
       * Closes the modal
       */
      closeDeactivateModal: function closeDeactivateModal() {
        this.set('showDeactivateMenuModal', false);
        this.get('selectedModels').clear();
      },

      /**
       * Closes the modal
       */
      closeUnlockMenuModal: function closeUnlockMenuModal() {
        this.set('showLockMenuModal', false);
        this.get('selectedModels').clear();
      },

      /**
       * Closes the modal
       */
      closePushMenuModal: function closePushMenuModal() {
        this.set('showPushMenuModal', false);
        this.set('selectedMenuId', null);
        this.get('selectedModels').clear();
      },

      pushMenu: function pushMenu(menuId, locations) {
        this.set('showPushMenuModal', false);
        this.sendAction('pushMenu', menuId, locations);
        this.set('selectedMenuId', null);
      },

      /**
       * Closes the modal and set the selected copy menu id to 0
       */
      closeCopyModal: function closeCopyModal() {
        this.set('copyMenuId', 0);
        this.set('copyMenuName', "");
        this.set('showCopyMenuModal', false);
        this.get('selectedModels').clear();
      },
      editMenu: function editMenu(menuId) {
        this.sendAction('editMenu', menuId);
      },
      toggleMenusArchive: function toggleMenusArchive(archive) {
        var _this3 = this;

        if (this.get('selectedModels.length') > 0) {
          (function () {
            var menuIds = A();
            _this3.get('selectedModels').forEach(function (menu) {
              menuIds.addObject(parseInt(menu.id));
            });

            _this3.sendAction('toggleMenusArchive', menuIds, archive);
          })();
        }
      }
    }
  });
});