define('fcs-dashboard/mirage/factories/user', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  _emberCliMirage.faker.locale = 'en_US';

  exports['default'] = _emberCliMirage['default'].Factory.extend({
    name: _emberCliMirage.faker.name.firstName(),
    lastName: _emberCliMirage.faker.name.lastName(),
    email: _emberCliMirage.faker.internet.email(),
    username: _emberCliMirage.faker.internet.userName(),
    password: _emberCliMirage.faker.internet.password(8),
    state: "active",
    updatedAt: _emberCliMirage.faker.date.recent(),
    userType: 7,
    company: 1,
    userProfile: 1
  });
});
/**
 * Created by alberto on 16/09/16.
 */