define("fcs-dashboard/pods/menu/model", ["exports", "ember", "ember-data/model", "ember-data/attr", "ember-data/relationships", "model-fragments/attributes", "ember-cp-validations"], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberDataRelationships, _modelFragmentsAttributes, _emberCpValidations) {
  var computed = _ember["default"].computed;
  var inject = _ember["default"].inject;

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'menu.builder.steps.stepOne.menuName.label' }), (0, _emberCpValidations.validator)('length', { min: 1, max: 254, descriptionKey: 'menu.builder.steps.stepOne.menuName.label' })],
    adminCode: [(0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'menu.builder.steps.stepOne.administratorCode.label' }), (0, _emberCpValidations.validator)('format', { regex: /^\d{5}$/, messageKey: 'menu.builder.steps.stepOne.errors.adminCodeNotNumeric' })],
    cashlessAdminCode: [(0, _emberCpValidations.validator)('format', { regex: /^(|\d{5})$/, messageKey: 'menu.builder.steps.stepOne.errors.cashlessAdminCodeNotNumeric' })],
    voidAdminCode: [(0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'menu.builder.steps.stepOne.voidAdministratorCode.label' }), (0, _emberCpValidations.validator)('format', { regex: /^\d{5}$/, messageKey: 'menu.builder.steps.stepOne.errors.voidAdminCodeNotNumeric' })],
    receipt: (0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'menu.builder.steps.receiptOpts.title' }),
    currency: [(0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'menu.builder.steps.stepOne.menuCurrency.label' }), (0, _emberCpValidations.validator)('length', { min: 1, max: 254, descriptionKey: 'menu.builder.steps.stepOne.menuCurrency.label' })]
  });

  exports["default"] = _emberDataModel["default"].extend(Validations, {
    name: (0, _emberDataAttr["default"])('string'),
    locked: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    tipAmountButtonsEnabled: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    tipPercentageButtonsEnabled: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    tipManualEntryEnabled: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    tipMinusPlusOneButtonsEnabled: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    tabEnabled: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    cloudTabsEnabled: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    multiplePagesEnabled: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    serviceFeeEnabled: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    serviceFeeTaxRate: (0, _emberDataAttr["default"])('string', { defaultValue: '0' }),
    employeeDiscountPercentage: (0, _emberDataAttr["default"])('number', { defaultValue: 0 }),
    menuTaxType: (0, _emberDataRelationships.belongsTo)('taxType'),
    menuTaxRate: (0, _emberDataAttr["default"])('number', { defaultValue: 0 }),
    adminCode: (0, _emberDataAttr["default"])('string', { defaultValue: '' }),
    cashlessAdminCode: (0, _emberDataAttr["default"])('string', { defaultValue: '' }),
    voidAdminCode: (0, _emberDataAttr["default"])('string', { defaultValue: '' }),
    pastOrdersManagerCodeRequired: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    buttonColorEnabled: (0, _emberDataAttr["default"])('boolean', { defaultValue: true }),
    productImageEnabled: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    standaloneEnabled: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    orderNoteEnabled: (0, _emberDataAttr["default"])('boolean', { defaultValue: true }),
    diningPreferenceSelectionEnabled: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    currency: (0, _emberDataAttr["default"])('string'),
    customProductEnabled: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    receipt: (0, _modelFragmentsAttributes.fragment)('receipt', {
      defaultValue: {
        enabled: false,
        kitchenPrinterAvailable: false,
        printableTenders: [],
        merchantCopies: 1,
        customerCopies: 1
      }
    }),
    tipButton: (0, _modelFragmentsAttributes.fragment)('tip-button', {
      defaultValue: {
        buttonOne: 1,
        buttonTwo: 5,
        buttonThree: 10
      }
    }),
    tipPercentage: (0, _modelFragmentsAttributes.fragment)('tip-percentage', {
      defaultValue: {
        percentageOne: 15,
        percentageTwo: 18,
        percentageThree: 20
      }
    }),
    menuProductTypes: (0, _modelFragmentsAttributes.fragmentArray)('menuProductType'),
    menuLocations: (0, _modelFragmentsAttributes.fragmentArray)('menuLocation'),
    openCashDrawerTenders: (0, _emberDataRelationships.hasMany)('tenderType'),
    orderSendingType: (0, _emberDataRelationships.belongsTo)('orderSendingType'),
    categories: (0, _emberDataRelationships.hasMany)('category'),
    modifiers: (0, _emberDataRelationships.hasMany)('modifier'),
    deletedCategories: (0, _emberDataAttr["default"])({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    company: (0, _emberDataRelationships.belongsTo)('company'),
    updatedAt: (0, _emberDataAttr["default"])('date'),
    changed: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    archived: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    usable: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    intl: inject.service(),
    translatedUsableStatus: computed('usable', function () {
      if (this.get('usable')) {
        return this.get('intl').t('menu.list.table.usable.usable');
      } else {
        return this.get('intl').t('menu.list.table.usable.unusable');
      }
    }),
    translatedStatus: computed('archived', function () {
      if (this.get('archived')) {
        return this.get('intl').t('menu.list.table.status.inactive');
      } else {
        return this.get('intl').t('menu.list.table.status.active');
      }
    }),
    hasLocations: computed('menuLocations.[]', function () {
      return this.get('menuLocations.length') > 0;
    })
  });
});