define("fcs-dashboard/pods/reports-location-group/model", ["exports", "ember-data/model", "ember-data/attr", "model-fragments/attributes"], function (exports, _emberDataModel, _emberDataAttr, _modelFragmentsAttributes) {
  exports["default"] = _emberDataModel["default"].extend({
    name: (0, _emberDataAttr["default"])('string'),
    address: (0, _emberDataAttr["default"])('string'),
    producer: (0, _modelFragmentsAttributes.fragment)('reportsCompanyRole'),
    locations: (0, _emberDataAttr["default"])({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    vendorManagementCompanies: (0, _modelFragmentsAttributes.fragmentArray)('reportsCompanyRole', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    vendorCompanies: (0, _modelFragmentsAttributes.fragmentArray)('reportsCompanyRole', {
      defaultValue: function defaultValue() {
        return [];
      }
    })
  });
});