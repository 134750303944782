define('fcs-dashboard/pods/components/user-mng/details/component', ['exports', 'ember', 'fcs-dashboard/mixins/base-component', 'fcs-dashboard/mixins/user-mixin'], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsUserMixin) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var Logger = _ember['default'].Logger;
  var run = _ember['default'].run;
  exports['default'] = Component.extend(_fcsDashboardMixinsBaseComponent['default'], _fcsDashboardMixinsUserMixin['default'], {
    passwordConfirmation: '',
    showUserType: false,
    showCompany: false,
    _locationGroups: computed(function () {
      Logger.debug('BR-user: Getting all cashless location groups.');
      return this.get('store').query('locationGroup', { isCashless: true });
    }),
    showProfiles: computed('model.company', function () {
      return this.get('model.company') !== undefined && this.get('model.company.id') !== undefined;
    }),
    isCashlessUser: computed('model.userType.id', function () {
      return Number(this.get('model.userType.id')) === this.get('CASHLESS_USERTYPE');
    }),
    showCompanySection: false,
    userTypes: computed(function () {
      return this.get('store').findAll('userType');
    }),
    companyProfiles: computed('model.company', function () {
      if (this.get('model.company.id')) {
        return this.get('store').query('userProfile', { company: this.get('model.company.id') });
      }
    }),
    companies: computed(function () {
      return this.get('store').query('company', { pageSize: -1 });
    }),
    _sortingCriteria: ['name'],
    _sortedCompanies: computed.sort('companies', '_sortingCriteria'),
    _showUnlockedBRUserFeatures: function _showUnlockedBRUserFeatures() {
      return Number(this.get('loggedAccount.userType.id')) === this.get('BR_USERTYPE') && this.get('lockedCompany') === undefined;
    },
    _showCompanyFeatures: function _showCompanyFeatures() {
      return Number(this.get('accountSession.account.userType.id')) === this.get('BR_USERTYPE') && this.get('lockedCompany') !== undefined;
    },
    _initializeLocationGroups: function _initializeLocationGroups() {
      var _this = this;

      Logger.debug('Loading LocationGroups for Simple Sales rpt');
      if (this.get('_locationGroups').then !== undefined) {
        this.get('_locationGroups').then(function (content) {
          run(function () {
            Logger.debug('Loaded LocationGroups for Simple Sales rpt');
            _this.set('_locationGroups', content);
          });
        });
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this._initializeLocationGroups();
      if (Number(this.get('accountSession.account.userType.id')) === this.get('COMPANY_USERTYPE')) {
        this.set('model.company', this.get('accountSession.account.company'));
      } else if (Number(this.get('accountSession.account.userType.id')) === this.get('BR_USERTYPE') && this.get('lockedCompany') !== undefined) {
        this.set('model.company', this.get('lockedCompany'));
      }
      this.set('showUserType', this._showUnlockedBRUserFeatures());
      this.showCompanyFeatures();
    },
    showCompanyFeatures: function showCompanyFeatures() {
      this.set('showCompany', this._showUnlockedBRUserFeatures());
      this.set('showCompanySection', Number(this.get('model.userType.id')) === this.get('COMPANY_USERTYPE'));
    },
    actions: {
      userTypeSelected: function userTypeSelected(selection) {
        if (selection) {
          this.set('model.userType', selection);
          this.set('model.company', undefined);
          this.set('model.userProfile', undefined);
          this.showCompanyFeatures();
        }
      },
      companySelected: function companySelected(selection) {
        if (selection) {
          this.set('showProfiles', true);
          this.set('model.company', selection);
          this.set('model.userProfile', undefined);
        }
      },
      stateSelected: function stateSelected(selection) {
        this.set('model.state', selection.toLowerCase());
      },
      cancelAction: function cancelAction(user) {
        this.sendAction('cancel', user);
      },
      saveAction: function saveAction(user, password) {
        this.sendAction('save', user, password);
      }
    }
  });
});