define("fcs-dashboard/pods/components/menu/menu-wizard/category-modal/component", ["exports", "ember", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var isEmpty = _ember["default"].isEmpty;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], {
    newPages: [],
    newCategory: undefined,
    pagesDisabledOption: computed('newPages', function () {
      return !(this.get('newPages.length') > 1 && this.get('menu.multiplePagesEnabled'));
    }),
    deletePageEnabled: computed('menu.multiplePagesEnabled', 'newPages.length', function () {
      return this.get('menu.multiplePagesEnabled') && this.get('newPages.length') > 1;
    }),
    validatePages: function validatePages(pages) {
      var _this = this;

      var valid = true;
      if (pages.length > 1) {
        pages.forEach(function (page) {
          if (isEmpty(page.get('name')) && valid) {
            valid = false;
            _this.get('notify').alert(_this.get('intl').t('menu.modal.categoryModal.errors.pagesEmptyNames'), { closeAfter: 5000 });
          }
        });
      }
      return valid;
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.get('newPages').addObjects(this.get('newCategory.pages'));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('newPages').clear();
    },
    actions: {
      addPage: function addPage() {
        if (this.get('newPages.length') > 7) {
          this.get('notify').alert(this.get('intl').t('menu.modal.categoryModal.errors.pagesMax'), { closeAfter: 5000 });
        } else {
          var newPage = this.get('store').createRecord('page');
          this.get('newPages').addObject(newPage);
          if (this.get('newPages.length') > 1) {
            this.set('pagesEnabledOption', true);
          }
          this.get('menu').incrementProperty('pageCounter');
        }
      },
      removePage: function removePage(page) {
        if (page.id !== null && page.id !== undefined) {
          this.get('newCategory.deletedPages').addObject(page.id);
        }
        this.get('newPages').removeObject(page);
        if (this.get('newPages.length') === 1) {
          this.set('pagesEnabledOption', false);
        }
        this.get('menu').decrementProperty('pageCounter');
      },
      addCategory: function addCategory(category) {
        if (isEmpty(category.get('name'))) {
          this.get('notify').alert(this.get('intl').t('menu.modal.categoryModal.errors.nameEmpty'), { closeAfter: 5000 });
        } else if (this.get('menu.categories').filterBy('name', category.get('name')).length > 0) {
          this.get('notify').alert(this.get('intl').t('menu.modal.categoryModal.errors.duplicatedCategoryName'), { closeAfter: 5000 });
        } else {
          if (this.validatePages(this.get('newPages'))) {
            category.set('pages', this.get('newPages'));
            this.addModalCategory(category);
          }
        }
      },
      editCategory: function editCategory(category) {
        if (isEmpty(category.get('name'))) {
          this.get('notify').alert(this.get('intl').t('menu.modal.categoryModal.errors.nameEmpty'), { closeAfter: 5000 });
        } else if (this.get('menu.categories').filterBy('name', category.get('name')).length > 1) {
          this.get('notify').alert(this.get('intl').t('menu.modal.categoryModal.errors.duplicatedCategoryName'), { closeAfter: 5000 });
        } else {
          if (this.validatePages(this.get('newPages'))) {
            category.set('pages', this.get('newPages'));
            this.editModalCategory(category);
          }
        }
      },
      cancelModal: function cancelModal(category) {
        this.cancelCategoryModal(category);
      }
    }
  });
});