define('fcs-dashboard/initializers/error-monitor', ['exports', 'ember'], function (exports, _ember) {
  exports.initialize = initialize;

  function initialize() {
    _ember['default'].onerror = function (err) {
      if (err && (err.isAdapterError || err.name == 'TransitionAborted')) {
        return;
      }
      err && _ember['default'].Logger.error(err);
    };
    _ember['default'].RSVP.on('error', function (err) {
      if (err && (err.isAdapterError || err.name == 'TransitionAborted')) {
        return;
      }
      err && _ember['default'].Logger.error(err);
    });
  }

  exports['default'] = {
    name: 'error-monitor',
    initialize: initialize
  };
});