define("fcs-dashboard/pods/components/product-catalog/combo-selection-catalog/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/table-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsTableComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var A = _ember["default"].A;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], _fcsDashboardMixinsTableComponent["default"], {
    comboSelectionProductCatalogColumns: computed(function () {
      return [{
        title: '',
        template: "combo-selection-product-catalog/delete-action",
        className: 'deleteRow'
      }, {
        title: this.get('intl').t('comboSelectionProductCatalog.list.productCatalogName'),
        propertyName: 'productCatalogName',
        className: 'comboSelectionProductRow'
      }, {
        title: this.get('intl').t('comboSelectionProductCatalog.list.priceChange'),
        propertyName: 'priceChange',
        template: "combo-selection-product-catalog/suggested-price-cell",
        className: 'comboSelectionProductRow'
      }];
    }),
    columns: computed(function () {
      return [{
        template: "meta-product-catalog/actions/row-select-checkbox",
        useFilter: false,
        mayBeHidden: false,
        templateForSortCell: "meta-product-catalog/actions/row-select-all-checkbox"
      }, {
        propertyName: 'name',
        title: this.get('intl').t('productCatalog.list.product'),
        filteredBy: 'catalogName'
      }, {
        propertyName: 'productType.name',
        title: this.get('intl').t('productCatalog.list.productType'),
        filteredBy: 'typeName'
      }, {
        propertyName: 'suggestedPrice',
        title: this.get('intl').t('productCatalog.list.suggestedPrice'),
        filteredBy: 'suggestedPrice',
        template: "product-catalog/suggested-price-cell",
        className: 'suggestedPriceRow'
      }, {
        propertyName: 'productCatalogTemplate',
        title: this.get('intl').t('productCatalog.list.productCatalogTemplate'),
        filterWithSelect: true,
        predefinedFilterOptions: this.get('productCatalogType'),
        template: 'product-catalog/meta-product-catalog-flag'
      }];
    }),
    selectedModels: computed('comboSelectionCatalog.comboSelectionProductCatalogList', 'comboSelectionCatalog.comboSelectionProductCatalogList.length', function () {
      return A();
    }),
    _getCompany: function _getCompany() {
      var company = undefined;
      if (this.get('isBRUser')) {
        if (this.get('lockedCompany') !== undefined) {
          company = this.get('lockedCompany');
        }
      } else {
        company = this.get('loggedAccount.company');
      }
      return company;
    },
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var company = undefined;
      if (this.get('lockedCompany') !== undefined) {
        company = this.get('lockedCompany.id');
      } else if (!this.get('isBRUser')) {
        company = this.get('loggedAccount.company.id');
      }
      this.set('company', company);

      var params = {
        companyId: company,
        loadAttributes: false,
        productClass: 'REGULAR',
        page: 1,
        pageSize: 10
      };

      var temp = this.get('store').query('productCatalog', params);
      if (temp.then !== undefined) {
        temp.then(function (content) {
          _this.set('catalogs', content);
        });
      }
    },
    actions: {
      refreshComboSelectionList: function refreshComboSelectionList(comboSelectionCatalog, direction) {
        this.sendAction('refreshComboSelectionList', comboSelectionCatalog, direction);
      },
      deleteComboSelectionProduct: function deleteComboSelectionProduct(comboSelectionProductCatalog) {
        // Remove comboSelectionProductCatalog
        this.get('comboSelectionCatalog.comboSelectionProductCatalogList').removeObject(comboSelectionProductCatalog);
        if (!comboSelectionProductCatalog.get('isNew')) {
          this.get('comboSelectionCatalog.deletedComboSelectionProductCatalogList').addObject(comboSelectionProductCatalog.get('comboSelectionProductCatalogId'));
        } else {
          comboSelectionProductCatalog.rollbackAttributes();
        }
      },
      deleteComboSelection: function deleteComboSelection(comboSelectionCatalog) {
        this.sendAction('deleteComboSelection', comboSelectionCatalog);
      },
      openModalProducts: function openModalProducts() {
        var _this2 = this;

        // Initialize Selected Models if Null or Undefined
        if (this.get('selectedModels') === null || this.get('selectedModels') === undefined) {
          this.set('selectedModels', A());
        }

        var catalog = A();

        var promises = this.get('comboSelectionCatalog.comboSelectionProductCatalogList').map(function (product) {
          var temp = _this2.get('store').findRecord('productCatalog', product.get('productCatalogId').toString());
          if (temp.then !== undefined) {
            temp.then(function (content) {
              return catalog.addObject(content);
            });
          }
          return temp;
        });

        window.Promise.all(promises).then(function () {
          _this2.get('selectedModels').clear();
          _this2.get('selectedModels').addObjects(catalog);
          _this2.set('showModalProducts', true);
        });
      },
      addComboSelectionProducts: function addComboSelectionProducts() {
        var _this3 = this;

        this.send('cleanMessages');

        this.get('selectedModels').forEach(function (item) {
          var contains = false;
          _this3.get('comboSelectionCatalog.comboSelectionProductCatalogList').forEach(function (product) {
            if (product.get('productCatalogId').toString() === item.get('id')) {
              contains = true;
            }
          });

          if (!contains) {
            _this3.get('comboSelectionCatalog.comboSelectionProductCatalogList').addObject(_this3.get('store').createFragment('comboSelectionProductCatalog', {
              productCatalogId: item.get('id'),
              productCatalogName: item.get('name'),
              priceChange: 0
            }));
          }
        });
        this.send('closeModalProducts');
      },
      closeModalProducts: function closeModalProducts() {
        this.send('cleanMessages');
        this.set('showModalProducts', false);
      }
    }
  });
});