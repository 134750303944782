define("fcs-dashboard/pods/product-type/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin", "fcs-dashboard/mixins/common-mixin", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _fcsDashboardMixinsCommonMixin, _fcsDashboardMixinsBaseComponent) {
  var Route = _ember["default"].Route;
  var Logger = _ember["default"].Logger;
  exports["default"] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], _fcsDashboardMixinsCommonMixin["default"], _fcsDashboardMixinsBaseComponent["default"], {
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      this.store.findAll('userType');
      if (this.get('session.isAuthenticated')) {
        this.store.unloadAll('productType');
      }
    },
    model: function model() {
      Logger.debug('getting data!');
      var company = undefined;
      if (this.get('lockedCompany') !== undefined) {
        company = this.get('lockedCompany.id');
      } else if (!this.get('isBRUser')) {
        company = this.get('loggedAccount.company.id');
      }
      return this.store.query('productType', { companyId: company });
    },
    afterModel: function afterModel() {
      this._super.apply(this, arguments);
      if (this.get('isReportingUser')) {
        this.transitionTo('');
      }
    },
    actions: {
      willTransition: function willTransition() {
        window.stop();
        return true;
      },
      save: function save(item) {
        var _this = this;

        item.save().then(function () {
          _this.send('cleanMessages');
          _this.get('notify').success(_this.get('intl').t('productType.details.errors.successSave', { name: item.get('name') }));
          _this.refresh();
        })["catch"](function (error) {
          var message = 'productType.details.errors.savingError';
          if (error !== undefined) {
            message = "productType.details.errors." + error.errors.get(0).detail;
          }
          var errorMessage = _this.get('notify').alert(_this.get('intl').t(message, { name: item.get('name') }), { closeAfter: 5000 });
          _this.get('errorMessages').addObject(errorMessage);
          item.rollbackAttributes();
        });
      },
      "delete": function _delete(item) {
        var _this2 = this;

        item.destroyRecord().then(function () {
          _this2.send('cleanMessages');
          _this2.get('notify').success(_this2.get('intl').t('productType.list.errors.deleteSuccess'));
          _this2.refresh();
        })["catch"](function (error) {
          var message = 'productType.list.errors.deletingError';
          if (error !== undefined) {
            message = "productType.list.errors." + error.errors.get(0).detail;
          }
          var errorMessage = _this2.get('notify').alert(_this2.get('intl').t(message, { name: item.get('name') }), { closeAfter: 5000 });
          _this2.get('errorMessages').addObject(errorMessage);
          item.rollbackAttributes();
        });
      },
      refresh: function refresh() {
        Logger.debug('refreshing');
        this.refresh();
      }
    }
  });
});