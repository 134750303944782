define("fcs-dashboard/pods/components/user-mng/account-details/component", ["exports", "ember", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var Component = _ember["default"].Component;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], {
    passwordConfirmation: '',
    showCompanySection: false,
    _showCompanyFeatures: function _showCompanyFeatures() {
      return Number(this.get('accountSession.account.userType.id')) === this.get('BR_USERTYPE') && this.get('lockedCompany') !== undefined;
    },
    _showUnlockedBRUserFeatures: function _showUnlockedBRUserFeatures() {
      return Number(this.get('loggedAccount.userType.id')) === this.get('BR_USERTYPE') && this.get('lockedCompany') === undefined;
    },
    init: function init() {
      this._super.apply(this, arguments);
      if (Number(this.get('accountSession.account.userType.id')) === this.get('COMPANY_USERTYPE')) {
        this.set('model.company', this.get('accountSession.account.company'));
      } else if (Number(this.get('accountSession.account.userType.id')) === this.get('BR_USERTYPE') && this.get('lockedCompany') !== undefined) {
        this.set('model.company', this.get('lockedCompany'));
      }
      this.set('showUserType', this._showUnlockedBRUserFeatures());
      this.showCompanyFeatures();
    },
    showCompanyFeatures: function showCompanyFeatures() {
      this.set('showCompany', this._showUnlockedBRUserFeatures());
      this.set('showCompanySection', Number(this.get('model.userType.id')) === this.get('COMPANY_USERTYPE'));
    },
    actions: {
      saveAction: function saveAction(user, password) {
        this.sendAction('save', user, password);
      },
      cancelAction: function cancelAction(user) {
        this.sendAction('cancel', user);
      }
    }
  });
});