define('fcs-dashboard/mirage/factories/financialReport', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  _emberCliMirage.faker.locale = 'en_US';

  exports['default'] = _emberCliMirage['default'].Factory.extend({
    date: _emberCliMirage.faker.date.past,
    cashRevenue: _emberCliMirage.faker.commerce.price,
    creditCardRevenue: _emberCliMirage.faker.commerce.price,
    rfidRevenue: _emberCliMirage.faker.commerce.price,
    grossRevenue: _emberCliMirage.faker.commerce.price,
    tax: _emberCliMirage.faker.commerce.price,
    voidTotal: _emberCliMirage.faker.commerce.price,
    couponDiscountTotal: _emberCliMirage.faker.commerce.price,
    refundTotal: _emberCliMirage.faker.commerce.price,
    creditCardTips: _emberCliMirage.faker.commerce.price,
    rfidTips: _emberCliMirage.faker.commerce.price,
    cashTransactionCount: _emberCliMirage.faker.random.number(10),
    creditTransactionCount: _emberCliMirage.faker.random.number(10),
    rfidTransactionCount: _emberCliMirage.faker.random.number(10),
    tabletsCount: _emberCliMirage.faker.random.number(10)
  });
});
/**
 * Created by ernesto on 19/10/17.
 */