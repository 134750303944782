define('fcs-dashboard/pods/components/menu/menu-wizard/topping-row/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    rowSelected: _ember['default'].computed('selections', function () {
      var _this = this;

      var selected = '';
      this.get('selections').forEach(function (item) {
        if (item.get('id') === _this.get('item.id')) {
          selected = _ember['default'].String.htmlSafe('row-selected');
        }
      });
      return selected;
    }),
    actions: {
      selectedItem: function selectedItem(item) {
        if (!this.get('rowDisabled')) {
          if (this.get('selections').includes(this.get('item'))) {
            this.get('selections').removeObject(item);
            this.set('rowSelected', _ember['default'].String.htmlSafe(''));
          } else {
            this.get('selections').addObject(item);
            this.set('rowSelected', _ember['default'].String.htmlSafe('row-selected'));
          }
        }
      }
    }
  });
});