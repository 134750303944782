define('fcs-dashboard/pods/email-recipient/model', ['exports', 'ember', 'ember-data/model', 'ember-data/attr', 'ember-cp-validations'], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberCpValidations) {
  var isEmpty = _ember['default'].isEmpty;
  var computed = _ember['default'].computed;

  var Validations = (0, _emberCpValidations.buildValidations)({
    address: (0, _emberCpValidations.validator)('format', {
      type: 'email',
      regex: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
      descriptionKey: 'user.details.email.label',
      disabled: computed(function () {
        return isEmpty(this.get('model.address'));
      }).volatile()
    })
  });

  exports['default'] = _emberDataModel['default'].extend(Validations, {
    address: (0, _emberDataAttr['default'])('string')
  });
});