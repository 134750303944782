define('fcs-dashboard/mixins/menu-mixin', ['exports', 'ember'], function (exports, _ember) {
  var Mixin = _ember['default'].Mixin;
  var computed = _ember['default'].computed;
  exports['default'] = Mixin.create({
    usableStatuses: computed(function () {
      return [this.get('intl').t('menu.list.table.usable.usable'), this.get('intl').t('menu.list.table.usable.unusable')];
    }),
    menuStatuses: computed(function () {
      return [this.get('intl').t('menu.list.table.status.active'), this.get('intl').t('menu.list.table.status.inactive')];
    })
  });
});