define("fcs-dashboard/pods/components/financial-overview-report/financial-report-result/financial-admin-report/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/table-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsTableComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  exports["default"] = Component.extend(_fcsDashboardMixinsTableComponent["default"], _fcsDashboardMixinsBaseComponent["default"], {
    data: null,
    hideCompanyColumn: computed(function () {
      var d = this.data;
      var summarizeBy = d['summarizeBy'];
      return summarizeBy !== '3';
    }),
    _columns: computed(function () {
      return [{
        title: this.get('intl').t('report.result.financialOverviewReport.headers.eventVenue'),
        className: 'sales-by-product-cell',
        propertyName: 'name',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.financialOverviewReport.headers.timezone'),
        className: 'sales-by-product-cell',
        propertyName: 'timezone',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.financialOverviewReport.headers.menu'),
        className: 'date-cell',
        propertyName: 'date',
        disableSorting: true
      }, {
        className: 'sales-by-product-cell',
        propertyName: 'company',
        disableSorting: true,
        isHidden: this.get('hideCompanyColumn')
      }, {
        title: this.get('intl').t('report.result.financialOverviewReport.headers.cashSales'),
        className: 'sales-by-product-cell',
        propertyName: 'cashSales',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.financialOverviewReport.headers.creditSales'),
        className: 'sales-by-product-cell',
        propertyName: 'creditCardPresentSales',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.financialOverviewReport.headers.cashlessSales'),
        className: 'sales-by-product-cell',
        propertyName: 'openLoopSales',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.financialOverviewReport.headers.totalSales'),
        className: 'sales-by-product-cell',
        propertyName: 'totalSales',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-product-cell',
        propertyName: 'tax',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-product-cell',
        propertyName: 'voids',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-product-cell',
        propertyName: 'tokenDiscount',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-product-cell',
        propertyName: 'cashDiscount',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-product-cell',
        propertyName: 'couponDiscount',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-product-cell',
        propertyName: 'digitalDiscount',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-product-cell',
        propertyName: 'discounts',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-product-cell',
        propertyName: 'refunds',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-product-cell',
        propertyName: 'creditCardTips',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.financialOverviewReport.headers.cashlessTips'),
        className: 'sales-by-product-cell',
        propertyName: 'openLoopTips',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-product-cell',
        propertyName: 'digitalTips',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.financialOverviewReport.headers.cashPaymentCount'),
        className: 'centerContent',
        propertyName: 'cashOrderCount',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.financialOverviewReport.headers.creditPaymentCount'),
        className: 'centerContent',
        propertyName: 'creditOrderCount',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.financialOverviewReport.headers.cashlessPaymentCount'),
        className: 'centerContent',
        propertyName: 'openLoopOrderCount',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.financialOverviewReport.headers.totalOrderCount'),
        className: 'centerContent',
        propertyName: 'totalOrderCount',
        disableSorting: true
      }, {
        className: 'centerContent',
        propertyName: 'tabletCount',
        disableSorting: true
      }];
    })
  });
});