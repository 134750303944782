define("fcs-dashboard/pods/search-options/model", ["exports", "ember-data/model", "ember-data/attr"], function (exports, _emberDataModel, _emberDataAttr) {
  exports["default"] = _emberDataModel["default"].extend({
    searchText: (0, _emberDataAttr["default"])('string', { defaultValue: '' }),
    fromDate: (0, _emberDataAttr["default"])('date'),
    toDate: (0, _emberDataAttr["default"])('date'),
    tabName: (0, _emberDataAttr["default"])('string'),
    selectedTenders: (0, _emberDataAttr["default"])({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    lastName: (0, _emberDataAttr["default"])('string'),
    lastFour: (0, _emberDataAttr["default"])('string', { defaultValue: '' }),
    ccExpiration: (0, _emberDataAttr["default"])('string'),
    selectedCCTypes: (0, _emberDataAttr["default"])({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    users: (0, _emberDataAttr["default"])({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    orderStatuses: (0, _emberDataAttr["default"])({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    userProfiles: (0, _emberDataAttr["default"])({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    locationGroups: (0, _emberDataAttr["default"])({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    locations: (0, _emberDataAttr["default"])({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    vendors: (0, _emberDataAttr["default"])({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    menus: (0, _emberDataAttr["default"])({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    productTypes: (0, _emberDataAttr["default"])({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    priceStatuses: (0, _emberDataAttr["default"])({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    tabStatuses: (0, _emberDataAttr["default"])({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    paymentStatuses: (0, _emberDataAttr["default"])({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    paymentModes: (0, _emberDataAttr["default"])({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    gateways: (0, _emberDataAttr["default"])({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    reportOutput: (0, _emberDataAttr["default"])('string', { defaultValue: undefined }),
    isEmailDelivery: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    emails: (0, _emberDataAttr["default"])('string', { defaultValue: undefined }),
    orderId: (0, _emberDataAttr["default"])('string', { defaultValue: undefined }),
    orderNote: (0, _emberDataAttr["default"])('string', { defaultValue: undefined }),
    cashUid: (0, _emberDataAttr["default"])('string', { defaultValue: undefined }),
    accessLevel: (0, _emberDataAttr["default"])('string', { defaultValue: undefined }),
    reference: (0, _emberDataAttr["default"])('string', { defaultValue: undefined }),
    authCode: (0, _emberDataAttr["default"])('string', { defaultValue: undefined }),
    product: (0, _emberDataAttr["default"])('string', { defaultValue: undefined }),
    serviceFee: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    tips: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    surcharge: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    isExport: (0, _emberDataAttr["default"])('boolean', { defaultValue: false })
  });
});