define("fcs-dashboard/pods/application/route", ["exports", "ember", "ember-simple-auth/mixins/application-route-mixin"], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin) {
  var Route = _ember["default"].Route;
  var isEqual = _ember["default"].isEqual;
  var service = _ember["default"].inject.service;
  var Logger = _ember["default"].Logger;
  exports["default"] = Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin["default"], {
    intl: service(),
    beforeModel: function beforeModel() {
      this.get('intl').setLocale('en-us');
    },
    afterModel: function afterModel(model, transition) {
      if (isEqual(transition.intent.url, '/login')) {
        this.transitionTo('application');
      }
    },
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('session', this.get('session'));
    },
    actions: {
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      },
      willTransition: function willTransition(transition) {
        if (isEqual(transition.targetName, 'login') && this.get('session.isAuthenticated')) {
          Logger.debug('Aborting login transition since user is authenticated');
          transition.abort();
        } else {
          window.stop();
          return true;
        }
      }
    }
  });
});