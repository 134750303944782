define("fcs-dashboard/pods/order/model", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "model-fragments/attributes", "ember-cp-validations", "moment", "ember"], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships, _modelFragmentsAttributes, _emberCpValidations, _moment, _ember) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    emailToSend: [(0, _emberCpValidations.validator)('format', {
      type: 'email',
      regex: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
      descriptionKey: 'search.details.modal.orderEmail'
    }), (0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'search.details.modal.orderEmail' })]
  });

  var tz = _moment["default"].tz;
  var computed = _ember["default"].computed;
  exports["default"] = _emberDataModel["default"].extend(Validations, {
    grandTotal: (0, _emberDataAttr["default"])('number'),
    customFeeTotal: (0, _emberDataAttr["default"])('number'),
    serviceFeeRate: (0, _emberDataAttr["default"])('string'),
    serviceFee: (0, _emberDataAttr["default"])('number'),
    subtotal: (0, _emberDataAttr["default"])('number'),
    taxesTotal: (0, _emberDataAttr["default"])('number'),
    surcharge: (0, _emberDataAttr["default"])('number'),
    coinRoundedAmountTotal: (0, _emberDataAttr["default"])('number'),
    tip: (0, _emberDataAttr["default"])('number'),
    user: (0, _emberDataAttr["default"])('string'),
    orderNote: (0, _emberDataAttr["default"])('string'),
    orderStatus: (0, _emberDataAttr["default"])('string'),
    customerName: (0, _emberDataAttr["default"])('string'),
    sendType: (0, _emberDataAttr["default"])('string'),
    locationGroup: (0, _emberDataAttr["default"])('string'),
    company: (0, _emberDataAttr["default"])('string'),
    location: (0, _emberDataAttr["default"])('string'),
    menu: (0, _emberDataAttr["default"])('string'),
    occurrenceDate: (0, _emberDataAttr["default"])('date'),
    items: (0, _emberDataRelationships.hasMany)('orderItem'),
    payments: (0, _emberDataRelationships.hasMany)('orderPayment'),
    action: (0, _emberDataAttr["default"])('string'),
    actionResult: (0, _modelFragmentsAttributes.fragment)('order/actionResult'),
    tabletGeneratedId: (0, _emberDataAttr["default"])('string'),
    emails: (0, _emberDataAttr["default"])(),
    orderFees: (0, _modelFragmentsAttributes.fragmentArray)('order/orderFee'),
    //this can be an array in the future
    emailToSend: (0, _emberDataAttr["default"])('string'),
    locationGroupTimezone: (0, _emberDataAttr["default"])('string'),
    formattedDate: computed(function () {
      return tz(this.get('occurrenceDate'), this.get('locationGroupTimezone')).format();
    }),
    tabName: (0, _emberDataAttr["default"])('string'),
    tabStatus: (0, _emberDataAttr["default"])('string')
  });
});