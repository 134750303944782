define("fcs-dashboard/pods/components/menu/menu-wizard/menu-structure/component", ["exports", "ember", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var isEqual = _ember["default"].isEqual;
  var run = _ember["default"].run;
  var Logger = _ember["default"].Logger;
  var A = _ember["default"].A;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], {
    data: computed(function () {
      return A();
    }),
    tabViewSelected: computed(function () {
      if (this.get('tabViewSelected ') === undefined) {
        return "list_view";
      } else {
        return this.get('tabViewSelected');
      }
    }),
    tabCategorySelected: undefined,
    showModalCategory: false,
    newCategory: null,
    saveStepError: '',
    needRefreshCategory: true,
    needRefreshPagesTable: true,
    needRefreshTabletView: true,
    editing: false,
    _pageIndex: 0,
    _page: undefined,
    init: function init() {
      this._super.apply(this, arguments);
      var addCategoryButton = {
        tag: 'add',
        label: this.get('intl').t('menu.builder.steps.stepSix.buttons.category.add.label')
      };
      this.get('menu.categories').setEach('activeCategoryButton', false);
      this.get('data').addObjects(this.get('menu.categories')).addObject(addCategoryButton);
      if (this.get('menu.categories') !== undefined && this.get('menu.categories.length') > 0) {
        this.set('tabCategorySelected', this.get('menu.categories').objectAt(0));
        this.set('tabCategorySelected.activeCategoryButton', true);
        this.set('_page', this.get('tabCategorySelected.pages').objectAt(this.get('_pageIndex')));
        this.initProductPositions(this.get('_page'));
      }
    },
    initProductPositions: function initProductPositions(page) {
      //init product positions in case they don't have i.e. position is zero (it's possible that some new imported menus do not a position yet)
      page.get('products').forEach(function (product, idx) {
        Logger.debug("index: " + idx + ", product " + product.get('id') + ", position " + product.get('position'));
        if (isEqual(product.get('position'), 0)) {
          product.set('position', idx + 1);
        }
      });
    },
    refreshCategory: function refreshCategory() {
      if (this.get('menu.categories.length') > 0) {
        this.set('_pageIndex', 0);
        if (this.get('tabCategorySelected') !== undefined) {
          this.set('tabCategorySelected.activeCategoryButton', false);
        }
        this.set('tabCategorySelected', this.get('menu.categories').objectAt(0));
        this.set('tabCategorySelected.activeCategoryButton', true);
        if (this.get('tabCategorySelected.pages.length') > 0) {
          this.set('_page', this.get('tabCategorySelected.pages').objectAt(this.get('_pageIndex')));
        }
      } else {
        this.set('tabCategorySelected', null);
      }
    },
    setFirstPageSelected: function setFirstPageSelected(category) {
      this.set('_pageIndex', 0);
      if (category.get('pages.length') > 0) {
        this.set('_page', category.get('pages').objectAt(this.get('_pageIndex')));
      }
    },
    refreshPagesTable: function refreshPagesTable(category) {
      var _this = this;

      this.set('needRefreshPagesTable', false);
      if (this.get('tabCategorySelected')) {
        //un-select previous selected category
        this.set('tabCategorySelected.activeCategoryButton', false);
      }
      //assign new selected category
      this.set('tabCategorySelected', category);
      //set as active new category
      this.set('tabCategorySelected.activeCategoryButton', true);
      if (category.get("isNew")) {
        this.setFirstPageSelected(category);
      } else {
        if (!category.get('pages').includes(this.get('_page'))) {
          this.setFirstPageSelected(category);
        }
      }
      run.next(function () {
        _this.set('needRefreshPagesTable', true);
      });
    },
    actions: {
      nextStep: function nextStep() {
        this.send('cleanMessages');
        this.sendAction('nextStep', true);
      },
      previousStep: function previousStep() {
        this.send('cleanMessages');
        this.sendAction('previousStep');
      },
      showMenuList: function showMenuList() {
        this.sendAction('showMenuList');
      },
      tabCategorySelectedAction: function tabCategorySelectedAction(categorySelected) {
        this.set('tabCategorySelected.activeCategoryButton', false);
        this.refreshPagesTable(categorySelected);
      },
      addCategory: function addCategory(category) {
        this.set('menu.changed', true);
        if (category.get('pages.length') === 0) {
          var categoryDefaultPage = this.get('store').createRecord('page');
          category.get('pages').addObject(categoryDefaultPage);
          this.get('menu').incrementProperty('pagesCounter');
        }
        this.get('menu.categories').addObject(category);
        this.get('data').insertAt(this.get('data').length - 1, category);
        this.send('closeModal');
        this.refreshPagesTable(category);
      },
      editCategory: function editCategory(category) {
        this.set('menu.changed', true);
        this.set('tabCategorySelected', category);
        this.send('closeModal');
        this.refreshPagesTable(category);
      },
      openModal: function openModal() {
        this.set('editing', false);
        this.set('newCategory', this.get('store').createRecord('category'));
        this.set('showModalCategory', true);
      },
      editModal: function editModal() {
        this.set('editing', true);
        this.set('newCategory', this.get('tabCategorySelected'));
        this.set('showModalCategory', true);
      },
      closeModal: function closeModal() {
        this.set('showModalCategory', false);
      },
      cancelModal: function cancelModal(newCategory) {
        if (!newCategory.get('isNew')) {
          newCategory.rollbackAttributes();
          this.set('newCategory', newCategory);
        }
        this.set('showModalCategory', false);
      },
      removeCategory: function removeCategory() {
        this.set('menu.changed', true);
        this.get('data').removeObject(this.get('tabCategorySelected'));
        if (this.get('tabCategorySelected.id') !== null && this.get('tabCategorySelected.id') !== undefined) {
          this.get('menu.deletedCategories').addObject(this.get('tabCategorySelected.id'));
        }
        this.get('menu.categories').removeObject(this.get('tabCategorySelected'));
        this.refreshCategory();
      },
      sortAction: function sortAction(list) {
        list.forEach(function (item, index) {
          item.set('position', index + 1);
        });
        Logger.debug('sorted list', list);
        this.set('_page.products', list);
      },
      categoryBackAction: function categoryBackAction() {
        Logger.debug('left pressed!!!');
        if (this.get('_pageIndex') > 0) {
          this.decrementProperty('_pageIndex');
          var page = this.get('tabCategorySelected.pages').objectAt(this.get('_pageIndex'));
          this.set('_page', page);
          this.initProductPositions(page);
        }
      },
      categoryNextAction: function categoryNextAction() {
        Logger.debug('right pressed!!!');
        if (this.get('_pageIndex') < this.get('tabCategorySelected.pages.length') - 1) {
          this.incrementProperty('_pageIndex');
          var page = this.get('tabCategorySelected.pages').objectAt(this.get('_pageIndex'));
          this.set('_page', page);
          this.initProductPositions(page);
        }
      },
      onTabClickAction: function onTabClickAction(selectedTab) {
        var _this2 = this;

        this.get('tabCategorySelected.pages').every(function (page, index) {
          if (isEqual(page.get('pageId'), selectedTab)) {
            _this2.set('_page', page);
            _this2.set('_pageIndex', index);
            _this2.initProductPositions(page);
            return false;
          }
          return true;
        });
      },
      changePositionAction: function changePositionAction(product, position) {
        this.set('needRefreshTabletView', false);
        if (position !== null) {
          var targetProductAtPosition = null;
          if (this.get('_page.products').findBy('position', position) !== null && this.get('_page.products').findBy('position', position) !== undefined) {
            targetProductAtPosition = this.get('_page.products').findBy('position', position);
          } else {
            targetProductAtPosition = this.get('_page.productGroups').findBy('position', position);
          }
          if (targetProductAtPosition !== undefined) {
            targetProductAtPosition.set('position', product.get('position'));
            product.set('position', position);
          } else {
            product.set('position', position);
          }
        } else {
          product.set('position', 0);
        }
        this.set('needRefreshTabletView', true);
      },
      onChangeTabView: function onChangeTabView(selectedTabView) {
        this.set('tabViewSelected', selectedTabView);
      },
      save: function save() {
        this.send('cleanMessages');
        this.sendAction('save');
      }
    }
  });
});