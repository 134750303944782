define("fcs-dashboard/pods/components/product-mix-report/product-mix-result/pmix-by-products/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/table-component", "fcs-dashboard/mixins/company-mixin"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsTableComponent, _fcsDashboardMixinsCompanyMixin) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var isEmpty = _ember["default"].isEmpty;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], _fcsDashboardMixinsTableComponent["default"], _fcsDashboardMixinsCompanyMixin["default"], {
    _showData: computed('data', function () {
      return this.get('data') !== null && !isEmpty(this.get('data'));
    }),
    data: null,
    hideProductColumn: computed(function () {
      return this.get('summarizeBy') === "1" || this.get('summarizeBy') === "3" || this.get('summarizeBy') === "7";
    }),
    didRender: function didRender() {
      this._super.apply(this, arguments);

      var val = this.get('summarizeBy');
      var colNum = 2;
      if (val === "1" || val === "3" || val === "7") {
        colNum = 1;
      }

      jQuery('.table tr').each(function () {
        var val = jQuery(this).children().eq(colNum).html().trim();
        if (val === 'Total') {
          jQuery(this).css('font-weight', 'bold');
          var col1 = jQuery(this).children().eq(0).html().trim();
          jQuery(this).children().eq(0).html("Total - " + col1);
          jQuery(this).children().eq(colNum).html("");
        }
      });
    },
    _productMixesColumns: computed(function () {
      var _this = this;

      var columns = [{
        title: this.get('intl').t('report.result.productDescription'),
        propertyName: 'productCatalogName',
        isHidden: this.get('hideProductColumn'),
        filterFunction: function filterFunction(cell, filterString) {
          _this.sendAction('onFilterChanged', {
            propertyName: 'productCatalogName',
            filterString: filterString
          });
          return new RegExp(filterString, 'i').test(cell);
        }
      }, {
        title: this.get('intl').t('report.result.productType'),
        propertyName: 'productTypeName',
        filterFunction: function filterFunction(cell, filterString) {
          _this.sendAction('onFilterChanged', {
            propertyName: 'productTypeName',
            filterString: filterString
          });
          return new RegExp(filterString, 'i').test(cell);
        }
      }, {
        title: this.get('intl').t('report.result.itemState'),
        propertyName: 'productStatus',
        filterFunction: function filterFunction(cell, filterString) {
          _this.sendAction('onFilterChanged', {
            propertyName: 'productStatus',
            filterString: filterString
          });
          return new RegExp(filterString, 'i').test(cell);
        }
      }];
      var tenderColumns = [{
        title: this.get('intl').t('report.result.productMix.productsByTenderType.cashPayment'),
        propertyName: 'cashPayment',
        template: 'productMixReport/amountCell',
        filterFunction: function filterFunction(cell, filterString) {
          _this.sendAction('onFilterChanged', {
            propertyName: 'cashPayment',
            filterString: filterString
          });
          return new RegExp(filterString, 'i').test(cell);
        }
      }, {
        title: this.get('intl').t('report.result.productMix.productsByTenderType.creditPayment'),
        propertyName: 'creditPayment',
        template: 'productMixReport/amountCell',
        filterFunction: function filterFunction(cell, filterString) {
          _this.sendAction('onFilterChanged', {
            propertyName: 'creditPayment',
            filterString: filterString
          });
          return new RegExp(filterString, 'i').test(cell);
        }
      }, {
        title: this.get('intl').t('report.result.productMix.productsByTenderType.cashlessPayment'),
        propertyName: 'cashlessPayment',
        template: 'productMixReport/amountCell',
        filterFunction: function filterFunction(cell, filterString) {
          _this.sendAction('onFilterChanged', {
            propertyName: 'cashlessPayment',
            filterString: filterString
          });
          return new RegExp(filterString, 'i').test(cell);
        }
      }];

      var otherColumns = [{
        title: this.get('intl').t('report.result.totalWithTax'),
        propertyName: 'totalWithTax',
        template: 'productMixReport/amountCell',
        filterFunction: function filterFunction(cell, filterString) {
          _this.sendAction('onFilterChanged', {
            propertyName: 'totalWithTax',
            filterString: filterString
          });
          return new RegExp(filterString, 'i').test(cell);
        }
      }, {
        title: this.get('intl').t('report.result.amountModified'),
        propertyName: 'amount',
        template: 'productMixReport/amountCell',
        filterFunction: function filterFunction(cell, filterString) {
          _this.sendAction('onFilterChanged', {
            propertyName: 'amount',
            filterString: filterString
          });
          return new RegExp(filterString, 'i').test(cell);
        }
      }, {
        title: this.get('intl').t('report.result.totalCount'),
        propertyName: 'productQuantity',
        filterFunction: function filterFunction(cell, filterString) {
          _this.sendAction('onFilterChanged', {
            propertyName: 'productQuantity',
            filterString: filterString
          });
          return new RegExp(filterString, 'i').test(cell);
        }
      }, {
        title: this.get('intl').t('report.result.percentOfItem'),
        propertyName: 'percentageOfProduct',
        template: 'productMixReport/percentageCell',
        filterFunction: function filterFunction(cell, filterString) {
          _this.sendAction('onFilterChanged', {
            propertyName: 'percentageOfProduct',
            filterString: filterString
          });
          return new RegExp(filterString, 'i').test(cell);
        }
      }];

      var productPlusLocationPlusHour = [{
        title: this.get('intl').t('report.result.date'),
        propertyName: 'dateString',
        filterFunction: function filterFunction(cell, filterString) {
          _this.sendAction('onFilterChanged', {
            propertyName: 'dateString',
            filterString: filterString
          });
          return new RegExp(filterString, 'i').test(cell);
        }
      }].concat(columns, [{
        title: this.get('intl').t('report.result.totalWithTax'),
        propertyName: 'totalWithTax',
        template: 'productMixReport/amountCell',
        filterFunction: function filterFunction(cell, filterString) {
          _this.sendAction('onFilterChanged', {
            propertyName: 'totalWithTax',
            filterString: filterString
          });
          return new RegExp(filterString, 'i').test(cell);
        }
      }, {
        title: this.get('intl').t('report.result.amountModified'),
        propertyName: 'amount',
        template: 'productMixReport/amountCell',
        filterFunction: function filterFunction(cell, filterString) {
          _this.sendAction('onFilterChanged', {
            propertyName: 'amount',
            filterString: filterString
          });
          return new RegExp(filterString, 'i').test(cell);
        }
      }, {
        title: this.get('intl').t('report.result.totalCount'),
        propertyName: 'productQuantity',
        filterFunction: function filterFunction(cell, filterString) {
          _this.sendAction('onFilterChanged', {
            propertyName: 'productQuantity',
            filterString: filterString
          });
          return new RegExp(filterString, 'i').test(cell);
        }
      }]);

      if (this.get('summarizeBy') === '6' || this.get('summarizeBy') === '7') {
        return productPlusLocationPlusHour;
      } else if (this.get('summarizeBy') === '4' || this.get('summarizeBy') === '5') {
        return [].concat(columns, tenderColumns, otherColumns);
      } else {
        return [].concat(columns, otherColumns);
      }
    })
  });
});