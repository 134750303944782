define("fcs-dashboard/pods/components/clerk-report/report-result/locations/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/table-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsTableComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var isEmpty = _ember["default"].isEmpty;
  exports["default"] = Component.extend(_fcsDashboardMixinsTableComponent["default"], _fcsDashboardMixinsBaseComponent["default"], {
    data: [],
    _showData: computed('data', function () {
      return this.get('data') !== null && !isEmpty(this.get('data'));
    }),
    _columns: computed(function () {
      return [{
        title: this.get('intl').t('report.result.clerkReport.locations.name'),
        className: 'non-cash-category-cell',
        propertyName: 'name',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.clerkReport.clerks.cashDrawer'),
        className: 'non-cash-quantity-cell',
        template: 'clerkReport/amountCell',
        propertyName: 'cashDrawer',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.clerkReport.clerks.cashGrossSales'),
        className: 'non-cash-quantity-cell',
        template: 'clerkReport/amountCell',
        propertyName: 'cashSales',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.clerkReport.clerks.creditGrossSales'),
        className: 'non-cash-quantity-cell',
        template: 'clerkReport/amountCell',
        propertyName: 'creditSales',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.clerkReport.clerks.rfidGrossSales'),
        className: 'non-cash-quantity-cell',
        template: 'clerkReport/amountCell',
        propertyName: 'rfidSales',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.clerkReport.clerks.netSalesWithTax'),
        className: 'non-cash-quantity-cell',
        template: 'clerkReport/amountCell',
        propertyName: 'totalSales',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.clerkReport.clerks.coupon'),
        className: 'non-cash-total-cell',
        template: 'clerkReport/amountCell',
        propertyName: 'coupon',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.clerkReport.clerks.spills'),
        className: 'non-cash-total-cell',
        template: 'clerkReport/amountCell',
        propertyName: 'spills',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.clerkReport.clerks.comp'),
        className: 'non-cash-total-cell',
        template: 'clerkReport/amountCell',
        propertyName: 'comp',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.clerkReport.clerks.cashDiscounts'),
        className: 'non-cash-total-cell',
        template: 'clerkReport/amountCell',
        propertyName: 'cashDiscounts',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.clerkReport.clerks.digitalDiscounts'),
        className: 'non-cash-total-cell',
        template: 'clerkReport/amountCell',
        propertyName: 'digitalDiscounts',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.clerkReport.clerks.discounts'),
        className: 'non-cash-total-cell',
        template: 'clerkReport/amountCell',
        propertyName: 'totalDiscounts',
        disableSorting: true
      }, {
        className: 'non-cash-total-cell',
        template: 'clerkReport/amountCell',
        propertyName: 'serviceFees',
        disableSorting: true
      }, {
        className: 'non-cash-total-cell',
        template: 'clerkReport/amountCell',
        propertyName: 'taxes',
        disableSorting: true
      }, {
        className: 'non-cash-total-cell',
        template: 'clerkReport/amountCell',
        propertyName: 'netSales',
        disableSorting: true
      }, {
        className: 'non-cash-total-cell',
        template: 'clerkReport/amountCell',
        propertyName: 'creditCardTips',
        disableSorting: true
      }, {
        className: 'non-cash-total-cell',
        template: 'clerkReport/amountCell',
        propertyName: 'rfidTips',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.clerkReport.clerks.totalDigitalTips'),
        className: 'non-cash-total-cell',
        template: 'clerkReport/amountCell',
        propertyName: 'totalDigitalTips',
        disableSorting: true
      }, {
        className: 'non-cash-total-cell',
        template: 'clerkReport/amountCell',
        propertyName: 'refunds',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.clerkReport.clerks.voids'),
        className: 'non-cash-total-cell',
        template: 'clerkReport/amountCell',
        propertyName: 'voids',
        disableSorting: true
      }];
    })
  });
});