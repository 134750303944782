define('fcs-dashboard/pods/components/location-group/list/component', ['exports', 'ember', 'fcs-dashboard/mixins/base-component', 'fcs-dashboard/mixins/table-component', 'fcs-dashboard/mixins/location-group-mixin'], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsTableComponent, _fcsDashboardMixinsLocationGroupMixin) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var run = _ember['default'].run;
  var get = _ember['default'].get;
  var extend = _ember['default'].$.extend;
  exports['default'] = Component.extend(_fcsDashboardMixinsBaseComponent['default'], _fcsDashboardMixinsTableComponent['default'], _fcsDashboardMixinsLocationGroupMixin['default'], {
    tagName: '',
    metaItemsCountProperty: 'total',
    _locationGroups: [],
    _columns: computed(function () {
      var cashlessAvailable = this.get('cashlessAvailable');
      return [{
        title: '',
        template: "locationGroup/actions/edit-action",
        className: 'editRow'
      }, {
        propertyName: 'name',
        filteredBy: 'name',
        title: this.get('intl').t('locationGroups.list.name')
      }, {
        propertyName: 'translatedIsCashless',
        title: this.get('intl').t('locationGroups.list.cashless.header'),
        className: 'centerContent menusRow',
        filteredBy: 'translatedIsCashless',
        filterWithSelect: true,
        predefinedFilterOptions: cashlessAvailable
      }, {
        propertyName: 'timezone',
        filteredBy: 'timezone',
        title: this.get('intl').t('locationGroups.list.timezone'),
        className: 'centerContent menusRow'
      }, {
        title: '',
        template: "locationGroup/actions/status-action",
        className: 'editRow'
      }];
    }),
    _typeOptions: computed(function () {
      return this.get('store').findAll('locationGroupType');
    }),
    _selectedType: undefined,
    _selectedStatus: { label: 'locationGroups.list.status.active', value: true },
    _statusOptions: computed(function () {
      return [{ label: 'locationGroups.list.status.active', value: true }, { label: 'locationGroups.list.status.inactive', value: false }];
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      //initialize locationGroups
      if (this.get('locationGroups').then === undefined) {
        // no promise, lets load the data
        this.set('_locationGroups', this.get('locationGroups'));
        this.set('_filteredLocationGroups', this.get('_locationGroups').filterBy('isActive', true));
      } else {
        // if a promise set the content when it resolves
        this.get('locationGroups').then(function (content) {
          run(function () {
            _this.set('_locationGroups', content);
            _this.set('_filteredLocationGroups', _this.get('_locationGroups').filterBy('isActive', true));
          });
        });
      }

      //initialize locationGroupTypes
      if (this.get('_typeOptions').then !== undefined) {
        // if a promise set the content when it resolves
        this.get('_typeOptions').then(function (content) {
          run(function () {
            _this.set('_typeOptions', content);
          });
        });
      }

      //set selectedStatus as active by default
      this.set('_selectedStatus', this.get('_statusOptions').objectAt(0));
    },
    actions: {
      changeStatus: function changeStatus(item) {
        item.set('isActive', !item.get('isActive'));
        this.sendAction('saveLocationGroup', item);
      },
      changeSelectedStatus: function changeSelectedStatus(item) {
        this.set('_selectedStatus', item);
        this.send('updateLocationGroups');
      },
      changeSelectedType: function changeSelectedType(item) {
        this.set('_selectedType', item);
        this.send('updateLocationGroups');
      },
      updateLocationGroups: function updateLocationGroups() {
        var data = this.get('locationGroups');
        var queryParams = get(data, 'query');
        if (this.get('_selectedStatus') !== null && this.get('_selectedStatus') !== undefined) {
          queryParams = extend(queryParams, { isActive: this.get('_selectedStatus.value') });
        } else {
          delete queryParams.isActive;
        }
        if (this.get('_selectedType') !== null && this.get('_selectedType') !== undefined) {
          var selectedType = this.get('_selectedType.id') === '0' ? 'EVENT' : 'VENUE';
          queryParams = extend(queryParams, { selectedType: selectedType });
        } else {
          delete queryParams.selectedType;
        }

        data.set('query', queryParams);
        jQuery('#j-table-filter-button').trigger('click');
      }
    }
  });
});