define("fcs-dashboard/pods/components/location-group/custom-fee-modal/custom-fee-log-modal/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "ember-simple-auth/mixins/authenticated-route-mixin", "moment"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _moment) {
  var Component = _ember["default"].Component;
  var isEmpty = _ember["default"].isEmpty;
  var run = _ember["default"].run;
  var computed = _ember["default"].computed;
  exports["default"] = Component.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], _fcsDashboardMixinsBaseComponent["default"], {
    logsLoaded: false,
    logs: computed(function () {
      return this.get('store').query('customFeeLog', { customFeeId: this.get('customFeeId') });
    }),
    _columns: computed(function () {
      return [{
        propertyName: 'name',
        title: this.get('intl').t('locationGroups.details.customFees.list.table.name')
      }, {
        propertyName: 'username',
        title: this.get('intl').t('locationGroups.details.customFees.list.table.username')
      }, {
        propertyName: 'logDate',
        title: this.get('intl').t('locationGroups.details.customFees.list.table.date')
      }, {
        propertyName: 'value',
        title: this.get('intl').t('locationGroups.details.customFees.list.table.value')
      }, {
        propertyName: 'type',
        title: this.get('intl').t('locationGroups.details.customFees.list.table.type')
      }, {
        propertyName: 'appliesTo',
        title: this.get('intl').t('locationGroups.details.customFees.list.table.appliesTo')
      }, {
        title: this.get('intl').t('locationGroups.details.customFees.list.table.enable'),
        template: "custom-fee/actions/enable-action",
        className: 'editRow'
      }, {
        title: this.get('intl').t('locationGroups.details.customFees.list.table.taxable'),
        template: "custom-fee/actions/taxable-action",
        className: 'editRow'
      }];
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      if (this.get('logs').then !== undefined) {
        this.get('logs').then(function (content) {
          run(function () {
            _this.set('logs', content);
            _this.set('logsLoaded', true);
          });
        });
      }
    },
    actions: {
      cancelCustomFeeLogModal: function cancelCustomFeeLogModal() {
        this.set('logsLoaded', false);
        this.sendAction('cancelCustomFeeLogModal');
      }
    }
  });
});