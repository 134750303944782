define("fcs-dashboard/pods/components/fcs-password-reset/component", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var service = _ember["default"].inject.service;
  var computed = _ember["default"].computed;
  var htmlSafe = _ember["default"].String.htmlSafe;
  var isEmpty = _ember["default"].isEmpty;
  exports["default"] = Component.extend({
    token: null,
    session: service(),
    intl: service(),
    errorMessage: "",
    displayErrorClass: 'hideError',
    displayProperty: computed('displayErrorClass', function () {
      return htmlSafe(this.get('displayErrorClass'));
    }),
    actions: {
      resetPassword: function resetPassword() {
        var _this = this;

        this.set('displayErrorClass', 'hideError');
        this.set('errorMessage', '');

        var _getProperties = this.getProperties('confirmPassword', 'password');

        var confirmPassword = _getProperties.confirmPassword;
        var password = _getProperties.password;

        if (!isEmpty(password)) {
          if (!isEmpty(confirmPassword)) {
            if (confirmPassword === password) {
              var passwordInfo = {
                token: this.get('token'),
                password: password
              };

              var path = '/api/v1/passwordResets';
              var _window$location = window.location;
              var protocol = _window$location.protocol;
              var host = _window$location.host;

              var url = protocol + "//" + host + path;
              var options = {
                data: passwordInfo,
                url: url,
                type: 'POST'
              };
              options.success = function (result) {
                if (result !== undefined && result !== null) {
                  _this.sendAction('transitionToLogin');
                }
              };
              options.error = function () {
                console.log('error');
              };
              _ember["default"].$.ajax(options);
            } else {
              this.set('displayErrorClass', 'displayError');
              this.set('errorMessage', this.get('intl').t('resetPassword.errors.passwordsDontMatch'));
            }
          } else {
            this.set('displayErrorClass', 'displayError');
            this.set('errorMessage', this.get('intl').t('resetPassword.errors.confirmPasswordRequired'));
          }
        } else {
          this.set('displayErrorClass', 'displayError');
          this.set('errorMessage', this.get('intl').t('resetPassword.errors.passwordRequired'));
        }
      }
    }
  });
});