define('fcs-dashboard/pods/components/fcs-navbar/top-item/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var htmlSafe = _ember['default'].String.htmlSafe;
  exports['default'] = Component.extend({
    tagName: '',
    iconClass: '',
    init: function init() {
      this._super.apply(this, arguments);
      this.set('iconClass', htmlSafe('fa-caret-down'));
    },
    actions: {
      itemAction: function itemAction(iconClass) {
        var icon = String(iconClass.toString());
        icon = icon.includes('down') ? 'fa-caret-up' : 'fa-caret-down';
        this.set('iconClass', htmlSafe(icon));
      }
    }
  });
});