define("fcs-dashboard/pods/components/menu/menu-wizard/payment-information/cashless-management/component", ["exports", "ember", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var Component = _ember["default"].Component;
  var run = _ember["default"].run;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], {
    selectedPaypalSubmerchant: undefined,
    selectedCreditCardSubmerchant: undefined,
    gatewaySubmerchants: [],
    paypalSubmerchants: [],
    creditCardSubmerchants: [],
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      if (this.get('gatewaySubmerchants').then !== undefined) {
        this.get('gatewaySubmerchants').then(function (content) {
          run(function () {
            _this.set('gatewaySubmerchants', content.filterBy('isNew', false));
            _this.set('paypalSubmerchants', content.filterBy('type', 1).filterBy('isNew', false));
            _this.set('creditCardSubmerchants', content.filterBy('type', 0).filterBy('isNew', false));
            if (_this.get('menuLocation.cashless') !== undefined && _this.get('menuLocation.cashless') !== null && _this.get('menuLocation.cashless.enabled')) {
              if (_this.get('menuLocation.cashless.creditCardSubmerchant') !== 0) {
                _this.set('selectedCreditCardSubmerchant', _this.get('creditCardSubmerchants').findBy('id', _this.get('menuLocation.cashless.creditCardSubmerchant').toString()));
              }

              if (_this.get('menuLocation.cashless.paypalSubmerchant') !== 0) {
                _this.set('selectedPaypalSubmerchant', _this.get('paypalSubmerchants').findBy('id', _this.get('menuLocation.cashless.paypalSubmerchant').toString()));
              }
            }
          });
        });
      } else {
        this.set('paypalSubmerchants', this.get('gatewaySubmerchants').filterBy('type', 1).filterBy('isNew', false));
        this.set('creditCardSubmerchants', this.get('gatewaySubmerchants').filterBy('type', 0).filterBy('isNew', false));

        if (this.get('menuLocation.cashless') !== undefined && this.get('menuLocation.cashless') !== null && this.get('menuLocation.cashless.enabled')) {
          if (this.get('menuLocation.cashless.creditCardSubmerchant') !== 0) {
            this.set('selectedCreditCardSubmerchant', this.get('creditCardSubmerchants').findBy('id', this.get('menuLocation.cashless.creditCardSubmerchant').toString()));
          }

          if (this.get('menuLocation.cashless.paypalSubmerchant') !== 0) {
            this.set('selectedPaypalSubmerchant', this.get('paypalSubmerchants').findBy('id', this.get('menuLocation.cashless.paypalSubmerchant').toString()));
          }
        }
      }
    },
    actions: {
      inputKeyUp: function inputKeyUp() {
        if (this.get('menuLocation.openLoopSurchargePercentage') > 10) {
          this.set('menuLocation.openLoopSurchargePercentage', 0);
        }
      },
      updateCashlessSurcharge: function updateCashlessSurcharge() {
        this.toggleProperty('menuLocation.openLoopSurchargeEnabled');
        if (this.get('menuLocation.openLoopSurchargeEnabled')) {
          this.set('menuLocation.cash.discountEnabled', false);
          this.set('menuLocation.cash.discount', 0);
        }
      },
      selectPaypalSubmerchant: function selectPaypalSubmerchant(paypalSubmerchant) {
        if (paypalSubmerchant !== null && paypalSubmerchant !== undefined) {
          this.set('menuLocation.cashless.paypalSubmerchant', paypalSubmerchant.id);
          this.set('selectedPaypalSubmerchant', paypalSubmerchant);
        } else {
          this.set('menuLocation.cashless.paypalSubmerchant', 0);
          this.set('selectedPaypalSubmerchant', null);
        }
      },
      selectCreditCardSubmerchant: function selectCreditCardSubmerchant(creditCardSubmerchant) {
        if (creditCardSubmerchant !== null && creditCardSubmerchant !== undefined) {
          this.set('menuLocation.cashless.creditCardSubmerchant', creditCardSubmerchant.id);
          this.set('selectedCreditCardSubmerchant', creditCardSubmerchant);
        } else {
          this.set('menuLocation.cashless.creditCardSubmerchant', 0);
          this.set('selectedCreditCardSubmerchant', null);
        }
      }
    }
  });
});