define("fcs-dashboard/pods/components/high-level-invoicing-report/search-criteria/component", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/report-mixin", "moment"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsReportMixin, _moment) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var run = _ember["default"].run;
  exports["default"] = Component.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], _fcsDashboardMixinsBaseComponent["default"], _fcsDashboardMixinsReportMixin["default"], {
    isBrAdmin: computed.alias('isBRUser'),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('selectedVendor', null);
      this._initializeLocationGroups();
      this._initializeReportExportParameters();

      var stime = (0, _moment["default"])('05:00 AM', "hh:mm a");
      var fdate = (0, _moment["default"])(this.get('fromDate')).set({
        'hour': stime.get('hour'),
        'minute': stime.get('minute')
      });
      this.set('fromDate', fdate);

      var etime = (0, _moment["default"])('05:00 AM', "hh:mm a");
      var tdate = (0, _moment["default"])(this.get('toDate')).set({
        'hour': etime.get('hour'),
        'minute': etime.get('minute')
      });
      this.set('toDate', tdate);
    },
    didRender: function didRender() {
      this._super();
      var evt = document.createEvent('Event');
      evt.initEvent('load', false, false);
      window.dispatchEvent(evt);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (!this.get('selectedLocationGroup.isDestroyed') || this.get('selectedLocationGroup.isDestroying')) {
        this.set('selectedLocationGroup', null);
      }
    },
    actions: {
      showExportModal: function showExportModal() {
        this._initializeReportExportParameters();
        this.set('showExportModal', true);
      },
      closeModal: function closeModal() {
        this.set('showExportModal', false);
      },
      accept: function accept() {
        this.set('showExportModal', false);

        this.get('reportOptions.vendors').clear();
        // Adding the list of companies for selection
        // Check SelectedVendor, if there is a vendor selected add the selected vendor to the list & ignore the VMCs list
        if (this.get('selectedVendor') !== null && this.get('selectedVendor') !== undefined) {
          try {
            this.get('reportOptions.vendors').addObjects(this.get('selectedVendor').mapBy('companyId'));
          } catch (e) {
            this.get('reportOptions.vendors').addObject(this.get('selectedVendor.companyId'));
          }
        }

        this.send('search', this.get('reportOptions'));
      },
      searchReport: function searchReport() {
        this._initializeReportExportParameters();
        this.set('isShowExportButton', false);
        this.send('search');
      },
      search: function search() {
        this.send('cleanMessages');
        var isValid = true;
        var errorMessage = undefined;

        this._setDefaultFilters(isValid, errorMessage);

        if (isValid) {
          this.set('page', 0);
          this.sendAction('search', this.get('reportOptions'));
        }
      },
      multipleLocationGroupSelectionForReport: function multipleLocationGroupSelectionForReport(locationGroup) {
        var _this = this;

        var selectedLocationGroups = [];
        locationGroup.forEach(function (item) {
          selectedLocationGroups.addObject(item);
        });
        if (selectedLocationGroups.length > 0) {
          locationGroup = selectedLocationGroups;
        }
        this.set('selectedLocationGroup', locationGroup);
        this.get('selectedLocations').clear();
        this.get('selectedMenus').clear();
        this.set('selectedVendor', null);
        this.set('selectedVendorManagement', null);

        if (locationGroup !== null && locationGroup.length === 1) {
          this.set('hasVendors', true);
          var _locationGroup = this._getSingleSelectedLocationGroup(locationGroup);
          this.get('store').queryRecord('reportsLocationGroup', { locationGroupId: _locationGroup.id }).then(function (content) {
            run(function () {
              if (content.get('vendorCompanies.length') > 0) {
                _this.set('_vendorCompanies', content.get('vendorCompanies').sortBy('companyName'));
              } else {
                _this.get('_vendorCompanies').clear();
              }
              _this.get('_vendorCompanies').addObject(content.get('producer'));
              _this.set('producerOwner', content.get('producer'));
              _this._updateSelectedCompanyRoleOptions();
            });
          });
        } else if (locationGroup !== null && locationGroup.length > 1) {
          (function () {
            var endpoint = "/api/v1/reportsLocationGroups/multipleLocationGroups";
            var _authToken = _this.get('session.data.authenticated.access_token');
            var options = {
              url: endpoint,
              type: 'GET',
              data: { locationGroupIds: locationGroup.mapBy("id") }
            };
            options.beforeSend = function (xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + _authToken);
            };
            options.success = function (content) {
              var vendorCompanies = [];
              content.reportsLocationGroups.forEach(function (reportsLocationGroup) {
                if (reportsLocationGroup.vendorCompanies.length > 0) {
                  reportsLocationGroup.vendorCompanies.forEach(function (company) {
                    var tempCompany = vendorCompanies.filterBy('companyId', company.companyId);
                    if (tempCompany.length === 0) {
                      vendorCompanies.addObject(company);
                    }
                  });
                }
                var tempProducer = vendorCompanies.filterBy('companyId', reportsLocationGroup.producer.companyId);
                if (tempProducer.length === 0) {
                  vendorCompanies.addObject(reportsLocationGroup.producer);
                }
              });
              if (vendorCompanies.length > 0) {
                _this.set('hasVendors', true);
                _this.set('_vendorCompanies', vendorCompanies);
              }
            };
            options.error = function () {
              var errorMessage = undefined;
              // Catch error and return proper error message
              errorMessage = _this.get('notify').alert(_this.get('intl').t('menu.builder.steps.stepSix.errors.saveError'), { closeAfter: 5000 });
              _this.get('errorMessages').addObject(errorMessage);
            };
            _ember["default"].$.ajax(options);
          })();
        } else {
          this.get('_vendorCompanies').clear();
          this.get('_filteredMenus').clear();
          this.get('_filteredLocations').clear();
          this.get('_filteredVendorCompanies').clear();
          this.get('_vendorManagementCompanies').clear();
          this.get('_locations').clear();
          this.set('producerOwner', null);
        }
      }
    }
  });
});