define("fcs-dashboard/pods/company-role/model", ["exports", "ember", "ember-data/attr", "model-fragments/fragment", "ember-cp-validations"], function (exports, _ember, _emberDataAttr, _modelFragmentsFragment, _emberCpValidations) {
  var computed = _ember["default"].computed;

  var Validations = (0, _emberCpValidations.buildValidations)({
    roleId: (0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'locationGroups.details.companies.role.label' }),
    companyId: (0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'locationGroups.details.companies.company.label' })
  });

  exports["default"] = _modelFragmentsFragment["default"].extend(Validations, {
    roleId: (0, _emberDataAttr["default"])('string'),
    companyId: (0, _emberDataAttr["default"])('string'),
    reportsTo: (0, _emberDataAttr["default"])('string'),
    locations: (0, _emberDataAttr["default"])({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    locationObjects: computed(function () {
      return [];
    })
  });
});