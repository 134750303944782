define('fcs-dashboard/helpers/url-encode', ['exports', 'ember'], function (exports, _ember) {
  exports.urlEncode = urlEncode;

  function urlEncode(params) {
    if (Array.isArray(params)) {

      var paramString = '';
      var i = undefined;
      for (i = 0; i < params.length; i++) {
        paramString += encodeURI(params[i]);
        if (params.length > 1 && i < params.length - 1) {
          paramString += ',';
        }
      }

      return paramString;
    }

    return encodeURI(params[0]);
  }

  exports['default'] = _ember['default'].Helper.helper(urlEncode);
});