define("fcs-dashboard/pods/components/product-catalog/meta-product-catalog/product-catalog-selection/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/table-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsTableComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var A = _ember["default"].A;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], _fcsDashboardMixinsTableComponent["default"], {
    selectedModels: A(),
    columns: computed(function () {
      return [{
        template: "meta-product-catalog/actions/row-select-checkbox",
        useFilter: false,
        mayBeHidden: false,
        templateForSortCell: "meta-product-catalog/actions/row-select-all-checkbox"
      }, {
        propertyName: 'name',
        title: this.get('intl').t('productCatalog.list.product'),
        template: "meta-product-catalog/name-cell"
      }, {
        propertyName: 'suggestedPrice',
        title: this.get('intl').t('productCatalog.list.suggestedPrice'),
        template: "meta-product-catalog/suggested-price-cell",
        className: 'editRow'
      }, {
        propertyName: 'attributesNames',
        title: this.get('intl').t('attributeSet.list.attributes')
      }];
    }),

    productCatalogsColumns: computed(function () {
      return [{
        propertyName: 'name',
        title: this.get('intl').t('productCatalog.list.product'),
        template: "meta-product-catalog/name-cell"
      }, {
        propertyName: 'suggestedPrice',
        title: this.get('intl').t('productCatalog.list.suggestedPrice'),
        template: "meta-product-catalog/suggested-price-cell",
        className: 'editRow'
      }, {
        propertyName: 'attributesNames',
        title: this.get('intl').t('attributeSet.list.attributes')
      }];
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('selectedModels', null);
    },
    actions: {
      closeMetaProductCatalogModal: function closeMetaProductCatalogModal() {
        this.sendAction('closeMetaProductCatalogModal');
      },
      acceptMetaProductCatalogModal: function acceptMetaProductCatalogModal() {
        var _this = this;

        if (this.get('productCatalogs.length') > 0 || this.get('selectedModels.length') > 0) {
          (function () {
            var isDuplicatedName = false;
            var productCatalogName = "";

            // Create new list with all existing and selected product catalogs
            var allTemplateProductCatalogs = A();
            allTemplateProductCatalogs.addObjects(_this.get('productCatalogs'));
            allTemplateProductCatalogs.addObjects(_this.get('selectedModels'));

            // Verify that there are no duplicates
            allTemplateProductCatalogs.forEach(function (productCatalog) {
              if (allTemplateProductCatalogs.filterBy('name', productCatalog.get('name')).length > 1) {
                productCatalogName = productCatalog.get('name');
                isDuplicatedName = true;
              }
            });

            if (isDuplicatedName) {
              _this.get('notify').alert(_this.get('intl').t('metaProductCatalog.modal.errors.duplicatedName', { name: productCatalogName }), { closeAfter: 5000 });
            } else {
              _this.sendAction('acceptMetaProductCatalogModal', _this.get('selectedModels'));
            }
          })();
        } else {
          this.get('notify').alert(this.get('intl').t('metaProductCatalog.modal.errors.noGeneratedProductCatalogsSelected'), { closeAfter: 5000 });
        }
      }
    }
  });
});