define("fcs-dashboard/pods/daily-close-out-report/clerks/model", ["exports", "ember-data/attr", "model-fragments/fragment"], function (exports, _emberDataAttr, _modelFragmentsFragment) {
  exports["default"] = _modelFragmentsFragment["default"].extend({
    name: (0, _emberDataAttr["default"])('string'),
    cashDrawer: (0, _emberDataAttr["default"])('number'),
    grossCashSales: (0, _emberDataAttr["default"])('number'),
    grossCreditSales: (0, _emberDataAttr["default"])('number'),
    grossRfidSales: (0, _emberDataAttr["default"])('number'),
    grossSales: (0, _emberDataAttr["default"])('number'),
    coupon: (0, _emberDataAttr["default"])('number'),
    spills: (0, _emberDataAttr["default"])('number'),
    comp: (0, _emberDataAttr["default"])('number'),
    discounts: (0, _emberDataAttr["default"])('number'),
    serviceFees: (0, _emberDataAttr["default"])('number'),
    taxes: (0, _emberDataAttr["default"])('number'),
    netSales: (0, _emberDataAttr["default"])('number'),
    creditCardTips: (0, _emberDataAttr["default"])('number'),
    rfidTips: (0, _emberDataAttr["default"])('number'),
    totalDigitalTips: (0, _emberDataAttr["default"])('number'),
    voids: (0, _emberDataAttr["default"])('number'),
    refunds: (0, _emberDataAttr["default"])('number')
  });
});