define('fcs-dashboard/mirage/fixtures/pages', ['exports'], function (exports) {
  /**
   * Created by alberto on 03/02/16.
   */
  exports['default'] = [{
    id: 1,
    name: 'Page 1',
    products: [1, 2, 3]
  }, {
    id: 2,
    name: 'Page 2',
    products: [4, 5]
  }, {
    id: 3,
    name: 'Page 3',
    products: [6, 7]
  }, {
    id: 4,
    name: 'Page 4',
    products: [8, 9]
  }, {
    id: 5,
    name: 'Page 5',
    products: [10]
  }, {
    id: 6,
    name: 'Page 6',
    products: [1]
  }, {
    id: 7,
    name: 'Page 7',
    products: [2, 11]
  }, {
    id: 8,
    name: 'Page 8',
    products: [12, 13]
  }];
});