define("fcs-dashboard/pods/product-type/model", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'productType.details.name.label' }),
    companyId: (0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'productType.details.company.label' })
  });

  exports["default"] = _emberDataModel["default"].extend(Validations, {
    name: (0, _emberDataAttr["default"])('string'),
    companyId: (0, _emberDataAttr["default"])('number'),
    companyName: (0, _emberDataAttr["default"])('string'),
    productsCount: (0, _emberDataAttr["default"])('number'),
    printerType: (0, _emberDataRelationships.belongsTo)('printerType'),
    buttonColor: (0, _emberDataAttr["default"])('string', { defaultValue: '#f8f8f8' })
  });
});