define("fcs-dashboard/pods/components/daily-close-out/report-result/product-types/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/table-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsTableComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var isEmpty = _ember["default"].isEmpty;
  exports["default"] = Component.extend(_fcsDashboardMixinsTableComponent["default"], _fcsDashboardMixinsBaseComponent["default"], {
    data: [],
    _showData: computed('data', function () {
      return this.get('data') !== null && !isEmpty(this.get('data'));
    }),
    _columns: computed(function () {
      return [{
        title: this.get('intl').t('report.result.dailyCloseOutReport.productTypes.name'),
        className: 'non-cash-category-cell',
        propertyName: 'name',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.dailyCloseOutReport.productTypes.netSales'),
        className: 'non-cash-quantity-cell',
        template: 'dailyCloseOutReport/amountCell',
        propertyName: 'netSales',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.dailyCloseOutReport.productTypes.tax'),
        className: 'non-cash-quantity-cell',
        template: 'dailyCloseOutReport/amountCell',
        propertyName: 'tax',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.dailyCloseOutReport.productTypes.voids'),
        className: 'non-cash-total-cell',
        template: 'dailyCloseOutReport/amountCell',
        propertyName: 'voids',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.dailyCloseOutReport.productTypes.comps'),
        className: 'non-cash-total-cell',
        template: 'dailyCloseOutReport/amountCell',
        propertyName: 'comps',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.dailyCloseOutReport.productTypes.spills'),
        className: 'non-cash-total-cell',
        template: 'dailyCloseOutReport/amountCell',
        propertyName: 'spills',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.dailyCloseOutReport.productTypes.compsDueToDecline'),
        className: 'non-cash-total-cell',
        template: 'dailyCloseOutReport/amountCell',
        propertyName: 'declined',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.dailyCloseOutReport.productTypes.refund'),
        className: 'non-cash-total-cell',
        template: 'dailyCloseOutReport/amountCell',
        propertyName: 'refund',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.dailyCloseOutReport.productTypes.discount'),
        className: 'non-cash-total-cell',
        template: 'dailyCloseOutReport/amountCell',
        propertyName: 'discount',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.dailyCloseOutReport.productTypes.coupon'),
        className: 'non-cash-total-cell',
        template: 'dailyCloseOutReport/amountCell',
        propertyName: 'coupon',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.dailyCloseOutReport.productTypes.grossSales'),
        className: 'non-cash-total-cell',
        template: 'dailyCloseOutReport/amountCell',
        propertyName: 'grossSales',
        disableSorting: true
      }];
    })
  });
});