define("fcs-dashboard/pods/components/location-group/promotion-modal/item-token-promotion-products/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/promotions-table-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsPromotionsTableComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var A = _ember["default"].A;
  var run = _ember["default"].run;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], _fcsDashboardMixinsPromotionsTableComponent["default"], {
    _isLoading: true,
    disableOk: true,
    displayProductsList: true,
    productCatalogsLoaded: false,
    metaProductCatalogsLoaded: false,
    data: [],
    displayClearSearch: false,
    inputProductSearch: '',
    columns: computed('filterClass', function () {
      return [{
        template: "meta-product-catalog/actions/row-select-checkbox",
        useFilter: false,
        mayBeHidden: false,
        templateForSortCell: "meta-product-catalog/actions/row-select-all-checkbox"
      }, {
        propertyName: 'name',
        title: this.get('intl').t('productCatalog.list.product')
      }, {
        propertyName: 'companyName',
        title: this.get('intl').t('productCatalog.list.company')
      }, {
        propertyName: 'productType.name',
        title: this.get('intl').t('productCatalog.list.productType')
      }];
    }),
    loadReady: computed('productCatalogsLoaded', 'metaProductCatalogsLoaded', function () {
      return this.get('productCatalogsLoaded') && this.get('metaProductCatalogsLoaded');
    }),
    selectedModels: computed('products', 'products.length', function () {
      var _this = this;

      var catalog = A();
      this.get('promotion.productCatalogs').forEach(function (product) {
        var productCatalog = _this.get('data').filterBy('id', product);
        catalog.addObjects(productCatalog);
      });
      return catalog;
    }),
    showModalProducts: false,
    newProductError: '',
    actions: {
      clearAllSelected: function clearAllSelected() {
        var _this2 = this;

        this.set('displayProductsList', false);
        run.next(function () {
          _this2.get('selectedModels').clear();
          _this2.set('displayProductsList', true);
        });
      },
      closeModalProducts: function closeModalProducts() {
        this.send('cleanMessages');
        this.sendAction('closeModalProducts');
      },
      addProducts: function addProducts() {
        this.send('cleanMessages');

        //this._updateSelectedProductsByMetaProducts();
        var productCatalogs = [];
        this.get('selectedModels').forEach(function (item) {

          productCatalogs.addObject(item.id);
        });
        this.sendAction('addProducts', productCatalogs);
      },
      selectItem: function selectItem(isNotNewItem) {
        this.set('disableOk', isNotNewItem);
      },
      clearSearchInput: function clearSearchInput() {
        this.set('inputProductSearch', '');
        this.send('filterProductsList');
      }
    }
  });
});