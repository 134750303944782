define('fcs-dashboard/mirage/fixtures/processorTypes', ['exports'], function (exports) {
  /**
   * Created by alberto on 08/06/16.
   */
  exports['default'] = [{
    id: 0,
    name: 'Cc processor'
  }, {
    id: 1,
    name: 'Rfid processor'
  }];
});