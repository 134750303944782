define('fcs-dashboard/mirage/fixtures/productTypes', ['exports'], function (exports) {
  /**
   * Created by alberto on 03/02/16.
   */
  exports['default'] = [{
    id: 1,
    name: 'product type 1'
  }, {
    id: 2,
    name: 'product type 2'
  }, {
    id: 3,
    name: 'product type 3'
  }];
});