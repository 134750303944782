define("fcs-dashboard/pods/components/menu/menu-locations/locations/component", ["exports", "ember", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var Logger = _ember["default"].Logger;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], {
    selectedLocations: [],
    _internalSelectedLocations: [],
    _locations: [],
    _sortCriteria: ['name'],
    _sortedLocations: computed.sort('_locations', '_sortCriteria'),
    _showLocationsContent: true,
    _getLocations: function _getLocations(locations) {
      var _this = this;

      var selectedLocations = [];
      locations.forEach(function (location) {
        _this.get('selectedLocations').forEach(function (selected) {
          if (selected.get('id') === location.get('id')) {
            selectedLocations.addObject(location);
          }
        });
      });
      this.set('selectedLocations', selectedLocations);
    },
    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);
      Logger.debug('didReceiveAttrs hook method');
      this.set('_showLocationsContent', false);
      if (this.get('_locations').then !== undefined) {
        this.get('_locations').then(function (locations) {
          _this2._getLocations(locations);
          _this2.set('_showLocationsContent', true);
        });
      } else {
        this.set('_showLocationsContent', true);
      }
    }
  });
});