define('fcs-dashboard/mirage/scenarios/default', ['exports'], function (exports) {
  exports['default'] = function (server) {
    server.loadFixtures();
    // Seed your development database using your factories. This
    // data will not be loaded in your tests.

    // server.createList('contact', 10);
    server.createList('taxRateType', 4);
    server.createList('searchOrders', 1000);
    server.createList('creditCardType', 6);
    server.createList('orderStatus', 10);
    for (var i = 0; i < 100; i++) {
      var items = server.createList('item', 10);
      var itemIds = items.map(function (obj) {
        return obj.id;
      });
      var payment = server.create('payment');
      server.create('orders', { items: itemIds, payment: payment.id });
    }

    var nonCashPaymentReport = server.createList('nonCashPaymentReport', 10);
    var dailySummaryProductSales = server.createList('dailySummaryProductSales', 10);

    server.createList('financialReportFilter', 3);
    server.createList('financialOverviewReport', 100);

    server.createList('salesReportByProductFilter', 5);
    server.createList('simpleReportByProduct', 100);

    //Report Daily Close Out
    server.createList('dailyCloseOutFilter', 3);
    var reportClerkByLocation = server.createList('dailyCloseOutClerksByLocation', 10, { clerks: server.createList('dailyCloseOutClerk', 10) });
    var reportLocations = server.createList('dailyCloseOutLocation', 10);
    var reportProductTypes = server.createList('dailyCloseOutProductTypes', 10);
    var clerks = server.createList('dailyCloseOutClerk', 10);
    server.create('clerkReport', { clerks: clerks, productTypes: reportProductTypes, locations: reportLocations, clerksByLocations: reportClerkByLocation });

    server.create('salesReport', {
      nonCashPaymentReport: nonCashPaymentReport,
      dailySummaryProductSales: dailySummaryProductSales
    });

    server.createList('productCatalog', 100);
  };
});