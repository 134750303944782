define('fcs-dashboard/mirage/factories/simpleReportByProduct', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  _emberCliMirage.faker.locale = 'en_US';

  exports['default'] = _emberCliMirage['default'].Factory.extend({
    product: _emberCliMirage.faker.hacker.noun,
    productType: _emberCliMirage.faker.hacker.noun,
    sales: _emberCliMirage.faker.commerce.price,
    percentageTotal: _emberCliMirage.faker.commerce.price,
    itemsSold: _emberCliMirage.faker.commerce.number,
    avgPrice: _emberCliMirage.faker.commerce.price
  });
});
/**
 * Created by ernesto on 17/01/17.
 */