define("fcs-dashboard/pods/components/company/company-list/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/table-component", "fcs-dashboard/mixins/company-mixin"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsTableComponent, _fcsDashboardMixinsCompanyMixin) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var isEqual = _ember["default"].isEqual;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], _fcsDashboardMixinsTableComponent["default"], _fcsDashboardMixinsCompanyMixin["default"], {
    data: [],
    _columns: computed(function () {
      var availableStatuses = this.get('statuses');
      return [{
        title: '',
        template: "company/actions/edit-action",
        className: 'editRow'
      }, {
        title: '',
        template: "company/actions/lock-action",
        className: 'editRow'
      }, {
        propertyName: 'id',
        filteredBy: 'id',
        title: this.get('intl').t('company.list.table.id'),
        className: 'idEditRow'
      }, {
        propertyName: 'name',
        filteredBy: 'name',
        title: this.get('intl').t('company.list.table.name')
      }, {
        propertyName: 'translatedStatus',
        filteredBy: 'translatedStatus',
        title: this.get('intl').t('company.list.table.status'),
        filterWithSelect: true,
        predefinedFilterOptions: availableStatuses,
        className: 'stateRow',
        filterFunction: function filterFunction(cell, neededStr, obj) {
          return isEqual(obj.get('status'), neededStr);
        }
      }, {
        title: '',
        template: "company/actions/change-password-action",
        className: 'editRow'
      }];
    }),
    _company: null,
    showPasswordModal: false,
    _filteredCompanies: [],
    init: function init() {
      this._super.apply(this, arguments);
      this.set('_filteredCompanies', this.get('data'));
    },
    actions: {
      showChangePasswordModal: function showChangePasswordModal(company) {
        this.set('_company', company);
        this.set('_company.password', null);
        this.set('showPasswordModal', true);
      },
      lockCompany: function lockCompany(company) {
        if (isEqual(this.get('loggedAccount.lockedCompany.id'), company.get('id'))) {
          this.set('loggedAccount.lockedCompany', undefined);
        } else {
          this.set('loggedAccount.lockedCompany', company);
        }
      },
      changePassword: function changePassword(model, password) {
        this.set('showPasswordModal', false);
        model.set('decryptedPassword', password);
        model.set('password', password);
        this.sendAction('save', model);
      },
      closeModal: function closeModal() {
        this.set('showPasswordModal', false);
      }
    }
  });
});