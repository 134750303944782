define("fcs-dashboard/pods/user/edit-account/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin", "fcs-dashboard/mixins/common-mixin", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _fcsDashboardMixinsCommonMixin, _fcsDashboardMixinsBaseComponent) {
  var Route = _ember["default"].Route;
  var isEqual = _ember["default"].isEqual;
  var isEmpty = _ember["default"].isEmpty;
  exports["default"] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], _fcsDashboardMixinsCommonMixin["default"], _fcsDashboardMixinsBaseComponent["default"], {
    model: function model(params) {
      if (this.get('loggedAccount.id') === params.user_id) {
        return this.store.findRecord('user', params.user_id);
      } else {
        var errorMessage = this.get('notify').alert("Invalid action", { closeAfter: 5000 });
        this.get('errorMessages').addObject(errorMessage);
        this.transitionTo('/');
      }
    },
    renderTemplate: function renderTemplate() {
      this.render('user/editAccount', { into: 'application' });
    },
    _validateUser: function _validateUser(user, passwordConfirmation) {
      var _this = this;

      var isValid = true;
      if (user.get('validations.isValid')) {
        if (!isEmpty(user.get('password'))) {
          if (!isEqual(user.get('password'), passwordConfirmation)) {
            isValid = false;
            var errorMessage = this.get('notify').alert(this.get('intl').t('user.details.errors.passwordNotMatch'), { closeAfter: 5000 });
            this.get('errorMessages').addObject(errorMessage);
          }
        }
      } else {
        isValid = false;
        user.get('validations.errors').forEach(function (error) {
          var errorMessage = _this.get('notify').alert(error.get('message'), { closeAfter: 5000 });
          _this.get('errorMessages').addObject(errorMessage);
        });
      }

      return isValid;
    },
    actions: {
      cancel: function cancel(user) {
        this.send('cleanMessages');
        user.rollbackAttributes();
        this.transitionTo('/');
      },
      save: function save(user, passwordConfirmation) {
        var _this2 = this;

        this.send('cleanMessages');
        if (this._validateUser(user, passwordConfirmation)) {
          user.save().then(function () {
            _this2.get('notify').success(_this2.get('intl').t('user.details.errors.successSave'), { closeAfter: 5000 });
            _this2.send('cleanMessages');
            _this2.transitionTo('/');
          })["catch"](function (error) {
            var message = 'user.details.errors.savingError';
            if (error !== undefined) {
              message = "user.details.errors." + error.errors.get(0).detail;
            }
            var errorMessage = _this2.get('notify').alert(_this2.get('intl').t(message, { name: _this2.get('user.username') }), { closeAfter: 5000 });
            _this2.get('errorMessages').addObject(errorMessage);
          });
        }
      }
    }
  });
});