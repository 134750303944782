define("fcs-dashboard/pods/components/saved-network/saved-network-details/component", ["exports", "ember", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var Component = _ember["default"].Component;
  var isEmpty = _ember["default"].isEmpty;
  var isBlank = _ember["default"].isBlank;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], {
    savedNetwork: undefined,
    actions: {
      saveAction: function saveAction() {
        if (!isEmpty(this.get('savedNetwork.ssid')) || !isEmpty(this.get('savedNetwork.password')) || !isBlank(this.get('savedNetwork.ssid')) || !isBlank(this.get('savedNetwork.password'))) {
          this.sendAction('saveAction', this.get('savedNetwork'));
        } else {
          this.get('notify').alert(this.get('intl').t('savedNetwork.list.errors.blankValues'), { closeAfter: 3000 });
        }
      },
      cancelAction: function cancelAction() {
        this.sendAction('cancelAction', this.get('savedNetwork'));
      }
    }
  });
});