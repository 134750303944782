define("fcs-dashboard/pods/components/menu/menu-wizard/products-table/product-group-row/component", ["exports", "ember", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var isEqual = _ember["default"].isEqual;
  var observer = _ember["default"].observer;
  var isEmpty = _ember["default"].isEmpty;
  var scheduleOnce = _ember["default"].run.scheduleOnce;
  var Logger = _ember["default"].Logger;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], {
    palette: [["#f8f8f8"], ["#e06666", "#f6b26b", "#ffd966", "#93c47d", "#76a5af", "#6fa8dc", "#8e7cc3", "#c27ba0"]],
    tagName: '',
    toppingSortCriteria: ['name'],
    toppings: computed(function () {
      return this.get('store').findAll('topping');
    }),
    sortedToppings: computed.sort('toppings', 'toppingSortCriteria'),
    headersToppings: ['menu.builder.steps.stepSix.productTable.toppingModal.headers.extra', 'menu.builder.steps.stepSix.productTable.toppingModal.headers.price'],
    taxRateTypeSortCriteria: ['name'],
    taxRateTypes: [],
    sortedTaxRateTypes: computed.sort('taxRateTypes', 'taxRateTypeSortCriteria'),
    selectedToppings: computed.alias('product.toppings'),
    showModalToppings: false,
    newTopping: null,
    newToppingError: '',
    isProductTaxRate: computed('product.taxRateType', function () {
      return isEqual(this.get('product.taxRateType.id'), this.get('PRODUCT_TAX_RATE'));
    }),
    menuChangedObserver: observer('product.price', 'product.position', 'product.taxRate', 'product.productType', 'product.taxRateType', 'taxRate', function () {
      if (this.get('product.hasDirtyAttributes')) {
        this.set('menu.changed', true);
      }
    }),
    inputProductPrice: computed.alias('product.price'),
    inputTaxRate: computed.alias('product.taxRate'),
    taxRate: computed('product.taxRateType', 'product.productType', function () {
      var _this = this;

      var taxRateType = this.get('product.taxRateType.id');
      Logger.debug("tax rate type changed: " + this.get('product.taxRateType.type'));
      if (isEqual(taxRateType, this.get('MENU_TAX_RATE'))) {
        return this.get('menu.menuTaxRate');
      } else if (isEqual(taxRateType, this.get('PRODUCT_TYPE_TAX_RATE'))) {
        var taxRate = 0;
        this.get('menu.menuProductTypes').forEach(function (menuProductType) {
          if (isEqual(menuProductType.get('productType'), _this.get('product.productCatalog.productType.id'))) {
            taxRate = menuProductType.get('taxRate');
          }
        });
        return taxRate;
      }
    }),
    didRender: function didRender() {
      this._super.apply(this, arguments);
      scheduleOnce('afterRender', this, 'cleanAttributes');
    },
    cleanAttributes: function cleanAttributes() {
      this._checkTaxRateType();
    },
    _checkTaxRateType: function _checkTaxRateType() {
      var _this2 = this;

      //disabled product type tax rate if any product type was selected as available
      if (this.get('sortedTaxRateTypes.length') > 0) {
        this.get('sortedTaxRateTypes').forEach(function (item) {
          if (isEqual(item.get('id'), _this2.get('PRODUCT_TYPE_TAX_RATE')) && _this2.get('productTypes.length') === 0) {
            if (isEqual(_this2.get('product.taxRateType.id'), _this2.get('PRODUCT_TYPE_TAX_RATE'))) {
              _this2.set('product.taxRateType', undefined);
            }
            item.set('disabled', true);
          } else {
            item.set('disabled', false);
          }
        });
      }
    },
    actions: {
      selectTaxRateTypeAction: function selectTaxRateTypeAction(selectedItem) {
        this.set('menu.changed', true);
        this.set('product.changed', true);
        this.set('product.taxRate', 0);
        this.set('product.taxRateType', selectedItem);
      },
      openModalToppings: function openModalToppings() {
        this.set('showModalToppings', true);
        this.set('newTopping', this.get('store').createRecord('topping', {
          price: 0
        }));
      },
      cancelModalToppings: function cancelModalToppings() {
        this.set('showModalToppings', false);
      },
      addNewToppings: function addNewToppings() {
        var _this3 = this;

        var valid = true;
        if (isEmpty(this.get('newTopping.name'))) {
          valid = false;
          this.set('newToppingError', 'menu.builder.steps.stepSix.productTable.toppingModal.errors.nameEmpty');
        }

        if (valid) {
          this.set('newToppingError', '');
          this.get('newTopping').save().then(function () {
            _this3.set('newTopping', _this3.get('store').createRecord('topping', {
              price: 0
            }));
            _this3.set('productTypes', _this3.get('store').findAll('productCatalog'));
          })["catch"](function (error) {
            _this3.set('newToppingError', error);
          });
        }
      },
      addToppings: function addToppings() {
        this.send('cancelModalToppings');
      },
      deleteProductGroup: function deleteProductGroup(selectedItem) {
        this.sendAction('deleteGroup', selectedItem);
      },
      editProductGroup: function editProductGroup(selectedItem) {
        this.sendAction('openModalProductGroups', selectedItem);
      },
      positionSelected: function positionSelected(position) {
        this.sendAction('changePositionAction', this.get('productGroup'), position);
      }
    }
  });
});