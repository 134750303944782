define('fcs-dashboard/mirage/fixtures/receiptTypes', ['exports'], function (exports) {
  /**
   * Created by alberto on 03/02/16.
   */
  exports['default'] = [{
    id: 0,
    name: 'No Receipt'
  }, {
    id: 1,
    name: 'Email'
  }, {
    id: 2,
    name: 'Print'
  }];
});