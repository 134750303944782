define("fcs-dashboard/pods/components/item-token-usage-report/report-result/component", ["exports", "ember", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], {
    data: null,
    _columns: computed(function () {
      return [{
        title: "Menu",
        className: 'centerContent',
        propertyName: 'menuName',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.itemTokenUsageReport.headers.itemTokenName'),
        className: 'centerContent',
        propertyName: 'itemTokenName',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.itemTokenUsageReport.headers.value'),
        className: 'amount-cell',
        template: 'itemTokenUsageReport/amountCell',
        propertyName: 'value',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.itemTokenUsageReport.headers.quantity'),
        className: 'centerContent',
        propertyName: 'quantity',
        disableSorting: true
      }];
    })
  });
});