define('fcs-dashboard/mirage/factories/product', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  _emberCliMirage.faker.locale = 'en_US';

  exports['default'] = _emberCliMirage['default'].Factory.extend({
    name: _emberCliMirage.faker.hacker.noun,
    version: 1,
    productCatalog: 1,
    toppings: [],
    price: _emberCliMirage.faker.commerce.price,
    taxRate: _emberCliMirage.faker.random.number(100),
    allowModifications: false,
    productType: 1,
    taxRateType: 0
  });
});
/**
 * Created by alberto on 03/02/16.
 */