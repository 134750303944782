define("fcs-dashboard/pods/components/location-mng/embedded-list/component", ["exports", "ember", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], {
    locationTypes: [],
    __showModal: false,
    _sortCriteria: ['name'],
    _sortedLocations: computed.sort('company.locations', '_sortCriteria'),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var temp = this.get('store').findAll('locationType');
      if (temp.then !== undefined) {
        temp.then(function (content) {
          _this.set('locationTypes', content);
        });
      }
    },
    actions: {
      editModal: function editModal(model) {
        this.set('model', model);
        this.set('_showModal', true);
      },
      openModal: function openModal() {
        var company = undefined;
        if (this.get('isBRUser')) {
          if (this.get('lockedCompany') !== undefined) {
            company = this.get('lockedCompany');
          } else {
            company = this.get('company');
          }
        } else {
          company = this.get('loggedAccount.company');
        }

        this.set('model', this.get('store').createRecord('location', { company: company, companyId: company.id, companyName: company.get('name') }));
        this.set('_showModal', true);
      },
      closeModal: function closeModal() {
        var _this2 = this;

        this.get('store').query('location', { companyId: this.get('company.id') }).then(function (locations) {
          _this2.set('_locations', locations);
          _this2.set('_showModal', false);
        });
        this.sendAction('refreshAction');
      }
    }
  });
});