define("fcs-dashboard/pods/components/menu/menu-wizard/products-table/product-row/combo-product-modifiers/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/table-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsTableComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], _fcsDashboardMixinsTableComponent["default"], {
    newComboModifiers: [],
    selectionProduct: null,
    showModalModifiers: false,
    columns: computed(function () {
      return [{
        title: " ",
        propertyName: 'productCatalog.name',
        className: 'editRow'
      }, {
        title: " ",
        template: "product/modifiers-action",
        className: 'editRow'
      }];
    }),
    title: computed(function () {
      return this.get('intl').t('menu.builder.steps.stepSix.productTable.modal.modifiers.title') + " " + this.get('product.productCatalog.name');
    }),
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('newComboModifiers').clear();
    },
    actions: {
      closeModalComboModifiers: function closeModalComboModifiers() {
        var _this = this;

        this.get('product.comboSelections').forEach(function (comboSelection) {
          comboSelection.get('products').forEach(function (selectionProduct) {
            selectionProduct.get('modifiers').removeObjects(_this.get('newComboModifiers'));
          });
        });
        this.sendAction('closeModalComboModifiers');
      },
      saveComboModifiers: function saveComboModifiers() {
        this.sendAction('closeModalComboModifiers');
      },
      openModifiers: function openModifiers(record) {
        this.set('selectionProduct', record);
        this.set('showModalModifiers', true);
      },
      closeModalModifiers: function closeModalModifiers() {
        this.set('showModalModifiers', false);
      },
      addModifiers: function addModifiers() {
        this.set('showModalModifiers', false);
      },
      saveModifiers: function saveModifiers() {
        this.set('showModalModifiers', false);
      }
    }
  });
});