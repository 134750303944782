define("fcs-dashboard/pods/components/user-mng/import-user-list/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/table-component", "ember-simple-auth/mixins/authenticated-route-mixin", "fcs-dashboard/config/environment"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsTableComponent, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _fcsDashboardConfigEnvironment) {
  var Component = _ember["default"].Component;
  var Logger = _ember["default"].Logger;
  exports["default"] = Component.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], _fcsDashboardMixinsBaseComponent["default"], _fcsDashboardMixinsTableComponent["default"], {
    disableImportUsersButton: false,
    importedObjects: 0,
    errors: 0,
    newUsers: 0,
    updatedUsers: 0,
    csvFile: null,
    usersOption: false,
    showResponse: false,
    actions: {
      importUsersSuccess: function importUsersSuccess() {
        this.sendAction('importUsersSuccess');
      },
      uploadFile: function uploadFile(fileList) {
        var re = new RegExp('text/csv');
        if (fileList[0].size <= 10000000) {
          if (re.test(fileList[0].type)) {
            var that = this;
            that.set('csvFile', fileList[0]);
          } else {
            Logger.debug('not a csv.');
            var errorMessage = this.get('notify').alert(this.get('intl').t('productCatalog.modal.errors.invalidImageFormat'), { closeAfter: 5000 });
            this.get('errorMessages').addObject(errorMessage);
          }
        } else {
          Logger.debug('image size max is 10mb.');
          var errorMessage = this.get('notify').alert(this.get('intl').t('productCatalog.modal.errors.invalidImageSize'), { closeAfter: 5000 });
          this.get('errorMessages').addObject(errorMessage);
        }
      },
      removeFile: function removeFile() {
        this.set('csvFile', null);
      },
      importUsers: function importUsers() {
        var _this = this;

        this.set('disableImportUsersButton', true);
        if (this.get('csvFile') !== null && this.get('csvFile') !== undefined) {
          (function () {
            var endpoint = "https://usermanagementapi-" + _fcsDashboardConfigEnvironment["default"].APP.stage + ".bestringpos.com/bulkCreation/usersOption/" + _this.get('usersOption');
            var apiKey = _fcsDashboardConfigEnvironment["default"].APP.userApiKey;
            var options = {
              contentType: false,
              processData: false,
              url: endpoint,
              type: 'POST',
              data: _this.get('csvFile')
            };
            options.beforeSend = function (xhr) {
              xhr.setRequestHeader('x-api-key', apiKey);
            };
            options.success = function (response) {
              var totalObjects = response.newUsers.length + response.updatedUsers.length;
              _this.set('importedObjects', totalObjects.toString());
              _this.set('newUsers', response.newUsers.length.toString());
              _this.set('updatedUsers', response.updatedUsers.length.toString());
              _this.set('errors', response.errorUsers.length.toString());
              _this.set('disableImportUsersButton', false);
              _this.set('showResponse', true);
            };
            options.error = function (e) {
              Logger.debug('got error response: ' + e);
              var errorMessage = undefined;
              // Catch error and return proper error message
              errorMessage = _this.get('notify').alert(_this.get('intl').t('user.modal.errors.errorUploading'), { closeAfter: 5000 });
              _this.get('errorMessages').addObject(errorMessage);
              _this.set('disableImportUsersButton', false);
            };
            _ember["default"].$.ajax(options);
          })();
        } else {
          this.get('notify').alert("Please select a file", { closeAfter: 5000 });
          this.set('disableImportUsersButton', false);
        }
      }
    }
  });
});