define("fcs-dashboard/pods/components/menu/menu-wizard/pages-table/component", ["exports", "ember", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], {
    tabPageSelected: computed('selectedPage', function () {
      return this.get('selectedPage.pageId');
    }),
    actions: {
      changePositionAction: function changePositionAction(product, position) {
        this.sendAction('changePositionAction', product, position);
      },
      deleteProductFromPage: function deleteProductFromPage(itemId) {
        this.get('selectedPage.deletedProducts').addObject(itemId);
      },
      deleteProductGroupFromPage: function deleteProductGroupFromPage(itemId) {
        this.get('selectedPage.deletedProductGroups').addObject(itemId);
      }
    }
  });
});