define("fcs-dashboard/pods/components/location-group/promotion-modal/promotion-image-row/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/table-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsTableComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var htmlSafe = _ember["default"].String.htmlSafe;
  var run = _ember["default"].run;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], _fcsDashboardMixinsTableComponent["default"], {
    imagePlacements: [],
    imageName: computed('promotionImage.image', 'promotionImage.imageURL', function () {
      return this.get('promotionImage.image') ? 'image' : null;
    }),
    imagePreview: computed('promotionImage.image', 'promotionImage.imageURL', function () {
      var url = "";
      if (this.get('promotionImage.image')) {
        url = this.get('promotionImage.image');
      } else {
        url = this.get('promotionImage.imageURL');
      }
      return htmlSafe("" + url);
    }),
    _selectedPlacement: undefined,
    /**
     * Ember's hook method
     */
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      //initialize imagePlacements
      if (this.get('imagePlacements').then !== undefined) {
        // if a promise set the content when it resolves
        this.get('imagePlacements').then(function (content) {
          run(function () {
            _this.set('imagePlacements', content);
          });
        });
      }
    },
    actions: {
      remove: function remove() {
        this.sendAction('remove', this.get('promotionImage'));
      },
      uploadImage: function uploadImage(fileList) {
        var _this2 = this;

        var re = new RegExp('image/(png|jpeg)');
        if (fileList[0].size <= 10000000) {
          if (re.test(fileList[0].type)) {
            (function () {
              var that = _this2;
              _this2.set('imageName', fileList[0].name);
              var reader = new FileReader();

              reader.onloadend = function () {
                that.set('promotionImage.image', reader.result);
              };

              reader.readAsDataURL(fileList[0]);
            })();
          } else {
            Logger.debug('not an image.');
            var errorMessage = this.get('notify').alert(this.get('intl').t('productCatalog.modal.errors.invalidImageFormat'), { closeAfter: 5000 });
            this.get('errorMessages').addObject(errorMessage);
          }
        } else {
          Logger.debug('image size max is 10mb.');
          var errorMessage = this.get('notify').alert(this.get('intl').t('productCatalog.modal.errors.invalidImageSize'), { closeAfter: 5000 });
          this.get('errorMessages').addObject(errorMessage);
        }
      },
      changePlacement: function changePlacement(placement) {
        this.set('promotionImage.imagePlacement', placement);
      },
      removeImage: function removeImage() {
        this.set('imageName', null);
        this.set('promotionImage.image', null);
        this.set('promotionImage.imageURL', null);
      }
    }
  });
});