define("fcs-dashboard/pods/modifier/model", ["exports", "ember", "ember-data/attr", "ember-data/model", "ember-data/relationships", "ember-cp-validations"], function (exports, _ember, _emberDataAttr, _emberDataModel, _emberDataRelationships, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    action: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      descriptionKey: 'menu.builder.steps.stepSix.productTable.modal.modifiers.action.label'
    })],
    placement: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      descriptionKey: 'menu.builder.steps.stepSix.productTable.modal.modifiers.placement.label'
    })],
    trait: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      descriptionKey: 'menu.builder.steps.stepSix.productTable.modal.modifiers.trait.label'
    })]
  });

  var computed = _ember["default"].computed;
  exports["default"] = _emberDataModel["default"].extend(Validations, {
    action: (0, _emberDataRelationships.belongsTo)('modifierAction'),
    placement: (0, _emberDataRelationships.belongsTo)('modifierPlacement'),
    trait: (0, _emberDataRelationships.belongsTo)('trait'),
    price: (0, _emberDataAttr["default"])('number'),
    menuId: (0, _emberDataAttr["default"])('number'),
    deleted: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    description: computed('action', 'trait', 'placement', function () {
      if (this.get('action.id') === '0' && this.get('placement.id') === '0') {
        return this.get('trait.name');
      } else if (this.get('action.id') === '0') {
        return this.get('trait.name') + " " + this.get('placement.name');
      } else if (this.get('placement.id') === '0') {
        return this.get('action.name') + " " + this.get('trait.name');
      } else {
        return this.get('action.name') + " " + this.get('trait.name') + " " + this.get('placement.name');
      }
    })
  });
});