define("fcs-dashboard/pods/processor-credential/model", ["exports", "ember", "ember-data/attr", "model-fragments/fragment", "ember-cp-validations"], function (exports, _ember, _emberDataAttr, _modelFragmentsFragment, _emberCpValidations) {
  var computed = _ember["default"].computed;

  var Validations = (0, _emberCpValidations.buildValidations)({
    value: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      descriptionKey: computed(function () {
        return this.get('model.displayName');
      }).volatile()
    })
  });

  exports["default"] = _modelFragmentsFragment["default"].extend(Validations, {
    credentialId: (0, _emberDataAttr["default"])('string'),
    name: (0, _emberDataAttr["default"])('string'),
    displayName: (0, _emberDataAttr["default"])('string'),
    type: (0, _emberDataAttr["default"])('number'),
    value: (0, _emberDataAttr["default"])('string')
  });
});