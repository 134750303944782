define("fcs-dashboard/pods/category/model", ["exports", "ember", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberDataRelationships, _emberCpValidations) {
  var computed = _ember["default"].computed;
  var observer = _ember["default"].observer;

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      descriptionKey: 'menu.builder.steps.stepSix.modals.category.name.label',
      messageKey: 'menu.builder.steps.stepSix.productTable.errors.nameEmpty'
    }), (0, _emberCpValidations.validator)('length', {
      min: 1,
      max: 25,
      descriptionKey: 'menu.builder.steps.stepSix.modals.category.name.label',
      messageKey: 'menu.builder.steps.stepSix.productTable.errors.nameLength'
    })]
  });

  exports["default"] = _emberDataModel["default"].extend(Validations, {
    name: (0, _emberDataAttr["default"])('string'),
    description: (0, _emberDataAttr["default"])('string'),
    pages: (0, _emberDataRelationships.hasMany)('page'),
    deletedPages: (0, _emberDataAttr["default"])({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    menus: (0, _emberDataRelationships.hasMany)('menu'),
    multiplePagesEnabled: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    changed: (0, _emberDataAttr["default"])('boolean'),
    isModified: observer('name', 'description', function () {
      this.set('changed', this.get('hasDirtyAttributes'));
    }),
    categoryId: computed('name', 'id', function () {
      return "category" + this.get('name') + "." + this.get('id') + "." + Date.now();
    }),
    hasPages: computed('pages.[]', function () {
      return this.get('pages.length') > 1;
    })
  });
});