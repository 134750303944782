define("fcs-dashboard/pods/components/forgot-password/component", ["exports", "ember"], function (exports, _ember) {
  var Component = _ember["default"].Component;
  var service = _ember["default"].inject.service;
  var computed = _ember["default"].computed;
  var htmlSafe = _ember["default"].String.htmlSafe;
  var isEmpty = _ember["default"].isEmpty;
  exports["default"] = Component.extend({
    resetCompleted: false,
    token: null,
    session: service(),
    intl: service(),
    errorMessage: "",
    displayErrorClass: 'hideError',
    displayProperty: computed('displayErrorClass', function () {
      return htmlSafe(this.get('displayErrorClass'));
    }),
    actions: {
      resetPassword: function resetPassword() {
        var _this = this;

        this.set('displayErrorClass', 'hideError');
        this.set('errorMessage', '');

        var _getProperties = this.getProperties('username', 'email');

        var username = _getProperties.username;
        var email = _getProperties.email;

        if (!isEmpty(username)) {
          if (!isEmpty(email)) {
            var userInfo = {
              username: this.get('username'),
              email: email
            };

            var path = '/api/v1/passwordResets/forgotPassword';
            var _window$location = window.location;
            var protocol = _window$location.protocol;
            var host = _window$location.host;

            var url = protocol + "//" + host + path;
            var options = {
              data: userInfo,
              url: url,
              type: 'POST'
            };
            options.success = function (result) {
              if (result !== undefined && result !== null) {
                _this.set('resetCompleted', true);
              }
            };
            options.error = function () {
              console.log('error');
            };
            _ember["default"].$.ajax(options);
          } else {
            this.set('displayErrorClass', 'displayError');
            this.set('errorMessage', this.get('intl').t('forgotPassword.errors.blankEmail'));
          }
        } else {
          this.set('displayErrorClass', 'displayError');
          this.set('errorMessage', this.get('intl').t('forgotPassword.errors.blankUsername'));
        }
      }
    }
  });
});