define('fcs-dashboard/pods/sales-report/cash-deposit-report/model', ['exports', 'ember-data/attr', 'model-fragments/fragment'], function (exports, _emberDataAttr, _modelFragmentsFragment) {
  exports['default'] = _modelFragmentsFragment['default'].extend({
    cashDeposit: (0, _emberDataAttr['default'])('number'),
    creditCardPayments: (0, _emberDataAttr['default'])('number'),
    rfidPayments: (0, _emberDataAttr['default'])('number'),
    couponDiscount: (0, _emberDataAttr['default'])('number'),
    voids: (0, _emberDataAttr['default'])('number'),
    comps: (0, _emberDataAttr['default'])('number'),
    spills: (0, _emberDataAttr['default'])('number'),
    refunds: (0, _emberDataAttr['default'])('number'),
    grossSales: (0, _emberDataAttr['default'])('number'),
    employeeDiscount: (0, _emberDataAttr['default'])('number'),
    digitalTips: (0, _emberDataAttr['default'])('number'),
    coinRoundedDelta: (0, _emberDataAttr['default'])('number'),
    cashDiscountedAmount: (0, _emberDataAttr['default'])('number'),
    customFeesAmount: (0, _emberDataAttr['default'])('number')
  });
});