define('fcs-dashboard/pods/components/location-group/delete-custom-fee-modal/component', ['exports', 'ember', 'fcs-dashboard/mixins/base-component'], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend(_fcsDashboardMixinsBaseComponent['default'], {
    actions: {
      cancel: function cancel() {
        this.sendAction('cancel');
      },
      deleteCustomFee: function deleteCustomFee() {
        this.set('showModal', false);
        this.sendAction('deleteCustomFee', this.get('customFee'));
      }
    }
  });
});