define('fcs-dashboard/mirage/fixtures/gateways', ['exports'], function (exports) {
  /**
   * Created by alberto on 08/06/16.
   */
  exports['default'] = [{
    id: 1,
    name: 'gateway 1',
    enabled: true,
    processor: 1,
    gatewayEnvironment: 1,
    processorType: 1,
    credentials: [{
      id: 0,
      name: 'credential 1',
      displayName: 'credential 1',
      type: 0,
      value: ''
    }],
    menuLocations: [{
      menuId: 1,
      menuName: 'test',
      locationId: 1,
      locationName: 'location'
    }]
  }, {
    id: 2,
    name: 'gateway 2',
    enabled: true,
    processorType: 1,
    processor: 2,
    gatewayEnvironment: 1,
    credentials: [{
      id: 0,
      name: 'credential 1',
      displayName: 'credential 1',
      type: 0,
      value: ''
    }],
    menuLocations: [{
      menuId: 1,
      menuName: 'test',
      locationId: 1,
      locationName: 'location'
    }]
  }, {
    id: 3,
    name: 'gateway 3',
    enabled: true,
    processorType: 0,
    processor: 1,
    gatewayEnvironment: 1,
    credentials: [{
      id: 0,
      name: 'credential 1',
      displayName: 'credential 1',
      type: 0,
      value: ''
    }],
    menuLocations: [{
      menuId: 1,
      menuName: 'test',
      locationId: 1,
      locationName: 'location'
    }]
  }, {
    id: 4,
    name: 'cashless gateway 4',
    enabled: true,
    processorType: 1,
    processor: 7,
    gatewayEnvironment: 1,
    credentials: [{
      credentialId: 16,
      name: 'Gateway',
      displayName: 'gateways.details.credentials.fcs.gateway',
      type: 0,
      value: ''
    }]
  }];
});