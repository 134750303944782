define('fcs-dashboard/pods/components/gateway-mng/gateway-submerchant/component', ['exports', 'ember', 'fcs-dashboard/mixins/base-component'], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend(_fcsDashboardMixinsBaseComponent['default'], {
    selectedType: null,
    types: computed(function () {
      return [{ label: 'Credit Card', value: 0 }, { label: 'PayPal', value: 1 }];
    }),
    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('subMerchant.type') !== null || this.get('subMerchant.type') !== undefined) {
        this.set('selectedType', this.get('types').findBy('value', this.get('subMerchant.type')));
      }
    },
    actions: {
      selectSubmerchantType: function selectSubmerchantType(type) {
        this.set('selectedType', type);
        this.set('subMerchant.type', type.value);
      },
      remove: function remove(subMerchant) {
        this.sendAction('remove', subMerchant);
      }
    }
  });
});