define('fcs-dashboard/pods/components/menu/cancel-modal/component', ['exports', 'ember', 'fcs-dashboard/mixins/base-component'], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend(_fcsDashboardMixinsBaseComponent['default'], {
    actions: {
      cancel: function cancel() {
        this.sendAction('cancel');
      },
      leave: function leave() {
        this.set('showModal', false);
        this.sendAction('leave', this.get('menu'), this.get('transition'));
      }
    }
  });
});