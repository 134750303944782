define('fcs-dashboard/pods/financial-overview-report/model', ['exports', 'ember-data/model', 'ember-data/attr', 'model-fragments/attributes'], function (exports, _emberDataModel, _emberDataAttr, _modelFragmentsAttributes) {
  exports['default'] = _emberDataModel['default'].extend({
    totalSales: (0, _emberDataAttr['default'])('string'),
    netSales: (0, _emberDataAttr['default'])('string'),
    totalOrders: (0, _emberDataAttr['default'])('string'),
    averageOrderAmount: (0, _emberDataAttr['default'])('number'),
    totalDigitalTips: (0, _emberDataAttr['default'])('number'),
    averageDigitalTip: (0, _emberDataAttr['default'])('number'),
    creditPercentageOfSales: (0, _emberDataAttr['default'])('number'),
    cashlessPercentageOfSales: (0, _emberDataAttr['default'])('number'),
    showAllEvents: (0, _emberDataAttr['default'])('boolean'),
    financialReportDetails: (0, _modelFragmentsAttributes.fragmentArray)('financialOverviewReport/financialReportDetails', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    financialReportDailyDetails: (0, _modelFragmentsAttributes.fragmentArray)('financialOverviewReport/financialReportDailyMenuDetails', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    summarizeBy: (0, _emberDataAttr['default'])('number'),
    outputFileName: (0, _emberDataAttr['default'])('string', { defaultValue: '' })
  });
});