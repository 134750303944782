define("fcs-dashboard/pods/components/menu/menu-wizard/payment-information/cash-management/component", ["exports", "ember", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], {
    cashCoinRoundingTypes: computed(function () {
      return this.get('store').findAll('cashCoinRoundingType');
    }),
    cashCoinRoundingModes: computed(function () {
      return this.get('store').findAll('cashCoinRoundingMode');
    }),
    selectedCashCoinRoundingType: computed('menuLocation.cash.cashCoinRoundingType', function () {
      if (this.get('menuLocation.cash.cashCoinRoundingType') !== undefined) {
        return this.get('store').findRecord('cashCoinRoundingType', this.get('menuLocation.cash.cashCoinRoundingType'));
      } else {
        this.set('menuLocation.cash.cashCoinRoundingType', 0);
        return this.get('store').findRecord('cashCoinRoundingType', 0);
      }
    }),
    selectedCashCoinRoundingMode: computed('menuLocation.cash.cashCoinRoundingMode', function () {
      if (this.get('menuLocation.cash.cashCoinRoundingMode') !== undefined) {
        return this.get('store').findRecord('cashCoinRoundingMode', this.get('menuLocation.cash.cashCoinRoundingMode'));
      } else {
        this.set('menuLocation.cash.cashCoinRoundingMode', 0);
        return this.get('store').findRecord('cashCoinRoundingMode', 0);
      }
    }),
    cashCalculatorDisplayTypes: computed(function () {
      return this.get('store').findAll('cashCalculatorDisplayType');
    }),
    selectedCashCalculatorDisplayType: computed('menuLocation.cash.displayType', function () {
      if (this.get('menuLocation.cash.displayType') !== undefined) {
        return this.get('store').findRecord('cashCalculatorDisplayType', this.get('menuLocation.cash.displayType'));
      } else {
        this.set('menuLocation.cash.displayType', 0);
        return this.get('store').findRecord('cashCalculatorDisplayType', 0);
      }
    }),
    actions: {
      cashCalculatorDisplayTypeSelected: function cashCalculatorDisplayTypeSelected(type) {
        this.set('menuLocation.cash.displayType', type.id);
      },
      cashCoinRoundingTypeSelected: function cashCoinRoundingTypeSelected(type) {
        this.set('menuLocation.cash.cashCoinRoundingType', type.id);
      },
      cashCoinRoundingModeSelected: function cashCoinRoundingModeSelected(type) {
        this.set('menuLocation.cash.cashCoinRoundingMode', type.id);
      },
      updateCashDiscount: function updateCashDiscount() {
        this.toggleProperty('menuLocation.cash.discountEnabled');
        if (this.get('menuLocation.cash.discountEnabled')) {
          this.set('menuLocation.creditCardSurchargeEnabled', false);
          this.set('menuLocation.creditCardSurchargePercentage', 0);
          this.set('menuLocation.openLoopSurchargeEnabled', false);
          this.set('menuLocation.openLoopSurchargePercentage', 0);
        }
      },
      inputKeyUp: function inputKeyUp() {
        if (this.get('menuLocation.cash.discount') > 100) {
          this.set('menuLocation.cash.discount', 0);
        }
      }
    }
  });
});