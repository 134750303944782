define('fcs-dashboard/pods/product-mix-report/product-mix-item/model', ['exports', 'ember-data/attr', 'model-fragments/fragment'], function (exports, _emberDataAttr, _modelFragmentsFragment) {
  exports['default'] = _modelFragmentsFragment['default'].extend({
    productCatalogId: (0, _emberDataAttr['default'])('number'),
    productTypeId: (0, _emberDataAttr['default'])('number'),
    productCatalogName: (0, _emberDataAttr['default'])('string'),
    productTypeName: (0, _emberDataAttr['default'])('string'),
    productStatusId: (0, _emberDataAttr['default'])('number'),
    productStatus: (0, _emberDataAttr['default'])('string'),
    dateString: (0, _emberDataAttr['default'])('string'),
    percentageOfProduct: (0, _emberDataAttr['default'])('number'),
    percentageOfAllProducts: (0, _emberDataAttr['default'])('number'),
    averagePrice: (0, _emberDataAttr['default'])('number'),
    cashPayment: (0, _emberDataAttr['default'])('number'),
    cashlessPayment: (0, _emberDataAttr['default'])('number'),
    creditPayment: (0, _emberDataAttr['default'])('number'),
    totalWithTax: (0, _emberDataAttr['default'])('number'),
    amount: (0, _emberDataAttr['default'])('number'),
    productQuantity: (0, _emberDataAttr['default'])('number')
  });
});