define("fcs-dashboard/pods/attribute/model", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations"], function (exports, _emberDataModel, _emberDataAttr, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'company.detail.name.label' })
  });

  exports["default"] = _emberDataModel["default"].extend(Validations, {
    name: (0, _emberDataAttr["default"])('string'),
    deleted: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    companyId: (0, _emberDataAttr["default"])('number'),
    companyName: (0, _emberDataAttr["default"])('string'),
    attributeSetId: (0, _emberDataAttr["default"])('number'),
    position: (0, _emberDataAttr["default"])('number')
  });
});