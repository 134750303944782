define("fcs-dashboard/pods/daily-close-out-report/product-types/model", ["exports", "ember-data/attr", "model-fragments/fragment"], function (exports, _emberDataAttr, _modelFragmentsFragment) {
  exports["default"] = _modelFragmentsFragment["default"].extend({
    name: (0, _emberDataAttr["default"])('string'),
    netSales: (0, _emberDataAttr["default"])('number'),
    tax: (0, _emberDataAttr["default"])('number'),
    netSalesPlusTax: (0, _emberDataAttr["default"])('number'),
    voids: (0, _emberDataAttr["default"])('number'),
    comps: (0, _emberDataAttr["default"])('number'),
    spills: (0, _emberDataAttr["default"])('number'),
    declined: (0, _emberDataAttr["default"])('number'),
    refund: (0, _emberDataAttr["default"])('number'),
    discount: (0, _emberDataAttr["default"])('number'),
    coupon: (0, _emberDataAttr["default"])('number'),
    grossSales: (0, _emberDataAttr["default"])('number')
  });
});