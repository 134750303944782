define("fcs-dashboard/mirage/factories/dailyCloseOutReport", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  _emberCliMirage.faker.locale = 'en_US';

  exports["default"] = _emberCliMirage["default"].Factory.extend({
    clerks: [],
    locations: [],
    clerksByLocations: [],
    productTypes: [],
    totalGrossSales: _emberCliMirage.faker.commerce.price,
    totalDigitalTips: _emberCliMirage.faker.commerce.price
  });

  /*{
   clerks: [
   {
   name: faker.name.firstName,
   cashNetSalesTax: faker.commerce.price,
   creditNetSalesTax: faker.commerce.price,
   creditTips: faker.commerce.price,
   cashMinusCreditTips: faker.commerce.price,
   coupon: faker.commerce.price,
   spills: faker.commerce.price,
   comp: faker.commerce.price,
   voids: faker.commerce.price,
   discounts: faker.commerce.price
   }
   ],
   locations: [
   {
   name: faker.company.companyName,
   cashNetSalesTax: faker.commerce.price,
   creditNetSalesTax: faker.commerce.price,
   creditTips: faker.commerce.price,
   cashMinusCreditTips: faker.commerce.price,
   coupon: faker.commerce.price,
   spills: faker.commerce.price,
   comp: faker.commerce.price,
   voids: faker.commerce.price,
   discounts: faker.commerce.price
   }
   ],
   clerksByLocations: [
   {
   name: faker.company.companyName,
   clerks: [{
   name: faker.name.firstName,
   cashNetSalesTax: faker.commerce.price,
   creditNetSalesTax: faker.commerce.price,
   creditTips: faker.commerce.price,
   cashMinusCreditTips: faker.commerce.price,
   coupon: faker.commerce.price,
   spills: faker.commerce.price,
   comp: faker.commerce.price,
   voids: faker.commerce.price,
   discounts: faker.commerce.price
   }]
   }
   ],
   productTypes: [
   {
   name: faker.commerce.product,
   netSales: faker.commerce.price,
   tax: faker.commerce.price,
   netSalesPlusTax: faker.commerce.price,
   voids: faker.commerce.price,
   comps: faker.commerce.price,
   spills: faker.commerce.price,
   declined: faker.commerce.price,
   refund: faker.commerce.price,
   discount: faker.commerce.price,
   coupon: faker.commerce.price,
   grossSales: faker.commerce.price
   }
   ],
   totalNetSalesTax: faker.commerce.price,
   totalTips: faker.commerce.price,
   totalCashTips: faker.commerce.price
   }*/
});
/**
 * Created by anavarro on 3/21/17.
 */