define("fcs-dashboard/pods/meta-product-catalog/model", ["exports", "ember", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberDataRelationships, _emberCpValidations) {
  var computed = _ember["default"].computed;

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'productCatalog.modal.name.label' }),
    suggestedPrice: [(0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'productCatalog.modal.suggestedPrice.label' }), (0, _emberCpValidations.validator)('number', {
      positive: true,
      allowString: true,
      descriptionKey: 'productCatalog.modal.suggestedPrice.label'
    })],
    companyId: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      dependentKey: ['disableValidateCompany'],
      descriptionKey: 'productCatalog.modal.company.label',
      disabled: computed(function () {
        return this.get('model.disableValidateCompany');
      }).volatile()
    }),
    productType: (0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'productCatalog.modal.productType.label' }),
    attributeSets: [(0, _emberCpValidations.validator)('length', { min: 1, message: 'Must add at least 1 attributeSet' })]
  });

  exports["default"] = _emberDataModel["default"].extend(Validations, {
    name: (0, _emberDataAttr["default"])('string'),
    companyId: (0, _emberDataAttr["default"])('number'),
    companyName: (0, _emberDataAttr["default"])('string'),
    suggestedPrice: (0, _emberDataAttr["default"])('number', { defaultValue: 0 }),
    description: (0, _emberDataAttr["default"])('string'),
    productType: (0, _emberDataRelationships.belongsTo)('productType'),
    attributeSets: (0, _emberDataRelationships.hasMany)('attributeSet'),
    productCatalogs: (0, _emberDataRelationships.hasMany)('productCatalog', { inverse: null }),
    generatedProductCatalogs: (0, _emberDataRelationships.hasMany)('productCatalog', { inverse: null }),
    productCatalogTemplate: true,
    displayClassName: (0, _emberDataAttr["default"])('string'),
    deleted: (0, _emberDataAttr["default"])('boolean', { defaultValue: false })
  });
});