define('fcs-dashboard/pods/menu-location/model', ['exports', 'ember', 'ember-data/attr', 'model-fragments/fragment', 'model-fragments/attributes'], function (exports, _ember, _emberDataAttr, _modelFragmentsFragment, _modelFragmentsAttributes) {
  var observer = _ember['default'].observer;
  exports['default'] = _modelFragmentsFragment['default'].extend({
    location: (0, _emberDataAttr['default'])('string'),
    creditCardSurchargeEnabled: (0, _emberDataAttr['default'])('boolean', { defaultValue: false }),
    patronLoadedFundsEnabled: (0, _emberDataAttr['default'])('boolean', { defaultValue: false }),
    creditCardSurchargePercentage: (0, _emberDataAttr['default'])('number', { defaultValue: 0 }),
    openLoopSurchargeEnabled: (0, _emberDataAttr['default'])('boolean', { defaultValue: false }),
    openLoopSurchargePercentage: (0, _emberDataAttr['default'])('number', { defaultValue: 0 }),
    cash: (0, _modelFragmentsAttributes.fragment)('tender/cash', {
      defaultValue: {
        enabled: false,
        fastCashEnabled: false,
        displayType: 0,
        cashCoinRoundingEnabled: false,
        cashCoinRoundingType: 0,
        cashCoinRoundingMode: 0
      }
    }),
    manualCredit: (0, _modelFragmentsAttributes.fragment)('tender/manualCreditCard', { defaultValue: { enabled: false, signatureEnabled: false } }),
    swipeCredit: (0, _modelFragmentsAttributes.fragment)('tender/swipeCreditCard', { defaultValue: { enabled: false, signatureEnabled: false } }),
    chipCredit: (0, _modelFragmentsAttributes.fragment)('tender/chipCreditCard', { defaultValue: { enabled: false } }),
    cashless: (0, _modelFragmentsAttributes.fragment)('tender/cashless', { defaultValue: { enabled: false, signatureEnabled: false, readerDisabled: false } }),
    gateway: (0, _emberDataAttr['default'])('string'),
    noGateway: (0, _emberDataAttr['default'])('boolean', { defaultValue: false }),
    updateCheckpoint: (0, _emberDataAttr['default'])('boolean', { defaultValue: false }),
    noPaymentMethods: (0, _emberDataAttr['default'])('boolean', { defaultValue: true }),
    locationObject: _ember['default'].computed('location', function () {
      return this.store.findRecord('location', this.get('location'));
    }),
    gatewayEnabled: observer('manualCredit.enabled', 'swipeCredit.enabled', 'chipCredit.enabled', 'gateway', function () {
      if (this.get('manualCredit.enabled') || this.get('swipeCredit.enabled') || this.get('chipCredit.enabled')) {
        if (this.get('gateway') !== null && this.get('gateway') !== undefined) {
          this.set('noGateway', false);
        } else {
          this.set('noGateway', true);
        }
      } else {
        this.set('noGateway', false);
      }
    }),
    paymentsEnabled: observer('manualCredit.enabled', 'swipeCredit.enabled', 'chipCredit.enabled', 'cashless.enabled', 'cash.enabled', function () {
      if (this.get('manualCredit.enabled') || this.get('swipeCredit.enabled') || this.get('chipCredit.enabled') || this.get('cashless.enabled') || this.get('cash.enabled')) {
        this.set('noPaymentMethods', false);
      } else {
        this.set('noPaymentMethods', true);
      }
    }),
    promotions: (0, _modelFragmentsAttributes.fragmentArray)('menuPromotion'),
    customFees: (0, _modelFragmentsAttributes.fragmentArray)('menuCustomFee'),
    creditEnabled: false
  });
});