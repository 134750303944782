define("fcs-dashboard/pods/reports-company-role/model", ["exports", "ember-data/attr", "model-fragments/fragment"], function (exports, _emberDataAttr, _modelFragmentsFragment) {
  exports["default"] = _modelFragmentsFragment["default"].extend({
    roleId: (0, _emberDataAttr["default"])('string'),
    companyId: (0, _emberDataAttr["default"])('string'),
    companyName: (0, _emberDataAttr["default"])('string'),
    reportsTo: (0, _emberDataAttr["default"])('string'),
    locations: (0, _emberDataAttr["default"])({
      defaultValue: function defaultValue() {
        return [];
      }
    })
  });
});