define("fcs-dashboard/pods/account-session/service", ["exports", "ember", "ember-data", "ember-data-preload"], function (exports, _ember, _emberData, _emberDataPreload) {
  var Service = _ember["default"].Service;
  var service = _ember["default"].inject.service;
  var computed = _ember["default"].computed;
  var isEmpty = _ember["default"].isEmpty;
  var Logger = _ember["default"].Logger;
  var PromiseObject = _emberData["default"].PromiseObject;
  exports["default"] = Service.extend({
    session: service(),
    store: service(),
    account: computed('session.data.authenticated.username', function () {
      var accountId = this.get('session.data.authenticated.username');
      Logger.debug("Authenticating user with: " + accountId);
      if (!isEmpty(accountId)) {
        return PromiseObject.create({
          promise: (0, _emberDataPreload["default"])(this.get('store').queryRecord('user', { username: accountId }), [{ company: 'locations' }, 'userType'])
        });
      }
    }),
    searchOptions: computed(function () {
      return this.get('store').createRecord('searchOptions');
    }),
    reportOptions: computed(function () {
      return this.get('store').createRecord('reportOptions');
    })
  });
});