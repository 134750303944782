define("fcs-dashboard/pods/user/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin", "fcs-dashboard/mixins/common-mixin", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _fcsDashboardMixinsCommonMixin, _fcsDashboardMixinsBaseComponent) {
  var Route = _ember["default"].Route;
  var isEmpty = _ember["default"].isEmpty;
  var isEqual = _ember["default"].isEqual;
  var Logger = _ember["default"].Logger;
  exports["default"] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], _fcsDashboardMixinsCommonMixin["default"], _fcsDashboardMixinsBaseComponent["default"], {
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      if (this.get('session.isAuthenticated')) {
        this.store.findAll('userType');
      }
      var allCompanies = this.store.peekAll('company');
      if (allCompanies.get('content').length === 0) {
        this.store.findAll('company');
      }
    },
    model: function model() {
      Logger.debug('getting data!');
      var company = undefined;
      if (this.get('lockedCompany') !== undefined) {
        company = this.get('lockedCompany.id');
      } else if (!this.get('isBRUser')) {
        company = this.get('loggedAccount.company.id');
      }
      return this.store.query('user', { companyId: company });
    },
    afterModel: function afterModel(model, transition) {
      this._super.apply(this, arguments);
      if (this.get('isReportingUser') && transition.intent.name !== "user.edit-account") {
        this.transitionTo('');
      }
    },
    actions: {
      willTransition: function willTransition() {
        window.stop();
        return true;
      },
      cancel: function cancel(user) {
        this.send('cleanMessages');
        user.rollbackAttributes();
        this.transitionTo('user');
      },
      save: function save(user, passwordConfirmation) {
        var _this = this;

        this.send('cleanMessages');
        if (user.get('validations.isValid')) {
          if (user.get('isNew') && isEmpty(user.get('password'))) {
            var errorMessage = this.get('notify').alert(this.get('intl').t('user.details.errors.passwordEmpty'), { closeAfter: 5000 });
            this.get('errorMessages').addObject(errorMessage);
          } else if (user.get('isNew') && !isEqual(user.get('password'), passwordConfirmation)) {
            var errorMessage = this.get('notify').alert(this.get('intl').t('user.details.errors.passwordNotMatch'), { closeAfter: 5000 });
            this.get('errorMessages').addObject(errorMessage);
          } else {
            (function () {
              var message = undefined;
              if (passwordConfirmation === undefined && !user.get('isNew')) {
                message = 'user.details.errors.successPasswordChange';
              } else {
                message = 'user.details.errors.successSave';
              }
              user.save().then(function () {
                _this.get('notify').success(_this.get('intl').t(message));
                _this.send('cleanMessages');
                _this.transitionTo('user');
                _this.refresh();
              })["catch"](function (error) {
                var message = 'user.details.errors.savingError';
                if (error !== undefined) {
                  message = "user.details.errors." + error.errors.get(0).detail;
                }
                var errorMessage = _this.get('notify').alert(_this.get('intl').t(message, { name: _this.get('user.username') }), { closeAfter: 5000 });
                _this.get('errorMessages').addObject(errorMessage);
              });
            })();
          }
        } else {
          user.get('validations.errors').forEach(function (error) {
            var errorMessage = _this.get('notify').alert(error.get('message'), { closeAfter: 5000 });
            _this.get('errorMessages').addObject(errorMessage);
          });
        }
      },
      refresh: function refresh() {
        Logger.debug('refreshing');
        this.refresh();
      }
    }
  });
});