define('fcs-dashboard/pods/components/fcs-modal-dialog/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    hasNoTitle: _ember['default'].computed.empty('title'),
    targetAttachment: 'middle center',
    isLargeSize: false,
    containerClass: _ember['default'].computed('isLargeSize', function () {
      if (this.get('isLargeSize')) {
        return 'modal-dialog modal-lg';
      } else {
        return 'modal-dialog';
      }
    }),
    actions: {
      close: function close() {
        this.sendAction('close');
      }
    }
  });
});