define("fcs-dashboard/mirage/fixtures/products", ["exports"], function (exports) {
  /**
   * Created by alberto on 03/02/16.
   */
  exports["default"] = [{
    id: 1,
    version: 1,
    productCatalog: 1,
    position: 1,
    toppings: [],
    price: 75,
    taxRate: 5,
    allowModifications: false,
    productType: 1,
    taxRateType: 0
  }, {
    id: 2,
    version: 1,
    productCatalog: 2,
    position: 2,
    toppings: [],
    price: 33,
    taxRate: 5,
    allowModifications: false,
    productType: 1,
    taxRateType: 0

  }, {
    id: 3,
    version: 1,
    position: 3,
    productCatalog: 3,
    toppings: [],
    price: 15,
    taxRate: 5,
    allowModifications: false,
    productType: 1,
    taxRateType: 0
  }, {
    id: 4,
    version: 1,
    productCatalog: 1,
    position: 1,
    toppings: [],
    price: 30,
    taxRate: 5,
    allowModifications: false,
    productType: 1,
    taxRateType: 0
  }, {
    id: 5,
    version: 1,
    productCatalog: 2,
    toppings: [],
    position: 2,
    price: 10,
    taxRate: 5,
    allowModifications: false,
    productType: 1,
    taxRateType: 0
  }, {
    id: 6,
    version: 1,
    productCatalog: 3,
    position: 1,
    toppings: [],
    price: 22,
    taxRate: 5,
    allowModifications: false,
    productType: 1,
    taxRateType: 0
  }, {
    id: 7,
    version: 1,
    productCatalog: 1,
    toppings: [],
    price: 12,
    taxRate: 5,
    allowModifications: false,
    productType: 1,
    taxRateType: 0
  }, {
    id: 8,
    version: 1,
    productCatalog: 2,
    toppings: [],
    price: 4,
    taxRate: 5,
    allowModifications: false,
    productType: 1,
    taxRateType: 0
  }, {
    id: 9,
    version: 1,
    productCatalog: 3,
    toppings: [],
    price: 21,
    taxRate: 5,
    allowModifications: false,
    productType: 1,
    taxRateType: 0
  }, {
    id: 10,
    version: 1,
    productCatalog: 1,
    toppings: [],
    price: 13,
    taxRate: 5,
    allowModifications: false,
    productType: 1,
    taxRateType: 0
  }, {
    id: 11,
    version: 1,
    productCatalog: 2,
    toppings: [],
    price: 11,
    taxRate: 5,
    allowModifications: false,
    productType: 1,
    taxRateType: 0
  }, {
    id: 12,
    version: 1,
    productCatalog: 3,
    toppings: [],
    price: 12,
    taxRate: 5,
    allowModifications: false,
    productType: 1,
    taxRateType: 0
  }, {
    id: 13,
    version: 1,
    productCatalog: 2,
    toppings: [],
    price: 6,
    taxRate: 5,
    allowModifications: false,
    productType: 1,
    taxRateType: 0
  }];
});