define('fcs-dashboard/pods/company/new/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    model: function model() {
      var company = this.store.createRecord('company', { status: 'active' });
      company.get('userProfiles').pushObjects(this.store.peekAll('userProfile').filterBy('defaultProfile', true));
      return company;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('companies', this.store.peekAll('company'));
    },
    renderTemplate: function renderTemplate() {
      this.render('company/new', { into: 'application' });
    },
    actions: {
      willTransition: function willTransition() {
        this.controller.get('model').rollbackAttributes();
      }
    }
  });
});