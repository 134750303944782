define("fcs-dashboard/pods/components/menu/menu-wizard/taxes/product-type-editor/component", ["exports", "ember", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], {
    menuCategories: [],
    _columns: computed(function () {
      return [{
        propertyName: 'productTypeObject.name',
        title: this.get('intl').t('menu.builder.steps.stepTwo.productTypes.headers.name')
      }, {
        title: this.get('intl').t('menu.builder.steps.stepTwo.productTypes.headers.taxRate'),
        template: "menu/actions/product-type-tax",
        className: 'productTypeTable-tax-input'
      }, {
        template: "menu/actions/product-type-tax-set-action",
        className: 'changePasswordRow'
      }];
    }),
    menuProductTypes: [],
    actions: {
      setTaxRate: function setTaxRate(record) {
        record.set('taxRate', 0);
        record.set('taxNotSet', false);
      },
      clearTaxRate: function clearTaxRate(record) {
        var productUsingTypeTaxCount = '0';
        this.get('menuCategories').forEach(function (category) {
          category.get('pages').forEach(function (page) {
            var products = page.get('products').filterBy('productCatalog.productType.id', record.get('productType'));
            products.forEach(function (product) {
              if (product.get('taxRateType.id') === '1') {
                productUsingTypeTaxCount++;
              }
            });

            page.get('productGroups').forEach(function (productGroup) {
              var products = productGroup.get('products').filterBy('productCatalog.productType.id', record.get('productType'));
              products.forEach(function (product) {
                if (product.get('taxRateType.id') === '1') {
                  productUsingTypeTaxCount++;
                }
              });
            });
          });
        });

        if (productUsingTypeTaxCount > 0) {
          this.get('notify').alert(record.get('productTypeObject.name') + " tax is being used by " + productUsingTypeTaxCount + " product(s)", { closeAfter: 5000 });
        } else {
          record.set('taxRate', null);
          record.set('taxNotSet', true);
        }
      },
      cancelAction: function cancelAction() {
        this.send('cleanMessages');
        this.get('menuProductTypes').clear();
        this.sendAction('closeProductTypeSelector', this.get('menuProductTypes'));
      },
      acceptProductTypeSelector: function acceptProductTypeSelector() {
        this.sendAction('acceptProductTypeSelector', this.get('menuProductTypes'));
      }
    }
  });
});