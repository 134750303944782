define("fcs-dashboard/pods/components/company/company-details/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/company-mixin"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsCompanyMixin) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var observer = _ember["default"].observer;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], _fcsDashboardMixinsCompanyMixin["default"], {
    passwordConfirmation: '',
    invalidTabletLogin: false,
    userProfileSorting: ['name'],
    userProfilesSorted: computed.sort('userProfiles', 'userProfileSorting'),
    userProfiles: computed(function () {
      return this.get('store').query('userProfile', { company: this.get('model.id') });
    }),
    invalidTabletLoginObserver: observer('model.tabletLogin', function () {
      if (this.get('companies').filterBy('tabletLogin', this.get('model.tabletLogin')).length > 1) {
        this.get('notify').alert(this.get('intl').t('company.detail.errors.invalidTabletLogin'), { closeAfter: 5000 });
        this.set('invalidTabletLogin', true);
      } else {
        this.set('invalidTabletLogin', false);
      }
    }),
    companyProfiles: computed.alias('model.userProfiles'),
    showLocationList: computed('model.isNew', function () {
      return !this.get('model.isNew');
    }),
    actions: {
      cancelAction: function cancelAction(model) {
        this.sendAction('cancel', model);
      },
      refreshAction: function refreshAction() {
        this.sendAction('refreshAction');
      },
      saveAction: function saveAction(model, passwordConfirmation) {
        if (model.get('isNew')) {
          model.set('password', passwordConfirmation);
        }
        this.sendAction('save', model, passwordConfirmation);
      },
      statusSelected: function statusSelected(selection) {
        this.set('model.status', selection.toLowerCase());
      }
    }
  });
});