define("fcs-dashboard/pods/page/model", ["exports", "ember", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
  var observer = _ember["default"].observer;
  var computed = _ember["default"].computed;
  var A = _ember["default"].A;
  exports["default"] = _emberDataModel["default"].extend({
    name: (0, _emberDataAttr["default"])('string'),
    categories: (0, _emberDataRelationships.hasMany)('category'),
    products: (0, _emberDataRelationships.hasMany)('product'),
    productGroups: (0, _emberDataRelationships.hasMany)('productGroup'),
    deletedProducts: (0, _emberDataAttr["default"])({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    deletedProductGroups: (0, _emberDataAttr["default"])({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    changed: (0, _emberDataAttr["default"])('boolean'),
    isModified: observer('name', function () {
      this.set('changed', this.get('hasDirtyAttributes'));
    }),
    pageId: computed('name', 'id', function () {
      return "page" + this.get('name') + "." + this.get('id') + "." + Date.now();
    }),
    pageDisplayList: computed('products', 'productGroups', function () {
      var displayList = A();
      displayList.addObjects(this.get('products')).addObjects(this.get('productGroups'));
      return displayList;
    })
  });
});