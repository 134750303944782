define('fcs-dashboard/mirage/factories/gateway', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  _emberCliMirage.faker.locale = 'en_US';

  exports['default'] = _emberCliMirage['default'].Factory.extend({
    name: _emberCliMirage.faker.hacker.noun,
    enabled: true,
    processor: 1,
    gatewayEnvironment: 1,
    processorType: 1,
    credentials: [{
      id: 0,
      name: _emberCliMirage.faker.hacker.noun,
      displayName: _emberCliMirage.faker.hacker.noun,
      type: 0,
      value: ''
    }],
    menuLocations: [{
      menuId: 1,
      menuName: _emberCliMirage.faker.hacker.noun,
      locationId: 1,
      locationName: _emberCliMirage.faker.hacker.noun
    }]
  });
});
/**
 * Created by alberto on 08/06/16.
 */