define('fcs-dashboard/mirage/factories/orders', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  _emberCliMirage.faker.locale = 'en_US';

  exports['default'] = _emberCliMirage['default'].Factory.extend({
    grandTotal: _emberCliMirage.faker.commerce.price,
    serviceFeeRate: _emberCliMirage.faker.random.number(100),
    serviceFee: _emberCliMirage.faker.commerce.price,
    subtotal: _emberCliMirage.faker.commerce.price,
    taxesTotal: _emberCliMirage.faker.commerce.price,
    tip: _emberCliMirage.faker.commerce.price,
    user: _emberCliMirage.faker.random.number,
    customer: _emberCliMirage.faker.name.lastName,
    sendType: 'Auto',
    locationGroup: _emberCliMirage.faker.company.companyName,
    company: _emberCliMirage.faker.company.companyName,
    location: _emberCliMirage.faker.company.companyName,
    menu: _emberCliMirage.faker.company.companyName,
    occurrenceDate: _emberCliMirage.faker.date.past,
    items: [],
    payment: null
  });
});
/**
 * Created by alberto on 09/08/16.
 */