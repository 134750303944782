define("fcs-dashboard/pods/components/menu/menu-wizard/taxes/component", ["exports", "ember", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var observer = _ember["default"].observer;
  var isEmpty = _ember["default"].isEmpty;
  var htmlSafe = _ember["default"].String.htmlSafe;
  var run = _ember["default"].run;
  var A = _ember["default"].A;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], {
    productTypeTaxes: A(),
    displayTaxesOptions: false,
    showTaxInclusiveRate: computed.alias('menu.taxInclusive'),
    showServiceFeeRate: computed.alias('menu.serviceFeeEnabled'),
    showProductTypeSelector: false,
    headers: ['menu.builder.steps.stepTwo.productTypes.headers.name', 'menu.builder.steps.stepTwo.productTypes.headers.taxRate'],
    menuProductTypes: computed.alias('menu.menuProductTypes'),
    menuCategories: computed.alias('menu.categories'),
    selectedProductTypes: [],
    productTypeCount: computed.alias('menu.menuProductTypes.length'),
    menuTaxRate: '',
    inputMenuTaxRate: computed(function () {
      return this.get('menu.menuTaxRate');
    }),
    inputMenuTaxRateObserver: observer('inputMenuTaxRate', function () {
      this.set('menu.menuTaxRate', parseFloat(this.get('inputMenuTaxRate')));
    }),
    inputServiceFeeTaxRate: computed(function () {
      return this.get('menu.serviceFeeTaxRate');
    }),
    inputServiceFeeTaxRateObserver: observer('inputServiceFeeTaxRate', function () {
      this.set('menu.serviceFeeTaxRate', parseFloat(this.get('inputServiceFeeTaxRate')));
    }),
    taxTypes: computed(function () {
      return this.get('store').query('taxType', { cashless: false });
    }),
    menuTaxTypes: [],
    serviceFeeRate: '',
    _loadTaxTypesLists: function _loadTaxTypesLists() {
      var _this = this;

      this.get('taxTypes').forEach(function (taxType) {
        _this.get('menuTaxTypes').addObject(taxType);
      });
    },
    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);
      if (this.get('showProductTypeSelector')) {
        this.get('menuProductTypes').forEach(function (menuProductTypeTax) {
          var productTypeTax = _this2.get('store').createFragment('menuProductType', {
            productType: menuProductTypeTax.get('productType'),
            taxRate: menuProductTypeTax.get('taxRate'),
            taxNotSet: menuProductTypeTax.get('taxNotSet')
          });
          _this2.get('productTypeTaxes').addObject(productTypeTax);
        });
      }

      if (this.get('taxTypes').then !== undefined) {
        this.get('taxTypes').then(function (content) {
          run(function () {
            _this2.set('taxTypes', content);
            _this2._loadTaxTypesLists();
            _this2.set('displayTaxesOptions', true);
          });
        });
      } else {
        this._loadTaxTypesLists();
        this.set('displayTaxesOptions', true);
      }
      if (this.get('menu.isNew')) {
        this.set('menu.taxInclusive', true);
      }
    },
    actions: {
      nextStep: function nextStep() {
        this.send('cleanMessages');
        var isValid = true;
        var errorMessage = undefined;

        if (isEmpty(this.get('menu.menuTaxRate'))) {
          isValid = false;
          this.set('menuTaxRate', htmlSafe('has-error alert-danger'));
          errorMessage = this.get('notify').alert(this.get('intl').t('menu.builder.steps.stepTwo.menuTaxes.errors.empty'), { closeAfter: 5000 });
          this.get('errorMessages').addObject(errorMessage);
        } else if (!(this.get('menu.menuTaxRate') > 100 || this.get('menu.menuTaxRate') < 0) && !/^(100|[0-9]{1,2})(\.[0-9]{1,2})?$/.test(this.get('menu.menuTaxRate'))) {
          isValid = false;
          this.set('menuTaxRate', htmlSafe('has-error alert-danger'));
          errorMessage = this.get('notify').alert(this.get('intl').t('menu.builder.steps.stepTwo.menuTaxes.errors.invalidPercentage'), { closeAfter: 5000 });
          this.get('errorMessages').addObject(errorMessage);
        }

        if (this.get('menu.serviceFeeEnabled')) {
          if (isEmpty(this.get('menu.serviceFeeTaxRate'))) {
            isValid = false;
            this.set('serviceFeeRate', htmlSafe('has-error alert-danger'));
            errorMessage = this.get('notify').alert(this.get('intl').t('menu.builder.steps.stepTwo.serviceFee.errors.empty'), { closeAfter: 5000 });
            this.get('errorMessages').addObject(errorMessage);
          } else if (!(this.get('menu.serviceFeeTaxRate') > 100 || this.get('menu.serviceFeeTaxRate') < 0) && !/^(100|[0-9]{1,2})(\.[0-9]{1,2})?$/.test(this.get('menu.serviceFeeTaxRate'))) {
            isValid = false;
            this.set('serviceFeeRate', htmlSafe('has-error alert-danger'));
            errorMessage = this.get('notify').alert(this.get('intl').t('menu.builder.steps.stepTwo.serviceFee.errors.invalidPercentage'), { closeAfter: 5000 });
            this.get('errorMessages').addObject(errorMessage);
          }
        }

        this.sendAction('nextStep', isValid);
      },
      previousStep: function previousStep() {
        this.sendAction('previousStep');
      },
      showMenuList: function showMenuList() {
        this.sendAction('showMenuList');
      },
      save: function save() {
        this.sendAction('save');
      },
      openProductTypeSelector: function openProductTypeSelector() {
        var _this3 = this;

        this.get('menuProductTypes').forEach(function (menuProductTypeTax) {
          var productTypeTax = _this3.get('store').createFragment('menuProductType', {
            productType: menuProductTypeTax.get('productType'),
            taxRate: menuProductTypeTax.get('taxRate'),
            taxNotSet: menuProductTypeTax.get('taxNotSet')
          });
          _this3.get('productTypeTaxes').addObject(productTypeTax);
        });
        this.set('showProductTypeSelector', true);
      },
      closeProductTypeSelector: function closeProductTypeSelector() {
        this.send('cleanMessages');
        this.get('productTypeTaxes').clear();
        this.set('showProductTypeSelector', false);
      },
      acceptProductTypeSelector: function acceptProductTypeSelector(menuProductTypes) {
        var _this4 = this;

        this.send('cleanMessages');
        var isValid = true;
        var errorMessage = undefined;
        menuProductTypes.forEach(function (productType) {
          if (productType.get('taxRate') !== null) {
            if (!(productType.get('taxRate') > 100 || productType.get('taxRate') < 0) && !/^(100|[0-9]{1,2})(\.[0-9]{1,2})?$/.test(productType.get('taxRate'))) {
              errorMessage = _this4.get('notify').alert(_this4.get('intl').t('menu.builder.steps.stepTwo.productTypes.errors.invalidPercentage', { productType: productType.get('productTypeObject.name') }), { closeAfter: 5000 });
              _this4.get('errorMessages').addObject(errorMessage);
              isValid = false;
            }
          }
        });
        if (isValid) {
          this.get('menuProductTypes').clear();
          this.get('menuProductTypes').addObjects(menuProductTypes);
          this.set('productTypeCount', menuProductTypes.get('length'));
          this.get('productTypeTaxes').clear();
          this.set('showProductTypeSelector', false);
        }
      }
    }
  });
});