define("fcs-dashboard/pods/components/menu/menu-wizard/payment-information/credit-card-management/component", ["exports", "ember", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var Logger = _ember["default"].Logger;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], {
    gateways: [],
    validationMethods: [],
    _sortCriteria: ['name'],
    _sortedGateway: computed.sort('gateways', '_sortCriteria'),
    _selectedGateway: undefined,
    _enableCreditCard: false,
    _selectedValidationMethod: computed('menuLocation.chipCredit.validationMethod', function () {
      if (this.get('menuLocation.chipCredit.validationMethod') !== undefined) {
        return this.get('store').findRecord('validationMethodType', this.get('menuLocation.chipCredit.validationMethod'));
      } else {
        this.set('menuLocation.chipCredit.validationMethod', 1);
        return this.get('store').findRecord('validationMethodType', 1);
      }
    }),
    showNoGatewayError: computed('_enableCreditCard', 'menuLocation.noGateway', function () {
      return this.get('_enableCreditCard') && this.get('menuLocation.noGateway');
    }),
    _setEnableCreditCard: function _setEnableCreditCard() {
      var enable = false;
      if (this.get('menuLocation.creditEnabled')) {
        enable = true;
      } else if (this.get('menuLocation.manualCredit.enabled') || this.get('menuLocation.swipeCredit.enabled') || this.get('menuLocation.chipCredit.enabled')) {
        enable = true;
        this.set('menuLocation.creditEnabled', true);
      }
      Logger.debug("setting checkbox Enable Credit Card, Enable: " + enable);
      this.set('_enableCreditCard', enable);
    },
    _setSelectedGateway: function _setSelectedGateway() {
      Logger.debug("setting selected gateway with id " + this.get('menuLocation.gateway'));
      this.set('_selectedGateway', this.get('gateways').findBy('id', this.get('menuLocation.gateway')));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      Logger.debug('didReceiveAttrs hook method');
      this._setEnableCreditCard();
      this._setSelectedGateway();
    },
    actions: {
      validationMethodSelected: function validationMethodSelected(validationMethod) {
        this.set('menuLocation.chipCredit.validationMethod', validationMethod.id);
      },
      gatewaySelected: function gatewaySelected(gateway) {
        this.set('menuLocation.gateway', gateway.get('id'));
        this.set('_selectedGateway', gateway);
      },
      enableCreditCardAction: function enableCreditCardAction() {
        this.set('menuLocation.creditEnabled', !this.get('_enableCreditCard'));
        this.set('_enableCreditCard', !this.get('_enableCreditCard'));
        if (!this.get('_enableCreditCard')) {
          this.set('menuLocation.manualCredit.enabled', false);
          this.set('menuLocation.manualCredit.signatureEnabled', false);
          this.set('menuLocation.swipeCredit.enabled', false);
          this.set('menuLocation.swipeCredit.signatureEnabled', false);
          this.set('menuLocation.chipCredit.enabled', false);
          this.set('menuLocation.chipCredit.validationMethods', undefined);
          this.set('menuLocation.gateway', undefined);
          this.set('_selectedGateway', undefined);
        }
      },
      inputKeyUp: function inputKeyUp() {
        if (this.get('menuLocation.creditCardSurchargePercentage') > 10) {
          this.set('menuLocation.creditCardSurchargePercentage', 0);
        }
      },
      updateCreditSurcharge: function updateCreditSurcharge() {
        this.toggleProperty('menuLocation.creditCardSurchargeEnabled');
        if (this.get('menuLocation.creditCardSurchargeEnabled')) {
          this.set('menuLocation.cash.discountEnabled', false);
          this.set('menuLocation.cash.discount', 0);
        }
      }
    }
  });
});