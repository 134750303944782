define("fcs-dashboard/pods/location-group/loading/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var Route = _ember["default"].Route;
  exports["default"] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], {
    renderTemplate: function renderTemplate() {
      this.render('location-group/loading', { into: 'application' });
    },
    actions: {
      willTransition: function willTransition() {
        window.stop();
        return true;
      }
    }
  });
});