define("fcs-dashboard/pods/components/menu/menu-wizard/products-table/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/table-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsTableComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var A = _ember["default"].A;
  var run = _ember["default"].run;
  var Logger = _ember["default"].Logger;
  var allSettled = _ember["default"].RSVP.allSettled;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], _fcsDashboardMixinsTableComponent["default"], {
    _isLoading: true,
    disableOk: true,
    displayProductsList: true,
    productCatalogsLoaded: false,
    metaProductCatalogsLoaded: false,
    data: [],
    displayClearSearch: false,
    inputProductSearch: '',
    columns: computed('filterClass', function () {
      return [{
        template: "meta-product-catalog/actions/row-select-checkbox",
        useFilter: false,
        mayBeHidden: false,
        templateForSortCell: "meta-product-catalog/actions/row-select-all-checkbox"
      }, {
        propertyName: 'name',
        title: this.get('intl').t('productCatalog.list.product')
      }, {
        propertyName: 'productType.name',
        title: this.get('intl').t('productCatalog.list.productType')
      }, {
        propertyName: 'suggestedPrice',
        title: this.get('intl').t('productCatalog.list.suggestedPrice'),
        template: "product-catalog/suggested-price-cell",
        className: 'suggestedPriceRow'
      }, {
        propertyName: 'displayClassName',
        title: this.get('intl').t('productCatalog.list.productCatalogClass'),
        filterBy: 'displayClassName',
        filterString: this.get('filterClass'),
        filterWithSelect: true,
        predefinedFilterOptions: this.get('productClasses')
      }];
    }),
    _traits: computed(function () {
      if (this.get('isBRUser')) {
        if (this.get('lockedCompany') !== undefined) {
          var company = this.get('lockedCompany.id');
          return this.get('store').query('trait', { companyId: company, pageSize: -1 });
        } else {
          return this.get('store').query('trait', { companyId: this.get('menu.company.id'), pageSize: -1 });
        }
      } else {
        var company = this.get('loggedAccount.company.id');
        return this.get('store').query('trait', { companyId: company, pageSize: -1 });
      }
    }),
    loadReady: computed('productCatalogsLoaded', 'metaProductCatalogsLoaded', function () {
      return this.get('productCatalogsLoaded') && this.get('metaProductCatalogsLoaded');
    }),
    selectedModels: computed('products', 'products.length', function () {
      var _this = this;

      var catalog = A();
      this.get('products').forEach(function (product) {
        var productCatalog = _this.get('data').find(function (catalog) {
          return catalog.id === product.get('productCatalog.id');
        });
        if (!productCatalog) {
          productCatalog = _this.get('store').peekRecord('productCatalog', product.get('productCatalog.id'));
        }

        catalog.addObject(productCatalog);
      });
      return catalog;
    }),
    showModalProducts: false,
    showModalProductGroups: false,
    showModalCombos: false,
    newProductGroup: null,
    newCombo: null,
    newProduct: computed(function () {
      return this.get('store').createRecord('productCatalog', {
        company: this._getCompany(),
        currency: 'USD',
        suggestedPrice: 0,
        custom: false
      });
    }),
    newProductError: '',
    positions: computed(function () {
      return this.createPositions();
    }),
    sortCriteria: ['position'],
    sortedProducts: computed.sort('pageDisplayList', 'sortCriteria'),
    createPositions: function createPositions() {
      var positions = A();
      Logger.debug("# of product positions 24");
      for (var i = 1; i < 25; i++) {
        positions.addObject(i);
      }
      return positions;
    },
    _getCompany: function _getCompany() {
      var companyId = undefined;
      if (this.get('isBRUser')) {
        if (this.get('lockedCompany') !== undefined) {
          companyId = this.get('lockedCompany.id');
        }
      } else {
        companyId = this.get('loggedAccount.company.id');
      }
      return companyId;
    },
    _taxRateTypes: computed(function () {
      return this.get('store').findAll('taxRateType');
    }),
    _modifierActions: computed(function () {
      return this.get('store').findAll('modifierAction');
    }),
    _modifierPlacements: computed(function () {
      return this.get('store').findAll('modifierPlacement');
    }),
    _loadTaxRateTypes: function _loadTaxRateTypes() {
      var _this2 = this;

      if (this.get('_taxRateTypes').then !== undefined) {
        // if a promise set the content when it resolves
        this.get('_taxRateTypes').then(function (content) {
          run(function () {
            _this2.set('_taxRateTypes', content);
          });
        });
      }
    },
    _menuProductTypes: [],
    _loadProductTypes: function _loadProductTypes() {
      var _this3 = this;

      var promise = [];
      promise = this.get('menu.menuProductTypes').mapBy('productTypeObject');
      allSettled(promise).then(function () {
        _this3.set('_menuProductTypes', promise);
        _this3.set('_isLoading', false);
      });
    },
    _deleteFromDisplayList: function _deleteFromDisplayList(item) {
      this.set('displayProductsList', false);
      this.get('pageDisplayList').removeObject(item);
      this.set('displayProductsList', true);
      this.set('positions', this.createPositions());
    },
    init: function init() {
      var _this4 = this;

      this._super.apply(this, arguments);
      this.get('data').clear();
      var company = this.get('menu.company.id');
      Logger.debug("COMPANY USER: Getting products from catalog for company " + company + ".");
      this.get('store').query('productCatalog', { companyId: company, loadAttributes: false }).then(function (productCatalogs) {
        _this4.get('data').addObjects(productCatalogs);
        _this4.set('productCatalogsLoaded', true);
      });
      this.get('store').query('metaProductCatalog', { companyId: company }).then(function (templates) {
        _this4.get('data').addObjects(templates);
        _this4.set('metaProductCatalogsLoaded', true);
      });
      this._loadTaxRateTypes();
      this._loadProductTypes();
    },
    actions: {
      clearAllSelected: function clearAllSelected() {
        var _this5 = this;

        this.set('displayProductsList', false);
        run.next(function () {
          _this5.get('selectedModels').clear();
          _this5.set('displayProductsList', true);
        });
      },
      openModalProducts: function openModalProducts(option) {
        var _this6 = this;

        if (option === 1) {
          this.set('filterClass', 'COMBO');
        } else {
          this.set('filterClass', '');
        }

        var catalog = A();
        this.get('products').forEach(function (product) {
          var productCatalog = _this6.get('data').find(function (catalog) {
            return catalog.id === product.get('productCatalog.id');
          });
          if (!productCatalog) {
            productCatalog = _this6.get('store').peekRecord('productCatalog', product.get('productCatalog.id'));
          }

          catalog.addObject(productCatalog);
        });

        // Initialize Selected Models if Null or Undefined
        if (this.get('selectedModels') === null || this.get('selectedModels') === undefined) {
          this.set('selectedModels', A());
        }
        this.get('selectedModels').clear();
        this.get('selectedModels').addObjects(catalog);
        this.set('newProduct', this.get('store').createRecord('productCatalog', {
          companyId: this._getCompany(),
          currency: 'USD',
          suggestedPrice: 0,
          custom: false
        }));
        this.set('showModalProducts', true);
      },
      openModalProductGroups: function openModalProductGroups(productGroup) {
        if (productGroup === null || productGroup === undefined) {
          this.set('displayProductCatalogsList', true);
          this.set('newProductGroup', this.get('store').createRecord('productGroup'));
        } else {
          this.set('displayProductCatalogsList', false);
          this.set('newProductGroup', productGroup);
        }
        this.set('showModalProductGroups', true);
      },
      closeModalProducts: function closeModalProducts() {
        this.send('cleanMessages');
        this.set('showModalProducts', false);
      },
      closeModalProductGroups: function closeModalProductGroups() {
        this.set('showModalProductGroups', false);
      },
      addNewProduct: function addNewProduct(product) {
        var _this7 = this;

        this.send('cleanMessages');
        this.set('displayProductsList', false);

        //Trims name before saving
        var name = product.get('name').trim();
        product.set('name', name);

        if (product.get('validations.isValid')) {
          product.save().then(function (newProductCatalog) {
            _this7.set('newProduct', _this7.get('store').createRecord('productCatalog', {
              companyId: _this7._getCompany(),
              currency: 'USD',
              suggestedPrice: 0,
              custom: false
            }));
            Logger.debug('Getting products from catalog after add a new one.');
            _this7.get('data').addObject(newProductCatalog);
            _this7.set('filteredCatalog', _this7.get('data'));
            _this7.set('displayProductsList', true);
          })["catch"](function (error) {
            _this7.set('displayProductsList', true);
            var errorMessage = _this7.get('notify').alert(_this7.get('intl').t("menu.builder.steps.stepSix.errors." + error.errors.get(0).detail), { closeAfter: 5000 });
            _this7.get('errorMessages').addObject(errorMessage);
          });
        } else {
          this.set('displayProductsList', true);
          product.get('validations.errors').forEach(function (error) {
            var errorMessage = _this7.get('notify').alert(error.get('message'), { closeAfter: 5000 });
            _this7.get('errorMessages').addObject(errorMessage);
          });
        }
      },
      addProducts: function addProducts() {
        var _this8 = this;

        this.send('cleanMessages');

        this._updateSelectedProductsByMetaProducts();
        // Initialize positions
        var newPosition = 1;

        this.get('selectedModels').forEach(function (item) {
          var contains = false;
          _this8.get('products').forEach(function (product) {
            if (product.get('productCatalog.id') === item.get('id')) {
              contains = true;
            }
          });

          if (!contains) {

            // Sets the position of the new product
            // the new position will be the first free position of 24
            for (newPosition; newPosition < _this8.get('positions.length'); newPosition++) {
              if (_this8.get('sortedProducts').filterBy('position', newPosition).length === 0) {
                break;
              }
            }

            if (item.get('productClass.id') === '1') {
              (function () {
                // Add product COMBO
                var newCombo = _this8.get('store').createRecord('product', {
                  productCatalog: item,
                  price: item.get('suggestedPrice'),
                  taxRate: 0,
                  productTypeTaxRate: 0,
                  toppings: [],
                  allowModifications: false,
                  taxRateType: _this8.get('_taxRateTypes').findBy('id', '0'),
                  position: newPosition,
                  productType: item.get('productType.name'),
                  productClass: _this8.get('store').peekRecord('productClass', 1)
                });
                item.get('comboSelectionCatalogs').forEach(function (selectionCatalog) {
                  if (selectionCatalog.get('id') !== null && selectionCatalog.get('id') !== undefined) {
                    (function () {
                      // Assign newComboSelection values
                      var newComboSelection = _this8.get('store').createRecord('comboSelection', {
                        position: selectionCatalog.get('position'),
                        comboSelectionCatalogId: selectionCatalog.get('id'),
                        displayText: selectionCatalog.get('displayText'),
                        deleted: false
                      });
                      selectionCatalog.get('comboSelectionProductCatalogList').forEach(function (selectionProductCatalog) {
                        // Get selectionProductCatalog ProductCatalog
                        var productCatalog = _this8.get('store').peekRecord('productCatalog', selectionProductCatalog.get('productCatalogId'));
                        // Assign newSelectionProduct values
                        var newSelectionProduct = _this8.get('store').createRecord('product', {
                          productCatalog: productCatalog,
                          price: 0,
                          priceChange: selectionProductCatalog.get('priceChange'),
                          taxRate: 0,
                          productTypeTaxRate: 0,
                          allowModifications: false,
                          position: 0,
                          productType: productCatalog.get('productType.name'),
                          comboSelection: newComboSelection,
                          productClass: _this8.get('store').peekRecord('productClass', 0)
                        });
                        // Add newSelectionProduct to comboSelection products
                        newComboSelection.get('products').addObject(newSelectionProduct);
                      });
                      // Add newComboSelection to combo selections
                      newCombo.get('comboSelections').addObject(newComboSelection);
                    })();
                  }
                });
                // Add newCombo to the page product list
                _this8.get('products').addObject(newCombo);
              })();
            } else {
              var productClass = undefined;
              if (item.get('productClass.id') === '0') {
                productClass = _this8.get('store').peekRecord('productClass', 0);
              } else {
                productClass = _this8.get('store').peekRecord('productClass', 2);
              }
              var productTypeTax = _this8.get('menu.menuProductTypes').findBy('productTypeObject.id', item.get('productType.id'));

              var taxRate = 0;
              var taxRateType = '0';
              if (!productTypeTax.get('taxNotSet')) {
                taxRate = parseInt(productTypeTax.get('taxRate'));
                taxRateType = '1';
              }
              // Add product REGULAR or CUSTOM
              _this8.get('products').addObject(_this8.get('store').createRecord('product', {
                productCatalog: item,
                price: item.get('suggestedPrice'),
                taxRate: taxRate,
                productTypeTaxRate: 0,
                allowModifications: false,
                taxRateType: _this8.get('_taxRateTypes').findBy('id', taxRateType),
                position: newPosition,
                productType: item.get('productType.name'),
                productClass: productClass
              }));
            }

            // Add position for next new item
            newPosition++;
            _this8.get('positions').addObject(newPosition);
          }
        });
        this.send('closeModalProducts');
      },
      deleteProduct: function deleteProduct(item) {
        // Initialize Selected Models if Null or Undefined
        if (this.get('selectedModels') === null || this.get('selectedModels') === undefined) {
          this.set('selectedModels', A());
        }

        this.set('menu.changed', true);
        this.get('products').removeObject(item);
        var productToRemove = this.get('products').filterBy('id', item.get('productCatalog.id'));
        this.get('selectedModels').removeObjects(productToRemove);
        this.set('positions', this.createPositions());
        this._deleteFromDisplayList(item);
        if (item.id !== null && item.id !== undefined) {
          this.sendAction('deleteProductFromPage', item.id);
        }
      },
      deleteGroup: function deleteGroup(item) {
        this.set('menu.changed', true);
        this.get('productGroups').removeObject(item);
        this.set('positions', this.createPositions());
        this._deleteFromDisplayList(item);
        if (item.id !== null && item.id !== undefined) {
          this.sendAction('deleteProductGroupFromPage', item.id);
        }
      },
      changePositionAction: function changePositionAction(product, position) {
        this.sendAction('changePositionAction', product, position);
      },
      selectItem: function selectItem(isNotNewItem) {
        this.set('disableOk', isNotNewItem);
      },
      clearSearchInput: function clearSearchInput() {
        this.set('inputProductSearch', '');
        this.send('filterProductsList');
      },
      addProductGroup: function addProductGroup(productGroup) {
        if (productGroup.get('position') === 0) {
          // Sets the position of the new product
          // the new position will be the first free position of 24
          var newPosition = 1;
          for (var position = 0; position < this.get('sortedProducts.length'); position++) {
            if (this.get('sortedProducts').filterBy('position', newPosition).length === 0) {
              newPosition = position + 1;
              break;
            } else {
              newPosition++;
            }
          }
          productGroup.set('position', newPosition);
        }

        this.get('productGroups').addObject(productGroup);
        this.set('showModalProductGroups', false);
      }
    }
  });
});