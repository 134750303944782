define("fcs-dashboard/pods/saved-network/model", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations"], function (exports, _emberDataModel, _emberDataAttr, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    ssid: (0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'attributeSet.details.name.label' }),
    password: (0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'attributeSet.details.name.label' })
  });

  exports["default"] = _emberDataModel["default"].extend(Validations, {
    ssid: (0, _emberDataAttr["default"])('string'),
    password: (0, _emberDataAttr["default"])('string'),
    deleted: (0, _emberDataAttr["default"])('boolean', { defaultValue: false })
  });
});