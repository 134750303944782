define("fcs-dashboard/pods/components/search/detail-cell/component", ["exports", "ember", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var Component = _ember["default"].Component;
  var Logger = _ember["default"].Logger;
  var isEqual = _ember["default"].isEqual;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], {
    _showModal: false,
    _isLoading: false,
    _waitingResponse: false,
    _order: null,
    actions: {
      closeModal: function closeModal() {
        this.send('cleanMessages');
        this.set('_showModal', false);
        this.set('_isLoading', false);
        this.set('_paymentEmail', '');
      },
      orderAction: function orderAction(action, order, payment, email) {
        var _this = this;

        this.send('cleanMessages');
        // set action value in order model
        order.set('action', action);
        //if orderAction is EMAIL, add and validate email
        var isValidEmail = true;
        if (isEqual(action, 'EMAIL')) {
          order.set('emailToSend', email);
          if (!order.get('validations.isValid')) {
            isValidEmail = false;
            order.get('validations.errors').forEach(function (error) {
              var errorMessage = _this.get('notify').alert(error.get('message'), { closeAfter: 5000 });
              _this.get('errorMessages').addObject(errorMessage);
            });
          }
        }

        if (isValidEmail) {
          // send PUT request to server, so, it can execute the action
          order.save().then(function () {
            var message = undefined;
            Logger.debug(action + " result for order " + order.get('id') + " is " + order.get('actionResult.paymentResult') + " - " + order.get('actionResult.resultMessage'));

            var notification = undefined;
            if (isEqual(order.get('actionResult.paymentResultId'), 0)) {
              //approved
              message = _this.get('intl').t('search.errors.successActionSent', { action: action });
              notification = _this.get('notify').success(message, { closeAfter: 5000 });
            } else {
              message = _this.get('intl').t('search.errors.errorResultSendingAction', { action: action, result: order.get('actionResult.paymentResult'), message: order.get('actionResult.resultMessage') });
              notification = _this.get('notify').alert(message, { closeAfter: 5000 });
            }

            _this.get('errorMessages').addObject(notification);
            _this.get('store').findRecord('orderPayment', payment.get('id'), { reload: true });
            _this.get('store').findRecord('order', order.get('id'), { reload: true });
            _this.set('_waitingResponse', false);
          })["catch"](function (error) {
            var message = 'search.errors.errorSendingAction';
            if (error !== undefined) {
              message = "search.errors." + error.errors.get(0).detail;
            }
            var errorMessage = _this.get('notify').alert(_this.get('intl').t(message));
            _this.get('errorMessages').addObject(errorMessage);
            _this.set('_waitingResponse', false);
          });
        }
      },
      openModal: function openModal(value) {
        var _this2 = this;

        this.set('_showModal', true);
        this.set('_isLoading', true);
        this.set('_paymentEmail', '');
        this.get('store').findRecord('order', value, { reload: true }).then(function (record) {
          record.get('items').then(function () {
            record.get('payments').then(function () {
              _this2.set('_isLoading', false);
              _this2.set('_order', record);
            });
          });
        });
      }
    }
  });
});