define("fcs-dashboard/pods/attribute-set/serializer", ["exports", "ember", "ember-data"], function (exports, _ember, _emberData) {
  exports["default"] = _emberData["default"].RESTSerializer.extend(_emberData["default"].EmbeddedRecordsMixin, {
    attrs: {
      attributes: { serialize: 'records', deserialize: 'records' }
    },
    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      var serialized = record.serialize(record, options);

      //remove the root element
      _ember["default"].merge(hash, serialized);
    }
  });
});