define("fcs-dashboard/pods/product-group/model", ["exports", "ember", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations", "model-fragments/attributes"], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberDataRelationships, _emberCpValidations, _modelFragmentsAttributes) {
  var computed = _ember["default"].computed;

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'menu.builder.steps.stepSix.productTable.modal.errors.productGroupEmptyName' }),
    products: [(0, _emberCpValidations.validator)('length', { min: 1, message: 'Must add at least 1 product' })]
  });

  exports["default"] = _emberDataModel["default"].extend(Validations, {
    name: (0, _emberDataAttr["default"])('string'),
    buttonColor: (0, _emberDataAttr["default"])('string', { defaultValue: '#f8f8f8' }),
    position: (0, _emberDataAttr["default"])('number', { defaultValue: 0 }),
    isProduct: false,
    products: (0, _emberDataRelationships.hasMany)('product'),
    image: (0, _emberDataAttr["default"])('string'),
    imageURL: (0, _emberDataAttr["default"])('string'),
    deleteImage: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    attributeSets: (0, _modelFragmentsAttributes.fragmentArray)('productGroupAttributeSet'),
    deletedProducts: (0, _emberDataAttr["default"])({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    productGroupId: computed('name', 'id', function () {
      return "page" + this.get('name') + "." + this.get('id') + "." + Date.now();
    })
  });
});