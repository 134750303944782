define("fcs-dashboard/mixins/promotions-table-component", ["exports", "ember"], function (exports, _ember) {
  var A = _ember["default"].A;
  exports["default"] = _ember["default"].Mixin.create({
    classes: _ember["default"].Object.create({
      "table": "table table-striped"
    }),
    generatedProductClasses: _ember["default"].Object.create({
      "table": "table table-condensed"
    }),
    icons: _ember["default"].Object.create({
      "sort-asc": "fa fa-caret-down",
      "sort-desc": "fa fa-caret-up",
      "column-visible": "fa fa-check-square-o",
      "column-hidden": "fa fa-square-o",
      "nav-first": "fa fa-chevron-left",
      "nav-prev": "fa fa-angle-left",
      "nav-next": "fa fa-angle-right",
      "nav-last": "fa fa-chevron-right",
      "caret": "fa fa-caret-down"
    }),
    filterQueryParameters: {
      sort: 'sort',
      sortDirection: 'sortDirection',
      page: 'page',
      pageSize: 'limit'
    },
    productClasses: ['CUSTOM', 'REGULAR', 'COMBO', 'TEMPLATE'],
    _containsAllFiltered: function _containsAllFiltered(filteredContent, selectedModels) {
      var contains = true;
      filteredContent.forEach(function (model) {
        var containsModel = selectedModels.findBy('id', model);
        if (containsModel !== null && containsModel === undefined) {
          contains = false;
        }
      });
      return contains;
    },
    _updateSelectedProductsByMetaProducts: function _updateSelectedProductsByMetaProducts() {
      var _this = this;

      // Create array for all the meta product catalogs - product catalogs
      var removeMetaProductCatalogs = [];
      var additionalProductCatalogs = [];
      this.get('selectedModels').forEach(function (item) {
        if (item.get('productCatalogTemplate') !== undefined && item.get('productCatalogTemplate')) {
          _this.get('data').forEach(function (productCatalog) {
            if (productCatalog.get('metaProductCatalogId') !== undefined && productCatalog.get('metaProductCatalogId').toString() === item.get('id')) {
              additionalProductCatalogs.addObject(productCatalog);
            }
          });
          removeMetaProductCatalogs.addObject(item);
        }
      });

      //Remove Meta Product Catalogs
      this.get('selectedModels').removeObjects(removeMetaProductCatalogs);
      // Add the additional products
      this.get('selectedModels').addObjects(additionalProductCatalogs);
    },
    actions: {
      displayDataChanged: function displayDataChanged(selection) {
        // Initialize Selected Models if Null or Undefined
        if (this.get('selectedModels') === null || this.get('selectedModels') === undefined) {
          this.set('selectedModels', A());
        }

        // If the list have been filtered
        if (selection.get('filteredContent.length') > 0 && selection.get('filteredContent.length') !== this.get('data.length')) {
          // If the whole list was selected
          if (selection.get('selectedItems.length') === this.get('data.length')) {

            // Create temp list to store currently selected items
            var tempSelectedItems = this.get('selectedModels');

            // Clear the component selected items
            selection.get('selectedItems').clear();

            // If selected models contains all filtered data, remove the objects from selected
            if (this._containsAllFiltered(selection.get('filteredContent'), this.get('selectedModels'))) {
              tempSelectedItems.removeObjects(selection.get('filteredContent'));
            } else {
              tempSelectedItems.addObjects(selection.get('filteredContent'));
            }

            // Set the new value of the selected models
            this.set('selectedModels', tempSelectedItems);
            // Set the new value of the component's selected items
            selection.get('selectedItems').addObjects(tempSelectedItems);
          } else {
            // Normal selection
            this.set('selectedModels', selection.get('selectedItems'));
          }
        } else {
          // If selected items length is Zero
          if (selection.get('selectedItems.length') === 0) {
            // Clear component selected items
            selection.get('selectedItems').clear();
            // Clear selected items
            this.get('selectedModels').clear();
          } else {
            // Normal selection
            this.set('selectedModels', selection.get('selectedItems'));
          }
        }
      }
    }
  });
});