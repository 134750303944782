define("fcs-dashboard/pods/components/search/search-result/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/table-component", "ember-light-table"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsTableComponent, _emberLightTable) {
  var Component = _ember["default"].Component;
  var isEqual = _ember["default"].isEqual;
  var isEmpty = _ember["default"].isEmpty;
  var computed = _ember["default"].computed;
  var Logger = _ember["default"].Logger;
  exports["default"] = Component.extend(_fcsDashboardMixinsTableComponent["default"], _fcsDashboardMixinsBaseComponent["default"], {
    tagName: '',
    data: [],
    isLoading: false,
    _canLoadMore: false,
    _searchResults: [],
    _sortCriteria: ['tabletGeneratedId:desc'],
    _sortedResults: computed.sort('_searchResults', '_sortCriteria'),
    _showModal: false,
    _isLoading: false,
    _waitingResponse: false,
    _order: null,
    _columns: computed(function () {
      return [{
        propertyName: 'tabletGeneratedId',
        title: this.get('intl').t('search.list.orderId')
      }, {
        propertyName: 'locationGroupName',
        title: this.get('intl').t('search.list.eventVenue')
      }, {
        propertyName: 'amount',
        template: "search-order/amount-cell",
        title: this.get('intl').t('search.list.amount')
      }, {
        propertyName: 'status',
        title: this.get('intl').t('search.list.status')
      }, {
        propertyName: 'tabStatus',
        title: this.get('intl').t('search.list.tabStatus')
      }, {
        propertyName: 'formattedDate',
        title: this.get('intl').t('search.list.date')
      }, {
        propertyName: '',
        title: '',
        template: "search-order/detail-cell"
      }];
    }),
    _totalResults: 0,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('_canLoadMore', false);
      this.set('_table', new _emberLightTable["default"](this.get('_columns')));
    },
    getRecords: function getRecords() {
      //get more records
      this.sendAction('fetchRecords', this.get('searchOptions'));
    },
    actions: {
      closeModal: function closeModal() {
        this.send('cleanMessages');
        this.set('_showModal', false);
        this.set('_isLoading', false);
        this.set('_paymentEmail', '');
      },
      orderAction: function orderAction(action, order, payment, email) {
        var _this = this;

        this.send('cleanMessages');
        // set action value in order model
        order.set('action', action);
        //if orderAction is EMAIL, add and validate email
        var isValid = true;
        if (isEqual(action, 'EMAIL')) {
          order.set('emailToSend', email);
          if (!order.get('validations.isValid')) {
            isValid = false;
            order.get('validations.errors').forEach(function (error) {
              var errorMessage = _this.get('notify').alert(error.get('message'), { closeAfter: 5000 });
              _this.get('errorMessages').addObject(errorMessage);
            });
          }
        }

        if (isEqual(action, 'UNLOCK_TAB')) {
          if (order.get('tabName') === null || order.get('tabName') === undefined || isEmpty(order.get('tabName'))) {
            isValid = false;
            var errorMessage = this.get('notify').alert(this.get('intl').t('search.errors.noTab', { action: action }), { closeAfter: 5000 });
            this.get('errorMessages').addObject(errorMessage);
          }
        }

        if (isValid) {
          // send PUT request to server, so, it can execute the action
          order.save().then(function () {
            var message = undefined;
            Logger.debug(action + " result for order " + order.get('id') + " is " + order.get('actionResult.paymentResult') + " - " + order.get('actionResult.resultMessage'));

            var notification = undefined;
            if (isEqual(order.get('actionResult.paymentResultId'), 0)) {
              //approved
              message = _this.get('intl').t('search.errors.successActionSent', { action: action });
              notification = _this.get('notify').success(message, { closeAfter: 5000 });
            } else {
              message = _this.get('intl').t('search.errors.errorResultSendingAction', { action: action, result: order.get('actionResult.paymentResult'), message: order.get('actionResult.resultMessage') });
              notification = _this.get('notify').alert(message, { closeAfter: 5000 });
            }

            _this.get('errorMessages').addObject(notification);
            _this.get('store').findRecord('orderPayment', payment.get('id'), { reload: true });
            _this.get('store').findRecord('order', order.get('id'), { reload: true });
            _this.set('_waitingResponse', false);
          })["catch"](function (error) {
            var message = 'search.errors.errorSendingAction';
            if (error !== undefined) {
              message = "search.errors." + error.errors.get(0).detail;
            }
            var errorMessage = _this.get('notify').alert(_this.get('intl').t(message));
            _this.get('errorMessages').addObject(errorMessage);
            _this.set('_waitingResponse', false);
          });
        }
      },
      openModal: function openModal(value) {
        var _this2 = this;

        this.set('_showModal', true);
        this.set('_isLoading', true);
        this.set('_paymentEmail', '');
        this.get('store').findRecord('order', value.id, { reload: true }).then(function (record) {
          record.get('items').then(function () {
            record.get('payments').then(function () {
              _this2.set('_isLoading', false);
              _this2.set('_order', record);
            });
          });
        });
      },
      onColumnClick: function onColumnClick(column) {
        if (column.sorted) {
          var dir = column.ascending ? 'asc' : 'desc';
          var sortCriteria = column.valuePath + ":" + dir;
          this.set('_sortCriteria', [sortCriteria]);
          Logger.debug('cleaning table.');
          this.get('_table.rows').clear();
          Logger.debug('adding sorted data.');
          this.get('_table').setRows(this.get('_sortedResults'));
        }
      }
    }
  });
});