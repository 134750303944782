define('fcs-dashboard/pods/user/new/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'fcs-dashboard/mixins/common-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _fcsDashboardMixinsCommonMixin) {
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _fcsDashboardMixinsCommonMixin['default'], {
    model: function model() {
      var defaultUserType = this.store.peekRecord('userType', this.get('COMPANY_USERTYPE'));
      return this.store.createRecord('user', { state: 'active', userType: defaultUserType });
    },
    renderTemplate: function renderTemplate() {
      this.render('user/new', { into: 'application' });
    }
  });
});