define("fcs-dashboard/pods/components/location-group/company-role/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/table-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsTableComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var observer = _ember["default"].observer;
  var isEqual = _ember["default"].isEqual;
  var run = _ember["default"].run;
  var A = _ember["default"].A;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], _fcsDashboardMixinsTableComponent["default"], {
    tagName: '',
    companies: [],
    locationTypes: [],
    _currentLocation: undefined,
    _sortCriteria: ['name'],
    _sortedCompanies: computed.sort('companies', '_sortCriteria'),
    _sortedRoles: computed.sort('roles', '_sortCriteria'),
    _locations: computed(function () {
      if (this.get('companyRole.companyId') !== undefined) {
        return this.get('store').query('location', { companyId: this.get('companyRole.companyId'), status: true });
      } else {
        return A();
      }
    }),
    data: computed('_locations', function () {
      return this.get('_locations').sortBy('name');
    }),
    selectedModels: computed.alias('companyRole.locationObjects'),
    showLocationsModal: false,
    _showLocation: false,
    locationsReady: false,
    _selectedRole: undefined,
    _selectedCompany: undefined,
    _selectedReportsTo: undefined,
    _isRoleTypeVendor: computed('_selectedRole', function () {
      if (this.get('_selectedRole.id') < 5) {
        return true;
      } else {
        this.set('_selectedReportsTo', null);
        return false;
      }
    }),
    selectedLocationsCount: computed('companyRole.locationObjects.length', function () {
      if (this.get('companyRole.locationObjects') !== undefined && this.get('companyRole.locationObjects.length') > 0) {
        return this.get('companyRole.locationObjects.length');
      } else {
        return '0';
      }
    }),
    columns: computed('filterClass', function () {
      return [{
        template: "meta-product-catalog/actions/row-select-checkbox",
        useFilter: false,
        mayBeHidden: false,
        templateForSortCell: "meta-product-catalog/actions/row-select-all-checkbox"
      }, {
        propertyName: 'name',
        title: this.get('intl').t('location.list.table.location')
      }, {
        propertyName: 'locationType.name',
        title: this.get('intl').t('location.list.table.type')
      }];
    }),
    /**
     * Ember's hook method
     */
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get('companyRole').addObserver('locationObjects.[]', function () {
        if (this.get('locationObjects') !== null && this.get('locationObjects.length') > 0) {
          this.set('locations', this.get('locationObjects').mapBy('id'));
        }
      });

      if (this.get('_locations').then !== undefined) {
        this.get('_locations').then(function (content) {
          run(function () {
            _this.set('_locations', content);
            //initialize companies
            _this.resolveCompanies();
          });
        });
      } else {
        //initialize companies
        this.resolveCompanies();
      }

      var reportsTo = this.get('reportsToCompanies').findBy('id', this.get('companyRole.reportsTo'));
      this.set('_selectedReportsTo', reportsTo);
      //initialize roles
      this.resolveRoles();
    },
    resolveCompanies: function resolveCompanies() {
      this.setCompanies(this.get('companies'));
    },
    resolveRoles: function resolveRoles() {
      this.setSelectedRole();
    },
    setSelectedRole: function setSelectedRole() {
      if (this.get('companyRole.roleId') !== undefined) {
        var role = this.get('roles').findBy('id', this.get('companyRole.roleId'));
        this.set('_selectedRole', role);
      }
    },
    /**
     * Ember's hook method
     */
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('companyRole').removeObserver('updateLocations');
      this.get('companies').setEach('disabled', false);
    },
    _updateSelectedCompanies: observer('companyRoles.@each.companyId', function () {
      this.updateCompanyStatus(this.get('companies'));
    }),
    updateCompanyStatus: function updateCompanyStatus(companies) {
      var _this2 = this;

      companies.setEach('disabled', false);
      var companyId = this.get('companyRoles').mapBy('companyId');
      this.get('companies').setEach('disabled', false);
      companyId.forEach(function (id) {
        if (id !== undefined) {
          _this2.get('companies').findBy('id', id).set('disabled', true);
        }
      });
    },
    setCompanies: function setCompanies(content) {
      this.updateCompanyStatus(content);
      if (this.get('companyRole.companyId') !== undefined) {
        var company = content.findBy('id', this.get('companyRole.companyId'));
        this.set('_selectedCompany', company);
        this.setSelectedLocations();
      }
    },
    setSelectedLocations: function setSelectedLocations() {
      var _this3 = this;

      this.set('_showLocation', false);
      var selectedLocations = this.get('companyRole.locations');
      selectedLocations.forEach(function (item) {
        _this3.get('selectedModels').addObject(_this3.get('data').findBy('id', item.toString()));
      });
      this.set('locationsReady', true);
      this.set('_showLocation', true);
    },
    setNewLocation: function setNewLocation() {
      var companyId = this.get('_selectedCompany.id');
      var companyName = this.get('_selectedCompany.name');
      this.set('_currentLocation', this.get('store').createRecord('location', { companyId: companyId, companyName: companyName }));
    },
    setLocations: function setLocations() {
      var _this4 = this;

      var locations = this.get('store').query('location', { companyId: this.get('_selectedCompany.id'), status: true });
      if (locations.then !== undefined) {
        locations.then(function (content) {
          run(function () {
            _this4.set('_locations', content);
            _this4.set('locationsReady', true);
          });
        });
      } else {
        this.set('_locations', locations);
        this.set('locationsReady', true);
      }
    },
    actions: {
      selectCompany: function selectCompany(item) {
        this.set('_showLocation', true);
        this.set('locationsReady', false);
        this.set('_locations', null);
        this.get('companyRole.locations').clear();
        this.get('companyRole.locationObjects').clear();
        this.set('companyRole.companyId', item.get('id'));
        this.set('_selectedCompany', item);
        this.setLocations();
      },
      selectReportsTo: function selectReportsTo(item) {
        this.set('_selectedReportsTo', item);
        this.set('companyRole.reportsTo', item.id);
      },
      selectRole: function selectRole(item) {
        var _this5 = this;

        var existsProducerOwner = false;
        this.get('companyRoles').forEach(function (role) {
          if (isEqual(item.id, '13') && !isEqual(_this5.get('companyRole.companyId'), role.get('companyId')) && isEqual(role.get('roleId'), '13')) {
            existsProducerOwner = true;
          }
        });

        if (existsProducerOwner) {
          this.set('companyRole.roleId', null);
          this.set('_selectedRole', null);
          this.get('notify').alert(this.get('intl').t('locationGroups.details.errors.producerOwnerExists'), { closeAfter: 5000 });
        } else {
          this.set('companyRole.roleId', item.id);
          this.set('_selectedRole', item);
        }
      },
      remove: function remove() {
        this.sendAction('remove', this.get('companyRole'));
      },
      createLocation: function createLocation() {
        this.setLocations();
        this.setNewLocation();
      },
      openLocationsModal: function openLocationsModal() {
        this.setSelectedLocations();
        this.setNewLocation();
        this.set('showLocationsModal', true);
      },
      addLocations: function addLocations() {
        if (this.get('selectedModels.length') === 0) {
          this.get('companyRole.locations').clear();
        }
        this.set('showLocationsModal', false);
      },
      closeModalLocations: function closeModalLocations() {
        this.set('showLocationsModal', false);
        this.get('companyRole').rollbackAttributes();
        this.get('selectedModels').clear();
        this.setSelectedLocations();
        this.get('notify').alert(this.get('intl').t('locationGroups.details.errors.selectedLocationsReverted'), { closeAfter: 3000 });
      }
    }
  });
});