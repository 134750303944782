define('fcs-dashboard/pods/payment-result-report/model', ['exports', 'ember-data/model', 'model-fragments/attributes', 'ember-data/attr'], function (exports, _emberDataModel, _modelFragmentsAttributes, _emberDataAttr) {
  exports['default'] = _emberDataModel['default'].extend({
    reportItemsByCompany: (0, _modelFragmentsAttributes.fragmentArray)('paymentResultReport/reportItemsByCompany', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    reportItemsByDay: (0, _modelFragmentsAttributes.fragmentArray)('paymentResultReport/reportItemsByDay', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    reportCount: (0, _emberDataAttr['default'])('number'),
    outputFileName: (0, _emberDataAttr['default'])('string')

  });
});