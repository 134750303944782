define('fcs-dashboard/pods/components/sponsor-loaded-fund-usage-report/report-result/component', ['exports', 'ember', 'fcs-dashboard/mixins/base-component'], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend(_fcsDashboardMixinsBaseComponent['default'], {
    data: null,
    dateMenuColumnTitle: computed(function () {
      var d = this.data;
      var summarizeBy = d['summarizeBy'];
      if (summarizeBy === '3' || summarizeBy === '4') {
        return 'Menu';
      } else if (summarizeBy === '5' || summarizeBy === '6') {
        return 'Location';
      } else {
        return 'Date';
      }
    }),
    _columns: computed(function () {
      return [{
        title: this.get('dateMenuColumnTitle'),
        className: 'centerContent',
        propertyName: 'summarizeBy',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.sponsorLoadedFundUsageReport.headers.sponsorLoadedFundName'),
        className: 'centerContent',
        propertyName: 'sponsorLoadedFundName',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.sponsorLoadedFundUsageReport.headers.amountSpent'),
        className: 'amount-cell',
        template: 'sponsorLoadedFundUsageReport/amountCell',
        propertyName: 'amountSpent',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.sponsorLoadedFundUsageReport.headers.paymentCount'),
        className: 'centerContent',
        propertyName: 'paymentCount',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.sponsorLoadedFundUsageReport.headers.wristbandsUsedCount'),
        className: 'centerContent',
        propertyName: 'wristbandsUsedCount',
        disableSorting: true
      }];
    })
  });
});