define('fcs-dashboard/pods/gateway/new/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'fcs-dashboard/mixins/common-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _fcsDashboardMixinsCommonMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _fcsDashboardMixinsCommonMixin['default'], {
    model: function model() {
      return this.store.createRecord('gateway');
    },
    renderTemplate: function renderTemplate() {
      this.render('gateway/new', { into: 'application' });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('saveEnabled', true);
    },
    actions: {
      willTransition: function willTransition() {
        this.get('currentModel').rollbackAttributes();
      },
      cancel: function cancel(item) {
        item.rollbackAttributes();
        this.transitionTo('gateway');
      },
      save: function save(item) {
        var _this = this;

        this.controller.set('saveEnabled', false);
        var validCredentials = true;
        this.send('cleanMessages');
        //validate credentials
        item.get('credentials').forEach(function (credential) {
          if (!credential.get('validations.isValid')) {
            validCredentials = false;
            credential.get('validations.errors').forEach(function (error) {
              var errorMessage = _this.get('notify').alert(error.get('message'), { closeAfter: 5000 });
              _this.get('errorMessages').addObject(errorMessage);
            });
          }
          credential.set('value', credential.get('value').trim());
        });
        if (item.get('validations.isValid') && validCredentials) {
          item.save().then(function () {
            _this.controller.set('saveEnabled', true);
            _this.get('notify').success(_this.get('intl').t('gateways.details.errors.successSave', { name: item.get('name') }));
            _this.transitionTo('gateway');
          })['catch'](function (error) {
            var message = 'gateways.details.errors.savingError';
            if (error !== undefined) {
              message = 'gateways.details.errors.' + error.errors.get(0).detail;
            }
            var errorMessage = _this.get('notify').alert(_this.get('intl').t(message, { name: item.get('name') }), { closeAfter: 5000 });
            _this.get('errorMessages').addObject(errorMessage);
          });
        } else {
          this.controller.set('saveEnabled', true);
          item.get('validations.errors').forEach(function (error) {
            var errorMessage = _this.get('notify').alert(error.get('message'), { closeAfter: 5000 });
            _this.get('errorMessages').addObject(errorMessage);
          });
        }
      }
    }
  });
});