define("fcs-dashboard/pods/components/menu/menu-wizard/menu-information/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/common-mixin"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsCommonMixin) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var Logger = _ember["default"].Logger;
  var isEmpty = _ember["default"].isEmpty;
  var htmlSafe = _ember["default"].String.htmlSafe;
  var run = _ember["default"].run;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], _fcsDashboardMixinsCommonMixin["default"], {
    //todo get these from server in the future
    currencies: ['USD'],
    menuName: '',
    menuCurrency: '',
    adminCode: '',
    cashlessAdminCode: '',
    voidAdminCode: '',
    linkLocations: false,
    selectedLocations: [],
    availableLocations: computed(function () {
      return this.get('store').query('location', { companyId: this.get('menu.company.id'), status: true });
    }),
    showCashlessCode: false,
    selectedLocationsCount: computed('menu.menuLocations.length', function () {
      var locationsCount = 0;
      if (this.get('menu.menuLocations.length') > 0) {
        locationsCount += this.get('menu.menuLocations.length');
      }
      return locationsCount;
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('menu.cashlessAdminCode')) {
        this.set('showCashlessCode', true);
      }

      if (this.get('availableLocations').then !== undefined) {
        this.get('availableLocations').then(function (content) {
          run(function () {
            _this.set('availableLocations', content);
          });
        });
      } else {
        this.set('availableLocations', this.get('availableLocations'));
      }
    },
    /**
     * Method that cycles the menuLocations to get and set the selectedLocations
     */
    updateSelectedLocations: function updateSelectedLocations() {
      var _this2 = this;

      // Initialize variables
      var currentMenu = this.get('menu');
      this.get('selectedLocations').clear();

      Logger.debug('setting up data for menuLocation modal');
      // For each location in the menuLocations, add it to the selectedLocations list
      currentMenu.get('menuLocations').forEach(function (menuLocation) {
        var location = _this2.get('availableLocations').findBy('id', menuLocation.get('locationObject.id'));
        if (location !== undefined) {
          _this2.get('selectedLocations').addObject(location);
        }
      });

      // Sets the selected company from the menu
      this.set('selectedCompany', currentMenu.get('company'));
      // Show selected locations modal
      this.set('linkLocations', true);
    },
    actions: {
      currencySelected: function currencySelected(currency) {
        this.set('menu.currency', currency);
      },
      nextStep: function nextStep() {
        this.sendAction('cleanMessages');
        var isValid = true;
        var errorMessage = undefined;
        if (isEmpty(this.get('menu.name'))) {
          isValid = false;
          this.set('menuName', htmlSafe('has-error alert-danger'));
          errorMessage = this.get('notify').alert(this.get('intl').t('menu.builder.steps.stepOne.errors.emptyName'), { closeAfter: 5000 });
          this.get('errorMessages').addObject(errorMessage);
        }

        if (isEmpty(this.get('menu.currency'))) {
          this.set('menuCurrency', htmlSafe('has-error alert-danger'));
          errorMessage = this.get('notify').alert(this.get('intl').t('menu.builder.steps.stepOne.errors.currencyNotSelected'), { closeAfter: 5000 });
          this.get('errorMessages').addObject(errorMessage);
          isValid = false;
        }

        var reg = new RegExp('^\\d{5}$');

        if (isEmpty(this.get('menu.adminCode'))) {
          this.set('adminCode', htmlSafe('has-error alert-danger'));
          errorMessage = this.get('notify').alert(this.get('intl').t('menu.builder.steps.stepOne.errors.adminCode'), { closeAfter: 5000 });
          this.get('errorMessages').addObject(errorMessage);
          isValid = false;
        } else if (!reg.test(this.get('menu.adminCode'))) {
          this.set('adminCode', htmlSafe('has-error alert-danger'));
          errorMessage = this.get('notify').alert(this.get('intl').t('menu.builder.steps.stepOne.errors.adminCodeNotNumeric'), { closeAfter: 5000 });
          this.get('errorMessages').addObject(errorMessage);
          isValid = false;
        }

        if (this.get('showCashlessCode')) {
          if (isEmpty(this.get('menu.cashlessAdminCode'))) {
            this.set('cashlessAdminCode', htmlSafe('has-error alert-danger'));
            errorMessage = this.get('notify').alert(this.get('intl').t('menu.builder.steps.stepOne.errors.cashlessAdminCode'), { closeAfter: 5000 });
            this.get('errorMessages').addObject(errorMessage);
            isValid = false;
          } else if (!reg.test(this.get('menu.cashlessAdminCode'))) {
            this.set('cashlessAdminCode', htmlSafe('has-error alert-danger'));
            errorMessage = this.get('notify').alert(this.get('intl').t('menu.builder.steps.stepOne.errors.cashlessAdminCodeNotNumeric'), { closeAfter: 5000 });
            this.get('errorMessages').addObject(errorMessage);
            isValid = false;
          }
        }

        if (isEmpty(this.get('menu.voidAdminCode'))) {
          this.set('voidAdminCode', htmlSafe('has-error alert-danger'));
          errorMessage = this.get('notify').alert(this.get('intl').t('menu.builder.steps.stepOne.errors.voidAdminCode'), { closeAfter: 5000 });
          this.get('errorMessages').addObject(errorMessage);
          isValid = false;
        } else if (!reg.test(this.get('menu.voidAdminCode'))) {
          this.set('voidAdminCode', htmlSafe('has-error alert-danger'));
          errorMessage = this.get('notify').alert(this.get('intl').t('menu.builder.steps.stepOne.errors.voidAdminCodeNotNumeric'), { closeAfter: 5000 });
          this.get('errorMessages').addObject(errorMessage);
          isValid = false;
        }

        this.sendAction('nextStep', isValid);
      },
      previousStep: function previousStep() {
        this.sendAction('previousStep');
      },
      showMenuList: function showMenuList() {
        this.sendAction('showMenuList');
      },
      save: function save() {
        this.sendAction('save');
      },
      openLocationsSelectors: function openLocationsSelectors() {
        var _this3 = this;

        //Validate if the availableLocations promise exists
        // wait for the promise to fulfill and update locations
        // or just update locations
        if (this.get('availableLocations').then !== undefined) {
          this.get('availableLocations').then(function (content) {
            run(function () {
              _this3.updateSelectedLocations();
            });
          });
        } else {
          this.updateSelectedLocations();
        }
      },
      closeLocationsSelectors: function closeLocationsSelectors() {
        this.set('linkLocations', false);
      },
      addLocationSelection: function addLocationSelection() {
        var _this4 = this;

        var menuLocations = _ember["default"].Map.create();
        //create and insert into a map a new menuLocation for each selected location
        this.get('selectedLocations').forEach(function (item) {
          var newMenuLocation = _this4.get('store').createFragment('menuLocation', { location: item.get('id') });
          menuLocations.set(item.get('id'), newMenuLocation);
        });

        //insert old menu locations into the map if they still there
        this.get('menu.menuLocations').forEach(function (menuLocation) {
          if (menuLocations.has(menuLocation.get('location'))) {
            menuLocations.set(menuLocation.get('location'), menuLocation);
          }
        });

        //transform map to an array
        var menuLocationList = [];
        menuLocations.forEach(function (menuLocation) {
          menuLocationList.addObject(menuLocation);
        });

        //set new menuLocations to the selected menu
        this.set('menu.menuLocations', menuLocationList);
        this.set('selectedLocationsCount', menuLocationList.length);
        this.send('closeLocationsSelectors');
      },
      showCashlessCodeChanged: function showCashlessCodeChanged(event) {
        var newVal = event.target.checked;
        this.set('showCashlessCode', newVal);
        if (!this.get('showCashlessCode')) {
          this.set('menu.cashlessAdminCode', '');
        }
      }
    }
  });
});