define('fcs-dashboard/pods/components/sales-report/sales-report-result/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    data: null,
    digitalTipsDeductionsEnabled: computed(function () {
      var dat = this.get('data').data;
      return dat.digitalTipsDeductionsEnabled;
    }),
    _cashDepositData: computed(function () {
      var dat = this.get('data').data;
      var dep = dat.cashDepositReport;
      return [dep];
    }),
    customClasses: {
      tfooterWrapper: "hidden"
    },
    _voidsColumns: computed(function () {
      return [{
        title: 'Product Type',
        className: 'product-type-cell',
        propertyName: 'productTypeName',
        disableSorting: true
      }, {
        title: 'Total',
        className: 'total-cell',
        template: 'salesReport/amountCell',
        propertyName: 'total',
        disableSorting: true
      }];
    }),
    _paymentsColumns: computed(function () {
      return [{
        title: 'Type',
        className: 'type-cell',
        propertyName: 'creditCardTypeName',
        disableSorting: true
      }, {
        title: 'Qty',
        className: 'quantity-cell',
        propertyName: 'quantity',
        disableSorting: true
      }, {
        title: 'Amount (minus tips)',
        className: 'amount-cell',
        template: 'salesReport/amountCell',
        propertyName: 'salesWithoutTips',
        disableSorting: true
      }, {
        title: 'Tips',
        className: 'amount-cell',
        template: 'salesReport/amountCell',
        propertyName: 'tips',
        disableSorting: true
      }, {
        title: 'Surcharge',
        className: 'amount-cell',
        template: 'salesReport/amountCell',
        propertyName: 'surcharge',
        disableSorting: true
      }];
    }),
    _closedLoopColumns: computed(function () {
      return [{
        title: 'Type',
        className: 'type-cell',
        propertyName: 'paymentTypeName',
        disableSorting: true
      }, {
        title: 'Qty',
        className: 'quantity-cell',
        propertyName: 'count',
        disableSorting: true
      }, {
        title: 'Amount Spent',
        className: 'amount-cell',
        template: 'salesReport/amountCell',
        propertyName: 'amountSpent',
        disableSorting: true
      }];
    }),
    _nonCashTableColumns: computed(function () {
      return [{
        className: 'non-cash-category-cell',
        propertyName: 'paymentType',
        disableSorting: true
      }, {
        title: 'Non Cash Total',
        className: 'non-cash-quantity-cell',
        template: 'salesReport/amountCell',
        propertyName: 'bankTotal',
        disableSorting: true
      }, {
        className: 'non-cash-quantity-cell',
        template: 'salesReport/amountCell',
        propertyName: 'discounts',
        disableSorting: true
      }, {
        className: 'non-cash-quantity-cell',
        template: 'salesReport/amountCell',
        propertyName: 'comps',
        disableSorting: true
      }, {
        className: 'non-cash-quantity-cell',
        template: 'salesReport/amountCell',
        propertyName: 'spills',
        disableSorting: true
      }, {
        className: 'non-cash-quantity-cell',
        template: 'salesReport/amountCell',
        propertyName: 'tips',
        disableSorting: true
      }, {
        className: 'non-cash-total-cell',
        title: 'Non Cash Gross Sales',
        template: 'salesReport/amountCell',
        propertyName: 'grossSales',
        disableSorting: true
      }];
    }),
    _salesByProductTableColumns: computed(function () {
      return [{
        className: 'sales-by-product-cell',
        propertyName: 'productTypeName',
        disableSorting: true
      }, {
        className: 'sales-by-product-cell',
        propertyName: 'netSales',
        template: 'salesReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-product-cell',
        propertyName: 'taxes',
        template: 'salesReport/amountCell',
        disableSorting: true
      }, {
        title: 'Comps Due to Decline',
        className: 'sales-by-product-cell',
        propertyName: 'declines',
        template: 'salesReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-product-cell',
        propertyName: 'discounts',
        template: 'salesReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-product-cell',
        propertyName: 'coupons',
        template: 'salesReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-product-cell',
        propertyName: 'comps',
        template: 'salesReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-product-cell',
        propertyName: 'spills',
        template: 'salesReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-product-cell',
        propertyName: 'grossSales',
        template: 'salesReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-product-cell',
        propertyName: 'voids',
        template: 'salesReport/amountCell',
        disableSorting: true
      }];
    }),
    _cashDepositTableColumns: computed(function () {
      return [{
        className: 'cash-deposit-quantity-cell',
        template: 'salesReport/amountCell',
        propertyName: 'cashDeposit',
        disableSorting: true
      }, {
        className: 'cash-deposit-quantity-cell',
        template: 'salesReport/amountCell',
        propertyName: 'creditCardPayments',
        disableSorting: true
      }, {
        className: 'cash-deposit-quantity-cell',
        template: 'salesReport/amountCell',
        propertyName: 'rfidPayments',
        disableSorting: true
      }, {
        title: 'Coupon Discounts',
        className: 'cash-deposit-quantity-cell',
        template: 'salesReport/amountCell',
        propertyName: 'couponDiscount',
        disableSorting: true
      }, {
        title: 'Employee Discounts',
        className: 'cash-deposit-quantity-cell',
        template: 'salesReport/amountCell',
        propertyName: 'employeeDiscount',
        disableSorting: true
      }, {
        className: 'cash-deposit-quantity-cell',
        template: 'salesReport/amountCell',
        propertyName: 'voids',
        disableSorting: true
      }, {
        className: 'cash-deposit-quantity-cell',
        template: 'salesReport/amountCell',
        propertyName: 'comps',
        disableSorting: true
      }, {
        className: 'cash-deposit-quantity-cell',
        template: 'salesReport/amountCell',
        propertyName: 'spills',
        disableSorting: true
      }, {
        className: 'cash-deposit-quantity-cell',
        template: 'salesReport/amountCell',
        propertyName: 'refunds',
        disableSorting: true
      }, {
        className: 'cash-deposit-quantity-cell',
        template: 'salesReport/amountCell',
        propertyName: 'digitalTips',
        disableSorting: true
      }, {
        className: 'cash-deposit-quantity-cell',
        template: 'salesReport/amountCell',
        propertyName: 'grossSales',
        disableSorting: true
      }, {
        className: 'cash-deposit-quantity-cell',
        template: 'salesReport/amountCell',
        propertyName: 'customFeesAmount',
        disableSorting: true
      }, {
        className: 'cash-deposit-quantity-cell',
        template: 'salesReport/amountCell',
        propertyName: 'coinRoundedDelta',
        disableSorting: true
      }, {
        className: 'cash-deposit-quantity-cell',
        template: 'salesReport/amountCell',
        propertyName: 'cashDiscountedAmount',
        disableSorting: true
      }];
    })
  });
});