define("fcs-dashboard/pods/embeddable-report/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin", "fcs-dashboard/mixins/base-component", "moment"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _fcsDashboardMixinsBaseComponent, _moment) {
  var Route = _ember["default"].Route;
  var A = _ember["default"].A;
  exports["default"] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], _fcsDashboardMixinsBaseComponent["default"], {
    model: function model(params) {
      return this.get('store').findRecord('embeddableReport', params.report_id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('reportOptions', this.get('accountSession.reportOptions'));
      controller.set('authToken', this.get('session.data.authenticated.access_token'));
      controller.set('refreshResults', false);
    },
    actions: {
      search: function search(queryOptions) {
        var _this = this;

        this.controller.set('refreshResults', true);
        var query = queryOptions.getProperties(['reportOutput']);

        //set property to TRUE
        this.controller.set('isLoading', true);
        this.controller.set('showReport', false);
        this.controller.set('isShowExportButton', false);
        this.controller.set('showAllEvents', false);

        var params = [];
        if (queryOptions.get('selectedLocationGroup') !== undefined) {
          // Filter selected locationGroups
          if (queryOptions.get('selectedLocationGroup.length') > 0) {
            params['locationGroups'] = queryOptions.get('selectedLocationGroup').mapBy('id');
            this.controller.set('filterLocationGroups', true);
          } else {
            this.controller.set('filterLocationGroups', false);
          }
        } else {
          this.controller.set('filterLocationGroups', false);
        }
        params['startDate'] = (0, _moment["default"])(queryOptions.get('fromDate')).format("YYYY-MM-DD HH:mm:ss").toString();
        params['endDate'] = (0, _moment["default"])(queryOptions.get('toDate')).format("YYYY-MM-DD HH:mm:ss").toString();

        // Filter selected menus
        if (queryOptions.get('menus.length') > 0) {
          params['menus'] = queryOptions.get('menus').mapBy('id');
          this.controller.set('filterMenus', true);
        } else {
          this.controller.set('filterMenus', false);
        }
        // Filter selected locations
        if (queryOptions.get('locations.length') > 0) {
          (function () {
            var locations = A();
            queryOptions.get('locations').forEach(function (location) {
              locations.addObject(location.id.toString());
            });
            params['locations'] = locations;
            _this.controller.set('filterLocations', true);
          })();
        } else {
          this.controller.set('filterLocations', false);
        }
        // Filter selected vendors/companies
        if (queryOptions.get('vendors.length') > 0) {
          params['vendor'] = queryOptions.get('vendors');
          this.controller.set('filterCompanies', true);
        } else {
          this.controller.set('filterCompanies', false);
        }
        var reportName = "";

        var sumByType = parseInt(queryOptions.get('summarizeByType.id'));
        if (sumByType === 0) {
          reportName = 'ByEvent';
        } else if (sumByType === 1) {
          reportName = 'ByMenu';
        } else if (sumByType === 2) {
          reportName = 'ByCompany';
        } else if (sumByType === 3) {
          reportName = 'ByLocation';
        }

        var sumByTime = parseInt(queryOptions.get('summarizeByTime.id'));
        if (sumByTime === 0) {
          params['byHour'] = 'Normal';
        } else if (sumByTime === 1) {
          reportName += 'ByDay';
          params['byHour'] = 'Normal';
        } else if (sumByTime === 2) {
          reportName += 'ByHour';
          params['byHour'] = 'Normal';
        } else if (sumByTime === 3) {
          reportName += 'ByHour';
          params['byHour'] = 'Blocked';
        }

        params['reportName'] = reportName;
        query['reportId'] = queryOptions.get('reportId');

        //Remove and recommit line once real report is built
        params['reportName'] = "AllFields";

        this.store.queryRecord('tableauToken', query).then(function (token) {
          _this.controller.set('token', token);
          _this.controller.set('params', params);
          _this.controller.set('isLoading', false);
          _this.controller.set('showReport', true);
        });
      },
      hideReportResults: function hideReportResults() {
        this.controller.set('showReport', false);
      },
      willTransition: function willTransition() {
        window.stop();
        return true;
      }
    }
  });
});