define("fcs-dashboard/pods/components/search/order-details/component", ["exports", "ember", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var isEmpty = _ember["default"].isEmpty;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], {
    showModal: false,
    isLoading: false,
    waitingResponse: false,
    _isPaymentEmailsEmpty: computed('order.emails', function () {
      return this.get('order.emails.lenght') === 1 || isEmpty(this.get('order.emails.lenght'));
    }),
    _paymentEmail: computed(function () {
      return this.get('order.emails').get(0);
    }),
    _disableSendEmailButton: computed('_paymentEmail', function () {
      return isEmpty(this.get('_paymentEmail'));
    }),
    _disableUnlockTabButton: computed('order.tabName', function () {
      return this.get('order.tabName') === null || this.get('order.tabName') === undefined || isEmpty(this.get('order.tabName'));
    })
  });
});