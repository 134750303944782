define('fcs-dashboard/pods/components/table-header-row-filtering/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: '',
    filterString: '',
    filterOptions: [],
    filter: null,
    options: computed('filterOptions', function () {
      return this.get('filterOptions').mapBy('label');
    }),
    actions: {
      optionChange: function optionChange(option) {
        if (option === null) {
          option = '';
        }
        this.set('filterString', option);
        this.filter && this.filter();
      }
    }
  });
});
/**
 * Component definition for the table-header-row-filtering component
 */