define("fcs-dashboard/pods/components/trait/trait-list/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/table-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsTableComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var observer = _ember["default"].observer;
  var Logger = _ember["default"].Logger;
  var isEmpty = _ember["default"].isEmpty;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], _fcsDashboardMixinsTableComponent["default"], {
    refreshList: true,
    traitName: '',
    _disableAddAction: computed('lockedCompany', function () {
      return this.get('isBRUser') && this.get('lockedCompany') === undefined;
    }),
    columns: computed(function () {
      var hideCompanyColumn = !this.get('isBRUser');
      return [{
        propertyName: 'name',
        filteredBy: 'name',
        title: this.get('intl').t('trait.list.name')
      }, {
        propertyName: 'companyName',
        filteredBy: 'companyName',
        title: this.get('intl').t('trait.list.company'),
        isHidden: hideCompanyColumn,
        className: 'companyNameRow'
      }, {
        title: '',
        template: "trait/delete-cell",
        className: 'editRow'
      }];
    }),
    _filteredData: [],
    _checkCompanyLock: observer('lockedCompany', function () {
      Logger.debug('calling refresh');
      this.sendAction('refreshAction');
    }),
    actions: {
      deleteTrait: function deleteTrait(item) {
        item.set('deleted', true);
        this.sendAction('save', item);
      },
      saveAction: function saveAction() {
        var companyId = undefined;
        if (this.get('isBRUser')) {
          if (this.get('lockedCompany') !== undefined) {
            companyId = this.get('lockedCompany.id');
          }
        } else {
          companyId = this.get('loggedAccount.company.id');
        }

        if (companyId !== undefined && companyId !== null) {
          if (this.get('_filteredData').filterBy('name', this.get('traitName')).length > 0) {
            this.get('notify').alert(this.get('intl').t('trait.list.errors.duplicatedTraitName', { name: this.get('traitName') }), { closeAfter: 5000 });
          } else {
            if (!isEmpty(this.get('traitName')) && this.get('traitName').trim().length > 0) {
              var trait = this.get('store').createRecord('trait', { name: this.get('traitName').trim(), companyId: companyId });
              this.set('traitName', '');
              this.sendAction('save', trait);
            } else {
              this.get('notify').alert(this.get('intl').t('trait.list.errors.blankTraitError'), { closeAfter: 3000 });
            }
          }
        } else {
          this.get('notify').alert(this.get('intl').t('trait.list.errors.notLockedToCompanyError'), { closeAfter: 3000 });
        }
      },
      inputKeyUp: function inputKeyUp(value) {
        this.set('traitName', value);
        this.set('_filteredData', this._filterData(this.get('traits')));
      }
    }
  });
});