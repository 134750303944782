define('fcs-dashboard/pods/product-mix-report/serializer', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var guidFor = _ember['default'].guidFor;
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      payload.productMixReport.id = guidFor(payload);
      payload.productMixReport.productMixes.forEach(function (productMix) {
        return productMix.id = guidFor(productMix);
      });
      payload.productMixReport.productMixLocations.forEach(function (productMixLocation) {
        productMixLocation.id = guidFor(productMixLocation);
        productMixLocation.productMixes.forEach(function (productMix) {
          return productMix.id = guidFor(productMix);
        });
      });
      return this._super.apply(this, arguments);
    }
  });
});