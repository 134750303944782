define("fcs-dashboard/pods/menu/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _fcsDashboardMixinsBaseComponent) {
  var Route = _ember["default"].Route;
  var Logger = _ember["default"].Logger;
  var isEqual = _ember["default"].isEqual;
  exports["default"] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], _fcsDashboardMixinsBaseComponent["default"], {
    isReload: false,
    company: null,
    model: function model() {
      _ember["default"].Logger.debug('getting data!');

      var company = undefined;
      if (this.get('lockedCompany') !== undefined) {
        company = this.get('lockedCompany.id');
      } else if (!this.get('isBRUser')) {
        company = this.get('loggedAccount.company.id');
      }
      this.set('company', company);

      return this.store.query('menu', { companyId: company, status: 'Active' });
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      if (this.get('session.isAuthenticated')) {
        this.store.findAll('tenderType');
      }
    },
    afterModel: function afterModel() {
      this._super.apply(this, arguments);
      if (this.get('isReportingUser')) {
        this.transitionTo('');
      } else {
        var company = this.get('company');
        if (!company) {
          if (!this.get('isBRUser') && !this.get('isReload')) {
            this.set('isReload', true);
            this.refresh();
          }
        }
      }
    },
    transitionToMenu: function transitionToMenu() {
      Logger.debug('Transitioning to index.');
      this.refresh();
      this.transitionTo('menu.index');
    },
    cleanupMenu: function cleanupMenu() {
      Logger.debug('Cleaning menu and relationships.');
      this.store.unloadAll('product');
      this.store.unloadAll('page');
      this.store.unloadAll('category');
      this.store.unloadAll('promotion');
      this.store.unloadAll('menu');
    },
    unlockMenu: function unlockMenu(menuId) {
      var _this = this;

      var endpoint = "/api/v1/menus/unlock";
      var _authToken = this.get('session.data.authenticated.access_token');
      var options = {
        url: endpoint,
        type: 'POST',
        data: { menuId: menuId }
      };
      options.beforeSend = function (xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + _authToken);
      };
      options.success = function () {
        Logger.debug('Menu unlocked!.');
        _this.send('cleanMessages');
        _this.transitionToMenu();
      };
      options.error = function () {
        _this.get('errorMessages').addObject(_this.get('notify').alert(_this.get('intl').t('menu.builder.steps.stepSix.errors.saveError'), { closeAfter: 5000 }));
      };
      _ember["default"].$.ajax(options);
    },
    actions: {
      willTransition: function willTransition() {
        window.stop();
        return true;
      },
      leaveWizard: function leaveWizard(menu, transition) {
        if (!menu.get('isNew')) {
          this.unlockMenu(menu.get("id"));
        }
        this.cleanupMenu();
        if (isEqual(transition.intent.name, 'menu')) {
          this.transitionToMenu();
        } else {
          transition.retry();
        }
      },
      cancel: function cancel(menu) {
        if (!menu.get('isNew')) {
          this.unlockMenu(menu.get("id"));
        }
        this.cleanupMenu();
        this.transitionToMenu();
      },
      unlockMenu: function unlockMenu(menuId) {
        this.unlockMenu(menuId);
      },
      saveMenu: function saveMenu(menu, shouldClean) {
        var _this2 = this;

        Logger.debug('Saving menu.');
        if (this.get('lockedCompany')) {
          menu.set('company', this.get('lockedCompany'));
        }
        menu.save().then(function () {
          Logger.debug('Menu saved!.');
          _this2.send('cleanMessages');
          _this2.get('notify').success(_this2.get('intl').t('menu.builder.steps.stepSix.errors.menuSave'));
          if (shouldClean) {
            _this2.cleanupMenu();
          }
          _this2.transitionToMenu();
        })["catch"](function (error) {
          var errorMessage = undefined;
          // Catch error and return proper error message
          if (error.errors[0].detail === 'duplicatedMenuNameError') {
            errorMessage = _this2.get('notify').alert(_this2.get('intl').t("menu.builder.steps.stepSix.errors." + error.errors[0].detail), { closeAfter: 5000 });
          } else {
            errorMessage = _this2.get('notify').alert(_this2.get('intl').t('menu.builder.steps.stepSix.errors.saveError'), { closeAfter: 5000 });
          }
          _this2.get('errorMessages').addObject(errorMessage);
        });
      },
      copyMenu: function copyMenu(menuId, copyName) {
        var _this3 = this;

        var endpoint = "/api/v1/menus/copy";
        var _authToken = this.get('session.data.authenticated.access_token');
        var options = {
          url: endpoint,
          type: 'POST',
          data: { menuId: menuId, name: copyName }
        };
        options.beforeSend = function (xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + _authToken);
        };
        options.success = function () {
          Logger.debug('Menu saved!.');
          _this3.send('cleanMessages');
          _this3.get('notify').success(_this3.get('intl').t('menu.builder.steps.stepSix.errors.menuCopy'));
          _this3.transitionToMenu();
        };
        options.error = function (error) {
          var errorMessage = undefined;
          // Catch error and return proper error message
          if (error.responseText === "duplicatedMenuNameError") {
            errorMessage = _this3.get('notify').alert(_this3.get('intl').t("menu.builder.steps.stepSix.errors." + error.responseText), { closeAfter: 5000 });
          } else {
            errorMessage = _this3.get('notify').alert(_this3.get('intl').t('menu.builder.steps.stepSix.errors.saveError'), { closeAfter: 5000 });
          }
          _this3.get('errorMessages').addObject(errorMessage);
        };
        _ember["default"].$.ajax(options);
      },
      pushMenu: function pushMenu(menuId, locations) {
        var _this4 = this;

        var endpoint = "/api/v1/menus/push";
        var _authToken = this.get('session.data.authenticated.access_token');
        var options = {
          url: endpoint,
          type: 'POST',
          data: { menuId: menuId, locations: locations }
        };
        options.beforeSend = function (xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + _authToken);
        };
        options.success = function () {
          Logger.debug('Menu saved!.');
          _this4.send('cleanMessages');
          _this4.get('notify').success(_this4.get('intl').t('menu.builder.steps.stepSix.errors.pushMenu'));
          _this4.transitionToMenu();
        };
        options.error = function () {
          var errorMessage = undefined;
          // Catch error and return proper error message
          errorMessage = _this4.get('notify').alert(_this4.get('intl').t('menu.builder.steps.stepSix.errors.saveError'), { closeAfter: 5000 });
          _this4.get('errorMessages').addObject(errorMessage);
        };
        _ember["default"].$.ajax(options);
      },
      editMenu: function editMenu(menuId) {
        var _this5 = this;

        var endpoint = "/api/v1/menus/lockStatus";
        var _authToken = this.get('session.data.authenticated.access_token');
        var options = {
          url: endpoint,
          type: 'POST',
          data: { menuId: menuId }
        };
        options.beforeSend = function (xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + _authToken);
        };
        options.success = function (response) {
          if (response.lockStatus.code === 1) {
            var errorMessage = undefined;
            // Catch error and return proper error message
            errorMessage = _this5.get('notify').alert(_this5.get('intl').t('menu.list.errors.lockedMenu', {
              menuName: response.lockStatus.menuName,
              userName: response.lockStatus.userName
            }), { closeAfter: 5000 });

            _this5.get('errorMessages').addObject(errorMessage);
          } else {
            _this5.transitionTo('menu.edit', menuId);
          }
        };
        options.error = function () {
          var errorMessage = undefined;
          // Catch error and return proper error message
          errorMessage = _this5.get('notify').alert(_this5.get('intl').t('menu.builder.steps.stepSix.errors.saveError'), { closeAfter: 5000 });

          _this5.get('errorMessages').addObject(errorMessage);
        };
        _ember["default"].$.ajax(options);
      },
      toggleMenusArchive: function toggleMenusArchive(menuIds, archive) {
        var _this6 = this;

        var endpoint = "/api/v1/menus/toggleMenusArchive";
        var _authToken = this.get('session.data.authenticated.access_token');
        var options = {
          url: endpoint,
          type: 'POST',
          data: { menuIds: menuIds,
            archive: archive }
        };
        options.beforeSend = function (xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + _authToken);
        };
        options.success = function () {
          Logger.debug('Menus toggle');
          _this6.send('cleanMessages');
          _this6.refresh();
        };
        options.error = function () {
          _this6.get('errorMessages').addObject(_this6.get('notify').alert(_this6.get('intl').t('menu.builder.steps.stepSix.errors.saveError'), { closeAfter: 5000 }));
        };
        _ember["default"].$.ajax(options);
      },
      refresh: function refresh() {
        Logger.debug('refreshing');
        this.refresh();
      }
    }
  });
});