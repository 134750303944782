define('fcs-dashboard/pods/contact/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'fcs-dashboard/mixins/base-component'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _fcsDashboardMixinsBaseComponent) {
  var Route = _ember['default'].Route;
  var Logger = _ember['default'].Logger;
  exports['default'] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _fcsDashboardMixinsBaseComponent['default'], {
    actions: {
      willTransition: function willTransition(transition) {
        window.stop();
        if (!this._isValidTransition(transition.targetName)) {
          Logger.debug('Aborting transition invalid route');
          this.transitionTo('index');
        } else {
          return true;
        }
      }
    }
  });
});