define("fcs-dashboard/pods/components/menu/menu-wizard/products-table/product-row/modifiers/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/table-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsTableComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var isBlank = _ember["default"].isBlank;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], _fcsDashboardMixinsTableComponent["default"], {
    traitList: [],
    refreshInput: true,
    traitName: '',
    newComboModifiers: null,
    newModifiers: [],
    selectedColumns: computed(function () {
      return [{
        title: " ",
        template: "modifier/delete-action"
      }, {
        propertyName: 'description',
        title: " "
      }, {
        propertyName: 'price',
        title: " "
      }];
    }),
    availableColumns: computed(function () {
      return [{
        title: " ",
        template: "modifier/add-action"
      }, {
        propertyName: 'description',
        title: " "
      }, {
        propertyName: 'price',
        title: " "
      }];
    }),
    title: computed(function () {
      return this.get('intl').t('menu.builder.steps.stepSix.productTable.modal.modifiers.title') + " " + this.get('product.productCatalog.name');
    }),
    selectedAction: null,
    selectedPlacement: null,
    selectedTrait: null,
    modifierPrice: 0,
    init: function init() {
      this._super.apply(this, arguments);
      this.get('traitList').addObjects(this.get('traits'));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('newModifiers').clear();
      this.get('traitList').clear();
    },
    _saveModifier: function _saveModifier(trait) {
      var _this = this;

      var modifier = this.get('store').createRecord('modifier', {
        action: this.get('selectedAction'),
        placement: this.get('selectedPlacement'),
        trait: trait,
        price: this.get('modifierPrice'),
        menuId: this.get('menu.id')
      });
      var isValid = true;
      if (!modifier.get('validations.isValid')) {
        isValid = false;
        modifier.get('validations.errors').forEach(function (error) {
          var errorMessage = _this.get('notify').alert(error.get('message'), { closeAfter: 5000 });
          _this.get('errorMessages').addObject(errorMessage);
        });
      }
      if (isValid) {
        this.set('refreshInput', false);
        if (this.get('newComboModifiers') !== null) {
          this.get('newComboModifiers').addObject(modifier);
        }
        this.get('newModifiers').addObject(modifier);
        this.get('product.modifiers').addObject(modifier);
        this.get('menu.modifiers').addObject(modifier);
        this.set('traitName', '');
        this.set('selectedTrait', null);
        this.set('refreshInput', true);
      }
    },
    actions: {
      closeModalModifiers: function closeModalModifiers() {
        this.get('product.modifiers').removeObjects(this.get('newModifiers'));
        this.set('showModalModifiers', false);
        this.sendAction('closeModalModifiers');
      },
      selectTrait: function selectTrait(selectedTrait) {
        this.set('selectedTrait', selectedTrait);
      },
      addNewModifier: function addNewModifier() {
        var _this2 = this;

        // If the trait is new save it before creating the modifier
        var newTrait = undefined;
        if (this.get('selectedTrait') !== null && this.get('selectedTrait') !== undefined) {
          // Get trait from selection
          newTrait = this.get('selectedTrait');
          // Create modifier using an existing trait
          this._saveModifier(newTrait);
        } else {
          // Verified trait name is not blank
          if (!isBlank(this.get('traitName'))) {
            newTrait = this.get('store').createRecord('trait', {
              name: this.get('traitName'),
              company: this.get('menu.company')
            });
            newTrait.save().then(function (trait) {
              // Add new trait to traits list
              _this2.get('traitList').addObject(trait);
              // Save modifier with new trait
              _this2._saveModifier(trait);
            });
          } else {
            var errorMessage = this.get('notify').alert(this.get('intl').t('menu.builder.steps.stepSix.productTable.modal.modifiers.error.nameBlank'), { closeAfter: 5000 });
            this.get('errorMessages').addObject(errorMessage);
          }
        }
      },
      deleteModifier: function deleteModifier(modifier) {
        // Remove modifiers
        this.get('newModifiers').removeObject(modifier);
        this.get('product.modifiers').removeObject(modifier);
        if (!modifier.get('isNew')) {
          this.get('product.deletedModifiers').addObject(modifier.get('id'));
        }
      },
      addModifier: function addModifier(modifier) {
        if (this.get('newComboModifiers') !== null) {
          this.get('newComboModifiers').addObject(modifier);
        }
        this.get('newModifiers').addObject(modifier);
        this.get('product.modifiers').addObject(modifier);
      },
      saveModifiers: function saveModifiers() {
        this.sendAction('saveModifiers');
      },
      updateTraitName: function updateTraitName(name) {
        this.set('traitName', name);
      }
    }
  });
});