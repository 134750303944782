define("fcs-dashboard/pods/components/pos-token/timer/component", ["exports", "ember", "moment"], function (exports, _ember, _moment) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  exports["default"] = Component.extend({
    delta: 0,
    expirationTime: null,
    _interval: null,
    timer: computed('delta', function () {
      var that = this;
      if (this._interval === null) {
        this._interval = setInterval(function () {
          var currentTime = (0, _moment["default"])();
          that.set('delta', that.get('expirationTime').diff(currentTime, 'minutes'));
        }, 1000);
      }
      if (this.get('delta') > 60 || this.get('delta') < 0) {
        this.send('refreshToken');
        return this.get('delta');
      } else {
        return this.get('delta');
      }
    }),
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      clearInterval(this.get('_interval'));
    },
    actions: {
      refreshToken: function refreshToken() {
        this.sendAction('refreshToken');
      }
    }
  });
});