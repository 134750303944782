define('fcs-dashboard/pods/components/report/search-criteria/component', ['exports', 'ember', 'fcs-dashboard/mixins/base-component'], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var run = _ember['default'].run;
  exports['default'] = Component.extend(_fcsDashboardMixinsBaseComponent['default'], {
    //*******************search options
    _locationGroups: [],
    _menus: [],
    selectedLocationGroup: computed.alias('reportOptions.selectedLocationGroup'),
    selectedMenu: computed.alias('reportOptions.selectedMenu'),
    fromDate: computed.alias('reportOptions.fromDate'),
    toDate: computed.alias('reportOptions.toDate'),
    isTimestampOccurrence: computed.alias('reportOptions.isTimestampOccurrence'),
    isEmailDelivery: computed.alias('reportOptions.isEmailDelivery'),
    isCsvOutput: computed.alias('reportOptions.isCsvOutput'),
    init: function init() {
      this._super.apply(this, arguments);
      this.initializeLocationGroups();
    },
    initializeLocationGroups: function initializeLocationGroups() {
      var _this = this;

      this.get('store').findAll('locationGroup').then(function (content) {
        run(function () {
          _this.set('_locationGroups', content);
        });
      });
    },
    actions: {
      search: function search() {
        this.send('cleanMessages');
        var isValid = true;
        var errorMessage = undefined;
        if (this.get('fromDate') === null) {
          isValid = false;
          errorMessage = this.get('notify').alert(this.get('intl').t('search.errors.emptyFromDate'), { closeAfter: 5000 });
          this.get('errorMessages').addObject(errorMessage);
        }

        if (this.get('toDate') === null) {
          isValid = false;
          errorMessage = this.get('notify').alert(this.get('intl').t('search.errors.emptyToDate'), { closeAfter: 5000 });
          this.get('errorMessages').addObject(errorMessage);
        }
        if (isValid) {
          this.set('page', 0);
          this.set('_canLoadMore', true);
          this.sendAction('search', this.get('reportOptions'));
        }
      },
      apply: function apply(startDate, endDate) {
        this.set('fromDate', startDate);
        this.set('toDate', endDate);
      },
      locationGroupSelection: function locationGroupSelection(locationGroup) {
        var _this2 = this;

        this.get('store').query('menu', { locationGroup: locationGroup.get('id') }).then(function (menus) {
          _this2.set('selectedLocationGroup', locationGroup);
          _this2.set('_menus', menus);
        });
      }
    }
  });
});