define('fcs-dashboard/services/modal-dialog', ['exports', 'ember', 'ember-modal-dialog/services/modal-dialog', 'fcs-dashboard/config/environment'], function (exports, _ember, _emberModalDialogServicesModalDialog, _fcsDashboardConfigEnvironment) {
  var computed = _ember['default'].computed;
  exports['default'] = _emberModalDialogServicesModalDialog['default'].extend({
    destinationElementId: computed(function () {
      /*
        everywhere except test, this property will be overwritten
        by the initializer that appends the modal container div
        to the DOM. because initializers don't run in unit/integration
        tests, this is a nice fallback.
      */
      if (_fcsDashboardConfigEnvironment['default'].environment === 'test') {
        return 'ember-testing';
      }
    })
  });
});