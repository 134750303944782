define("fcs-dashboard/pods/product-catalog/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _fcsDashboardMixinsBaseComponent) {
  var Route = _ember["default"].Route;
  var Logger = _ember["default"].Logger;
  var run = _ember["default"].run;
  exports["default"] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], _fcsDashboardMixinsBaseComponent["default"], {
    isReload: false,
    company: null,
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      if (this.get('session.isAuthenticated')) {
        this.store.unloadAll('productCatalog');
      }
    },
    model: function model() {
      Logger.debug('getting data!');

      var company = undefined;
      if (this.get('lockedCompany') !== undefined) {
        company = this.get('lockedCompany.id');
      } else if (!this.get('isBRUser')) {
        company = this.get('loggedAccount.company.id');
      }
      this.set('company', company);

      var params = {
        companyId: company,
        loadAttributes: false,
        page: 1,
        pageSize: 10
      };
      return this.store.query('productCatalog', params);
    },
    afterModel: function afterModel() {
      this._super.apply(this, arguments);
      if (this.get('isReportingUser')) {
        this.transitionTo('');
      } else {
        var company = this.get('company');
        if (!company) {
          if (!this.get('isBRUser') && !this.get('isReload')) {
            this.set('isReload', true);
            this.refresh();
          }
        }
      }
    },
    actions: {
      willTransition: function willTransition() {
        window.stop();
        return true;
      },
      save: function save(item) {
        var _this = this;

        item.save().then(function () {
          _this.send('cleanMessages');
          _this.get('notify').success(_this.get('intl').t('productCatalog.modal.errors.successSave', { name: item.get('name') }));
          _this.refresh();
        })["catch"](function (error) {
          var message = 'productCatalog.modal.errors.savingError';
          if (error !== undefined) {
            message = "productCatalog.modal.errors." + error.errors.get(0).detail;
          }
          var errorMessage = _this.get('notify').alert(_this.get('intl').t(message, { name: item.get('name') }), { closeAfter: 5000 });
          _this.get('errorMessages').addObject(errorMessage);
          item.rollbackAttributes();
          _this.refresh();
        });
      },
      "delete": function _delete(item) {
        var _this2 = this;

        // If item is meta product catalog get it from the store
        if (item.get('productCatalogTemplate')) {
          item = this.store.peekRecord('metaProductCatalog', item.get('id'));
        }

        if (item !== null && item !== undefined) {
          item.destroyRecord().then(function () {
            _this2.send('cleanMessages');
            _this2.get('notify').success(_this2.get('intl').t('productCatalog.list.errors.deleteSuccess'));
            _this2.refresh();
          })["catch"](function (error) {
            var message = 'productCatalog.list.errors.deletingError';
            if (error !== undefined) {
              message = "productCatalog.list.errors." + error.errors.get(0).detail;
            }
            var errorMessage = _this2.get('notify').alert(_this2.get('intl').t(message, { name: item.get('name') }), { closeAfter: 5000 });
            _this2.get('errorMessages').addObject(errorMessage);
            item.rollbackAttributes();
          });
        }
      },
      refresh: function refresh() {
        Logger.debug('refreshing');
        this.refresh();
      }
    }
  });
});