define("fcs-dashboard/pods/saved-network/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin", "fcs-dashboard/mixins/common-mixin", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _fcsDashboardMixinsCommonMixin, _fcsDashboardMixinsBaseComponent) {
  var Route = _ember["default"].Route;
  var Logger = _ember["default"].Logger;
  exports["default"] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], _fcsDashboardMixinsCommonMixin["default"], _fcsDashboardMixinsBaseComponent["default"], {
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      if (this.get('session.isAuthenticated')) {
        this.store.unloadAll('savedNetwork');
      }
    },
    model: function model() {
      Logger.debug('getting data!');
      return this.store.findAll('savedNetwork');
    },
    afterModel: function afterModel() {
      this._super.apply(this, arguments);
      if (!this.get('isBRUser')) {
        this.transitionTo('');
      }
    },
    actions: {
      willTransition: function willTransition() {
        window.stop();
        return true;
      },
      save: function save(item) {
        var _this = this;

        item.save().then(function () {
          _this.send('cleanMessages');
          _this.get('notify').success(_this.get('intl').t('trait.details.errors.successSave', { name: item.get('ssid') }));
          _this.refresh();
        })["catch"](function (error) {
          var message = 'trait.details.errors.savingError';
          if (error !== undefined) {
            message = "trait.details.errors." + error.errors.get(0).detail;
          }
          var errorMessage = _this.get('notify').alert(_this.get('intl').t(message, { name: item.get('ssid') }), { closeAfter: 5000 });
          _this.get('errorMessages').addObject(errorMessage);
          item.rollbackAttributes();
        });
      },
      refresh: function refresh() {
        Logger.debug('refreshing');
        this.refresh();
      }
    }
  });
});