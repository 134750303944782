define("fcs-dashboard/pods/components/embeddable-report/search-criteria/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/report-mixin", "fcs-dashboard/mixins/common-mixin", "moment"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsReportMixin, _fcsDashboardMixinsCommonMixin, _moment) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var A = _ember["default"].A;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], _fcsDashboardMixinsReportMixin["default"], _fcsDashboardMixinsCommonMixin["default"], {
    _scheduledReport: undefined,
    sortedFinancialReportFilters: computed.sort('_financialReportFilters', 'sortDefinition'),
    sortedClerkReportFilters: computed.sort('_clerkReportFilters', 'sortDefinition'),
    summarizeBy: computed.alias('reportOptions.summarizeBy'),
    isBrAdmin: computed.alias('isBRUser'),
    token: '',
    _summarizeByTypes: computed(function () {
      return [{ id: 0, name: 'Event/Venue' }, { id: 1, name: 'Menu' }, { id: 2, name: 'Company' }, { id: 3, name: 'Location' }];
    }),
    _summarizeByTimes: computed(function () {
      return [{ id: 0, name: 'None' }, { id: 1, name: 'Day' }, { id: 2, name: 'Hour' }, { id: 3, name: 'Hour Blocked' }];
    }),
    summarizeByType: computed.alias('reportOptions.summarizeByType'),
    summarizeByTime: computed.alias('reportOptions.summarizeByTime'),
    _financialReportFilters: computed(function () {
      return this.get('store').findAll('financialReportFilter');
    }),
    _clerkReportFilters: computed(function () {
      return this.get('store').findAll('dailyCloseOutFilter');
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('selectedVendor', null);
      this.set('selectedVendorManagement', null);
      this.get('selectedLocations').clear();
      this._initializeLocationGroups();
      this._initializeReportExportParameters();
      this._initializeMenus();

      var stime = (0, _moment["default"])('05:00 AM', "hh:mm a");
      var fdate = (0, _moment["default"])(this.get('fromDate')).set({
        'hour': stime.get('hour'),
        'minute': stime.get('minute')
      });
      this.set('fromDate', fdate);

      var etime = (0, _moment["default"])('05:00 AM', "hh:mm a");
      var tdate = (0, _moment["default"])(this.get('toDate')).set({
        'hour': etime.get('hour'),
        'minute': etime.get('minute')
      });
      this.set('toDate', tdate);

      this.set('summarizeByType', { id: 1, name: 'Menu' });
      this.set('summarizeByTime', { id: 0, name: 'None' });
    },
    didRender: function didRender() {
      this._super();
      var evt = document.createEvent('Event');
      evt.initEvent('load', false, false);
      window.dispatchEvent(evt);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (!this.get('selectedLocationGroup.isDestroyed') || this.get('selectedLocationGroup.isDestroying')) {
        this.set('selectedLocationGroup', null);
      }
      this.set('summarizeBy', null);
    },
    actions: {
      summarizeByTypeChanged: function summarizeByTypeChanged(type) {
        this.set('summarizeByType', type);
      },
      summarizeByTimeChanged: function summarizeByTimeChanged(time) {
        this.set('summarizeByTime', time);
      },
      searchReport: function searchReport() {
        this.send('search');
      },
      search: function search() {
        this.send('cleanMessages');
        this.set('showScheduleModal', false);
        var isValid = true;
        var errorMessage = undefined;

        this.set('reportOptions.reportId', this.get('report.id'));
        this._setDefaultFilters(isValid, errorMessage);

        if (isValid) {
          if (!this.get('isBRUser')) {
            if (this.get('selectedLocationGroup.length') === undefined || this.get('selectedLocationGroup.length') === 0) {
              if (this.get('selectedLocationGroup.length') === undefined) {
                this.set('selectedLocationGroup', A());
              }
              this.get('reportOptions.selectedLocationGroup').addObjects(this.get('sortedLocationGroups'));
            }

            if (this.get('selectedVendor.length') === undefined) {
              this.set('selectedVendor', A());
            }
            this.get('reportOptions.vendors').addObject(this.get('loggedAccount.company.id'));
          }
          if (this.get('selectedLocations.length') === 0) {
            this.get('reportOptions.locations').addObjects(this.get('_locations'));
          }
          if (this.get('selectedMenus.length') === 0) {
            this.get('reportOptions.menus').addObjects(this.get('_filteredMenus'));
          }
          this.set('page', 0);
          this.sendAction('search', this.get('reportOptions'));
        }
      },
      reportFilterSelection: function reportFilterSelection(filter) {
        this.set('summarizeBy', filter);
      }
    }
  });
});