define('fcs-dashboard/pods/version-session/service', ['exports', 'ember'], function (exports, _ember) {
  var Service = _ember['default'].Service;
  var inject = _ember['default'].inject;
  var computed = _ember['default'].computed;
  exports['default'] = Service.extend({
    store: inject.service(),
    version: computed(function () {
      return this.get('store').queryRecord('version', {});
    })
  });
});