define("fcs-dashboard/mirage/fixtures/oauth", ["exports"], function (exports) {
  /**
   * Created by alberto on 01/02/16.
   */
  exports["default"] = {
    "access_token": "cb44e128-fe08-445f-9a3c-a0919fe3cc94",
    "token_type": "bearer",
    "refresh_token": "fc4fb7bf-cd90-462f-b372-aac11131c656",
    "expires_in": 19567,
    "username": "test",
    "scope": "read write"
  };
});