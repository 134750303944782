define("fcs-dashboard/pods/components/menu/menu-wizard/tablet-view/component", ["exports", "ember", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var isEqual = _ember["default"].isEqual;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], {
    page: undefined,
    productsSorting: ['position'],
    sortedProducts: computed.sort('page.pageDisplayList', 'productsSorting'),
    emptyProducts: [],
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);
      var currentPosition = 1;
      this.get('sortedProducts').forEach(function (product) {
        if (!isEqual(product.get('position'), currentPosition)) {
          for (var i = currentPosition; i < product.get('position'); i++) {
            var emptyProduct = _this.get('store').createRecord('product');
            emptyProduct.set('position', i);
            _this.get('sortedProducts').addObject(emptyProduct);
          }
        }
        currentPosition = product.get('position') + 1;
      });
    }
  });
});