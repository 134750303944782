define('fcs-dashboard/pods/menu-name-filter/model', ['exports', 'ember-data/attr', 'ember-data/model', 'ember-cp-validations'], function (exports, _emberDataAttr, _emberDataModel, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', true)
  });

  exports['default'] = _emberDataModel['default'].extend(Validations, {
    name: (0, _emberDataAttr['default'])('string'),
    locations: (0, _emberDataAttr['default'])({
      defaultValue: function defaultValue() {
        return [];
      }
    })
  });
});