define("fcs-dashboard/pods/payment-result-report/totals/model", ["exports", "ember-data/attr", "ember-data/model"], function (exports, _emberDataAttr, _emberDataModel) {
   exports["default"] = _emberDataModel["default"].extend({
      approvedCount: (0, _emberDataAttr["default"])('number'),
      approvedAmount: (0, _emberDataAttr["default"])('number'),
      compsDueToDeclineCount: (0, _emberDataAttr["default"])('number'),
      compsDueToDeclineAmount: (0, _emberDataAttr["default"])('number'),
      rtDeclinesCount: (0, _emberDataAttr["default"])('number'),
      rtDeclinesAmount: (0, _emberDataAttr["default"])('number'),
      batchedErrorsCount: (0, _emberDataAttr["default"])('number'),
      batchedErrorsAmount: (0, _emberDataAttr["default"])('number'),
      rtErrorsCount: (0, _emberDataAttr["default"])('number'),
      rtErrorsAmount: (0, _emberDataAttr["default"])('number'),
      voidCount: (0, _emberDataAttr["default"])('number'),
      voidAmount: (0, _emberDataAttr["default"])('number'),
      refundCount: (0, _emberDataAttr["default"])('number'),
      refundAmount: (0, _emberDataAttr["default"])('number')
   });
});