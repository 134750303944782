define('fcs-dashboard/pods/tender/cash/model', ['exports', 'ember-data/attr', 'model-fragments/fragment'], function (exports, _emberDataAttr, _modelFragmentsFragment) {
  exports['default'] = _modelFragmentsFragment['default'].extend({
    enabled: (0, _emberDataAttr['default'])('boolean'),
    fastCashEnabled: (0, _emberDataAttr['default'])('boolean'),
    displayType: (0, _emberDataAttr['default'])('number'),
    discountEnabled: (0, _emberDataAttr['default'])('boolean'),
    discount: (0, _emberDataAttr['default'])('number', { defaultValue: 0 }),
    cashCoinRoundingEnabled: (0, _emberDataAttr['default'])('boolean'),
    cashCoinRoundingType: (0, _emberDataAttr['default'])('number'),
    cashCoinRoundingMode: (0, _emberDataAttr['default'])('number')
  });
});