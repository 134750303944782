define("fcs-dashboard/pods/high-level-invoicing-report/high-level-invoicing-company-row/model", ["exports", "ember-data/attr", "model-fragments/fragment"], function (exports, _emberDataAttr, _modelFragmentsFragment) {
  exports["default"] = _modelFragmentsFragment["default"].extend({
    companyId: (0, _emberDataAttr["default"])('string'),
    companyName: (0, _emberDataAttr["default"])('string'),
    creditSales: (0, _emberDataAttr["default"])('number'),
    cashlessSales: (0, _emberDataAttr["default"])('number'),
    creditTips: (0, _emberDataAttr["default"])('number'),
    cashlessTips: (0, _emberDataAttr["default"])('number'),
    digitalTips: (0, _emberDataAttr["default"])('number'),
    totalDigitalVolume: (0, _emberDataAttr["default"])('number'),
    tabletCount: (0, _emberDataAttr["default"])('number')
  });
});