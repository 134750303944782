define('fcs-dashboard/pods/order-item/model', ['exports', 'ember-data/model', 'ember-data/attr', 'ember', 'model-fragments/attributes'], function (exports, _emberDataModel, _emberDataAttr, _ember, _modelFragmentsAttributes) {
  exports['default'] = _emberDataModel['default'].extend({
    intl: _ember['default'].inject.service(),
    status: (0, _emberDataAttr['default'])('string'),
    discountPercentage: (0, _emberDataAttr['default'])('number'),
    price: (0, _emberDataAttr['default'])('number'),
    quantity: (0, _emberDataAttr['default'])('number'),
    productName: (0, _emberDataAttr['default'])('string'),
    finalPrice: (0, _emberDataAttr['default'])('number'),
    totalPrice: (0, _emberDataAttr['default'])('number'),
    orderItemModifiers: (0, _modelFragmentsAttributes.fragmentArray)('orderItemModifier'),
    subOrderItems: (0, _modelFragmentsAttributes.fragmentArray)('subOrderItem'),
    formattedPrice: _ember['default'].computed('price', function () {
      return this.get('intl').formatNumber(this.get('price'), { format: 'USD' });
    }),
    formattedFinalPrice: _ember['default'].computed('price', function () {
      return this.get('intl').formatNumber(this.get('finalPrice'), { format: 'USD' });
    }),
    formattedTotalPrice: _ember['default'].computed('price', function () {
      return this.get('intl').formatNumber(this.get('totalPrice'), { format: 'USD' });
    })
  });
});