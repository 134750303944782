define("fcs-dashboard/mirage/factories/dailyCloseOutClerksByLocation", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  _emberCliMirage.faker.locale = 'en_US';

  exports["default"] = _emberCliMirage["default"].Factory.extend({
    name: _emberCliMirage.faker.company.companyName,
    clerks: []
  });
});
/**
 * Created by anavarro on 3/21/17.
 */