define('fcs-dashboard/instance-initializers/logout-inactivity', ['exports', 'ember'], function (exports, _ember) {
  exports.initialize = initialize;

  function initialize(appInstance) {
    var session = appInstance.lookup('service:session');
    var logoutAfter = 60 * 60 * 1000;
    var logout = function logout() {
      _ember['default'].run.debounce(session, 'invalidate', logoutAfter);
    };
    window.onmousemove = logout;
    window.onkeypress = logout;
  }

  exports['default'] = {
    name: 'logout-inactivity',
    after: 'ember-simple-auth',
    initialize: initialize
  };
});