define('fcs-dashboard/mirage/factories/nonCashPaymentReport', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  _emberCliMirage.faker.locale = 'en_US';

  exports['default'] = _emberCliMirage['default'].Factory.extend({
    paymentType: _emberCliMirage.faker.hacker.noun,
    salesPlusTaxes: _emberCliMirage.faker.commerce.price,
    tips: _emberCliMirage.faker.commerce.price,
    total: _emberCliMirage.faker.commerce.price
  });
});
/**
 * Created by ernesto on 17/01/17.
 */