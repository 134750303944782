define('fcs-dashboard/pods/sales-report/credit-card-payment/model', ['exports', 'ember-data/attr', 'model-fragments/fragment'], function (exports, _emberDataAttr, _modelFragmentsFragment) {
  exports['default'] = _modelFragmentsFragment['default'].extend({
    creditCardTypeId: (0, _emberDataAttr['default'])('number'),
    creditCardTypeName: (0, _emberDataAttr['default'])('string'),
    quantity: (0, _emberDataAttr['default'])('number'),
    sales: (0, _emberDataAttr['default'])('number'),
    tips: (0, _emberDataAttr['default'])('number'),
    salesWithoutTips: (0, _emberDataAttr['default'])('number'),
    surcharge: (0, _emberDataAttr['default'])('number')
  });
});