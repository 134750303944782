define("fcs-dashboard/pods/components/menu/menu-wizard/features/component", ["exports", "ember", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var isEmpty = _ember["default"].isEmpty;
  var observer = _ember["default"].observer;
  var htmlSafe = _ember["default"].String.htmlSafe;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], {
    sendingTypes: computed(function () {
      return this.get('store').findAll('orderSendingType');
    }),
    multiplePagesEnabledOption: computed('menu.categoriesCount', function () {
      return !!(this.get('menu.categoriesCount') !== this.get('menu.pageCounter') && this.get('menu.multiplePagesEnabled'));
    }),
    employeeDiscountPercentageError: '',
    multiplePagesError: '',
    inputEmployeeDiscountPercentage: computed(function () {
      return this.get('menu.employeeDiscountPercentage');
    }),
    inputEmployeeDiscountPercentageObserver: observer('inputEmployeeDiscountPercentage', function () {
      this.set('menu.employeeDiscountPercentage', parseFloat(this.get('inputEmployeeDiscountPercentage')));
    }),
    actions: {
      nextStep: function nextStep() {
        this.sendAction('cleanMessages');
        var isValid = true;
        var errorMessage = undefined;
        if (!isEmpty(this.get('menu.employeeDiscountPercentage'))) {
          if (!(this.get('menu.employeeDiscountPercentage') > 100 || this.get('menu.employeeDiscountPercentage') < 0) && !/^(100|[0-9]{1,2})(\.[0-9]{1,2})?$/.test(this.get('menu.employeeDiscountPercentage'))) {
            isValid = false;
            this.set('employeeDiscountPercentageError', htmlSafe('has-error alert-danger'));
            errorMessage = this.get('notify').alert(this.get('intl').t('menu.builder.steps.stepFour.employeeDiscount.errors.invalidPercentage'), { closeAfter: 5000 });
            this.get('errorMessages').addObject(errorMessage);
          }
        }
        if (this.get('menu.orderSendingType') === null || this.get('menu.orderSendingType.id') === undefined) {
          isValid = false;
          errorMessage = this.get('notify').alert(this.get('intl').t('menu.builder.steps.stepFour.transactionSending.errors.transactionTypeNotSelected'), { closeAfter: 5000 });
          this.get('errorMessages').addObject(errorMessage);
        }
        this.sendAction('nextStep', isValid);
      },
      previousStep: function previousStep() {
        this.sendAction('previousStep');
      },
      showMenuList: function showMenuList() {
        this.sendAction('showMenuList');
      },
      sendingTypeSelected: function sendingTypeSelected(type) {
        this.set('menu.changed', true);
        this.set('menu.orderSendingType', type);
      },
      updateCloudTabsEnabled: function updateCloudTabsEnabled() {
        this.toggleProperty('menu.tabEnabled');
        if (!this.get('menu.tabEnabled')) {
          this.set('menu.cloudTabsEnabled', false);
        }
      },
      save: function save() {
        if (!this.get('menu.tipAmountButtonsEnabled')) {
          this.set('menu.tipButton.buttonOne', 1);
          this.set('menu.tipButton.buttonTwo', 5);
          this.set('menu.tipButton.buttonThree', 10);
        }

        if (!this.get('menu.tipPercentageButtonsEnabled')) {
          this.set('menu.tipPercentage.percentageOne', 15);
          this.set('menu.tipPercentage.percentageTwo', 18);
          this.set('menu.tipPercentage.percentageThree', 20);
        }

        this.sendAction('save');
      }
    }
  });
});