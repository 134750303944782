define('fcs-dashboard/pods/sub-order-item/model', ['exports', 'ember-data/attr', 'model-fragments/fragment', 'model-fragments/attributes', 'ember'], function (exports, _emberDataAttr, _modelFragmentsFragment, _modelFragmentsAttributes, _ember) {
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  exports['default'] = _modelFragmentsFragment['default'].extend({
    intl: inject.service(),
    status: (0, _emberDataAttr['default'])('string'),
    price: (0, _emberDataAttr['default'])('number'),
    quantity: (0, _emberDataAttr['default'])('number'),
    productName: (0, _emberDataAttr['default'])('string'),
    finalPrice: (0, _emberDataAttr['default'])('number'),
    totalPrice: (0, _emberDataAttr['default'])('number'),
    orderItemModifiers: (0, _modelFragmentsAttributes.fragmentArray)('orderItemModifier'),
    formattedTotalPrice: computed('price', function () {
      return this.get('intl').formatNumber(this.get('price'), { format: 'USD' });
    })
  });
});