define("fcs-dashboard/pods/components/fcs-navbar/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var Logger = _ember["default"].Logger;
  var run = _ember["default"].run;
  exports["default"] = Component.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], _fcsDashboardMixinsBaseComponent["default"], {
    userState: computed.alias('loggedAccount.state'),
    userType: computed.alias('loggedAccount.userType'),
    embeddableReports: computed(function () {
      return this.get('store').findAll('embeddableReport');
    }),
    showCompanyManagementMenuOption: computed.alias('isBRUser'),
    _isAppLoading: computed('loggedAccount.isLoading', function () {
      Logger.debug("observing account state: " + this.get('loggedAccount.isLoading'));
      return this.get('loggedAccount.isLoading') === undefined || this.get('loggedAccount.isLoading');
    }),
    unlockMenuByUser: function unlockMenuByUser() {
      var _this = this;

      var endpoint = "/api/v1/menus/unlockByUser";
      var _authToken = this.get('session.data.authenticated.access_token');
      var options = {
        url: endpoint,
        type: 'POST'
      };
      options.beforeSend = function (xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + _authToken);
      };
      options.success = function () {
        _this.sendAction('invalidateSession');
      };
      options.error = function () {
        _this.get('errorMessages').addObject(_this.get('notify').alert(_this.get('intl').t('menu.builder.steps.stepSix.errors.saveError'), { closeAfter: 5000 }));
      };
      _ember["default"].$.ajax(options);
    },
    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);
      if (this.get('dbVersion').then !== undefined) {
        this.get('dbVersion').then(function (content) {
          run(function () {
            _this2.set('dbVersion', content);
          });
        });
      }
    },
    didRender: function didRender() {
      this._super();
      var evt = document.createEvent('Event');
      evt.initEvent('load', false, false);
      window.dispatchEvent(evt);

      this.$(".sidebar").find('a').parents('li').removeClass('active');
      this.$(".sidebar").find('a.active').parents('li').addClass("active");
    },
    actions: {
      invalidateSession: function invalidateSession() {
        this.unlockMenuByUser();
      },
      hideMenu: function hideMenu() {
        this.$('#wrapper').toggleClass("active");
      },
      closeModal: function closeModal() {
        this.send('invalidateSession');
        this.set('showModal', false);
      },
      unlockCompany: function unlockCompany() {
        this.set('accountSession.account.lockedCompany', undefined);
      },
      closeMenu: function closeMenu() {
        var width = window.innerWidth > 0 ? window.innerWidth : screen.width;
        if (width < 768) {
          this.$('[data-toggle="push-menu"]').pushMenu('toggle');
        }
      }
    }
  });
});