define('fcs-dashboard/pods/sales-report/closed-loop-cashless-payment/model', ['exports', 'ember-data/attr', 'model-fragments/fragment'], function (exports, _emberDataAttr, _modelFragmentsFragment) {
  exports['default'] = _modelFragmentsFragment['default'].extend({
    paymentTypeId: (0, _emberDataAttr['default'])('number'),
    paymentTypeName: (0, _emberDataAttr['default'])('string'),
    quantityLoaded: (0, _emberDataAttr['default'])('number'),
    amountLoaded: (0, _emberDataAttr['default'])('number'),
    quantitySpent: (0, _emberDataAttr['default'])('number'),
    amountSpent: (0, _emberDataAttr['default'])('number'),
    quantityRefunded: (0, _emberDataAttr['default'])('number'),
    amountRefunded: (0, _emberDataAttr['default'])('number'),
    count: (0, _emberDataAttr['default'])('number')
  });
});