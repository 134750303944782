define('fcs-dashboard/pods/product-mix-report/model', ['exports', 'ember-data/model', 'ember-data/attr', 'model-fragments/attributes'], function (exports, _emberDataModel, _emberDataAttr, _modelFragmentsAttributes) {
  exports['default'] = _emberDataModel['default'].extend({
    productMixes: (0, _modelFragmentsAttributes.fragmentArray)('productMixReport/productMixItem', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    productMixLocations: (0, _modelFragmentsAttributes.fragmentArray)('productMixReport/productMixLocationItem', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    outputFileName: (0, _emberDataAttr['default'])('string', { defaultValue: '' })
  });
});