define("fcs-dashboard/mirage/fixtures/locationGroups", ["exports"], function (exports) {
  /**
   * Created by alberto on 16/06/16.
   */
  exports["default"] = [{
    "id": 1,
    "name": "Location Group 1",
    "address": "Austin, Texas",
    "locationGroupType": 1,
    "logo": '',
    "isCashless": false,
    "isActive": true,
    "cashlessGateway": 4,
    "startDate": "2016-06-08T00:00:00.000+0000",
    "endDate": "2016-06-08T00:00:00.000+0000",
    "timezone": "America/Costa_Rica",
    "companiesRoles": [{ "roleId": 1, "companyId": 1, "locations": [1] }]
  }, {
    "id": 2,
    "name": "Location Group 2",
    "address": "Austin, Texas",
    "locationGroupType": 2,
    "logo": '',
    "isCashless": true,
    "isActive": true,
    "cashlessGateway": 4,
    "startDate": "2016-06-08T00:00:00.000+0000",
    "endDate": "2016-06-08T00:00:00.000+0000",
    "timezone": "America/Costa_Rica",
    "companiesRoles": [{ "roleId": 1, "companyId": 1, "locations": [2] }]
  }, {
    "id": 3,
    "name": "Location Group 3",
    "address": "Austin, Texas",
    "locationGroupType": 3,
    "logo": '',
    "isCashless": true,
    "isActive": false,
    "cashlessGateway": 4,
    "startDate": "2016-06-08T00:00:00.000+0000",
    "endDate": "2016-06-08T00:00:00.000+0000",
    "timezone": "America/Costa_Rica",
    "companiesRoles": [{ "roleId": 1, "companyId": 1, "locations": [3] }]
  }];
});