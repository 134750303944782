define('fcs-dashboard/mirage/factories/reportOptions', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  _emberCliMirage.faker.locale = 'en_US';

  exports['default'] = _emberCliMirage['default'].Factory.extend({
    selectedMenu: _emberCliMirage.faker.hacker.noun,
    summarizeBy: _emberCliMirage.faker.hacker.noun,
    selectedLocationGroup: _emberCliMirage.faker.hacker.noun,
    isTimestampOccurrence: false,
    isCsvOutput: false,
    isEmailDelivery: false,
    fromDate: _emberCliMirage.faker.date.past,
    toDate: _emberCliMirage.faker.date.past
  });
});
/**
 * Created by ernesto on 17/01/17.
 */