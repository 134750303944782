define('fcs-dashboard/pods/sales-report/model', ['exports', 'ember-data/model', 'ember-data/attr', 'model-fragments/attributes'], function (exports, _emberDataModel, _emberDataAttr, _modelFragmentsAttributes) {
  exports['default'] = _emberDataModel['default'].extend({
    grossSales: (0, _emberDataAttr['default'])('number'),
    grossSalesWithoutVoids: (0, _emberDataAttr['default'])('number'),
    netSales: (0, _emberDataAttr['default'])('number'),
    taxes: (0, _emberDataAttr['default'])('number'),
    totalVoids: (0, _emberDataAttr['default'])('number'),
    totalCouponDiscount: (0, _emberDataAttr['default'])('number'),
    digitalTipsDeductionsEnabled: (0, _emberDataAttr['default'])('boolean'),
    voids: (0, _modelFragmentsAttributes.fragmentArray)('salesReport/salesReportProductTypeStatus', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    comps: (0, _modelFragmentsAttributes.fragmentArray)('salesReport/salesReportProductTypeStatus', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    spills: (0, _modelFragmentsAttributes.fragmentArray)('salesReport/salesReportProductTypeStatus', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    refunds: (0, _modelFragmentsAttributes.fragmentArray)('salesReport/salesReportProductTypeStatus', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    creditCardPayments: (0, _modelFragmentsAttributes.fragmentArray)('salesReport/creditCardPayment', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    openLoopCashlessPayments: (0, _modelFragmentsAttributes.fragmentArray)('salesReport/creditCardPayment', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    closedLoopCashlessPayments: (0, _modelFragmentsAttributes.fragmentArray)('salesReport/closedLoopCashlessPayment', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    cashDepositReport: (0, _modelFragmentsAttributes.fragment)('salesReport/cashDepositReport', {
      defaultValue: {
        cashDeposit: 0.0,
        creditCardPayments: 0.0,
        rfidPayments: 0.0,
        couponDiscount: 0.0,
        voids: 0.0,
        comps: 0.0,
        spills: 0.0,
        refunds: 0.0,
        grossSales: 0.0,
        employeeDiscount: 0.0,
        digitalTips: 0.0
      }
    }),
    productTypes: (0, _modelFragmentsAttributes.fragmentArray)('dailyCloseOutReport/productTypes', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    outputFileName: (0, _emberDataAttr['default'])('string', { defaultValue: '' })
  });
});