define('fcs-dashboard/pods/order-item-modifier/model', ['exports', 'ember-data/attr', 'model-fragments/fragment', 'ember'], function (exports, _emberDataAttr, _modelFragmentsFragment, _ember) {
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  exports['default'] = _modelFragmentsFragment['default'].extend({
    intl: inject.service(),
    description: (0, _emberDataAttr['default'])('string'),
    price: (0, _emberDataAttr['default'])('number'),
    formattedPrice: computed('price', function () {
      return this.get('intl').formatNumber(this.get('price'), { format: 'USD' });
    })
  });
});