define("fcs-dashboard/pods/order-payment/model", ["exports", "ember-data/model", "ember-data/attr", "ember", "moment"], function (exports, _emberDataModel, _emberDataAttr, _ember, _moment) {
  var computed = _ember["default"].computed;
  var isEqual = _ember["default"].isEqual;
  var service = _ember["default"].inject.service;
  var tz = _moment["default"].tz;
  exports["default"] = _emberDataModel["default"].extend({
    intl: service(),
    tenderType: (0, _emberDataAttr["default"])('string'),
    paymentType: (0, _emberDataAttr["default"])('string'),
    amount: (0, _emberDataAttr["default"])('number'),
    coinRoundedAmountLoss: (0, _emberDataAttr["default"])('number'),
    cashCoinRoundingType: (0, _emberDataAttr["default"])('number'),
    authCode: (0, _emberDataAttr["default"])('string'),
    paymentDate: (0, _emberDataAttr["default"])('date'),
    uid: (0, _emberDataAttr["default"])('number'),
    creditCardType: (0, _emberDataAttr["default"])('string'),
    lastFour: (0, _emberDataAttr["default"])('string'),
    lastName: (0, _emberDataAttr["default"])('string'),
    entryMethod: (0, _emberDataAttr["default"])('string'),
    status: (0, _emberDataAttr["default"])('string'),
    locationGroupTimezone: (0, _emberDataAttr["default"])('string'),
    formattedDate: computed('paymentDate', function () {
      var formattedDate = tz(this.get('paymentDate'), this.get('locationGroupTimezone')).format();
      return this.get('intl').formatDate(formattedDate, {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
        timeZoneName: 'short'
      });
    }),
    formattedAmount: computed('amount', function () {
      return this.get('intl').formatNumber(this.get('amount'), { format: 'USD' });
    }),
    isCashless: computed(function () {
      var tenderType = this.get('tenderType').toLowerCase();
      return isEqual(tenderType, 'cashless');
    }),
    isCreditCard: computed(function () {
      var tenderType = this.get('tenderType').toLowerCase();
      return tenderType.search('manual') !== -1 || tenderType.search('swipe') !== -1 || tenderType.search('chipped') !== -1;
    })
  });
});