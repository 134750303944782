define("fcs-dashboard/pods/components/daily-close-out/report-result/locations/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/table-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsTableComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var isEmpty = _ember["default"].isEmpty;
  exports["default"] = Component.extend(_fcsDashboardMixinsTableComponent["default"], _fcsDashboardMixinsBaseComponent["default"], {
    data: [],
    _showData: computed('data', function () {
      return this.get('data') !== null && !isEmpty(this.get('data'));
    }),
    _columns: computed(function () {
      return [{
        title: this.get('intl').t('report.result.dailyCloseOutReport.locations.name'),
        className: 'non-cash-category-cell',
        propertyName: 'name',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.dailyCloseOutReport.clerks.cashDrawer'),
        className: 'non-cash-quantity-cell',
        template: 'dailyCloseOutReport/amountCell',
        propertyName: 'cashDrawer',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.dailyCloseOutReport.clerks.totalDigitalTips'),
        className: 'non-cash-total-cell',
        template: 'dailyCloseOutReport/amountCell',
        propertyName: 'totalDigitalTips',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.dailyCloseOutReport.clerks.cashGrossSales'),
        className: 'non-cash-quantity-cell',
        template: 'dailyCloseOutReport/amountCell',
        propertyName: 'grossCashSales',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.dailyCloseOutReport.clerks.creditGrossSales'),
        className: 'non-cash-quantity-cell',
        template: 'dailyCloseOutReport/amountCell',
        propertyName: 'grossCreditSales',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.dailyCloseOutReport.clerks.rfidGrossSales'),
        className: 'non-cash-quantity-cell',
        template: 'dailyCloseOutReport/amountCell',
        propertyName: 'grossRfidSales',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.dailyCloseOutReport.clerks.grossSales'),
        className: 'non-cash-quantity-cell',
        template: 'dailyCloseOutReport/amountCell',
        propertyName: 'grossSales',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.dailyCloseOutReport.clerks.coupon'),
        className: 'non-cash-total-cell',
        template: 'dailyCloseOutReport/amountCell',
        propertyName: 'coupon',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.dailyCloseOutReport.clerks.spills'),
        className: 'non-cash-total-cell',
        template: 'dailyCloseOutReport/amountCell',
        propertyName: 'spills',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.dailyCloseOutReport.clerks.comp'),
        className: 'non-cash-total-cell',
        template: 'dailyCloseOutReport/amountCell',
        propertyName: 'comp',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.dailyCloseOutReport.clerks.discounts'),
        className: 'non-cash-total-cell',
        template: 'dailyCloseOutReport/amountCell',
        propertyName: 'discounts',
        disableSorting: true
      }, {
        className: 'non-cash-total-cell',
        template: 'dailyCloseOutReport/amountCell',
        propertyName: 'serviceFees',
        disableSorting: true
      }, {
        className: 'non-cash-total-cell',
        template: 'dailyCloseOutReport/amountCell',
        propertyName: 'taxes',
        disableSorting: true
      }, {
        className: 'non-cash-total-cell',
        template: 'dailyCloseOutReport/amountCell',
        propertyName: 'netSales',
        disableSorting: true
      }, {
        className: 'non-cash-total-cell',
        template: 'dailyCloseOutReport/amountCell',
        propertyName: 'creditCardTips',
        disableSorting: true
      }, {
        className: 'non-cash-total-cell',
        template: 'dailyCloseOutReport/amountCell',
        propertyName: 'rfidTips',
        disableSorting: true
      }, {
        className: 'non-cash-total-cell',
        template: 'dailyCloseOutReport/amountCell',
        propertyName: 'refunds',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.dailyCloseOutReport.clerks.voids'),
        className: 'non-cash-total-cell',
        template: 'dailyCloseOutReport/amountCell',
        propertyName: 'voids',
        disableSorting: true
      }];
    })
  });
});