define("fcs-dashboard/pods/location/model", ["exports", "ember", "ember-data/model", "ember-data/attr", "ember-cp-validations"], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberCpValidations) {
  var computed = _ember["default"].computed;

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'location.detail.name.label' }),
    companyId: (0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'location.detail.company.label' }),
    typeId: (0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'location.detail.locationType.label' })
  });

  exports["default"] = _emberDataModel["default"].extend(Validations, {
    name: (0, _emberDataAttr["default"])('string'),
    companyId: (0, _emberDataAttr["default"])('number'),
    companyName: (0, _emberDataAttr["default"])('string'),
    typeName: (0, _emberDataAttr["default"])('string'),
    typeId: (0, _emberDataAttr["default"])('number'),
    menuCount: (0, _emberDataAttr["default"])('number'),
    active: (0, _emberDataAttr["default"])('boolean', { defaultValue: true }),
    brainTreeEnabled: (0, _emberDataAttr["default"])('boolean'),
    intl: _ember["default"].inject.service(),
    translatedStatus: computed('active', function () {
      if (this.get('active')) {
        return this.get('intl').t('menu.list.table.status.active');
      } else {
        return this.get('intl').t('menu.list.table.status.inactive');
      }
    })
  });
});