define("fcs-dashboard/pods/menu/edit/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-data-preload", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _emberDataPreload, _fcsDashboardMixinsBaseComponent) {
  var Route = _ember["default"].Route;
  var Logger = _ember["default"].Logger;
  var isEmpty = _ember["default"].isEmpty;
  var set = _ember["default"].set;
  exports["default"] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], _fcsDashboardMixinsBaseComponent["default"], {
    renderTemplate: function renderTemplate() {
      this.render('menu/edit', { into: 'application' });
    },
    model: function model(params) {
      Logger.debug("Loading data for menu " + params.menu_id);
      return (0, _emberDataPreload["default"])(this.store.findRecord('menu', params.menu_id, { reload: true }), ['company', { menuLocations: { locationObject: 'company' } }, { categories: { pages: { products: ['productCatalog', 'taxRateType'] } } }]);
    },
    afterModel: function afterModel(model) {
      Logger.debug("Loading menu-location data for menu " + model.get('id'));
      this.store.query('location', { menu: model.get('id'), version: parseInt(new Date().getTime() / 1000, 10) }).then(function (locations) {
        var locationList = [];
        locations.forEach(function (item) {
          locationList.addObject(item.get('id'));
        });
        model.set('locations', locationList);
      });
      Logger.debug("menu-location data loaded for menu " + model.get('id'));
      this._loadProductTypes(model);
    },
    _loadProductTypes: function _loadProductTypes(model) {
      var _this = this;

      Logger.debug('loading menu-productTypes');
      var company = model.get('company.id');
      this.store.query('productType', { companyId: company, pageSize: -1 }).then(function (productTypes) {
        productTypes.forEach(function (productType) {
          var productTypeId = productType.get('id');
          if (!model.get('menuProductTypes').findBy('productType', productTypeId)) {
            model.get('menuProductTypes').addObject(_this.store.createFragment('menuProductType', { productType: productTypeId }));
          }
        });
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('showModal', false);
      controller.set('saveEnabled', true);
    },
    cleanupMenu: function cleanupMenu() {
      Logger.debug('Cleaning menu and relationships.');
      this.store.unloadAll('product');
      this.store.unloadAll('page');
      this.store.unloadAll('category');
      this.store.unloadAll('promotion');
      this.store.unloadAll('menu');
    },
    actions: {
      willTransition: function willTransition(transition) {
        Logger.debug('menu.edit aborting transition');
        if (this.controller.get('model.hasDirtyAttributes')) {
          this.controller.set('transition', transition);
          this.controller.set('showModal', true);
          transition.abort();
        } else {
          this.cleanupMenu();
        }
      },
      closeModal: function closeModal() {
        this.controller.set('showModal', false);
      },
      save: function save(menu, shouldClean, newMenu) {
        Logger.debug('Save action called.');
        var validMenu = true;
        if (!menu.get('isNew') && newMenu) {
          // Validate menuLocations conditions
          if (menu.get('menuLocations.length') > 0) {
            menu.get('menuLocations').forEach(function (menuLocation) {
              menuLocation.get('promotions').forEach(function (promotion) {
                set(promotion, 'id', promotion.id);
              });
            });
          }
        }

        var reg = new RegExp('^\\d{5}$');
        var showCashlessCheckbox = jQuery('#cashlessCodeCheckbox');
        var showCashless = showCashlessCheckbox.is(':checked');
        if (showCashless) {
          if (isEmpty(menu.get('cashlessAdminCode'))) {
            jQuery('#cashlessAdminCodeGroup').addClass('has-error');
            jQuery('#cashlessAdminCodeGroup').addClass('alert-danger');
            var errorMessage = this.get('notify').alert(this.get('intl').t('menu.builder.steps.stepOne.errors.cashlessAdminCode'), { closeAfter: 5000 });
            this.get('errorMessages').addObject(errorMessage);
            validMenu = false;
          } else if (!reg.test(menu.get('cashlessAdminCode'))) {
            jQuery('#cashlessAdminCodeGroup').addClass('has-error');
            jQuery('#cashlessAdminCodeGroup').addClass('alert-danger');
            var errorMessage = this.get('notify').alert(this.get('intl').t('menu.builder.steps.stepOne.errors.cashlessAdminCodeNotNumeric'), { closeAfter: 5000 });
            this.get('errorMessages').addObject(errorMessage);
            validMenu = false;
          }
        }

        if (validMenu) {
          shouldClean = shouldClean === undefined ? true : shouldClean;
          if (menu.get('validations.isValid')) {
            if (menu.get('receipt.enabled')) {
              if (menu.get('receipt.validations.isValid')) {
                this.send('saveMenu', menu, shouldClean);
              } else {
                this.controller.set('saveEnabled', true);
                this.send('displayErrorMessages', menu.get('receipt.validations.errors'));
              }
            } else {
              this.send('saveMenu', menu, shouldClean);
            }
          } else {
            this.controller.set('saveEnabled', true);
            this.send('displayErrorMessages', menu.get('validations.errors'));
          }
        } else {
          this.controller.set('saveEnabled', true);
          this.send('displayErrorMessages', menu.get('validations.errors'));
        }
      }
    }
  });
});