define('fcs-dashboard/pods/report-filters/model', ['exports', 'ember-data/attr', 'model-fragments/fragment'], function (exports, _emberDataAttr, _modelFragmentsFragment) {
  exports['default'] = _modelFragmentsFragment['default'].extend({
    datesEnabled: (0, _emberDataAttr['default'])('boolean'),
    eventEnabled: (0, _emberDataAttr['default'])('boolean'),
    vmcEnabled: (0, _emberDataAttr['default'])('boolean'),
    vendorEnabled: (0, _emberDataAttr['default'])('boolean'),
    locationEnabled: (0, _emberDataAttr['default'])('boolean'),
    menuEnabled: (0, _emberDataAttr['default'])('boolean'),
    dayHourMenuEnabled: (0, _emberDataAttr['default'])('boolean'),
    clerkLocationEnabled: (0, _emberDataAttr['default'])('boolean'),
    productEnabled: (0, _emberDataAttr['default'])('boolean')
  });
});