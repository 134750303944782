define("fcs-dashboard/pods/components/attribute-set/list/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/table-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsTableComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var observer = _ember["default"].observer;
  var Logger = _ember["default"].Logger;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], _fcsDashboardMixinsTableComponent["default"], {
    columns: computed(function () {
      var hideCompanyColumn = !this.get('isBRUser');
      return [{
        title: '',
        template: "attribute-set/edit-cell",
        className: 'editRow'
      }, {
        propertyName: 'name',
        filteredBy: 'name',
        title: this.get('intl').t('attributeSet.list.name')
      }, {
        propertyName: 'content',
        filteredBy: 'content',
        title: this.get('intl').t('attributeSet.list.attributes')
      }, {
        propertyName: 'companyName',
        filteredBy: 'companyName',
        title: this.get('intl').t('attributeSet.list.company'),
        isHidden: hideCompanyColumn,
        className: 'companyNameRow'
      }, {
        title: '',
        template: "attribute-set/delete-cell",
        className: 'editRow'
      }];
    }),
    showEditModal: false,
    currentAttributeSet: undefined,
    _filteredData: [],
    _checkCompanyLock: observer('lockedCompany', function () {
      Logger.debug('calling refresh');
      this.sendAction('refreshAction');
    }),
    actions: {
      addAttributeSet: function addAttributeSet() {
        var companyId = undefined;
        if (this.get('isBRUser')) {
          if (this.get('lockedCompany') !== undefined) {
            companyId = this.get('lockedCompany.id');
          }
        } else {
          companyId = this.get('loggedAccount.company.id');
        }
        this.set('currentAttributeSet', this.get('store').createRecord('attributeSet', { companyId: companyId }));
        this.set('showEditModal', true);
      },
      editAttributeSet: function editAttributeSet(item) {
        this.set('currentAttributeSet', item);
        this.set('showEditModal', true);
      },
      closeModal: function closeModal(item) {
        this.send('cleanMessages');
        this.set('showEditModal', false);
        item.rollbackAttributes();
      },
      accept: function accept(item) {
        var _this = this;

        if (item.get('validations.isValid')) {
          this.set('showEditModal', false);
          this.sendAction('save', item);
        } else {
          this.send('cleanMessages');
          item.get('validations.errors').forEach(function (error) {
            var errorMessage = _this.get('notify').alert(error.get('message'), { closeAfter: 5000 });
            _this.get('errorMessages').addObject(errorMessage);
          });
        }
      },
      deleteAttributeSet: function deleteAttributeSet(item) {
        // TODO Add validation to deleting AttributeSet
        item.set('deleted', true);
        this.sendAction('save', item);
      }
    }
  });
});