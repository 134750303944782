define("fcs-dashboard/pods/pos-token/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin", "fcs-dashboard/mixins/base-component", "moment"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _fcsDashboardMixinsBaseComponent, _moment) {
  var Route = _ember["default"].Route;
  exports["default"] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], _fcsDashboardMixinsBaseComponent["default"], {
    setupController: function setupController(controller) {
      controller.set('expirationTime', (0, _moment["default"])().add(1, "hour").seconds(0).minutes(1));
      controller.set('tokenExpired', false);
      controller.set('isLoading', true);
      controller.set('token', null);
    },
    beforeModel: function beforeModel() {
      var _this = this;

      var endpoint = '/api/v1/posTokens';
      var _authToken = this.get('session.data.authenticated.access_token');
      var options = {
        url: endpoint,
        type: 'GET'
      };

      options.beforeSend = function (xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + _authToken);
      };

      options.success = function (result) {
        _this.controller.set('token', result);
        _this.controller.set('isLoading', false);
      };
      options.error = function () {
        console.log('error');
      };
      _ember["default"].$.ajax(options);
    },
    afterModel: function afterModel() {
      this._super.apply(this, arguments);
      if (!this.get('isBRUser')) {
        this.transitionTo('');
      }
    },
    actions: {
      willTransition: function willTransition() {
        window.stop();
        return true;
      },
      refreshToken: function refreshToken() {
        var _this2 = this;

        this.controller.set('tokenExpired', true);
        var endpoint = '/api/v1/posTokens';
        var _authToken = this.get('session.data.authenticated.access_token');
        var options = {
          url: endpoint,
          type: 'GET'
        };

        options.beforeSend = function (xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + _authToken);
        };

        options.success = function (result) {
          _this2.controller.set('expirationTime', (0, _moment["default"])().add(1, "hour").seconds(0).minutes(1));
          _this2.controller.set('token', result);
          _this2.controller.set('tokenExpired', false);
        };
        options.error = function () {
          console.log('error');
        };
        _ember["default"].$.ajax(options);
      }
    }
  });
});