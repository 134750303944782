define("fcs-dashboard/pods/combined-product-catalog/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _fcsDashboardMixinsBaseComponent) {
  var Route = _ember["default"].Route;
  var Logger = _ember["default"].Logger;
  var run = _ember["default"].run;
  exports["default"] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], _fcsDashboardMixinsBaseComponent["default"], {
    isReload: false,
    company: null,
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      if (this.get('session.isAuthenticated')) {
        this.store.unloadAll('combinedProductCatalogs');
      }
    },
    model: function model() {
      var company = undefined;
      if (this.get('lockedCompany') !== undefined) {
        company = this.get('lockedCompany.id');
      } else if (!this.get('isBRUser')) {
        company = this.get('loggedAccount.company.id');
      }
      this.set('company', company);
      return this.store.query('combinedProductCatalog', { companyId: company, loadAttributes: false });
    },
    afterModel: function afterModel() {
      this._super.apply(this, arguments);

      if (this.get('isReportingUser')) {
        this.transitionTo('');
      } else {
        var company = this.get('company');
        if (!company) {
          if (!this.get('isBRUser') && !this.get('isReload')) {
            this.set('isReload', true);
            this.refresh();
          }
        }
      }
    },
    actions: {
      willTransition: function willTransition() {
        window.stop();
        return true;
      },
      refresh: function refresh() {
        Logger.debug('refreshing');
        this.refresh();
      }
    }
  });
});