define('fcs-dashboard/mirage/factories/productCatalog', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  _emberCliMirage.faker.locale = 'en_US';

  exports['default'] = _emberCliMirage['default'].Factory.extend({
    name: _emberCliMirage.faker.commerce.productName,
    suggestedPrice: _emberCliMirage.faker.commerce.price,
    version: 1,
    description: _emberCliMirage.faker.commerce.productAdjective,
    custom: false,
    currency: 'USD',
    company: _emberCliMirage.faker.random.arrayElement([1, 2, 3])
  });
});
/**
 * Created by aenrosello on 12/1/16.
 */