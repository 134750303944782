define('fcs-dashboard/pods/components/product-catalog/combo-selection-catalog/details/component', ['exports', 'ember', 'fcs-dashboard/mixins/base-component'], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var Component = _ember['default'].Component;
  var isEmpty = _ember['default'].isEmpty;
  var isBlank = _ember['default'].isBlank;
  exports['default'] = Component.extend(_fcsDashboardMixinsBaseComponent['default'], {
    actions: {
      closeComboSelectionModal: function closeComboSelectionModal() {
        this.sendAction('closeComboSelectionModal', this.get('comboSelectionCatalog'));
      },
      addComboSelection: function addComboSelection() {
        if (isEmpty(this.get('comboSelectionCatalog.displayText')) || isBlank(this.get('comboSelectionCatalog.displayText'))) {
          this.get('notify').alert(this.get('intl').t('comboSelectionProductCatalog.list.errors.blankComboSelectionCatalogError'), { closeAfter: 5000 });
        } else {
          this.sendAction('addComboSelection', this.get('comboSelectionCatalog'));
        }
      }
    }
  });
});