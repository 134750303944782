define("fcs-dashboard/pods/high-level-invoicing-report/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin", "fcs-dashboard/mixins/base-component", "moment"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _fcsDashboardMixinsBaseComponent, _moment) {
  var Route = _ember["default"].Route;
  var Logger = _ember["default"].Logger;
  exports["default"] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], _fcsDashboardMixinsBaseComponent["default"], {
    setupController: function setupController(controller) {
      controller.set('reportOptions', this.get('accountSession.reportOptions'));
      controller.set('authToken', this.get('session.data.authenticated.access_token'));
      controller.set('report', null);
      controller.set('noResults', false);
      controller.set('isLoading', false);
      controller.set('showReport', false);
      controller.set('isShowExportButton', false);
    },
    actions: {
      willTransition: function willTransition(transition) {
        window.stop();
        if (!this._isValidTransition(transition.targetName)) {
          Logger.debug('Aborting transition invalid route');
          this.transitionTo('index');
        } else {
          return true;
        }
      },
      search: function search(queryOptions) {
        var _this = this;

        var query = queryOptions.getProperties(['reportOutput']);

        if (queryOptions.get('selectedLocationGroup') !== null && queryOptions.get('selectedLocationGroup') !== undefined) {
          //get locationGroup id and push them into query
          query['locationGroup'] = queryOptions.get('selectedLocationGroup').mapBy('id');
        }

        if (this.get('isBRUser')) {
          if (this.get('lockedCompany') !== undefined) {
            Logger.debug('BR-user (locked to a company): Getting report for all company locations in locationGroup.');
            query['companyId'] = this.get('lockedCompany.id');
          } else {
            Logger.debug('BR-user: Getting locationGroup report.');
          }
        }

        //get to and from dates and parse them to UTC timezone and push them into query
        query['fromDate'] = (0, _moment["default"])(queryOptions.get('fromDate')).format("MM-DD-YYYY HH:mm:ss").toString();
        query['toDate'] = (0, _moment["default"])(queryOptions.get('toDate')).format("MM-DD-YYYY HH:mm:ss").toString();

        query['isEmailDelivery'] = queryOptions.get('isEmailDelivery');
        query['emails'] = queryOptions.get('emails');
        query['vendors'] = queryOptions.get('vendors');
        query['isExport'] = queryOptions.get('isExport');

        //set property to TRUE
        this.controller.set('isLoading', true);
        this.controller.set('showReport', true);
        this.controller.set('summarizeBy', queryOptions.get('summarizeBy.name'));
        this.controller.set('isShowExportButton', false);

        this.store.queryRecord('highLevelInvoicingReport', query).then(function (report) {
          if (query['isExport']) {
            (function () {
              _this.controller.set('showReport', true);
              var endpoint = report.get('outputFileName');
              var _authToken = _this.get('session.data.authenticated.access_token');
              var options = {
                url: endpoint,
                type: 'GET',
                xhrFields: {
                  responseType: 'blob'
                }
              };
              options.beforeSend = function (xhr) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + _authToken);
              };
              options.success = function (result, status, response) {
                //set property to FALSE
                _this.controller.set('isLoading', false);
                _this.controller.set('showReport', true);
                _this.controller.set('isShowExportButton', true);

                if (query['isEmailDelivery']) {
                  _this.get('notify').success(_this.get('intl').t('report.dialog.emailSent'));
                } else {
                  var blob = undefined;
                  if (query['reportOutput'] === _this.get('intl').t('report.searchCriteria.reportOutput.options.csv')) {
                    blob = new Blob([result], { type: "text/csv" });
                  } else if (query['reportOutput'] === _this.get('intl').t('report.searchCriteria.reportOutput.options.pdf')) {
                    blob = new Blob([result], { type: "application/pdf" });
                  }
                  var contentDispositionHeader = response.getResponseHeader('Content-Disposition');
                  var filename = contentDispositionHeader.split(';')[1].trim().split('=')[1];
                  window.saveAs(blob, filename);
                }
              };
              options.error = function () {
                _this.controller.set('isLoading', false);
                _this.controller.set('showReport', true);
                console.log('error');
                if (query['reportOutput'] === _this.get('intl').t('report.searchCriteria.reportOutput.options.csv')) {
                  _this.get('notify').alert(_this.get('intl').t('report.result.errors.csvFileError'), { closeAfter: 5000 });
                } else if (query['reportOutput'] === _this.get('intl').t('report.searchCriteria.reportOutput.options.pdf')) {
                  _this.get('notify').alert(_this.get('intl').t('report.result.errors.pdfFileError'), { closeAfter: 5000 });
                }
              };
              _ember["default"].$.ajax(options);
            })();
          } else {
            _this.controller.set('report', report);
            if (report.get('highLevelInvoicingItems.length') === 0) {
              _this.controller.set('noResults', true);
            } else {
              _this.controller.set('noResults', false);
            }
            //set property to FALSE
            _this.controller.set('isLoading', false);
            _this.controller.set('showReport', true);
          }
        });
      },
      hideReportResults: function hideReportResults() {
        this.controller.set('showReport', false);
      }
    }
  });
});