define('fcs-dashboard/pods/login/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    setupController: function setupController(controller) {
      controller.set('version', null);
    },
    model: function model() {
      var _this = this;

      var path = '/api/v1/versions';
      var _window$location = window.location;
      var protocol = _window$location.protocol;
      var host = _window$location.host;

      var url = protocol + '//' + host + path;
      var options = {
        url: url,
        type: 'GET'
      };
      options.success = function (result) {
        _this.controller.set('version', result);
      };
      options.error = function () {
        console.log('error');
      };
      _ember['default'].$.ajax(options);
    },
    actions: {
      willTransition: function willTransition() {
        window.stop();
        return true;
      }
    }
  });
});