define('fcs-dashboard/pods/gateway/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'fcs-dashboard/mixins/base-component'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _fcsDashboardMixinsBaseComponent) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _fcsDashboardMixinsBaseComponent['default'], {
    afterModel: function afterModel() {
      this._super.apply(this, arguments);
      if (!this.get('isBRUser')) {
        this.transitionTo('');
      }
    },
    model: function model() {
      return this.store.query('gateway', { processorType: 1 });
    },
    actions: {
      willTransition: function willTransition() {
        window.stop();
        return true;
      }
    }
  });
});