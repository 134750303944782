define('fcs-dashboard/pods/menu-custom-fee/model', ['exports', 'ember-data/attr', 'model-fragments/fragment'], function (exports, _emberDataAttr, _modelFragmentsFragment) {
  exports['default'] = _modelFragmentsFragment['default'].extend({
    customFeeId: (0, _emberDataAttr['default'])('number'),
    locationGroupName: (0, _emberDataAttr['default'])('string'),
    value: (0, _emberDataAttr['default'])('number'),
    type: (0, _emberDataAttr['default'])('string'),
    appliesTo: (0, _emberDataAttr['default'])('string'),
    taxable: (0, _emberDataAttr['default'])('boolean', { defaultValue: false }),
    enabled: (0, _emberDataAttr['default'])('boolean', { defaultValue: true }),
    name: (0, _emberDataAttr['default'])('string')
  });
});