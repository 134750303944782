define("fcs-dashboard/mirage/factories/dailyCloseOutClerk", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  _emberCliMirage.faker.locale = 'en_US';

  exports["default"] = _emberCliMirage["default"].Factory.extend({
    name: _emberCliMirage.faker.name.firstName,
    grossCashSales: _emberCliMirage.faker.commerce.price,
    grossCreditSales: _emberCliMirage.faker.commerce.price,
    digitalTips: _emberCliMirage.faker.commerce.price,
    coupon: _emberCliMirage.faker.commerce.price,
    spills: _emberCliMirage.faker.commerce.price,
    comp: _emberCliMirage.faker.commerce.price,
    voids: _emberCliMirage.faker.commerce.price,
    discounts: _emberCliMirage.faker.commerce.price
  });
});
/**
 * Created by anavarro on 3/21/17.
 */