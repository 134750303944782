define('fcs-dashboard/pods/components/gateway-mng/details/component', ['exports', 'ember', 'fcs-dashboard/mixins/base-component', 'fcs-dashboard/mixins/table-component'], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsTableComponent) {
  var Component = _ember['default'].Component;
  var run = _ember['default'].run;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend(_fcsDashboardMixinsBaseComponent['default'], _fcsDashboardMixinsTableComponent['default'], {
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      //initialize processors
      if (this.get('processors').then === undefined) {
        // no promise, lets load the data
        this.set('_processors', this.get('processors'));
      } else {
        // if a promise set the content when it resolves
        this.get('processors').then(function (content) {
          run(function () {
            _this.set('_processors', content);
          });
        });
      }
      //initialize processorTypes
      if (this.get('processorTypes').then === undefined) {
        // no promise, lets load the data
        this.set('_processorType', this.get('processorTypes'));
      } else {
        // if a promise set the content when it resolves
        this.get('processorTypes').then(function (content) {
          run(function () {
            _this.set('_processorTypes', content);
          });
        });
      }
      this.set('gateway.gatewaySubmerchants', this.get('gateway.gatewaySubmerchants').filterBy('isNew', false));
      this._setBackupCreditCardGateway();
    },
    _processors: [],
    _processorTypes: [],
    tagName: '',
    failoverEnabled: false,
    backupCreditCardGateway: null,
    _gatewaysCreditCard: computed(function () {
      return this.get('store').query('gateway', { cashless: false, pageSize: -1 }).then(function (results) {
        var resultsArray = results.toArray();
        return resultsArray.filter(function (gateway) {
          return gateway.get('processorType.id') === '0';
        });
      });
    }),
    showFailover: computed('gateway.processorType.id', function () {
      return this.get('gateway.processorType.id') === '0';
    }),
    showProcessor: computed(function () {
      return this.get('gateway.processor.id') !== undefined;
    }),
    showCredentials: computed(function () {
      return this.get('gateway.processor.id') !== undefined;
    }),
    showSubmerchants: computed('gateway.processor', function () {
      return this.get('gateway.processor.id') === "6";
    }),
    sortCriteria: ['name'],
    processorTypes: computed(function () {
      return this.get('store').findAll('processorType');
    }),
    sortedProcessorTypes: _ember['default'].computed.sort('_processorTypes', 'sortCriteria'),
    processors: computed(function () {
      return this.get('store').findAll('processor');
    }),
    sortedProcessors: computed('_processors.@each', function () {
      if (this.get('showProcessor')) {
        return this.get('_processors').filterBy('processorType.id', this.get('gateway.processorType.id'));
      }
    }),
    environments: computed(function () {
      return this.get('store').findAll('gatewayEnvironment');
    }),
    sortedEnvironments: computed.sort('environments', 'sortCriteria'),
    showDisable: computed(function () {
      return !this.get('gateway.isNew');
    }),
    _setBackupCreditCardGateway: function _setBackupCreditCardGateway() {
      var _this2 = this;

      var backupCreditCardGateway = undefined;
      if (!_ember['default'].isEmpty(this.get('gateway.backupCreditCardGatewayId'))) {
        backupCreditCardGateway = this.get('store').findRecord('gateway', this.get('gateway.backupCreditCardGatewayId'));
      }
      if (backupCreditCardGateway !== undefined && backupCreditCardGateway.then !== undefined) {
        backupCreditCardGateway.then(function (content) {
          run(function () {
            _this2.set('backupCreditCardGateway', content);
          });
        });
      }
    },
    actions: {
      changeSelectedBackupGateway: function changeSelectedBackupGateway(gateway) {
        this.set('backupCreditCardGateway', gateway);
        this.set('gateway.backupCreditCardGatewayId', gateway.id);
      },
      selectProcessorType: function selectProcessorType(processorType) {
        this.set('gateway.processorType', processorType);
        //clean gateway's processor and credentials
        this.set('gateway.processor', undefined);
        this.set('gateway.credentials', []);
        this.set('showCredentials', false);
        //filted processors list to show only those who belongs to the selected processor type
        this.set('sortedProcessors', this.get('_processors').filterBy('processorType.id', processorType.get('id')));
        this.set('showProcessor', true);
      },
      selectProcessor: function selectProcessor(processor) {
        this.set('showCredentials', true);
        this.set('gateway.processor', processor);
        //copy credentials from processor to gateway
        this.set('gateway.credentials', processor.get('processorCredentials').copy());
      },
      addSubmerchant: function addSubmerchant() {
        var newSubmerchant = this.get('store').createRecord('gatewaySubmerchant');
        this.get('gateway.gatewaySubmerchants').addObject(newSubmerchant);
      },
      remove: function remove(subMerchant) {
        var _this3 = this;

        subMerchant.destroyRecord().then(function () {
          _this3.send('cleanMessages');
          _this3.get('notify').success(_this3.get('intl').t('gateways.details.submerchant.errors.deleteSuccess'));
          _this3.get('gateway.gatewaySubmerchants').removeObject(subMerchant);
        })['catch'](function (error) {
          var message = 'gateways.details.submerchant.errors.unknownDeletingError';
          if (error !== undefined) {
            message = 'gateways.details.submerchant.errors.' + error.errors.get(0).detail;
          }
          var errorMessage = _this3.get('notify').alert(_this3.get('intl').t(message, { name: subMerchant.get('accountId') }), { closeAfter: 5000 });
          _this3.get('errorMessages').addObject(errorMessage);
          subMerchant.rollbackAttributes();
        });
      }
    }
  });
});