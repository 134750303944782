define('fcs-dashboard/pods/components/report-export-option/component', ['exports', 'ember', 'fcs-dashboard/mixins/base-component'], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend(_fcsDashboardMixinsBaseComponent['default'], {
    reportOptions: undefined,
    showEmailAdd: false,
    emails: computed.alias('reportOptions.emails'),
    reportOutput: computed.alias('reportOptions.reportOutput'),
    isEmailDelivery: computed.alias('reportOptions.isEmailDelivery'),
    isExport: computed.alias('reportOptions.isExport'),
    actions: {
      cancelAction: function cancelAction() {
        this.sendAction('cancelAction');
      },
      okAction: function okAction() {
        this.send('cleanMessages');
        var isValid = true;
        if (this.get('reportOutput') === undefined || this.get('reportOutput') === null) {
          var errorMessage = this.get('notify').alert(this.get('intl').t('report.dialog.errors.reportOutput'), { closeAfter: 5000 });
          this.get('errorMessages').addObject(errorMessage);
          isValid = false;
        }
        if (this.get('isEmailDelivery')) {
          if (this.get('emails') === '') {
            var errorMessage = this.get('notify').alert(this.get('intl').t('report.dialog.errors.emailAdd'), { closeAfter: 5000 });
            this.get('errorMessages').addObject(errorMessage);
            isValid = false;
          } else {
            if (this.get('emails').indexOf(',') > -1) {
              var cleanEmails = [];
              var validEmail = true;
              var emails = this.get('emails').trim().split(/\s*,\s*/);
              var regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
              for (var x = 0; x < emails.length; x++) {
                if (emails[x] !== "") {
                  if (regex.test(emails[x])) {
                    cleanEmails.push(emails[x]);
                  } else {
                    validEmail = false;
                  }
                }
              }
              if (cleanEmails.length > 5) {
                var errorMessage = this.get('notify').alert(this.get('intl').t('report.dialog.errors.invalidEmailAddNumber'), { closeAfter: 5000 });
                this.get('errorMessages').addObject(errorMessage);
                isValid = false;
              } else {
                if (!validEmail) {
                  var errorMessage = this.get('notify').alert(this.get('intl').t('report.dialog.errors.invalidEmailAdd'), { closeAfter: 5000 });
                  this.get('errorMessages').addObject(errorMessage);
                  isValid = false;
                }
              }
            }
          }
        }
        if (isValid) {
          this.set('isExport', true);
          this.sendAction('okAction', this.get('reportOptions'));
        }
      },
      toggleShowEmailAdd: function toggleShowEmailAdd(choice) {
        if (choice) {
          this.set('showEmailAdd', true);
        } else {
          this.set('showEmailAdd', false);
        }
      }
    }
  });
});