define("fcs-dashboard/pods/components/location-mng/details/component", ["exports", "ember", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var isEqual = _ember["default"].isEqual;
  var run = _ember["default"].run;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], {
    selectedCompany: null,
    selectedType: null,
    locationTypes: [],
    newCompanyError: '',
    saveBtnText: null,
    showCancelBtn: true,
    newCompanyErrorMessage: '',
    _sortCriteria: ['name'],
    sortedLocationTypes: computed.sort('locationTypes', '_sortCriteria'),
    _sortedCompanies: computed.sort('_companies', '_sortCriteria'),
    _companies: computed(function () {
      return this.get('store').query('company', { pageSize: -1 });
    }),
    showCompanies: computed(function () {
      return this.get('location.isNew') && this.get('isBRUser') && this.get('lockedCompany') === undefined;
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      if (this.get('locationTypes').then !== undefined) {
        this.get('locationTypes').then(function (content) {
          run(function () {
            if (_this.get('location.typeId') !== null && _this.get('location.typeId') !== undefined) {
              var type = content.findBy('id', _this.get('location.typeId'));
              _this.set('selectedType', type);
            }
            _this.set('locationTypes', content);
          });
        });
      } else {
        if (this.get('location.typeId') !== null && this.get('location.typeId') !== undefined) {
          var type = this.get('locationTypes').findBy('id', this.get('location.typeId').toString());
          this.set('selectedType', type);
        }
      }
    },
    actions: {
      saveAction: function saveAction() {
        var _this2 = this;

        this.send('cleanMessages');
        if (this.get('location.validations.isValid')) {
          this.get('location').save().then(function () {
            _this2.sendAction('saveAction');
          })["catch"](function (response) {
            var errorMessage = 'location.detail.errors.savingLocation';
            if (response !== undefined && isEqual(response.errors[0].detail, 'duplicateNameError')) {
              errorMessage = "location.detail.errors." + response.errors[0].detail;
            }
            _this2.get('errorMessages').addObject(_this2.get('notify').alert(_this2.get('intl').t(errorMessage), { closeAfter: 5000 }));
          });
        } else {
          this.get('location.validations.errors').forEach(function (error) {
            var errorMessage = _this2.get('notify').alert(error.get('message'), { closeAfter: 5000 });
            _this2.get('errorMessages').addObject(errorMessage);
          });
        }
      },
      cancelAction: function cancelAction() {
        this.get('location').rollbackAttributes();
        this.sendAction('closeModal');
      },
      selectCompany: function selectCompany(company) {
        this.set('selectedCompany', company);
        this.set('location.companyId', company.id);
        this.set('location.companyName', company.name);
      },
      selectType: function selectType(type) {
        this.set('selectedType', type);
        this.set('location.typeId', type.id);
        this.set('location.typeName', type.get('name'));
      }
    }
  });
});