define('fcs-dashboard/pods/simple-report-by-product/serializer', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var guidFor = _ember['default'].guidFor;
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      payload.simpleReportByProducts.forEach(function (simpleReportByProduct) {
        return simpleReportByProduct.id = guidFor(simpleReportByProduct);
      });
      return this._super.apply(this, arguments);
    }
  });
});