define("fcs-dashboard/pods/user/edit/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var Route = _ember["default"].Route;
  exports["default"] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], {
    model: function model(params) {
      return this.store.findRecord('user', params.user_id, { reload: true });
    },
    renderTemplate: function renderTemplate() {
      this.render('user/edit', { into: 'application' });
    },
    actions: {
      willTransition: function willTransition() {
        this.controller.get('model').rollbackAttributes();
      }
    }
  });
});