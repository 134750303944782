define('fcs-dashboard/pods/components/menu/menu-wizard/page-view/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var A = _ember['default'].A;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    items: [],
    sortField: 'position',
    sortCriteria: computed(function () {
      var criteria = A();
      criteria.addObject(this.get('sortField'));
      return criteria;
    }),
    sortedItems: computed.sort('items', 'sortCriteria')
  });
});