define('fcs-dashboard/pods/components/selectable-table/row/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var htmlSafe = _ember['default'].String.htmlSafe;
  var isEqual = _ember['default'].isEqual;
  exports['default'] = Component.extend({
    tagName: '',
    rowSelected: '',
    originalProducts: [],
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get('selections').forEach(function (item) {
        if (item.get('id') === _this.get('item.id')) {
          _this.set('rowSelected', htmlSafe('row-selected'));
        }
      });
      this.get('originalProducts').clear();
      this.get('originalProducts').addObjects(this.get('selections'));
    },
    actions: {
      selectedItem: function selectedItem(item) {
        var _this2 = this;

        var includesItem = false;
        var noNewProducts = true;
        var productToRemove = undefined;

        if (!this.get('originalProducts').any(function (originalProduct) {
          return isEqual(originalProduct.get('id'), item.id);
        })) {

          // Check if the selected item needs to be added or removed from the selections list
          this.get('selections').forEach(function (product) {
            if (isEqual(product.get('id'), item.get('id'))) {
              includesItem = true;
              //if the item needs to be removed, se the product to remove value
              productToRemove = product;
            }
          });

          // Add or remove the selected item
          if (includesItem) {
            this.get('selections').removeObject(productToRemove);
            this.set('rowSelected', htmlSafe(''));
          } else {
            this.get('selections').addObject(item);
            this.set('rowSelected', htmlSafe('row-selected'));
          }

          // Define if the selections list includes new items, and enable or disable the Ok button on the modal
          if (this.get('selections').length > this.get('originalProducts').length) {
            noNewProducts = false;
          } else {
            this.get('selections').forEach(function (product) {
              if (!_this2.get('originalProducts').any(function (originalProduct) {
                return isEqual(originalProduct.get('id'), product.get('id'));
              })) {
                noNewProducts = false;
              }
            });
          }
          this.selectItem(noNewProducts);
        }
      }
    }
  });
});