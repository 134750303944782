define("fcs-dashboard/pods/components/high-level-invoicing-report/report-result/high-level-invoicing-company-row/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/table-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsTableComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var isEmpty = _ember["default"].isEmpty;
  exports["default"] = Component.extend(_fcsDashboardMixinsTableComponent["default"], _fcsDashboardMixinsBaseComponent["default"], {
    data: [],
    _showData: computed('data', function () {
      return this.get('data') !== null && !isEmpty(this.get('data'));
    }),
    _columns: computed(function () {
      return [{
        title: this.get('intl').t('report.result.highLevelInvoicingReport.company'),
        className: 'non-cash-category-cell',
        propertyName: 'companyName',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.highLevelInvoicingReport.creditNetSalesPlusTax'),
        className: 'non-cash-quantity-cell',
        template: 'clerkReport/amountCell',
        propertyName: 'creditSales',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.highLevelInvoicingReport.cashlessNetSalesPlusTax'),
        className: 'non-cash-quantity-cell',
        template: 'clerkReport/amountCell',
        propertyName: 'cashlessSales',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.highLevelInvoicingReport.creditTips'),
        className: 'non-cash-quantity-cell',
        template: 'clerkReport/amountCell',
        propertyName: 'creditTips',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.highLevelInvoicingReport.cashlessTips'),
        className: 'non-cash-quantity-cell',
        template: 'clerkReport/amountCell',
        propertyName: 'cashlessTips',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.highLevelInvoicingReport.digitalTips'),
        className: 'non-cash-quantity-cell',
        template: 'clerkReport/amountCell',
        propertyName: 'digitalTips',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.highLevelInvoicingReport.totalDigitalVolume'),
        className: 'non-cash-quantity-cell',
        template: 'clerkReport/amountCell',
        propertyName: 'totalDigitalVolume',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.highLevelInvoicingReport.tabletCount'),
        className: 'quantity-cell',
        propertyName: 'tabletCount',
        disableSorting: true
      }];
    })
  });
});