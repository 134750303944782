define("fcs-dashboard/pods/tax-rate-type/model", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations"], function (exports, _emberDataModel, _emberDataAttr, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', true)
  });

  exports["default"] = _emberDataModel["default"].extend(Validations, {
    name: (0, _emberDataAttr["default"])('string'),
    disabled: false
  });
});