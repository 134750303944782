define("fcs-dashboard/pods/meta-product-catalog/serializer", ["exports", "ember", "ember-data"], function (exports, _ember, _emberData) {
  var guidFor = _ember["default"].guidFor;
  exports["default"] = _emberData["default"].RESTSerializer.extend(_emberData["default"].EmbeddedRecordsMixin, {
    attrs: {
      attributeSets: { serialize: 'records', deserialize: 'records' },
      productCatalogs: { serialize: 'records', deserialize: 'records' },
      generatedProductCatalogs: { serialize: 'records', deserialize: 'records' },
      productType: { embedded: 'always' }
    },
    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      var serialized = record.serialize(record, options);

      //remove the root element
      _ember["default"].merge(hash, serialized);
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      if (payload.metaProductCatalog !== null && payload.metaProductCatalog !== undefined) {
        payload.metaProductCatalog.generatedProductCatalogs.forEach(function (productCatalog) {
          return productCatalog.id = guidFor(productCatalog);
        });
      }
      return this._super.apply(this, arguments);
    }
  });
});