define("fcs-dashboard/pods/product-group-attribute-set/model", ["exports", "ember", "ember-data/attr", "model-fragments/fragment"], function (exports, _ember, _emberDataAttr, _modelFragmentsFragment) {
  var computed = _ember["default"].computed;
  var A = _ember["default"].A;
  exports["default"] = _modelFragmentsFragment["default"].extend({
    attributeSetId: (0, _emberDataAttr["default"])('string', { defaultValue: 1 }),
    attributeSetName: (0, _emberDataAttr["default"])('string'),
    priority: (0, _emberDataAttr["default"])('number', { defaultValue: 1 }),
    priorities: computed(function () {
      var positions = A();
      for (var i = 1; i < 10; i++) {
        positions.addObject(i);
      }
      return positions;
    })
  });
});