define('fcs-dashboard/mirage/config', ['exports'], function (exports) {
  exports['default'] = function () {
    this.post('/oauth/token', function (db) {
      return db.oauth[0];
    });

    this.post('/revoke', function (db) {
      return db.oauth;
    });

    this.namespace = 'api/v1'; // make this `api`, for example, if your API is namespaced
    this.timing = 400; // delay for each request, automatically set to 0 during testing

    this.get('/companies', function (db) {
      var companies = db.companies;
      return {
        companies: companies
      };
    });
    this.get('/companies/:id', function (db, request) {
      var company = db.companies.find(request.params.id);
      return {
        company: company
      };
    });

    this.get('/userProfiles', function (db) {
      var profiles = db.userProfiles;
      return {
        userProfiles: profiles,
        meta: { total: profiles.length }
      };
    });
    this.get('/userProfiles/:id', function (db, request) {
      var userProfile = db.userProfiles.find(request.params.id);
      return {
        userProfile: userProfile
      };
    });

    this.get('/userTypes', function (db) {
      var types = db.userTypes;
      return {
        userTypes: types
      };
    });
    this.get('/userTypes/:id', function (db, request) {
      var userType = db.userTypes.find(request.params.id);
      return {
        userType: userType
      };
    });

    this.get('/locations', function (db, request) {
      var locations = db.locations;
      if (request.queryParams.company) {
        locations = locations.where({ company: request.queryParams.company });
      }
      return { locations: locations, meta: { total: locations.length } };
    });

    this.get('/locations/:id', function (db, request) {
      var location = db.locations.find(request.params.id);
      return {
        location: location
      };
    });

    this.put('/locations/:id', function (db, request) {
      var id = request.params.id;
      var attrs = JSON.parse(request.requestBody);
      var record = db.locations.update(id, attrs);

      return {
        locations: record
      };
    });
    this.post('/locations', function (db, request) {
      var attrs = JSON.parse(request.requestBody);
      var record = db.locations.insert(attrs);

      return {
        location: record
      };
    });

    this.get('/locationTypes', function (db) {
      var locationTypes = db.locationTypes;
      return { locationTypes: locationTypes, meta: { total: locationTypes.length } };
    });

    this.get('/locationTypes/:id', function (db, request) {
      var locationType = db.locationTypes.find(request.params.id);
      return {
        locationType: locationType
      };
    });

    this.get('/users', function (db, request) {
      var users = db.users;
      if (request.queryParams.username) {
        var user = users.where({ username: request.queryParams.username });
        return {
          user: user[0]
        };
      } else {
        return {
          users: users, meta: { total: users.length }
        };
      }
    });

    this.get('/users/:id', function (db, request) {
      var user = db.users.find(request.params.id);
      return {
        user: user
      };
    });

    this.put('/users/:id', function (db, request) {
      var user = db.users.find(request.params.id);
      return {
        user: user
      };
    });

    this.post('/users', function (db, request) {
      var attrs = JSON.parse(request.requestBody).user;
      var record = db.users.insert(attrs);
      return {
        user: record
      };
    });

    this.get('/menus', function (db) {
      var menus = db.menus;
      return {
        menus: menus, meta: { total: menus.length }
      };
    });
    this.get('/menus/:id', function (db, request) {
      var menu = db.menus.find(request.params.id);
      return {
        menu: menu
      };
    });

    this.put('/menus/:id', function (db, request) {
      var menu = db.menus.find(request.params.id);
      return {
        menu: menu
      };
    });

    this.post('/menus', function (db, request) {
      var attrs = JSON.parse(request.requestBody).menu;
      var record = db.menus.insert(attrs);
      return {
        menu: record
      };
    });

    this.get('/categories', function (db) {
      var categories = db.categories;
      return {
        categories: categories, meta: { total: categories.length }
      };
    });
    this.get('/categories/:id', function (db, request) {
      var category = db.categories.find(request.params.id);
      return {
        category: category
      };
    });

    this.get('/pages', function (db) {
      var pages = db.pages;
      return {
        pages: pages, meta: { total: pages.length }
      };
    });
    this.get('/pages/:id', function (db, request) {
      var page = db.pages.find(request.params.id);
      return {
        page: page
      };
    });

    this.get('/productCatalogs', function (db, request) {
      var productCatalogs = db.productCatalogs;
      var queryParams = request.queryParams;
      var limit = parseInt(queryParams.limit || 10, 10);
      var page = parseInt(queryParams.page || 1, 10);
      var start = limit * (page - 1);
      var end = start + limit;

      var slice = productCatalogs.slice(start, end);
      return {
        productCatalogs: slice, meta: { total: productCatalogs.length, pages: productCatalogs.length / limit }
      };
    });
    this.get('/productCatalogs/:id', function (db, request) {
      var productCatalog = db.productCatalogs.find(request.params.id);
      return {
        productCatalog: productCatalog
      };
    });

    this.post('/productCatalogs', function (db, request) {
      var attrs = JSON.parse(request.requestBody).productCatalog;
      var record = db.productCatalogs.insert(attrs);
      return {
        productCatalog: record
      };
    });

    this.get('/products', function (db) {
      var products = db.products;
      return {
        products: products, meta: { total: products.length }
      };
    });
    this.get('/products/:id', function (db, request) {
      var product = db.products.find(request.params.id);
      return {
        product: product
      };
    });

    this.get('/toppings', function (db) {
      var toppings = db.toppings;
      return {
        toppings: toppings, meta: { total: toppings.length }
      };
    });
    this.get('/toppings/:id', function (db, request) {
      var topping = db.toppings.find(request.params.id);
      return {
        topping: topping
      };
    });

    this.post('/toppings', function (db, request) {
      var attrs = JSON.parse(request.requestBody).topping;
      var record = db.toppings.insert(attrs);
      return {
        topping: record
      };
    });

    this.get('/productTypes', function (db) {
      var productTypes = db.productTypes;
      return {
        productTypes: productTypes, meta: { total: productTypes.length }
      };
    });
    this.get('/productTypes/:id', function (db, request) {
      var productType = db.productTypes.find(request.params.id);
      return {
        productType: productType
      };
    });
    this.post('/productTypes', function (db, request) {
      var attrs = JSON.parse(request.requestBody).productType;
      var record = db.productTypes.insert(attrs);
      return {
        productType: record
      };
    });
    this.put('/productTypes/:id', function (db, request) {
      var id = request.params.id;
      var attrs = JSON.parse(request.requestBody);
      var productType = db.productTypes.update(id, attrs);
      return {
        productType: productType
      };
    });

    this.get('/tenderTypes', function (db) {
      var tenderTypes = db.tenderTypes;
      return {
        tenderTypes: tenderTypes, meta: { total: tenderTypes.length }
      };
    });
    this.get('/tenderTypes/:id', function (db, request) {
      var tenderType = db.tenderTypes.find(request.params.id);
      return {
        tenderType: tenderType
      };
    });

    this.get('/receiptTypes', function (db) {
      var receiptTypes = db.receiptTypes;
      return {
        receiptTypes: receiptTypes, meta: { total: receiptTypes.length }
      };
    });

    this.get('/receiptTypes/:id', function (db, request) {
      var receiptType = db.receiptTypes.find(request.params.id);
      return {
        receiptType: receiptType
      };
    });

    this.get('/orderSendingTypes', function (db) {
      var orderSendingTypes = db.orderSendingTypes;
      return {
        orderSendingTypes: orderSendingTypes, meta: { total: orderSendingTypes.length }
      };
    });
    this.get('/orderSendingTypes/:id', function (db, request) {
      var orderSendingType = db.orderSendingTypes.find(request.params.id);
      return {
        orderSendingType: orderSendingType
      };
    });

    this.get('/validationMethodTypes', function (db) {
      var validationMethodTypes = db.validationMethodTypes;
      return {
        validationMethodTypes: validationMethodTypes, meta: { total: validationMethodTypes.length }
      };
    });
    this.get('/validationMethodTypes/:id', function (db, request) {
      var validationMethodType = db.validationMethodTypes.find(request.params.id);
      return {
        validationMethodType: validationMethodType
      };
    });

    this.get('/tipDisplayTypes', function (db) {
      var tipDisplayTypes = db.tipDisplayTypes;
      return {
        tipDisplayTypes: tipDisplayTypes, meta: { total: tipDisplayTypes.length }
      };
    });
    this.get('/tipDisplayTypes/:id', function (db, request) {
      var tipDisplayType = db.tipDisplayTypes.find(request.params.id);
      return {
        tipDisplayType: tipDisplayType
      };
    });

    this.get('/taxRateTypes', function (db) {
      var types = db.taxRateTypes;
      return {
        taxRateTypes: types
      };
    });
    this.get('/taxRateTypes/:id', function (db, request) {
      var taxRateType = db.taxRateTypes.find(request.params.id);
      return {
        taxRateType: taxRateType
      };
    });

    this.get('/gateways', function (db) {
      var gateways = db.gateways;
      return {
        gateways: gateways
      };
    });
    this.get('/gateways/:id', function (db, request) {
      var gateway = db.gateways.find(request.params.id);
      return {
        gateway: gateway
      };
    });

    this.get('/gatewayEnvironments', function (db) {
      var gatewayEnvironments = db.gatewayEnvironments;
      return {
        gatewayEnvironments: gatewayEnvironments
      };
    });
    this.get('/gatewayEnvironments/:id', function (db, request) {
      var gatewayEnvironment = db.gatewayEnvironments.find(request.params.id);
      return {
        gatewayEnvironment: gatewayEnvironment
      };
    });

    this.get('/processors', function (db) {
      var processors = db.processors;
      return {
        processors: processors
      };
    });
    this.get('/processors/:id', function (db, request) {
      var processor = db.processors.find(request.params.id);
      return {
        processor: processor
      };
    });

    this.get('/processorTypes', function (db) {
      var processorTypes = db.processorTypes;
      return {
        processorTypes: processorTypes
      };
    });
    this.get('/processorTypes/:id', function (db, request) {
      var processorType = db.processorTypes.find(request.params.id);
      return {
        processorType: processorType
      };
    });

    this.get('/roleTypes', function (db) {
      var roles = db.roles;
      return {
        roleTypes: roles
      };
    });
    this.get('/roleTypes/:id', function (db, request) {
      var role = db.roles.find(request.params.id);
      return {
        roleType: role
      };
    });

    this.get('/locationGroupTypes', function (db) {
      var locationGroupTypes = db.locationGroupTypes;
      return {
        locationGroupTypes: locationGroupTypes
      };
    });
    this.get('/locationGroupTypes/:id', function (db, request) {
      var locationGroupType = db.locationGroupTypes.find(request.params.id);
      return {
        locationGroupType: locationGroupType
      };
    });

    this.get('/locationGroups', function (db) {
      var locationGroups = db.locationGroups;
      return {
        locationGroups: locationGroups
      };
    });
    this.get('/locationGroups/:id', function (db, request) {
      var locationGroup = db.locationGroups.find(request.params.id);
      return {
        locationGroup: locationGroup
      };
    });

    this.get('/searchOrders', function (db, request) {
      var qp = request.queryParams;
      var page = parseInt(qp.page);
      var limit = parseInt(qp.limit);
      var start = page * limit;
      var end = start + limit;
      var searchOrders = db.searchOrders;
      var slice = searchOrders.slice(start, end);
      return {
        searchOrders: slice,
        meta: { pages: searchOrders.length / limit, total: searchOrders.length }
      };
    });

    this.get('/orders/:id', function (db, request) {
      var order = db.orders.find(request.params.id);
      return {
        order: order
      };
    });

    this.get('/orderItems/:id', function (db, request) {
      var item = db.items.find(request.params.id);
      return {
        orderItem: item
      };
    });

    this.get('/orderPayments/:id', function (db, request) {
      var payment = db.payments.find(request.params.id);
      return {
        orderPayment: payment
      };
    });

    this.get('/creditCardTypes', function (db) {
      var creditCardTypes = db.creditCardTypes;
      return {
        creditCardTypes: creditCardTypes,
        meta: { total: creditCardTypes.length }
      };
    });

    this.get('/orderStatuses', function (db) {
      var statuses = db.orderStatuses;
      return {
        orderStatuses: statuses,
        meta: { total: statuses.length }
      };
    });

    this.get('/salesReportByProductFilters', function (db) {
      var salesReportByProductFilters = db.salesReportByProductFilters;
      return {
        salesReportByProductFilters: salesReportByProductFilters,
        meta: { total: salesReportByProductFilters.length }
      };
    });

    this.get('/financialOverviewReports', function (db) {
      var financialOverviewReports = db.financialOverviewReports;
      return {
        financialOverviewReports: financialOverviewReports,
        meta: { totalOrders: financialOverviewReports.length, grossSales: 12.22, totalNetSales: 100, avgOrderAmt: 12.8, totalDigitalTips: 12.8, avgTips: 0.66, creditPercentageOfGross: 0.33, rfidPercentageOfGross: 0.012 }
      };
    });

    this.get('/financialReportFilters', function (db) {
      var financialReportFilters = db.financialReportFilters;
      return {
        financialReportFilters: financialReportFilters,
        meta: { total: financialReportFilters.length }
      };
    });

    this.get('/simpleReportByProducts', function (db) {
      var simpleReportByProducts = db.simpleReportByProducts;
      return {
        simpleReportByProducts: simpleReportByProducts
      };
    });

    this.get('/dailySalesSummaries', function (db) {
      var salesReport = db.dailySalesSummaries;
      return {
        salesReport: salesReport
      };
    });

    this.get('/clerkReports', function (db, request) {
      var qp = request.queryParams;
      //todo change this to an enum
      var filter = qp.summarizeBy.toLocaleLowerCase();
      // let from = qp.fromDate;
      // let to = qp.toDate;
      // let locationGroup = qp.locationGroup;
      var clerkReport = db.clerkReports;
      if (filter === '1') {
        clerkReport[0].locations = [];
        clerkReport[0].clerksByLocations = [];
      } else if (filter === '2') {
        clerkReport[0].clerks = [];
        clerkReport[0].clerksByLocations = [];
      } else if (filter === '3') {
        clerkReport[0].clerks = [];
        clerkReport[0].locations = [];
      }
      return {
        clerkReport: clerkReport
      };
    });

    this.get('/dailyCloseOutFilters', function (db) {
      var dailyCloseOutFilters = db.dailyCloseOutFilters;
      return {
        dailyCloseOutFilters: dailyCloseOutFilters,
        meta: { total: dailyCloseOutFilters.length }
      };
    });
  };
});