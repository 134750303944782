define('fcs-dashboard/pods/components/gateway-mng/list/component', ['exports', 'ember', 'fcs-dashboard/mixins/base-component', 'fcs-dashboard/mixins/table-component', 'fcs-dashboard/mixins/gateway-mixin'], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsTableComponent, _fcsDashboardMixinsGatewayMixin) {
  var get = _ember['default'].get;
  var extend = _ember['default'].$.extend;
  exports['default'] = _ember['default'].Component.extend(_fcsDashboardMixinsBaseComponent['default'], _fcsDashboardMixinsTableComponent['default'], _fcsDashboardMixinsGatewayMixin['default'], {
    tagName: '',
    showList: true,
    processorTypeSortCriteria: ['name'],
    processorTypes: _ember['default'].computed(function () {
      return this.get('store').findAll('processorType');
    }),
    sortedProcessorTypes: _ember['default'].computed.sort('processorTypes', 'processorTypeSortCriteria'),
    filteredGateways: [],
    selectedProcessorType: _ember['default'].computed('sortedProcessorTypes', function () {
      if (this.get('sortedProcessorTypes') !== undefined && this.get('sortedProcessorTypes.length') > 0) {
        var selectedProcessorType = this.get('sortedProcessorTypes').objectAt(0);
        this.send('filterGateways', selectedProcessorType);
        return selectedProcessorType;
      }
    }),
    columns: _ember['default'].computed(function () {
      var availableStatuses = this.get('statuses');
      var availableEnvironments = this.get('environments');
      return [{
        title: '',
        template: "gateway/actions/edit-action",
        className: 'editRow'
      }, {
        propertyName: 'name',
        filteredBy: 'name',
        title: this.get('intl').t('gateways.list.name')
      }, {
        propertyName: 'processor.name',
        filteredBy: 'processorName',
        title: this.get('intl').t('gateways.list.processor')
      }, {
        propertyName: 'gatewayEnvironment.name',
        filteredBy: 'gatewayEnvironmentName',
        title: this.get('intl').t('gateways.list.environments.header'),
        filterWithSelect: true,
        predefinedFilterOptions: availableEnvironments,
        className: 'stateRow'
      }, {
        propertyName: 'menuLocations.length',
        filteredBy: 'menuLocationsCount',
        title: this.get('intl').t('gateways.list.menus'),
        className: 'centerContent menusRow'
      }, {
        propertyName: 'translatedStatus',
        filteredBy: 'translatedStatus',
        title: this.get('intl').t('gateways.list.status.header'),
        filterWithSelect: true,
        predefinedFilterOptions: availableStatuses,
        className: 'stateRow'
      }, {
        title: '',
        className: 'editRow'
      }];
    }),
    tableGateways: _ember['default'].computed('gateways', function () {
      return this.get('gateways');
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('showList', false);
      var environments = [];
      var temp = this.get('store').findAll('gatewayEnvironment');
      if (temp.then !== undefined) {
        temp.then(function (content) {
          content.forEach(function (gatewayEnvironment) {
            environments.pushObject(gatewayEnvironment._internalModel._data.name);
          });
          _this.set('environments', environments);
          _this.set('showList', true);
        });
      }
    },
    actions: {
      filterGateways: function filterGateways(processorType) {
        this.set('filteredGateways', this.get('gateways').filterBy('processorType.id', processorType.get('id')));
      },
      selectProcessorType: function selectProcessorType(item) {
        this.set('selectedProcessorType', item);
        this.send('filterGateways', item);
        this.send('updateGateway');
      },
      updateGateway: function updateGateway() {
        var data = this.get('gateways');
        var queryParams = get(data, 'query');
        if (this.get('selectedProcessorType') !== null && this.get('selectedProcessorType') !== undefined) {
          queryParams = extend(queryParams, { processorType: this.get('selectedProcessorType.id') });
        } else {
          delete queryParams.processorType;
        }
        data.set('query', queryParams);
        jQuery('#j-table-filter-button').trigger('click');
      }
    }
  });
});