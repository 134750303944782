define('fcs-dashboard/mirage/fixtures/tipDisplayTypes', ['exports'], function (exports) {
  /**
   * Created by alberto on 01/04/16.
   */
  exports['default'] = [{
    id: 0,
    name: 'Buttons'
  }, {
    id: 1,
    name: 'Entry field'
  }];
});