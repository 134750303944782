define('fcs-dashboard/pods/sponsor-loaded-fund-usage-report/model', ['exports', 'ember-data/model', 'ember-data/attr', 'model-fragments/attributes'], function (exports, _emberDataModel, _emberDataAttr, _modelFragmentsAttributes) {
  exports['default'] = _emberDataModel['default'].extend({
    amountLoaded: (0, _emberDataAttr['default'])('number'),
    wristbandsLoadedCount: (0, _emberDataAttr['default'])('number'),
    amountSpent: (0, _emberDataAttr['default'])('number'),
    paymentCount: (0, _emberDataAttr['default'])('number'),
    sponsorLoadedFundUsageReportItemList: (0, _modelFragmentsAttributes.fragmentArray)('sponsorLoadedFundUsageReport/sponsorLoadedFundUsageReportItem', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    outputFileName: (0, _emberDataAttr['default'])('string', { defaultValue: '' })
  });
});