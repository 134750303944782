define('fcs-dashboard/pods/components/menu/menu-wizard/action-buttons/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    actions: {
      nextStep: function nextStep() {
        this.sendAction('nextStep');
      },
      previousStep: function previousStep() {
        this.sendAction('previousStep');
      },
      showMenuList: function showMenuList() {
        this.sendAction('showMenuList');
      },
      saveAction: function saveAction() {
        this.set('saveEnabled', false);
        this.sendAction('saveAction');
      }
    }
  });
});