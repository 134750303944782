define('fcs-dashboard/initializers/model', ['exports', 'ember-data'], function (exports, _emberData) {
  exports.initialize = initialize;

  function initialize() {
    _emberData['default'].Model.reopen({
      rollbackAttributes: function rollbackAttributes() {
        this._super();
        this.rollbackRelationships();
      },

      rollbackRelationships: function rollbackRelationships() {
        var model = this;
        model.eachRelationship(function (name, meta) {
          if (meta.kind === 'belongsTo') {
            var oldId = model.get(name + 'Id');
            if (oldId) {
              var child = model.get(name);
              if (!(child && child.get('id') === oldId)) {
                var originalRecord = model.store.peekRecord(name, Number(oldId));
                model.set(name, originalRecord);
              }
            }
          }
        });
      },

      // keep a cache of all belongsTo ids before there are any user-initiated changes
      // so we can roll back manually in `rollbackAttributes`
      cacheRelationships: function cacheRelationships() {
        var model = this;
        model.eachRelationship(function (name, meta) {
          if (meta.kind === 'belongsTo') {
            model.get(name).then(function () {
              if (model.get(name + '.id')) {
                model.set(name + 'Id', model.get(name + '.id'));
              }
            });
          }
        });
      },

      ready: function ready() {
        this.cacheRelationships();
      }
    });
  }

  exports['default'] = {
    name: 'model',
    initialize: initialize
  };
});