define('fcs-dashboard/pods/components/location-group/timezone-modal/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var Logger = _ember['default'].Logger;
  exports['default'] = Component.extend({
    timezoneValue: '',
    showModal: false,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      Logger.debug('setting initial timezone from outside ' + this.get('timezone'));
      this.set('timezoneValue', this.get('timezone'));
    }
  });
});