define('fcs-dashboard/pods/components/autocomplete-input/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var isBlank = _ember['default'].isBlank;
  exports['default'] = Component.extend({
    items: [],
    name: '',
    hasResults: true,
    actions: {
      inputKeyUp: function inputKeyUp(keyword) {
        this.set('hasResults', false);
        var filteredData = this.get('items');
        var regex = new RegExp(keyword, "gi");
        if (isBlank(keyword) || keyword.length < 2) {
          this.set('hasResults', false);
          this.set('filteredItems', filteredData.sortBy('name'));
        } else {
          var filteredItems = filteredData.filter(function (item) {
            return item.get('name').match(regex);
          });
          this.set('filteredItems', filteredItems);
          this.set('hasResults', true);
        }
        this.sendAction('sendTraitName', this.get('name'));
      },
      selectItem: function selectItem(selectedItem) {
        this.set('name', selectedItem.get('name'));
        this.set('hasResults', false);
        this.sendAction('selectItem', selectedItem);
      },
      addNewItem: function addNewItem() {
        this.set('hasResults', false);
        this.sendAction('addNewItem', this.get('name'));
      }
    }
  });
});