define("fcs-dashboard/mirage/factories/dailyCloseOutProductTypes", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  _emberCliMirage.faker.locale = 'en_US';

  exports["default"] = _emberCliMirage["default"].Factory.extend({
    name: _emberCliMirage.faker.commerce.product,
    netSales: _emberCliMirage.faker.commerce.price,
    tax: _emberCliMirage.faker.commerce.price,
    netSalesPlusTax: _emberCliMirage.faker.commerce.price,
    voids: _emberCliMirage.faker.commerce.price,
    comps: _emberCliMirage.faker.commerce.price,
    spills: _emberCliMirage.faker.commerce.price,
    declined: _emberCliMirage.faker.commerce.price,
    refund: _emberCliMirage.faker.commerce.price,
    discount: _emberCliMirage.faker.commerce.price,
    coupon: _emberCliMirage.faker.commerce.price,
    grossSales: _emberCliMirage.faker.commerce.price
  });
});
/**
 * Created by anavarro on 3/21/17.
 */