define("fcs-dashboard/pods/components/location-group/custom-fee-modal/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "ember-simple-auth/mixins/authenticated-route-mixin", "moment"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _moment) {
  var Component = _ember["default"].Component;
  var isEmpty = _ember["default"].isEmpty;
  var run = _ember["default"].run;
  var computed = _ember["default"].computed;
  exports["default"] = Component.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], _fcsDashboardMixinsBaseComponent["default"], {
    enableLogs: computed(function () {
      return this.get('customFeeId') !== null && this.get('customFeeId') !== undefined;
    }),
    showCustomFeeLogsModal: false,
    disabled: false,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      //initialize customFeeTypes
      if (this.get('customFeeTypes').then !== undefined) {
        // if a promise set the content when it resolves
        this.get('customFeeTypes').then(function (content) {
          run(function () {
            _this.set('customFeeTypes', content);
          });
        });
      }
      //initialize customFeeAppliesTos
      if (this.get('customFeeAppliesTos').then !== undefined) {
        // if a promise set the content when it resolves
        this.get('customFeeAppliesTos').then(function (content) {
          run(function () {
            _this.set('customFeeAppliesTos', content);
          });
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.set('customFee', null);
    },
    actions: {
      addCustomFee: function addCustomFee(customFee) {
        var valid = true;

        if (isEmpty(customFee.get('name'))) {
          valid = false;
          this.get('notify').alert(this.get('intl').t('locationGroups.details.customFees.modal.errors.emptyName'), { closeAfter: 5000 });
        }

        if (customFee.get('type.id') === null || customFee.get('type.id') === undefined) {
          valid = false;
          this.get('notify').alert(this.get('intl').t('locationGroups.details.customFees.modal.errors.nullType'), { closeAfter: 5000 });
        }

        if (customFee.get('value') === null || customFee.get('value') === undefined || isEmpty(customFee.get('value'))) {
          valid = false;
          this.get('notify').alert(this.get('intl').t('locationGroups.details.customFees.modal.errors.nullValue'), { closeAfter: 5000 });
        }

        if (customFee.get('appliesTo.id') === null || customFee.get('appliesTo.id') === undefined) {
          valid = false;
          this.get('notify').alert(this.get('intl').t('locationGroups.details.customFees.modal.errors.nullAppliesTo'), { closeAfter: 5000 });
        }

        if (valid) {
          this.sendAction('addCustomFee', customFee);
        }
      },
      editCustomFee: function editCustomFee(customFee) {

        var valid = true;

        if (isEmpty(customFee.get('name'))) {
          valid = false;
          this.get('notify').alert(this.get('intl').t('locationGroups.details.customFees.modal.errors.emptyName'), { closeAfter: 5000 });
        }

        if (customFee.get('type.id') === null || customFee.get('type.id') === undefined) {
          valid = false;
          this.get('notify').alert(this.get('intl').t('locationGroups.details.customFees.modal.errors.nullType'), { closeAfter: 5000 });
        }

        if (customFee.get('value') === null || customFee.get('value') === undefined || isEmpty(customFee.get('value'))) {
          valid = false;
          this.get('notify').alert(this.get('intl').t('locationGroups.details.customFees.modal.errors.nullValue'), { closeAfter: 5000 });
        }

        if (customFee.get('appliesTo.id') === null || customFee.get('appliesTo.id') === undefined) {
          valid = false;
          this.get('notify').alert(this.get('intl').t('locationGroups.details.customFees.modal.errors.nullAppliesTo'), { closeAfter: 5000 });
        }

        if (valid) {
          this.sendAction('editCustomFee', customFee);
        }
      },
      cancelCustomFeeModal: function cancelCustomFeeModal() {
        this.sendAction('cancelCustomFeeModal');
      },
      changeCustomFeeType: function changeCustomFeeType(customFeeType) {
        this.set('customFee.value', 0);
        this.set('customFee.type', customFeeType);
      },
      changeCustomPaymentType: function changeCustomPaymentType(paymentType) {
        this.set('customFee.paymentType', paymentType);
      },
      changeCustomFeeAppliesTo: function changeCustomFeeAppliesTo(appliesTo) {
        this.set('customFee.appliesTo', appliesTo);
      },
      openCustomFeeLogModal: function openCustomFeeLogModal() {
        this.set('showCustomFeeLogsModal', true);
      },
      cancelCustomFeeLogModal: function cancelCustomFeeLogModal() {
        this.set('showCustomFeeLogsModal', false);
      }
    }
  });
});