define("fcs-dashboard/pods/components/attribute-set/details/component", ["exports", "ember", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var sort = _ember["default"].computed.sort;
  var A = _ember["default"].A;
  var Logger = _ember["default"].Logger;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], {
    newAttributes: [],
    valid: true,
    attributeSet: undefined,
    positions: [],
    _sortCriteria: ['name'],
    _sortedCompanies: sort('_companies', '_sortCriteria'),
    _companies: computed(function () {
      return this.get('store').query('company', { pageSize: -1 });
    }),
    _showCompanies: computed(function () {
      return this.get('attributeSet.isNew') && this.get('isBRUser') && this.get('lockedCompany') === undefined;
    }),
    generatePositions: function generatePositions() {
      var _this = this;

      this.get('positions').clear();
      // Add positions to options
      var position = 1;
      this.get('newAttributes').forEach(function (attribute) {
        _this.get('positions').addObject(position);
        position++;
      });
    },
    /**
     * Ember's hook method
     */
    init: function init() {
      this._super.apply(this, arguments);
      this.get('newAttributes').addObjects(this.get('attributeSet.attributes'));
      this.generatePositions();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('newAttributes').clear();
    },
    actions: {
      saveAction: function saveAction() {
        if (this.get('newAttributes').filter(function (attribute) {
          return _ember["default"].isEmpty(attribute.get('name')) || _ember["default"].isBlank(attribute.get('name'));
        }).mapBy('name').get('length') > 0) {
          this.get('notify').alert(this.get('intl').t('attributeSet.list.errors.blankAttributeError'), { closeAfter: 5000 });
        } else if (this.get('newAttributes').get('length') > 1) {
          var duplicates = [];
          for (var i = 0; i < this.get('newAttributes').get('length'); i++) {
            for (var j = i + 1; j < this.get('newAttributes').get('length'); j++) {
              if (this.get('newAttributes').get(i).get('name') === this.get('newAttributes').get(j).get('name')) {
                duplicates.push(this.get('newAttributes').get(i));
                break;
              }
            }
          }
          if (duplicates.length > 0) {
            this.get('notify').alert(this.get('intl').t('attributeSet.list.errors.duplicatedAttributeNameError'), { closeAfter: 5000 });
          } else {
            this.set('attributeSet.attributes', this.get('newAttributes'));
            this.sendAction('saveAction', this.get('attributeSet'));
          }
        } else {
          this.set('attributeSet.attributes', this.get('newAttributes'));
          this.sendAction('saveAction', this.get('attributeSet'));
        }
      },
      cancelAction: function cancelAction() {
        this.get('attributeSet.attributes').forEach(function (attribute) {
          attribute.rollbackAttributes();
        });
        this.sendAction('cancelAction', this.get('attributeSet'));
      },
      addAttribute: function addAttribute() {
        var newAttribute = this.get('store').createRecord('attribute');
        newAttribute.set('position', this.get('newAttributes.length') + 1);
        this.get('newAttributes').addObject(newAttribute);
        this.generatePositions();
      },
      removeAttribute: function removeAttribute(attribute) {
        if (attribute.get('id') !== null && attribute.get('id') !== undefined) {
          if (this.get('attributeSet.deletedAttributes') === null || this.get('attributeSet.deletedAttributes') === undefined) {
            this.set('attributeSet.deletedAttributes', A());
          }
          this.get('attributeSet.deletedAttributes').addObject(attribute.get('id'));
        }
        this.get('newAttributes').removeObject(attribute);
        this.generatePositions();
      },
      selectCompany: function selectCompany(company) {
        this.get('attributeSet').set('companyId', company.get('id'));
        this.get('attributeSet').set('companyName', company.get('name'));
        this.set('selectedCompany', company);
      },
      changePositionAction: function changePositionAction(attribute, position) {
        Logger.info('changePosition');
        if (position !== null) {
          var targetAttributeAtPosition = null;
          if (this.get('newAttributes').findBy('position', position) !== null && this.get('newAttributes').findBy('position', position) !== undefined) {
            targetAttributeAtPosition = this.get('newAttributes').findBy('position', position);
          }
          if (targetAttributeAtPosition !== undefined && targetAttributeAtPosition !== null) {
            targetAttributeAtPosition.set('position', attribute.get('position'));
            attribute.set('position', position);
          } else {
            attribute.set('position', position);
          }
        } else {
          attribute.set('position', 0);
        }
      }
    }
  });
});