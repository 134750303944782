define('fcs-dashboard/pods/user-type/model', ['exports', 'ember', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = _emberDataModel['default'].extend({
    type: (0, _emberDataAttr['default'])('string'),
    users: (0, _emberDataRelationships.hasMany)('user'),
    intl: service(),
    translatedType: computed(function () {
      return this.get('intl').t('userType.' + this.get('type'));
    })
  });
});