define('fcs-dashboard/mirage/fixtures/tenderTypes', ['exports'], function (exports) {
  /**
   * Created by alberto on 03/02/16.
   */
  exports['default'] = [{
    id: 0,
    name: 'Cash'
  }, {
    id: 1,
    name: 'Fast cash'
  }, {
    id: 2,
    name: 'Manual Credit Card'
  }, {
    id: 3,
    name: 'Swiped Credit Card'
  }, {
    id: 4,
    name: 'Chipped Credit Card'
  }, {
    id: 5,
    name: 'Cashless'
  }];
});