define('fcs-dashboard/mirage/fixtures/gatewayEnvironments', ['exports'], function (exports) {
  /**
   * Created by alberto on 08/06/16.
   */
  exports['default'] = [{
    id: 0,
    name: 'Live environment'
  }, {
    id: 1,
    name: 'Sandbox environment'
  }];
});