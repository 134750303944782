define("fcs-dashboard/pods/search-order/model", ["exports", "ember-data/model", "ember-data/attr", "ember", "moment"], function (exports, _emberDataModel, _emberDataAttr, _ember, _moment) {
  var computed = _ember["default"].computed;
  var service = _ember["default"].inject.service;
  var tz = _moment["default"].tz;
  exports["default"] = _emberDataModel["default"].extend({
    intl: service(),
    tabletGeneratedId: (0, _emberDataAttr["default"])('string'),
    orderId: (0, _emberDataAttr["default"])('string'),
    amount: (0, _emberDataAttr["default"])('number'),
    status: (0, _emberDataAttr["default"])('string'),
    tabStatus: (0, _emberDataAttr["default"])('string'),
    authCode: (0, _emberDataAttr["default"])('string'),
    date: (0, _emberDataAttr["default"])('date'),
    locationGroupName: (0, _emberDataAttr["default"])('string'),
    //used to send an action to the server, it can be VOID, CHARGE or REFUND
    action: (0, _emberDataAttr["default"])('number'),
    locationGroupTimezone: (0, _emberDataAttr["default"])('string'),
    formattedDate: computed('locationGroupTimezone', 'date', function () {
      var formattedDate = tz(this.get('date'), this.get('locationGroupTimezone')).format();
      return this.get('intl').formatDate(formattedDate, {
        hour: 'numeric',
        minute: 'numeric',
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZoneName: 'short',
        hour12: false
      });
    })
  });
});