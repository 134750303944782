define("fcs-dashboard/pods/clerk-report/model", ["exports", "ember-data/model", "ember-data/attr", "model-fragments/attributes"], function (exports, _emberDataModel, _emberDataAttr, _modelFragmentsAttributes) {
  exports["default"] = _emberDataModel["default"].extend({
    clerks: (0, _modelFragmentsAttributes.fragmentArray)('clerkReport/clerkReportItem', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    locations: (0, _modelFragmentsAttributes.fragmentArray)('clerkReport/clerkReportItem', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    clerksByLocations: (0, _modelFragmentsAttributes.fragmentArray)('clerkReport/locationClerk', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    productTypes: (0, _modelFragmentsAttributes.fragmentArray)('dailyCloseOutReport/productTypes', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    totalSales: (0, _emberDataAttr["default"])('number', { defaultValue: 0 }),
    totalResults: (0, _emberDataAttr["default"])('number', { defaultValue: 0 }),
    totalDigitalTips: (0, _emberDataAttr["default"])('number', { defaultValue: 0 }),
    outputFileName: (0, _emberDataAttr["default"])('string', { defaultValue: '' })
  });
});