define('fcs-dashboard/mirage/factories/searchOrders', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  _emberCliMirage.faker.locale = 'en_US';

  exports['default'] = _emberCliMirage['default'].Factory.extend({
    tabletGeneratedId: _emberCliMirage.faker.random.uuid,
    amount: _emberCliMirage.faker.commerce.price,
    status: 'Approved',
    authCode: _emberCliMirage.faker.random.number,
    date: _emberCliMirage.faker.date.past
  });
});
/**
 * Created by alberto on 08/08/16.
 */