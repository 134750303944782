define("fcs-dashboard/pods/processor/model", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "model-fragments/attributes", "ember-cp-validations"], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships, _modelFragmentsAttributes, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', true)
  });

  exports["default"] = _emberDataModel["default"].extend(Validations, {
    name: (0, _emberDataAttr["default"])('string'),
    processorType: (0, _emberDataRelationships.belongsTo)('processorType'),
    processorCredentials: (0, _modelFragmentsAttributes.fragmentArray)('processorCredential')
  });
});