define('fcs-dashboard/mirage/fixtures/processors', ['exports'], function (exports) {
  /**
   * Created by alberto on 08/06/16.
   */
  exports['default'] = [{
    id: 1,
    name: 'Auth.net',
    processorType: 0,
    credentials: [{
      credentialId: 1,
      name: 'loginID',
      displayName: 'gateways.details.credentials.authNet.loginID',
      type: 1,
      value: ''
    }, {
      credentialId: 2,
      name: 'TransactionKey',
      displayName: 'gateways.details.credentials.authNet.transactionKey',
      type: 1,
      value: ''
    }]
  }, {
    id: 2,
    name: 'MasterCard',
    processorType: 0,
    credentials: [{
      credentialId: 3,
      name: 'Public Key',
      displayName: 'gateways.details.credentials.mastercard.publicKey',
      type: 1,
      value: ''
    }, {
      credentialId: 4,
      name: 'Private Key',
      displayName: 'gateways.details.credentials.mastercard.privateKey',
      type: 1,
      value: ''
    }]
  }, {
    id: 3,
    name: 'PPS',
    processorType: 0,
    credentials: [{
      credentialId: 5,
      name: 'User',
      displayName: 'gateways.details.credentials.pps.user',
      type: 1,
      value: ''
    }, {
      credentialId: 6,
      name: 'Password',
      displayName: 'gateways.details.credentials.pps.password',
      type: 1,
      value: ''
    }, {
      credentialId: 7,
      name: 'Merchant ID',
      displayName: 'gateways.details.credentials.pps.merchantId',
      type: 1,
      value: ''
    }]
  }, {
    id: 4,
    name: 'BrainTree',
    processorType: 0,
    credentials: [{
      credentialId: 8,
      name: 'Merchant ID',
      displayName: 'gateways.details.credentials.brainTree.merchantId',
      type: 1,
      value: ''
    }, {
      credentialId: 9,
      name: 'Sub-Merchant ID',
      displayName: 'gateways.details.credentials.brainTree.subMerchantId',
      type: 1,
      value: ''
    }, {
      credentialId: 10,
      name: 'Public Key',
      displayName: 'gateways.details.credentials.brainTree.publicKey',
      type: 1,
      value: ''
    }, {
      credentialId: 11,
      name: 'Private Key',
      displayName: 'gateways.details.credentials.brainTree.privateKey',
      type: 1,
      value: ''
    }]
  }, {
    id: 5,
    name: 'FGT',
    processorType: 1,
    credentials: [{
      credentialId: 12,
      name: 'API Key',
      displayName: 'gateways.details.credentials.fgt.apiKey',
      type: 1,
      value: ''
    }]
  }, {
    id: 6,
    name: 'EventBright',
    processorType: 1,
    credentials: [{
      credentialId: 13,
      name: 'Event Key',
      displayName: 'gateways.details.credentials.eventBright.eventKey',
      type: 1,
      value: ''
    }, {
      credentialId: 14,
      name: 'Token',
      displayName: 'gateways.details.credentials.eventBright.token',
      type: 1,
      value: ''
    }, {
      credentialId: 15,
      name: 'Gateway',
      displayName: 'gateways.details.credentials.eventBright.gateway',
      type: 0,
      value: ''
    }]
  }, {
    id: 7,
    name: 'FCS',
    processorType: 1,
    credentials: [{
      credentialId: 16,
      name: 'Gateway',
      displayName: 'gateways.details.credentials.fcs.gateway',
      type: 0,
      value: ''
    }]
  }];
});