define('fcs-dashboard/pods/menu-product-type/model', ['exports', 'ember', 'ember-data/attr', 'model-fragments/fragment'], function (exports, _ember, _emberDataAttr, _modelFragmentsFragment) {
  exports['default'] = _modelFragmentsFragment['default'].extend({
    productType: (0, _emberDataAttr['default'])('string'),
    taxRate: (0, _emberDataAttr['default'])('string', { defaultValue: 0 }),
    taxNotSet: (0, _emberDataAttr['default'])('boolean', { defaultValue: true }),
    productTypeObject: _ember['default'].computed('productType', function () {
      if (this.get('productType') !== undefined) {
        return this.store.findRecord('productType', this.get('productType'));
      }
    })
  });
});