define('fcs-dashboard/mirage/factories/payment', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  _emberCliMirage.faker.locale = 'en_US';

  exports['default'] = _emberCliMirage['default'].Factory.extend({
    tenderType: 'Manual Credit Card',
    paymentType: 'Charge',
    amount: _emberCliMirage.faker.commerce.price,
    authCode: _emberCliMirage.faker.random.number,
    paymentDate: _emberCliMirage.faker.date.past,
    uid: _emberCliMirage.faker.random.number,
    creditCardType: 'Visa',
    lastFour: _emberCliMirage.faker.finance.mask(4, false, false),
    entryMethod: 'Manual',
    status: 'Approved'
  });
});
/**
 * Created by alberto on 09/08/16.
 */