define("fcs-dashboard/pods/components/search/order-details/payment-detail/component", ["exports", "ember", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var Component = _ember["default"].Component;
  var isEqual = _ember["default"].isEqual;
  var computed = _ember["default"].computed;
  var Logger = _ember["default"].Logger;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], {
    message: '',
    _showVoidAction: computed('payment.status', function () {
      return isEqual(this.get('payment.status'), 'APPROVED');
    }),
    _showChargeAction: computed('payment.status', function () {
      return this.get('payment.isCashless') || isEqual(this.get('payment.status'), 'VOIDED');
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      Logger.debug('didReceiveAttrs');
    },
    actions: {
      orderAction: function orderAction(actionType) {
        if (isEqual("CHARGE", actionType)) {
          this.set('message', this.get('intl').t('search.details.modal.chargingOrder.label'));
        } else if (isEqual("VOID", actionType)) {
          this.set('message', this.get('intl').t('search.details.modal.voidingOrder.label'));
        }
        this.set('_waitingResponse', true);
        this.sendAction('orderAction', actionType, this.get('order'), this.get('payment'));
      }
    }
  });
});